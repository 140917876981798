import React, { ReactElement, useEffect, useState } from "react"
import { apiGetReturningParcels } from "services/parcels"
import { PageWrapper } from "components/Layout/Structure"
import Title from "components/Title"
import { FilterChip, FiltersContainer } from "components/Chips/FilterChip"
import ParcelsList from "components/Parcels/ParcelsList"
import { ParcelStatus } from "types/parcel.types"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "types/reduxState.types"

export function Returning(): ReactElement {
  const [filter, setFilter] = useState("ALL")
  const dispatch = useDispatch()
  const returning = useSelector((state: ReduxState) => state.parcels.returning)

  useEffect(() => {
    dispatch(apiGetReturningParcels())
  }, [])

  const data = {
    RETURN_REQUESTED: returning.data.filter(({ status }) => status === ParcelStatus.RETURN_REQUESTED),
    RETURNING: returning.data.filter(({ status }) => status === ParcelStatus.RETURNING),
  }

  const _data = filter === "ALL" ? returning.data : data[filter]

  return (
    <PageWrapper>
      <Title>Retours</Title>

      <FiltersContainer>
        <FilterChip text={"Tous"} active={filter === "ALL"} onClick={() => setFilter("ALL")} />

        <FilterChip
          text={"Demandé"}
          active={filter === "RETURN_REQUESTED"}
          onClick={() => setFilter("RETURN_REQUESTED")}
          loading={returning.loading}
          number={data.RETURN_REQUESTED.length}
        />

        <FilterChip
          text={"En cours"}
          active={filter === "RETURNING"}
          onClick={() => setFilter("RETURNING")}
          loading={returning.loading}
          number={data.RETURNING.length}
        />
      </FiltersContainer>

      <ParcelsList parcels={_data} loading={_data.loading} withFilters={false} />
    </PageWrapper>
  )
}

export default Returning
