import React, { ReactElement } from "react"

import { AddressAvailability, AvailabilitySlotType } from "types/agenda.types"
import {
  AvailabilitySelectorRow,
  AvailabilitySelectorSpacer,
  AvailabilitySelectorWrapper,
} from "./DailyAvailabilitiesSelector.styled"
import { AvailabilitySlot } from "../AvailabilitySlot/AvailabilitySlot"

interface AvailabilitySelectorProps {
  dayAvailabilities: AddressAvailability[]
}

export function DailyAvailabilitiesSelector({ dayAvailabilities }: AvailabilitySelectorProps): ReactElement {
  return (
    <AvailabilitySelectorWrapper>
      <AvailabilitySelectorRow>
        <AvailabilitySlot
          slotType={AvailabilitySlotType.MORNING}
          availability={dayAvailabilities?.find(({ startHour }) => startHour === 9)}
        />
        <AvailabilitySelectorSpacer />
        <AvailabilitySlot
          slotType={AvailabilitySlotType.NOON}
          availability={dayAvailabilities?.find(({ startHour }) => startHour === 12)}
        />
        <AvailabilitySelectorSpacer />
        <AvailabilitySlot
          slotType={AvailabilitySlotType.AFTERNOON}
          availability={dayAvailabilities?.find(({ startHour }) => startHour === 15)}
        />
        <AvailabilitySelectorSpacer />
        <AvailabilitySlot
          slotType={AvailabilitySlotType.EVENING}
          availability={dayAvailabilities?.find(({ startHour }) => startHour === 18)}
        />
      </AvailabilitySelectorRow>
    </AvailabilitySelectorWrapper>
  )
}
