export enum CompetitorsName {
  MONDIAL_RELAY = "Mondial Relay",
  PICKUP = "Pickup",
  RELAIS_COLIS = "Relais Colis",
  LA_POSTE = "Bureau de Poste",
}

export interface CompetitorPickupPoints {
  id: string
  name: string
  competitorNetworkId: string
  cityName: string
  formattedLineAddress: string
  zipcode: string
  latitude: number
  longitude: number
  numero: number
  createdAt: string
  updatedAt: string
  competitorNetwork: {
    name: string
    id: string
  }
}
