import styled from "styled-components"

import { COLOR } from "utils/color"

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TableStats = styled.div`
  font-size: 10px;
  color: ${COLOR.LIGHT_GREY};
`

export const ParcelsListContainer = styled.div`
  padding: 10px 0;
`
export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
`

export const ButtonContainer = styled.div`
  margin-top: 10px;
`
