import React, { ReactElement } from "react"
import styled from "styled-components"

import { COLOR } from "utils/color"

const EnvDisclaimerContainer = styled.div(
  ({ color }) => `
    width: 100%;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${color};
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10px;
    color: ${COLOR.WHITE};
    font-weight: 600;
    height: 17px;
  `,
)

interface TestEnvironmentDisclaimerProps {
  environment: string
}

const TestEnvironmentDisclaimer = ({ environment }: TestEnvironmentDisclaimerProps): ReactElement => {
  const getColor = (environment: string) => {
    switch (environment) {
      case "development":
        return COLOR.TOMATO
      case "testing":
        return COLOR.MEDIUM_BLUE
      case "staging":
        return COLOR.YELLOW
      default:
        return "transparent"
    }
  }

  return <EnvDisclaimerContainer color={getColor(environment)}>{environment.toUpperCase()}</EnvDisclaimerContainer>
}

export default TestEnvironmentDisclaimer
