import { LOGOUT_USER, AUTH_USER } from "actions/users"
import { createReducer } from "@reduxjs/toolkit"
import { toFailure, toSuccess } from "actions"
import { AdminUserRole } from "types/admin-user.types"

export interface AuthProps {
  error: boolean
  isLogged: boolean
  loading: boolean
  passwordRecoverySuccess: boolean
  token: string
  role: AdminUserRole
}

const initialState: AuthProps = {
  loading: false,
  error: false,
  token: null,
  role: null,
  isLogged: false,
  passwordRecoverySuccess: false,
}

const auth = createReducer(initialState, {
  /* Authentification */
  [AUTH_USER]: state => {
    state.loading = true
    state.error = false
  },
  [toSuccess(AUTH_USER)]: (state, action) => {
    state.loading = false
    state.error = false

    state.token = action.payload.data.token
    state.role = action.payload.data.user.role
    state.isLogged = true
  },
  [toFailure(AUTH_USER)]: (state, action) => {
    state.loading = false
    state.error = action.error.response

    state.isLogged = false
  },
  // [toSuccess(API_RESET_PASSWORD)]: (state, action) => {
  //   state.passwordRecoverySuccess = true
  // },

  [LOGOUT_USER]: state => {
    state.loading = false
    state.error = false
    state.token = null
    state.role = null
    state.isLogged = false
  },
})

export default auth
