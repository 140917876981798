import { RESET_SPONSORSHIP, API_GET_KEEPER_SPONSORSHIP } from "actions/sponsorship"
import { SponsorshipKeeper } from "types/sponsorship.types"
import { FctServiceReturn } from "./services.types"

export function apiGetKeeperSponsorship(
  keeperId: string,
  first: number,
  after?: string,
): FctServiceReturn<SponsorshipKeeper> {
  return {
    type: API_GET_KEEPER_SPONSORSHIP,
    payload: {
      request: {
        url: `/admin/keepers/${keeperId}/sponsorship?first=${first}&after=${after || ""}`,
      },
    },
  }
}

export function resetSponsorship(): { type: string } {
  return {
    type: RESET_SPONSORSHIP,
  }
}
