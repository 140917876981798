import styled from "styled-components"
import { COLOR } from "utils/color"

export const AppointmentContainer = styled.div`
  width: 50%;
  margin-right: 1em;
`

export const AppointmentTitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 20px;
  padding: 2px;
`

export const AppointmentEmoji = styled.div`
  font-size: 15px;
  width: 25px;
  height: 25px;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AppointmentTitle = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.9em;
  font-weight: 600;
  color: ${COLOR.DARK_GREY};
`

export const AppointmentContentBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0 5px 5px;
  font-size: 11px;
`

export const AppointmentButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`

export const ModalAppointmentContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalAppointmentErrorMessage = styled.p`
  color: red;
`
