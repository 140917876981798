import React, { ReactElement } from "react"
import styled from "styled-components"

interface PageTitleProps {
  children: ReactElement | string
}

const Text = styled.p``

const PageDescriptionHeader = ({ children }: PageTitleProps): ReactElement => {
  return <Text>{children}</Text>
}

export default PageDescriptionHeader
