import React, { ReactElement } from "react"

interface EmojiProps {
  label: string
  children: React.ReactNode
  style?: object
}

export const Emoji = ({ label, children, style }: EmojiProps): ReactElement => (
  <span role="img" aria-label={label} style={style}>
    {children}
  </span>
)
