/**
 * Based on the description of the CARRIER_DELIVERY_FAILURE events (KEEPER_OK / OPSFILTERS_OK...), generate a human readable message.
 *
 * @param description - Description of the event
 *
 * @returns Readable message. If the description doesn't contain data related to ops filters or keeper's availabilities, returns nothing
 */
export function getKeeperOkKoDetails(description: string): string {
  if (description?.includes("KEEPER_OK, OPSFILTERS_OK")) {
    return "Keeper déclaré dispo 🟢 | Livraison entre 9h et 15h 🟢"
  } else if (description?.includes("KEEPER_KO, OPSFILTERS_OK")) {
    return "Keeper déclaré dispo 🔴 | Livraison entre 9h et 15h 🟢"
  } else if (description?.includes("KEEPER_OK, OPSFILTERS_KO")) {
    return "Keeper déclaré dispo 🟢 | Livraison entre 9h et 15h 🔴"
  } else if (description?.includes("KEEPER_KO, OPSFILTERS_KO")) {
    return "Keeper déclaré dispo 🔴 | Livraison entre 9h et 15h 🔴"
  }

  return ""
}
