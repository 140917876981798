import React, { ReactElement } from "react"
import styled from "styled-components"

import { COLOR } from "utils/color"

export const FiltersContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 1em;
`

const FilterChipContainer = styled.div<{ active?: boolean }>`
  padding: 6px 12px;
  margin-right: 5px;
  border-radius: 25px;
  cursor: pointer;
  color: ${({ active }) => (active ? COLOR.MEDIUM_GREY : COLOR.LIGHT_GREY)};
  background-color: ${({ active }) => (active ? "#ececec" : "transparent")};
  font-size: 11px;
  position: relative;
  text-align: center;
`

const FilterChipNumber = styled.div<{ nb?: number }>`
  display: inline-block;
  margin-top: 4px;
  text-align: center;
  padding: 0 5px;
  border-radius: 10px;
  background-color: ${({ nb }) => (nb === 0 ? COLOR.BRIGHT_MEDIUM_GREEN : COLOR.RED)};
  color: ${COLOR.WHITE};
  font-weight: 600;
`

interface FilterChipProps {
  text: string
  onClick: () => void
  active: boolean
  loading?: boolean
  number?: number
}

export function FilterChip({ text, onClick, active, loading, number }: FilterChipProps): ReactElement {
  return (
    <FilterChipContainer active={active} onClick={onClick}>
      {text}
      <br />
      {number !== undefined && !loading && <FilterChipNumber nb={number}>{number}</FilterChipNumber>}
      {loading && <FilterChipNumber nb={0}>...</FilterChipNumber>}
    </FilterChipContainer>
  )
}
