import React, { ReactElement, useState, useEffect } from "react"
import styled from "styled-components"
import StatsLine from "components/Stats/StatsLine"
import CardWalletPayment from "components/CardWalletPayment"
import { CardWallet, Container } from "components/CardWallet"
import { WalletPacman } from "types/wallet.types"
import { GetKeeperQuery } from "types/keeper.types"
import { useLazyQuery } from "@apollo/client"

const Card = styled.div`
  width: 35%;
  margin-right: 10px;
`

interface CardWalletProps {
  keeperId: string
  wallet: WalletPacman
  refetchKeeperData: ReturnType<typeof useLazyQuery<GetKeeperQuery>>[1]["refetch"]
  askKeeperWalletTransfer: () => void
  askKeeperWalletDonation: (associationId: string) => void
  askKeeperWalletGiveaway: () => void
  apiGetKeeperWalletDetails: (walletId: string, take: number, skip: number) => void
  loadingDetails: boolean
  noMoreDetails: boolean
  balanceDetails: WalletPacman["balanceDetails"]
}

const PAGINATION_TAKE_DEFAULT = 10

export default function KeeperWallet({
  keeperId,
  wallet,
  refetchKeeperData,
  askKeeperWalletTransfer,
  askKeeperWalletDonation,
  askKeeperWalletGiveaway,
  apiGetKeeperWalletDetails,
  loadingDetails,
  noMoreDetails,
  balanceDetails,
}: CardWalletProps): ReactElement {
  const bankAccount = wallet?.bankAccount
  const [pagination, setPagination] = useState({
    take: PAGINATION_TAKE_DEFAULT,
    skip: PAGINATION_TAKE_DEFAULT,
  })

  useEffect(() => {
    apiGetKeeperWalletDetails(wallet.id, pagination.take, 0)
  }, [])

  if (wallet === null) return <div className="no-content">Ce keeper n&apos;a pas de portefeuille</div>

  const currentBalance = wallet?.balance / 100

  // const totalPaid = wallet.balanceDetails.reduce((acc, detail) => {
  //   if (detail.entity === WalletDetailEntity.WALLET_PAYMENT) {
  //     return acc + detail.amount
  //   }
  //   return acc
  // }, 0)

  const loadMore = () => {
    apiGetKeeperWalletDetails(wallet.id, pagination.take, pagination.skip)
    setPagination({
      take: pagination.take,
      skip: pagination.skip + PAGINATION_TAKE_DEFAULT,
    })
  }

  return (
    <div>
      <StatsLine>
        <Card>
          <Container>
            <CardWalletPayment
              keeperId={keeperId}
              refetchKeeperData={refetchKeeperData}
              askKeeperWalletTransfer={askKeeperWalletTransfer}
              askKeeperWalletDonation={askKeeperWalletDonation}
              askKeeperWalletGiveaway={askKeeperWalletGiveaway}
              bankAccount={bankAccount}
              balance={currentBalance}
            />
          </Container>
        </Card>
        <Card>
          <CardWallet
            balance={wallet?.balance}
            content={balanceDetails}
            onPress={() => loadMore()}
            loading={false}
            loadingDetails={loadingDetails}
            canLoadMore={true}
            noMoreDetails={noMoreDetails}
          />
        </Card>
      </StatsLine>

      {/* <StatsLine>
        <CardWallet
          balance={0}
          content={[]}
          onPress={() => {}}
          loading={false}
          loadingDetails={false}
          canLoadMore={false}
        />
      </StatsLine> */}
    </div>
  )
}
