import styled from "styled-components"

import { COLOR } from "utils/color"

export const HorizontalSelector = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`

export const HorizontalSelectorChip = styled.div<{ selected: boolean }>(
  ({ selected }) => `
  background-color: ${selected ? COLOR.SUPER_LIGHT_BLUE : COLOR.SUPER_LIGHT_GREY};
  color: ${selected ? COLOR.MEDIUM_DARK_BLUE : COLOR.GREY};
  padding: 5px 10px;
  height: 28px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${selected ? COLOR.SUPER_LIGHT_BLUE : COLOR.SUPER_LIGHT_GREY};
  }
`,
)
