import { API_GET_REASSIGNMENT_LOGS } from "actions/reassignment-logs"
import { toFailure, toSuccess } from "actions"
import { createReducer } from "@reduxjs/toolkit"
import { RESET_REASSIGNMENT_LOGS } from "actions/reassignment-logs"
import { uniqBy } from "lodash"

const initialState = {
  list: {
    loading: false,
    error: false,
    data: [],
    pageInfos: {},
  },
}

const reassignmentLogs = createReducer(initialState, {
  [RESET_REASSIGNMENT_LOGS]: state => {
    state.list.data = []
  },
  [API_GET_REASSIGNMENT_LOGS]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_REASSIGNMENT_LOGS)]: (state, action) => {
    state.list.loading = false
    state.list.error = false

    if (action.payload.data.edges && action.payload.data.totalCount > 0) {
      state.list.data = uniqBy([...state.list.data, ...action.payload.data.edges], "cursor")

      state.list.pageInfos = action.payload.data.pageInfos
    } else {
      state.list.data = action.payload.data.edges
    }
  },
  [toFailure(API_GET_REASSIGNMENT_LOGS)]: state => {
    state.list.loading = false
    state.list.error = true
  },
})

export default reassignmentLogs
