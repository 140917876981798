import React, { Fragment, ReactElement, useRef } from "react"
import { AddressAvailability } from "types/agenda.types"
import { AgendaSelector } from "./AgendaSelector/AgendaSelector"

interface AgendaProps {
  agendaList: AddressAvailability[]
}

export function Agenda({ agendaList }: AgendaProps): ReactElement {
  const agendaSelectorRef = useRef()

  return (
    <Fragment>
      <AgendaSelector
        ref={agendaSelectorRef}
        agenda={agendaList}
        onAvailabilitiesUpdated={() => true}
        // onInvalidAvailibilityPressed={() => true}
      />
    </Fragment>
  )
}
