import { toFailure, toSuccess } from "actions"
import { API_GET_TRACKING, RESET_TRACKING } from "actions/system"
import { createReducer } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

const initialState = {
  focus: {
    loading: false,
    error: false,
    data: null,
  },
}

const tracking = createReducer(initialState, {
  [API_GET_TRACKING]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_GET_TRACKING)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
  },
  [toFailure(API_GET_TRACKING)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },
  [RESET_TRACKING]: state => {
    state.focus.data = null
  },
})

export default tracking
