import React, { ReactElement } from "react"
import { Formik, Field } from "formik"
import { Form as SemForm } from "semantic-ui-react"

import { LoginBoxForm, LoginBoxTop } from "components/Login/LoginForm.styled"
import LoginConnectButton from "components/Login/LoginConnectButton"
import { FormError } from "components/Form/FormError"

interface FormError {
  email?: string
  password?: string
}

const validate = values => {
  const errors: FormError = {}
  if (!values.email) {
    errors.email = "Email requis"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Email invalide"
  }
  return errors
}

const CustomInputTextComponent = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <SemForm.Input {...field} {...props} fluid placeholder={"Email"} style={{ marginBottom: 20 }} />
    {touched[field.name] && errors[field.name] && <FormError>{errors[field.name]}</FormError>}
  </div>
)

const CustomInputPasswordComponent = ({ field, form: { touched, errors } }) => (
  <div>
    <SemForm.Input {...field} fluid placeholder={"Mot de passe"} type="password" style={{ marginBottom: 20 }} />
    {touched[field.name] && errors[field.name] && <FormError>{errors[field.name]}</FormError>}
  </div>
)

const LoginForm = ({
  submitAuth,
  authError,
  loading,
}: {
  submitAuth: (values) => void
  authError: boolean
  loading: boolean
}): ReactElement => (
  <Formik
    initialValues={{ email: "", password: "" }}
    validate={values => validate(values)}
    onSubmit={values => submitAuth(values)}
  >
    {() => (
      <LoginBoxForm>
        <LoginBoxTop>
          <Field type="email" name="email" component={CustomInputTextComponent} />
          <Field type="password" name="password" component={CustomInputPasswordComponent} />

          {authError && <FormError>Erreur d&apos;authentification</FormError>}

          <LoginConnectButton text="Connexion" isSubmitting={loading} disabled={false} />
        </LoginBoxTop>
      </LoginBoxForm>
    )}
  </Formik>
)

export default LoginForm
