import React, { ReactElement } from "react"
import { Checkbox } from "semantic-ui-react"

interface RatingSortProps {
  onlyLowRating: boolean
  handleOnlyLowRating: () => void
}

const RatingSort = ({ onlyLowRating, handleOnlyLowRating }: RatingSortProps): ReactElement => (
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
    Afficher seulement les notes en dessous de 7
    <div style={{ marginTop: 5, marginLeft: 10 }}>
      <Checkbox checked={onlyLowRating} onClick={handleOnlyLowRating} />
    </div>
  </div>
)

export default RatingSort
