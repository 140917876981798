import React, { ReactElement, Fragment, useState } from "react"
import { Link } from "react-router-dom"
import { Popup } from "semantic-ui-react"

import { CopyIcon } from "components/Icons/CopyIcon"
import AddressInfosBlock from "components/Address/AddressInfosBlock"
import EmojiBackground from "components/EmojiBackground"
import ParcelKeeperSkeleton from "components/Parcels/ParcelSkeleton/ParcelKeeperSkeleton"
import AgendaAvailabilities from "components/Keeper/Calendar/AgendaAvailabilities"
import PreviousAddressStatusShip from "components/Keeper/Address/status/PreviousAddressStatusShip"
import KeeperScoring from "components/KeeperScoring/KeeperScoring"
import { LiveStatsFlag, NbParcelsContainer } from "components/KeeperScoring/KeeperScoring.styled"
import { ParcelKeeper as ParcelKeeperQuery } from "types/parcel.types"
import {
  ParcelKeeperContainer,
  ParcelKeeperIconInfo,
  ParcelKeeperName,
  ParcelKeeperTitle,
} from "components/Parcels/ParcelInfos.styled"

interface ParcelKeeperProps {
  parcelKeeper: ParcelKeeperQuery
  loading: boolean
}

const ParcelKeeper = ({ parcelKeeper, loading }: ParcelKeeperProps): ReactElement => {
  const [isFullKeeperDataCopied, setFullKeeperDataCopied] = useState(false)
  const [isPhoneCopied, setPhoneCopied] = useState(false)
  const [isEmailCopied, setEmailCopied] = useState(false)
  const [isKeeperAddressCopied, setKeeperAddressCopied] = useState(false)

  const _copyFullKeeperData = (keeper, address) => {
    setFullKeeperDataCopied(true)

    // Basic Keeper data formatted
    const keeperName = `${keeper.firstName} ${keeper.lastName}`
    const formattedAddress = `${address.streetLine} ${address.zipcode} ${address.city}`
    const addressInstructions = `${address.building !== null ? address.building + "," : ""}${
      address.floor !== null ? "Étage" + address.floor + "," : ""
    }${address.digicode !== null ? address.digicode + "," : ""}${
      address.instructions !== null ? address.instructions : ""
    }`

    const formattedBasicKeeperInfos = `${keeperName}\n${formattedAddress}\n${addressInstructions}\n${keeper.phone}`

    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(formattedBasicKeeperInfos)

    setTimeout(() => {
      setFullKeeperDataCopied(false)
    }, 1000)
  }

  const _copyPhone = value => {
    setPhoneCopied(true)
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(value)

    setTimeout(() => {
      setPhoneCopied(false)
    }, 1000)
  }

  const _copyEmail = value => {
    setEmailCopied(true)
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(value)

    setTimeout(() => {
      setEmailCopied(false)
    }, 1000)
  }

  const _copyKeeperAddress = value => {
    setKeeperAddressCopied(true)
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(value)

    setTimeout(() => {
      setKeeperAddressCopied(false)
    }, 1000)
  }

  if (loading || !parcelKeeper) {
    return <ParcelKeeperSkeleton />
  }

  const { keeper, keeperStats, keeperScoring } = parcelKeeper

  const address = keeper?.address
  const agenda = address?.agenda

  return (
    <Fragment>
      <ParcelKeeperContainer>
        <ParcelKeeperTitle>
          👤 Keeper{" "}
          <Popup
            content={isFullKeeperDataCopied ? "Copié ✅" : `Copier les infos du Keeper`}
            size="small"
            position="bottom center"
            trigger={
              <div style={{ height: "100%" }}>
                <CopyIcon name="copy outline" onClick={() => _copyFullKeeperData(keeper, address)} />
              </div>
            }
          />
        </ParcelKeeperTitle>

        <ParcelKeeperName>
          {keeper?.firstName} {keeper?.lastName}
        </ParcelKeeperName>

        {/* style */}
        <div>
          <NbParcelsContainer marginBottom={false}>
            {keeperStats?.nbTotalDeliveredParcels} colis livrés
          </NbParcelsContainer>
          <LiveStatsFlag>
            ({keeperStats?.nbCurrentSleepingParcels} dormants, {keeperStats?.nbCurrentUnscannedParcels} non scannés)
          </LiveStatsFlag>
          <KeeperScoring keeperScoring={keeperScoring} />
        </div>

        <ParcelKeeperIconInfo>
          <Popup
            content={isPhoneCopied ? "Copié ✅" : keeper?.phone}
            size="small"
            position="bottom center"
            trigger={
              <span style={{ marginRight: 10, cursor: "pointer" }} onClick={() => _copyPhone(keeper?.phone)}>
                <EmojiBackground emoji="📞" smallIcon />
              </span>
            }
          />
          <Popup
            content={isEmailCopied ? "Copié ✅" : keeper?.email}
            size="small"
            position="bottom center"
            trigger={
              <span style={{ marginRight: 10, cursor: "pointer" }} onClick={() => _copyEmail(keeper?.email)}>
                <EmojiBackground emoji="📧" smallIcon />
              </span>
            }
          />
          <Popup
            content={"Fiche Keeper (nouvel onglet )"}
            size="small"
            position="bottom center"
            trigger={
              <span style={{ marginRight: 10, cursor: "pointer" }}>
                <Link to={`/keepers/${keeper.id}`} target="_blank">
                  <EmojiBackground emoji="📋" smallIcon />
                </Link>
              </span>
            }
          />
        </ParcelKeeperIconInfo>

        <Popup
          content={isKeeperAddressCopied ? "Copié ✅" : "Copier l'adresse"}
          size="small"
          position="bottom left"
          trigger={
            <div onClick={() => _copyKeeperAddress(`${address?.streetLine} ${address?.zipcode} ${address?.city}`)}>
              <div className="address">{address?.streetLine}</div>
              <div className="city-postalcode">
                {address?.zipcode} {address?.city}
              </div>
            </div>
          }
        />
        <PreviousAddressStatusShip status={address.status} />
        <AddressInfosBlock address={address} />

        <div style={{ marginTop: 5, marginBottom: 10 }}>
          <AgendaAvailabilities agenda={agenda} />
        </div>
      </ParcelKeeperContainer>
    </Fragment>
  )
}

export default ParcelKeeper
