import { gql } from "@apollo/client"
import { Address } from "types/address.types"
import { Keeper } from "types/keeper.types"
import { Parcel } from "types/parcel.types"

export interface SearchVariables {
  input: string
}

export interface SearchParcelsData {
  searchParcels: Parcel[]
}

export const searchParcels = gql`
  query searchParcels($input: String!) {
    searchParcels(input: $input) {
      id
      parcelNo
      order {
        recipient {
          firstName
          lastName
        }
      }
    }
  }
`

export interface SearchKeepersData {
  searchKeepers: Keeper[]
}

export const searchKeepers = gql`
  query searchKeepers($input: String!) {
    searchKeepers(input: $input) {
      id
      firstName
      lastName
      email
    }
  }
`

export interface SearchAddressesData {
  searchAddresses: Address[]
}

export const searchAddresses = gql`
  query searchAddresses($input: String!) {
    searchAddresses(input: $input) {
      id
      streetLine
      zipcode
      city
      keeper {
        id
        firstName
        lastName
        email
      }
    }
  }
`
