import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

Sentry.init({
  dsn: "https://5f23e428d5e748e4b7366409b6380c60@o527816.ingest.sentry.io/4504129703116800",
  integrations: [new BrowserTracing({})],
  enabled: process.env.NODE_ENV !== "development",
  debug: false,
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0,
})

const startApp = () => {
  ReactDOM.render(<App />, document.getElementById("root"))
}

// @ts-ignore
if (!window.cordova) {
  startApp()
} else {
  document.addEventListener("deviceready", startApp, false)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
