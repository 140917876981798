import styled from "styled-components"
import { COLOR } from "utils/color"

export const KeeperDocumentsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const KeeperDocumentCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  align-items: flex-start;
  height: 263px;
  width: 283px;
  padding: 9px 10px;
  margin-bottom: 20px;
  margin-right: 14px;
  position: relative;
  background: white;
  border: 1px solid ${COLOR.WHITE_SMOKE};
  box-shadow: 1px 2px 3px rgba(196, 196, 196, 0.2);
`

export const KeeperDocumentContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const KeeperEmptyCardContainer = styled.div`
  padding: 14px 0 12px 0;
`

export const KeeperEmptyCardText = styled.div`
  font-weight: 600;
  font-size: 9px;
`

export const Keeper = styled.div`
  font-weight: 600;
  font-size: 9px;
`

export const KeeperControllersContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 14px;
  row-gap: 8px;
  font-size: 9px;
`

export const KeeperController = styled.button<{ isDropdown?: boolean }>(
  ({ isDropdown = false }) => `
  width: fit-content;
  color: ${COLOR.BLACK};
  border-radius: 10px;
  border: none;
  cursor: pointer;
  padding: ${isDropdown ? "0" : "0 15px"};
  font-size: 9px;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  height: 20px;
`,
)

export const KeeperDocumentTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${COLOR.MEDIUM_LIGHT_GREY};
  margin-bottom: 24px;
`

export const KeeperStatusLoaderContainer = styled.div`
  position: absolute;
  top: 9px;
  right: 20px;
`
