import { combineReducers } from "redux"
import ThemeOptions from "reducers/ThemeOptions"
import metisMenuReducer from "react-metismenu/lib/reducers"
import auth from "reducers/auth"
import companies from "reducers/companies"
import keepers from "reducers/keepers"
import orders from "reducers/orders"
import systemLogs from "reducers/systemLogs"
import tracking from "reducers/tracking"
import systemUpdates from "reducers/systemUpdates"
import integrationUpdates from "reducers/integrationUpdates"
import appVersions from "reducers/app-version"
import parcels from "reducers/parcels"
import leads from "reducers/leads"
import ratings from "reducers/ratings"
import associations from "reducers/associations"
import sponsorship from "reducers/sponsorship"
import reassignmentLogs from "reducers/reassignment-logs"
import { LOGOUT_USER } from "actions/users"
import carrierMapLogs from "reducers/carrier-map-logs"
import searchKeeperLogs from "reducers/search-keeper-monitoring"
import returnedAddresses from "reducers/returned-addresses"
import generatedOrders from "reducers/generated-orders"
import competitors from "reducers/competitors"
import payments from "./payments"

const appReducer = combineReducers({
  ThemeOptions,
  auth,
  companies,
  competitors,
  keepers,
  orders,
  systemLogs,
  tracking,
  systemUpdates,
  integrationUpdates,
  appVersions,
  parcels,
  leads,
  ratings,
  associations,
  sponsorship,
  reassignmentLogs,
  carrierMapLogs,
  searchKeeperLogs,
  returnedAddresses,
  generatedOrders,
  payments,
  metisMenuStore: metisMenuReducer,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    // for all keys defined in your persistConfig(s)
    // eslint-disable-next-line no-undef
    window.localStorage.removeItem("persist:root")
    // storage.removeItem('persist:otherKey')

    // eslint-disable-next-line no-param-reassign
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
