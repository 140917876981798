import React, { Fragment, ReactElement } from "react"
import GLS from "assets/carriers/GLS.png"
import DHL from "assets/carriers/DHL.png"
import Colissimo from "assets/carriers/Colissimo.png"
import Geodis from "assets/carriers/Geodis.png"
import Paack from "assets/carriers/Paack.png"
import RelaisColis from "assets/carriers/RelaisColis.png"
import DPD from "assets/carriers/DPD.png"
import styled from "styled-components"
import { CarriersName } from "types/carrier.types"
import { getTranslatedCarrierName } from "utils/getTranslatedCarrierName"

const Logo = styled.img`
  height: 100%;
  max-width: 150px;
`

interface CarrierLogoProps {
  name: string
}

const CarrierLogo = ({ name }: CarrierLogoProps): ReactElement => {
  const availableLogos: (CarriersName | string)[] = [
    CarriersName.GLS,
    CarriersName.DHL,
    CarriersName.COLISSIMO,
    CarriersName.GEODIS,
    CarriersName.PAACK,
    CarriersName.RELAISCOLIS,
    CarriersName.DPD,
  ]

  return (
    <Fragment>
      {name === CarriersName.GLS && <Logo src={GLS} />}
      {name === CarriersName.DHL && <Logo src={DHL} />}
      {name === CarriersName.COLISSIMO && <Logo src={Colissimo} />}
      {name === CarriersName.GEODIS && <Logo src={Geodis} />}
      {name === CarriersName.PAACK && <Logo src={Paack} />}
      {name === CarriersName.RELAISCOLIS && <Logo src={RelaisColis} />}
      {name === CarriersName.DPD && <Logo src={DPD} />}
      {!availableLogos.includes(name) && getTranslatedCarrierName(name)}
    </Fragment>
  )
}

export default CarrierLogo
