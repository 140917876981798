import {
  API_GET_COMPETITORS,
  API_GET_COMPETITOR_PICKUP_POINTS,
  RESET_COMPETITORS,
  REMOVE_COMPETITOR_PICKUP_POINTS,
} from "actions/competitors"

export function resetCompetitors() {
  return {
    type: RESET_COMPETITORS,
  }
}

export function apiGetCompetitors() {
  return {
    type: API_GET_COMPETITORS,
    payload: {
      request: {
        url: `/admin/competitor-networks`,
      },
    },
  }
}

export function apiGetCompetitorPickupPoints(competitorId: string) {
  return {
    type: API_GET_COMPETITOR_PICKUP_POINTS,
    payload: {
      request: {
        url: `/admin/competitor-networks/${competitorId}/pickup-points`,
      },
      competitorId,
    },
  }
}

export function removeCompetitorPickupPoints(competitorId: string) {
  return {
    type: REMOVE_COMPETITOR_PICKUP_POINTS,
    competitorId,
  }
}
