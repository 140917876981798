import React, { ReactElement, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import { Loader } from "semantic-ui-react"
import { Link, useParams } from "react-router-dom"

import Title from "components/Title"
import { PageWrapper } from "components/Layout/Structure"
import SimpleTable from "components/Table/SimpleTable"
import { Pagination } from "components/Pagination"
import { getAssociationDetails } from "services/associations"
import { ReduxState } from "types/reduxState.types"

const DEFAULT_PAGINATION_TAKE = 10

export function Association(): ReactElement {
  const { id } = useParams()

  const associations = useSelector((state: ReduxState) => state.associations)
  const { donations, take, skip, total } = associations?.detail?.data

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAssociationDetails(id, DEFAULT_PAGINATION_TAKE, 0))
  }, [])

  const loadMoreDonations = (page: number) => {
    dispatch(getAssociationDetails(id, take, page * DEFAULT_PAGINATION_TAKE))
  }

  const totalPages = Math.ceil(total / DEFAULT_PAGINATION_TAKE)

  return (
    <PageWrapper>
      <Title>Détail des dons</Title>
      {associations.detail.loading ? (
        <Loader active inline />
      ) : donations && donations.length > 0 ? (
        <SimpleTable
          headers={[
            {
              name: "Montant",
              size: 2,
              value: "amount",
              component: amount => <>{amount / 100}€</>,
            },
            {
              name: "Keeper",
              size: 2,
              value: "wallet",
              component: wallet => wallet.__keeper__.firstName || "Aucun Keeper",
            },
            {
              name: "Fiche Keeper",
              size: 2,
              value: "wallet",
              component: wallet =>
                wallet?.__keeper__?.id ? <Link to={`/keepers/${wallet.__keeper__.id}`}>📋</Link> : "Pas de fiche",
            },
            {
              name: "Date",
              size: 2,
              value: "createdAt",
              component: date => moment(date).format("DD/MM/YYYY HH:mm"),
            },
          ]}
          data={donations}
        />
      ) : (
        <div className="no-content">Aucun don</div>
      )}

      <Pagination
        page={skip / DEFAULT_PAGINATION_TAKE}
        totalPages={totalPages}
        onPress={(page: number) => loadMoreDonations(page)}
      />
    </PageWrapper>
  )
}

export default Association
