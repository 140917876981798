export enum KeeperEventCode {
  // User
  APP_FIRST_LAUNCH = "APP_FIRST_LAUNCH",
  USER_SIGNUP_STARTED = "USER_SIGNUP_STARTED",
  // Keeper
  KEEPER_CREATED = "KEEPER_CREATED",
  KEEPER_UPDATED = "KEEPER_UPDATED",
  KEEPER_DELETION_STARTED = "KEEPER_DELETION_STARTED",
  KEEPER_DELETION = "KEEPER_DELETION",
  KEEPER_ACTIVITY = "KEEPER_ACTIVITY",

  // Bank account
  KEEPER_BANK_ACCOUNT_CREATED = "KEEPER_BANK_ACCOUNT_CREATED",
  KEEPER_BANK_ACCOUNT_UPDATED = "KEEPER_BANK_ACCOUNT_UPDATED",
  KEEPER_BANK_ACCOUNT_DELETED = "KEEPER_BANK_ACCOUNT_DELETED",

  // Wallet
  WALLET_CREATED = "WALLET_CREATED",
  WALLET_UPDATED = "WALLET_UPDATED",
  WALLET_DELETED = "WALLET_DELETED",
  WALLET_ACTIVITY = "WALLET_ACTIVITY",

  // Address
  ADDRESS_CREATED = "ADDRESS_CREATED",
  ADDRESS_UPDATED = "ADDRESS_UPDATED",

  // Document
  DOCUMENT_CREATED = "DOCUMENT_CREATED",
  DOCUMENT_UPDATED = "DOCUMENT_UPDATED",
  DOCUMENT_DELETED = "DOCUMENT_DELETED",

  // Agenda
  AGENDA_UPDATED = "AGENDA_UPDATED",

  // Notifications
  KEEPER_MAIL_NOTICE = "KEEPER_MAIL_NOTICE",
  KEEPER_SMS_NOTICE = "KEEPER_SMS_NOTICE",
  KEEPER_PUSH_NOTICE = "KEEPER_PUSH_NOTICE",

  // Activity on map
  KEEPER_ON_MAP = "KEEPER_ON_MAP",
}

export enum KeeperEventReason {
  // For "Keeper"
  SIGNUP_ZONE_OK = "SIGNUP_ZONE_OK",
  SIGNUP_ZONE_KO = "SIGNUP_ZONE_KO",
  CGU_ACCEPTED = "CGU_ACCEPTED",
  DELIVERY_SERVICE_ACTIVATED = "DELIVERY_SERVICE_ACTIVATED",
  DELIVERY_SERVICE_DEACTIVATED = "DELIVERY_SERVICE_DEACTIVATED",
  APP_LOGIN = "APP_LOGIN",
  PENDING_FOR_3_DAYS = "PENDING_FOR_3_DAYS",
  INACTIVE_FOR_3_WEEKS = "INACTIVE_FOR_3_WEEKS",
  BROKE_RECURRING_MASS_POINT_DAY_ADDRESS = "BROKE_RECURRING_MASS_POINT_DAY_ADDRESS",
  REJECTED_FOR_CARRIER = "REJECTED_FOR_CARRIER",
  ACTIVATED_FOR_CARRIER = "ACTIVATED_FOR_CARRIER",
  PASSWORD_UPDATED = "PASSWORD_UPDATED",
  PASSWORD_RESET = "PASSWORD_RESET",
  FIRST_PARCEL = "FIRST_PARCEL",
  // -- Onboarding events
  ONBOARDING_PERSONAL_INFORMATIONS_OK = "ONBOARDING_PERSONAL_INFORMATIONS_OK",
  ONBOARDING_ADDRESS_INFORMATIONS_OK = "ONBOARDING_ADDRESS_INFORMATIONS_OK",
  CHART_ACCEPTED = "CHART_ACCEPTED",

  // For all entities
  INFOS_UPDATED = "INFOS_UPDATED",
  STATUS_UPDATED = "STATUS_UPDATED",

  // For "Wallet"
  DONATION_ASSOCIATION = "DONATION_ASSOCIATION",
  PAYOUT = "PAYOUT",
  ABANDONED = "ABANDONED",
  VALIDATED_BY_STRIPE = "VALIDATED_BY_STRIPE",
  VALIDATED_BY_ADMIN = "VALIDATED_BY_ADMIN",
  UNVERIFIED_BY_STRIPE = "UNVERIFIED_BY_STRIPE",
  UNVERIFIED_BY_ADMIN = "UNVERIFIED_BY_ADMIN",

  // For "Address"
  PARCEL_CAPACITY_UPDATED = "PARCEL_CAPACITY_UPDATED",

  // For "Agenda"
  AGENDA_FULL_CLEANED_FOR_INACTIVITY = "AGENDA_FULL_CLEANED_FOR_INACTIVITY",
  AGENDA_FULL_CLEANED_BY_KEEPER = "AGENDA_FULL_CLEANED_BY_KEEPER",
  AGENDA_FULL_CLEANED_BY_ADMIN = "AGENDA_FULL_CLEANED_BY_ADMIN",

  // For HMB
  CHATBOT_STARTED = "CHATBOT_STARTED",
}
