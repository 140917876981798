import { toFailure, toSuccess } from "actions"
import {
  API_GET_COMPANY,
  API_GET_COMPANIES,
  RESET_FOCUS_COMPANY,
  API_GET_CURRENT_CLIENT_BILL,
  API_UPDATE_BILLING_PAYMENT_STATUS,
} from "actions/companies"
import { createReducer } from "@reduxjs/toolkit"

const initialState = {
  list: {
    loading: false,
    error: false,
    data: [],
  },
  focus: {
    loading: false,
    error: false,
    data: null,
    currentBill: null,
  },
}

const companies = createReducer(initialState, {
  [API_GET_COMPANY]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_GET_COMPANY)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
  },
  [toFailure(API_GET_COMPANY)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  [API_GET_COMPANIES]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_COMPANIES)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data = action.payload.data
  },
  [toFailure(API_GET_COMPANIES)]: state => {
    state.list.loading = false
    state.list.error = true
  },

  [toSuccess(API_GET_CURRENT_CLIENT_BILL)]: (state, action) => {
    state.focus.currentBill = action.payload.data
  },
  [toFailure(API_GET_CURRENT_CLIENT_BILL)]: (state, action) => {
    state.focus.currentBill = {
      deliveries: [],
      returning: null,
    }
  },

  [API_UPDATE_BILLING_PAYMENT_STATUS]: () => {},
  [toSuccess(API_UPDATE_BILLING_PAYMENT_STATUS)]: (state, action) => {
    const billingPaymentIndex = state.focus.data.__accountancy__.__payments__.findIndex(
      i => i.id === action.payload.data.id,
    )
    if (billingPaymentIndex > -1) {
      state.focus.data.__accountancy__.__payments__[billingPaymentIndex] = action.payload.data
    }
  },
  [RESET_FOCUS_COMPANY]: state => {
    state.focus.data = null
  },
})

export default companies
