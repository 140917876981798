import React, { ReactElement, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Row } from "reactstrap"
import { Input, Loader } from "semantic-ui-react"

import Title from "components/Title"
import Select from "components/Form/Select"
import { apiGetDataFromCarrierAPI } from "services/parcels"
import { apiGetCompanies } from "services/companies"
import { CarrierAPIProps } from "types/carrier.types"
import { ReduxState } from "types/reduxState.types"
import generateCompaniesOptions from "utils/formik/generateCompaniesOptions"

export function CarrierAPI({ copyData }: CarrierAPIProps): ReactElement {
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [company, setCompany] = useState({ value: null, label: null })
  const [parcelData, setParcelData] = useState({
    loaded: false,
    parcelNo: null,
    shipmentNo: null,
    shipperName: null,
    recipient: {
      firstName: null,
      lastName: null,
      name: null,
      email: null,
      phone: null,
    },
  })

  const companies = useSelector((state: ReduxState) => state.companies)
  const dispatch = useDispatch()

  const fetch = async () => {
    setParcelData({
      loaded: false,
      parcelNo: null,
      shipmentNo: null,
      shipperName: null,
      recipient: {
        firstName: null,
        lastName: null,
        name: null,
        email: null,
        phone: null,
      },
    })

    if (company.value !== null && searchText !== "") {
      setLoading(true)
      const r = await dispatch(apiGetDataFromCarrierAPI({ parcelNo: searchText, carrierId: company.value }))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data = r?.payload?.data
      if (!!data) setParcelData({ ...data, loaded: true })

      setLoading(false)
    }
  }

  const changeCompany = companySelected => {
    setCompany(companySelected)
  }

  useEffect(() => {
    if (companies.list.data.length === 0) dispatch(apiGetCompanies())
  }, [])

  return (
    <div style={{ flex: 1, padding: 10 }}>
      <div style={{ padding: 30 }}>
        <Title>🚛 Informations transporteur</Title>

        <p>
          Recherche directe sur les APIs des transporteurs des infos colis, pour un numéro de colis ou un numéro
          d&apos;expédition
        </p>

        <Row style={{ marginTop: "1em" }}>
          <Col xs={4} md={4}>
            <Select
              value={company}
              onChange={changeCompany}
              options={generateCompaniesOptions(companies.list.data)}
              placeholder="Choisir"
              menuPosition="fixed"
            />
          </Col>
          <Col xs={4} md={4}>
            <Input
              fluid
              placeholder="Référence colis"
              onChange={e => setSearchText(e.target.value)}
              className="inputText"
              value={searchText}
            />
          </Col>
          <Col xs={4} md={4}>
            {loading ? (
              <Loader active inline />
            ) : (
              <Button className="search-button" onClick={() => fetch()}>
                Chercher
              </Button>
            )}
          </Col>
        </Row>

        {parcelData.loaded && (
          <Row style={{ marginTop: "2em" }}>
            <Col xs={12}>
              <b>N° Colis :</b> {parcelData.parcelNo}
            </Col>
            <Col xs={12} style={{ marginBottom: 20 }}>
              <b>N° expédition :</b> {parcelData.shipmentNo}
            </Col>
            <Col xs={12} style={{ marginBottom: 20 }}>
              <b>Chargeur :</b> {parcelData.shipperName}
            </Col>
            <Col xs={12}>
              <b>Identité :</b> {parcelData.recipient.name}
            </Col>
            <Col xs={12}>
              <b>Email :</b> {parcelData.recipient.email}
            </Col>
            <Col xs={12} style={{ marginBottom: 20 }}>
              <b>Téléphone :</b> {parcelData.recipient.phone}
            </Col>
          </Row>
        )}
        {parcelData.loaded && !!copyData && (
          <Button className="search-button" onClick={() => copyData(parcelData, company)}>
            Recopier dans le formulaire
          </Button>
        )}
      </div>
    </div>
  )
}

export default CarrierAPI
