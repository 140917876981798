import React, { ReactElement } from "react"
import { Marker } from "react-map-gl"
import { Popup } from "semantic-ui-react"

import PinBlue from "assets/mapbox/pin_blue.png"
import PinMondialRelay from "assets/mapbox/carriers/mondialrelay_pin.png"
import PinRelaiColis from "assets/mapbox/carriers/rc_pin.png"
import PinPickup from "assets/mapbox/carriers/pickup_pin.png"
import PinLaPoste from "assets/mapbox/carriers/laposte_pin.png"
import KeeperPopup from "components/Keeper/KeeperPopup"
import { MapPin } from "components/Maps/MapPin"
import CompetitorPickupPoints from "components/Competitors/CompetitorsPickupPoints"
import { CompetitorsCheckboxesContainer, CheckboxContainer } from "components/Competitors/CompetitorsCheckboxes.styled"
import CompetitorsCheckboxes from "components/Competitors/CompetitorsCheckboxes"
import { CarrierMapLogsMarkersProps } from "types/carrier-map-log.types"
import { CompetitorsName } from "types/competitor.types"

function CarrierMapLogsMarkers({ data, competitorsPickupPoints }: CarrierMapLogsMarkersProps): ReactElement {
  return (
    <>
      {competitorsPickupPoints.map(pickupPoint => (
        <Marker
          key={`competitor-pickup-point-${pickupPoint.id}`}
          longitude={Number(pickupPoint.longitude)}
          latitude={Number(pickupPoint.latitude)}
        >
          <Popup
            trigger={
              <span>
                {
                  {
                    [CompetitorsName.MONDIAL_RELAY]: <MapPin src={PinMondialRelay} />,
                    [CompetitorsName.RELAIS_COLIS]: <MapPin src={PinRelaiColis} />,
                    [CompetitorsName.PICKUP]: <MapPin src={PinPickup} />,
                    [CompetitorsName.LA_POSTE]: <MapPin src={PinLaPoste} />,
                  }[pickupPoint.competitorNetwork.name]
                }
              </span>
            }
            content={<CompetitorPickupPoints pickupPoint={pickupPoint} />}
            on="hover"
            position="top center"
          />
        </Marker>
      ))}
      {data
        .filter(d => !!d.latitude && !!d.longitude)
        .map(d => (
          <Marker key={d.id} longitude={d.longitude} latitude={d.latitude}>
            <Popup
              trigger={<MapPin src={PinBlue} />}
              content={<KeeperPopup keeper={d.keeper} address={d} />}
              on="click"
              position="top center"
            />
          </Marker>
        ))}
      <CheckboxContainer>
        <CompetitorsCheckboxesContainer>
          <CompetitorsCheckboxes />
        </CompetitorsCheckboxesContainer>
      </CheckboxContainer>
    </>
  )
}

export default CarrierMapLogsMarkers
