import React, { ReactElement, useRef } from "react"
import AsyncSelect from "react-select/async"
import { COLOR } from "utils/color"
import { GroupsType, LoaderPosition, OptionsType } from "types/search.types"
import { CustomMenu } from "components/SearchBar/CustomMenu"
import { Container } from "components/SearchBar/SearchBar.styled"

interface SearchProps {
  onSearch?: (input: string) => Promise<{ value: string; label: string; type: string }[]>
  onChange: (data: { label: string; type: string; value: string }) => void
  placeholder?: string
  marginBottom?: boolean
  isLoading?: boolean
  isLoadingMessage?: boolean
  shouldUpdateOptions?: boolean
  loaderPosition?: LoaderPosition
  options?: OptionsType | GroupsType
  onInputChange?: (input: string) => void
}

const SearchBar = ({
  onChange,
  placeholder,
  marginBottom,
  onSearch,
  isLoading = false,
  isLoadingMessage = true,
  shouldUpdateOptions = false,
  loaderPosition = LoaderPosition.START,
  onInputChange = () => {},
  options = [],
}: SearchProps): ReactElement => {
  const ref = useRef(null)
  if (ref?.current?.state && shouldUpdateOptions) ref.current.state.loadedOptions = options
  return (
    <Container marginBottom={marginBottom}>
      <AsyncSelect
        ref={ref}
        components={{ Menu: CustomMenu }}
        onInputChange={onInputChange}
        loadOptions={onSearch}
        onChange={onChange}
        placeholder={placeholder ? placeholder : "🔍 Rechercher un colis, un keeper, une adresse..."}
        selectProps={{ isLoading, loaderPosition }}
        loadingMessage={() => (isLoadingMessage ? "Loading..." : "")}
        noOptionsMessage={() => (isLoading ? "" : "No options")}
        styles={{
          menu: provided => ({
            ...provided,
            zIndex: 10,
          }),
          control: provided => ({
            ...provided,
            borderColor: COLOR.SUPER_LIGHT_GREY,
          }),
        }}
      />
    </Container>
  )
}

export default SearchBar
