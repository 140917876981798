import React, { ReactElement, useEffect, useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import Skeleton from "react-loading-skeleton"
import { Button, Icon, Input, Modal } from "semantic-ui-react"
import { toast } from "react-toastify"

import {
  AppointmentContainer,
  AppointmentTitleBlock,
  AppointmentEmoji,
  AppointmentTitle,
  AppointmentContentBlock,
  AppointmentButtonContainer,
  ModalAppointmentContent,
  ModalAppointmentErrorMessage,
} from "components/Appointment/AppointmentHandler.styled"
import { Emoji } from "components/Emoji/Emoji"
import { MiniButtonCancel } from "components/Buttons/MiniButton"
import { getOrderAppointment } from "services/graphql/queries/appointment.queries"
import { createOrderAppointment, cancelOrderAppointments } from "services/graphql/mutations/appointment.mutations"
import {
  GetOrderAppointmentQuery,
  CreateOrderAppointmentInput,
  CreateOrderAppointmentOutput,
  CancelOrderAppointmentsInput,
  CancelOrderAppointmentsOutput,
} from "types/appointment.types"
import { formatDateToDisplayableString } from "utils/date"

enum APPOINTMENT_ACTIONS {
  CREATION = "CREATION",
  MODIFICATION = "MODIFICATION",
  CANCELLATION = "CANCELLATION",
}

interface ModalAppointmentProps {
  action: APPOINTMENT_ACTIONS
  onSubmit?: (date: Date) => void
  onCancel?: () => void
}

const ModalAppointment = ({ action, onSubmit, onCancel }: ModalAppointmentProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [date, setDate] = useState<string>(null)
  const [time, setTime] = useState<string>(null)
  const [emptyFieldError, setEmptyFieldError] = useState<string>(null)
  return (
    <Modal
      onClose={() => {
        setEmptyFieldError(null)
        setOpen(false)
      }}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={
        {
          [APPOINTMENT_ACTIONS.CREATION]: <MiniButtonCancel onClick={() => setOpen(true)}>Créer</MiniButtonCancel>,
          [APPOINTMENT_ACTIONS.MODIFICATION]: (
            <MiniButtonCancel onClick={() => setOpen(true)}>Modifier</MiniButtonCancel>
          ),
          [APPOINTMENT_ACTIONS.CANCELLATION]: (
            <MiniButtonCancel onClick={() => setOpen(true)}>Supprimer</MiniButtonCancel>
          ),
        }[action]
      }
    >
      <Modal.Header>
        <Emoji label="mantelpiece clock">🕰️</Emoji>
        {` Rendez-vous Keeper <> Desti`}
      </Modal.Header>

      <Modal.Content>
        <ModalAppointmentContent>
          {action === APPOINTMENT_ACTIONS.CANCELLATION ? (
            "Vous êtes sur le point de supprimer le rendez-vous existant"
          ) : (
            <>
              Le
              <Input type="date" style={{ margin: "0px 5px 0px 5px" }} onChange={e => setDate(e.target.value)} />
              à
              <Input type="time" style={{ margin: "0px 5px 0px 5px" }} onChange={e => setTime(e.target.value)} />
            </>
          )}
        </ModalAppointmentContent>
        {emptyFieldError && <ModalAppointmentErrorMessage>{emptyFieldError}</ModalAppointmentErrorMessage>}
      </Modal.Content>

      <Modal.Actions>
        <Button
          color="red"
          inverted
          onClick={() => {
            setEmptyFieldError(null)
            setOpen(false)
          }}
        >
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            setEmptyFieldError(null)

            if (action === APPOINTMENT_ACTIONS.CANCELLATION) {
              onCancel()
              setOpen(false)
            } else {
              if (!date || !time) {
                const emptyField = !date ? "La date" : "L'heure"
                setEmptyFieldError(`${emptyField} doit être renseignée`)
              } else {
                const appointmemtDate = new Date(`${date} ${time}`)
                onSubmit(appointmemtDate)
                setOpen(false)
              }
            }
          }}
        >
          <Icon name="checkmark" />
          {action === APPOINTMENT_ACTIONS.CANCELLATION ? "Confirmer la suppression" : "Confirmer le RDV"}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

interface AppointmentHandlerProps {
  orderId: string
}

export const AppointmentHandler = ({ orderId }: AppointmentHandlerProps): ReactElement => {
  const [appointment, setAppointment] = useState<string>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [getOrderAppointmentQuey, { loading: gettingLoading }] =
    useLazyQuery<GetOrderAppointmentQuery>(getOrderAppointment)
  const [createOrderAppointmentMutation, { loading: creationLoading }] = useMutation<
    CreateOrderAppointmentOutput,
    CreateOrderAppointmentInput
  >(createOrderAppointment)
  const [cancelOrderAppointmentMutation, { loading: cancellationLoading }] = useMutation<
    CancelOrderAppointmentsOutput,
    CancelOrderAppointmentsInput
  >(cancelOrderAppointments)

  useEffect(() => {
    if (orderId) {
      getOrderAppointmentQuey({ variables: { orderId } }).then(({ data }) => {
        setLoading(false)
        if (data?.getLatestActiveAppointment?.mostRecentActiveAppointment?.date) {
          const appointmentDate = formatDateToDisplayableString(
            data.getLatestActiveAppointment.mostRecentActiveAppointment.date,
          )
          setAppointment(appointmentDate)
        }
      })
    }
  }, [orderId])

  const onCreateAppointment = (date: Date) => {
    createOrderAppointmentMutation({ variables: { orderId, date } }).then(({ data }) => {
      const appointmentDate = formatDateToDisplayableString(data.createAdminRecipientAppointment.date)
      setAppointment(appointmentDate)
    })
  }

  const onCancelAppointments = () => {
    cancelOrderAppointmentMutation({ variables: { orderId } }).then(({ data }) => {
      if (data.cancelOrderAppointments) {
        setAppointment(null)
      } else {
        toast.error("Une erreur s'est produite lors de la suppression, veuillez contacter l'équipe tech")
      }
    })
  }

  return (
    <AppointmentContainer>
      <AppointmentTitleBlock>
        <AppointmentEmoji>
          <Emoji label="mantelpiece clock">🕰️</Emoji>
        </AppointmentEmoji>
        <AppointmentTitle>{"Rendez-vous Keeper <> Desti"}</AppointmentTitle>
      </AppointmentTitleBlock>
      <AppointmentContentBlock>
        {loading || gettingLoading || creationLoading || cancellationLoading ? (
          <Skeleton width={"100px"} height={"20px"} />
        ) : appointment ? (
          <>
            <>{appointment}</>
            <AppointmentButtonContainer>
              <ModalAppointment action={APPOINTMENT_ACTIONS.MODIFICATION} onSubmit={onCreateAppointment} />
              <ModalAppointment action={APPOINTMENT_ACTIONS.CANCELLATION} onCancel={onCancelAppointments} />
            </AppointmentButtonContainer>
          </>
        ) : (
          <>
            <>Pas de rendez-vous pour le moment</>
            <AppointmentButtonContainer>
              <ModalAppointment action={APPOINTMENT_ACTIONS.CREATION} onSubmit={onCreateAppointment} />
            </AppointmentButtonContainer>
          </>
        )}
      </AppointmentContentBlock>
    </AppointmentContainer>
  )
}
