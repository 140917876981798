import { toFailure, toSuccess } from "actions"
import { createReducer } from "@reduxjs/toolkit"
import uniqBy from "lodash/uniqBy"
import { RESET_SPONSORSHIP, API_GET_KEEPER_SPONSORSHIP } from "actions/sponsorship"

const initialState = {
  list: {
    loading: false,
    error: false,
    data: {
      sponsorship: [],
      keeperSponsorship: [],
    },
  },
  extract: {
    loading: false,
    data: {
      sponsorship: [],
    },
    error: false,
  },
  keeperSponsor: {},
  pageInfos: {},
  totalCount: 0,
  stats: {
    loading: false,
    data: {},
  },
}

const sponsorship = createReducer(initialState, {
  [RESET_SPONSORSHIP]: state => {
    state.list.data.sponsorship = []
    state.list.data.keeperSponsorship = []
    state.keeperSponsor = {}
    state.pageInfos = {}
    state.totalCount = 0
  },
  [API_GET_KEEPER_SPONSORSHIP]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_KEEPER_SPONSORSHIP)]: (state, action) => {
    state.list.loading = false
    state.list.error = false

    if (state.list.data.keeperSponsorship && action.payload.data.sponsored.edges) {
      state.list.data.keeperSponsorship = uniqBy(
        [...state.list.data.keeperSponsorship, ...action.payload.data.sponsored.edges],
        "cursor",
      )
      state.keeperSponsor = { sponsor: action.payload.data.sponsor, sponsorEarning: action.payload.data.sponsorEarning }
      state.pageInfos = action.payload.data.sponsored.pageInfos
      state.totalCount = action.payload.data.sponsored.totalCount
    } else {
      state.list.data.keeperSponsorship = action.payload.data.sponsored.edges
      state.keeperSponsor = { sponsor: action.payload.data.sponsor, sponsorEarning: action.payload.data.sponsorEarning }
      state.pageInfos = action.payload.data.sponsored.pageInfos
      state.totalCount = action.payload.data.sponsored.totalCount
    }
  },
  [toFailure(API_GET_KEEPER_SPONSORSHIP)]: state => {
    state.list.loading = false
    state.list.error = true
  },
})

export default sponsorship
