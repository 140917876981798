import Stat from "components/Stats/Stat"
import StatsLine from "components/Stats/StatsLine"
import React, { ReactElement } from "react"

interface BillingStatsProps {
  stats: {
    toBill: number
    waitingPayment: number
    paid: number
    total: number
  }
  loading: boolean
}

export function BillingStats({ stats, loading }: BillingStatsProps): ReactElement {
  return (
    <StatsLine>
      <Stat title="À facturer" content={`${stats?.toBill / 100} €`} loading={loading} />
      <Stat title="Attente paiement" content={`${stats?.waitingPayment / 100} €`} loading={loading} />
      <Stat title="Payé" content={`${stats?.paid / 100} €`} loading={loading} />
      <Stat title="Total" content={`${stats?.total / 100} €`} loading={loading} />
    </StatsLine>
  )
}
