import React, { ReactElement, useState } from "react"
import styled from "styled-components"
import moment from "moment"
import FeatherIcon from "feather-icons-react"
import { Link } from "react-router-dom"
import { Button, Loader } from "semantic-ui-react"

import EmojiBackground from "components/EmojiBackground"
import { WalletEntryType, WalletFeesType } from "types/wallet-entry.types"
import { WalletDetailEntity, WalletEntriesDetail } from "types/wallet.types"
import { COLOR } from "utils/color"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  align-items: flex-start;
  max-height: 550px;
  margin-bottom: 20px;

  background: white;
  border: 1px solid ${COLOR.WHITE_SMOKE};
  box-shadow: 1px 2px 3px rgba(196, 196, 196, 0.2);
`

export const TextTitle = styled.div`
  font-size: 12px;
  font-family: Poppins-Medium;
  color: ${COLOR.MEDIUM_LIGHT_GREY};
  padding: 10px 20px 0px 20px;
`

const ContainerAmount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 20px 10px 20px;
  width: 100%;
`

const TextAmount = styled.div`
  flex: auto;
  font-size: 18px;
  font-family: Poppins-Semibold;
  margin-top: 5px;
  color: ${COLOR.DARK_GREY};
`

const ButtonAmount = styled.button`
  margin: auto;
  background: none;
  border: none;
  &:active {
    opacity: 0.4;
  }
`

// STATUS OPERATION

const ContainerStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 5px 0px;
`

const ContainerStatusIcon = styled.div<{ backgroundColor: string }>(
  ({ backgroundColor }) => `
  ${backgroundColor ? "background:" + backgroundColor : ""};
  padding: 0px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
`,
)

const TextStatus = styled.div`
  font-size: 11px;
  font-family: Poppins-Medium;
  margin-left: 5px;
  color: ${COLOR.DARK_GREY};
`

// DETAILS

const ScrollableWrapper = styled.div`
  width: 100%;
  padding: 0px 20px 0px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${COLOR.WHITE_SMOKE};
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(245, 245, 245, 0.2);
    margin-right: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLOR.LIGHT_GREY};
  }
`

const ContainerDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const ContainerSubDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ContainerDetailTitle = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
`

const TextDetailTitle = styled.div<{ isProcessing: boolean }>(
  ({ isProcessing }) => `
  flex: auto;
  font-size: 12px;
  font-family: Poppins-Regular;
  color: ${isProcessing ? COLOR.LIGHT_GREY : COLOR.DARK_GREY};
  margin-top: 2px;
`,
)

const TextDetailDate = styled.div`
  flex: auto;
  font-size: 10px;
  font-family: Poppins-Light;
  color: ${COLOR.MEDIUM_LIGHT_GREY};
  margin-top: 2px;
`

const TextDetailAmount = styled.div<{ isEntry: boolean }>(
  ({ isEntry }) => `
  font-size: 12px;
  font-family: Poppins-Medium;
  color: ${isEntry ? COLOR.MEDIUM_LIGHT_GREEN : COLOR.CORAIL};
`,
)

// LOAD MORE

const LoadMoreContainer = styled.div`
  width: 120px;
  align-self: center;
  margin-top: 20px;
`

const ParcelRefContainer = styled.div`
  display: flex;
  overflow-x: auto;
  align-items: center;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${COLOR.WHITE_SMOKE};
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(245, 245, 245, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLOR.LIGHT_GREY};
  }
`

const ParcelRefButton = styled(Link)`
  background-color: ${COLOR.WHITE_SMOKE};
  padding: 10px;
  border-radius: 10px;
  margin: 10px 10px 0px 0px;
  min-width: 165px;
`

interface CardWalletProps {
  loading?: boolean
  balance: number
  content: WalletEntriesDetail[]
  onPress: () => void
  canLoadMore: boolean
  loadingDetails: boolean
  noMoreDetails: boolean
}

export function CardWallet({
  loading,
  balance,
  content,
  onPress,
  canLoadMore,
  loadingDetails,
  noMoreDetails,
}: CardWalletProps): ReactElement {
  const [open, setOpen] = useState(false)

  // const scrollViewRef = useRef(null)

  // useEffect(() => {
  //   if (content && content.length > 0 && scrollViewRef && scrollViewRef.current) {
  //     scrollViewRef?.current?.scrollToEnd({ animated: true })
  //   }
  // }, [content])

  const emojiDetail = (entity: WalletDetailEntity, type: WalletEntryType | WalletFeesType | null) => {
    switch (entity) {
      case WalletDetailEntity.WALLET_DONATION:
        return "💕"
      case WalletDetailEntity.WALLET_PAYMENT:
        return "🏛"
      case WalletDetailEntity.WALLET_GIVEAWAY:
        return "💸"
      case WalletDetailEntity.WALLET_FEES:
        switch (type) {
          case WalletFeesType.STRIPE_FEES:
            return "💸"
          default:
            return ""
        }
      case WalletDetailEntity.WALLET_ENTRY:
        switch (type) {
          case WalletEntryType.PENDING_RECEPTION:
            return "📦"
          case WalletEntryType.DROPOFF:
            return "🤲"
          case WalletEntryType.DELIVERY:
          case WalletEntryType.KEEPER_DELIVERY_SERVICE:
            return "🤝"
          case WalletEntryType.RETURNING:
            return "🚛"
          case WalletEntryType.COMPENSATION:
            return "👍"
          case WalletEntryType.REWARD:
          case WalletEntryType.SPONSOR_FIRST_DELIVERY:
          case WalletEntryType.SPONSORED_FIRST_DELIVERY:
          case WalletEntryType.SPONSOR_DELIVERY_BONUS:
            return "🎁"
          case WalletEntryType.RETURNING_BP:
            return "🏃"
          default:
            return ""
        }
      case WalletDetailEntity.WALLET_TIPS:
        return `🎁`
      default:
        return ""
    }
  }

  const typePaymentParcel = (detail: WalletEntriesDetail) => {
    switch (detail?.entity) {
      case WalletDetailEntity.WALLET_DONATION:
        return `Cagnotte solidaire - ${detail?.associationName}`
      case WalletDetailEntity.WALLET_PAYMENT:
        return "Virement bancaire"
      case WalletDetailEntity.WALLET_GIVEAWAY:
        return `Renonciation à la cagnotte`
      case WalletDetailEntity.WALLET_FEES:
        switch (detail?.type) {
          case WalletFeesType.STRIPE_FEES:
            return "Frais bancaire - Frais Stripe cagnotte inférieur à 15€"
          default:
            return ""
        }
      case WalletDetailEntity.WALLET_ENTRY:
        switch (detail?.type) {
          case WalletEntryType.PENDING_RECEPTION:
            return `[En attente] Réception de ${detail?.nbParcels} colis`
          case WalletEntryType.DROPOFF:
            return `Réception de ${detail?.nbParcels} colis`
          case WalletEntryType.DELIVERY:
            return `Livraison de ${detail?.nbParcels} colis`
          case WalletEntryType.RETURNING:
            return `Retour de ${detail?.nbParcels} colis`
          case WalletEntryType.COMPENSATION:
            return `Compensation colis`
          case WalletEntryType.REWARD:
            return `Cadeau Pickme`
          case WalletEntryType.SPONSOR_FIRST_DELIVERY:
            return `Parrainage - Un ami a effectué sa première livraison !`
          case WalletEntryType.SPONSORED_FIRST_DELIVERY:
            return `Parrainage - Premier colis livré, bravo !`
          case WalletEntryType.KEEPER_DELIVERY_SERVICE:
            return `Livraison de ${detail?.nbParcels} colis à domicile`
          case WalletEntryType.RETURNING_BP:
            return `Retour en bureau de Poste`
          case WalletEntryType.SPONSOR_DELIVERY_BONUS:
            return "Parrainage boosté - Un de vos filleuls a effectué une livraison"
          default:
            return ""
        }
      case WalletDetailEntity.WALLET_TIPS:
        return `Pourboire de ${detail.recipient?.name || ""}`
      default:
        return ""
    }
  }

  const entryEntity = [WalletDetailEntity.WALLET_ENTRY, WalletDetailEntity.WALLET_TIPS]

  const generateDetails = (content: WalletEntriesDetail[]) => {
    return (
      <ScrollableWrapper>
        {content?.map((element, index) => (
          <ContainerDetail key={index}>
            <ContainerSubDetail>
              <EmojiBackground emoji={emojiDetail(element.entity, element.type)} smallIcon={true} />
              <ContainerDetailTitle>
                <TextDetailTitle isProcessing={element.processing}>{typePaymentParcel(element)}</TextDetailTitle>
                {element.processing ? (
                  <ContainerStatus>
                    <ContainerStatusIcon backgroundColor={COLOR.BLUE}>
                      <FeatherIcon icon="clock" color={COLOR.WHITE} size={12} />
                    </ContainerStatusIcon>
                    <TextStatus>Traitement en cours</TextStatus>
                  </ContainerStatus>
                ) : (
                  <TextDetailDate>{moment(element.date).format("Do MMM YYYY")}</TextDetailDate>
                )}
              </ContainerDetailTitle>
              <TextDetailAmount isEntry={entryEntity.includes(element.entity)}>
                {entryEntity.includes(element.entity) ? "+" : "-"} {element.amount / 100}€
              </TextDetailAmount>
            </ContainerSubDetail>

            <ParcelRefContainer>
              {element?.parcels?.length > 0 &&
                element.parcels.map((parcel, index) => (
                  <ParcelRefButton key={`btn-${index}`} to={`/parcels/${parcel.id}`} target="_blank">
                    📦 {parcel.parcelNo}
                  </ParcelRefButton>
                ))}
            </ParcelRefContainer>
          </ContainerDetail>
        ))}

        {canLoadMore && !noMoreDetails && (
          <LoadMoreContainer>
            <Button onClick={() => onPress()} loading={loadingDetails} disabled={loadingDetails}>
              Charger plus
            </Button>
          </LoadMoreContainer>
        )}
      </ScrollableWrapper>
    )
  }

  return (
    <Container>
      <TextTitle>{"Votre solde actuel"}</TextTitle>
      <ContainerAmount>
        {loading ? <Loader active inline /> : <TextAmount>{balance / 100}€</TextAmount>}
        {content?.length > 0 && !loading && (
          <ButtonAmount onClick={() => setOpen(!open)}>
            <FeatherIcon icon={open ? "chevron-up" : "chevron-down"} size={25} color={COLOR.DARK_GREY} />
          </ButtonAmount>
        )}
      </ContainerAmount>
      {open && content && generateDetails(content)}
    </Container>
  )
}
