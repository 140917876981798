import React, { ReactElement, useState } from "react"
import Zoom from "react-medium-image-zoom"

import {
  RecipientIdCardContainer,
  RecipientIdCardTitleBlock,
  RecipientIdCardEmoji,
  RecipientIdCardTitle,
  RecipientIdCardContentBlock,
  RecipientIdCardImageLink,
} from "./RecipientIdCard.styled"
import { Emoji } from "components/Emoji/Emoji"
import { Parcel } from "../../types/parcel.types"

interface RecipientIdCardHandlerProps {
  parcel: Parcel
}

export const RecipientIdCard = ({ parcel }: RecipientIdCardHandlerProps): ReactElement => {
  if (!parcel.recipientIdCardUrl) return

  const [isImageAvailable, setIsImageAvailable] = useState(true)

  const handleImageError = () => {
    setIsImageAvailable(false)
  }

  if (!isImageAvailable) {
    return
  }

  return (
    <RecipientIdCardContainer>
      <RecipientIdCardTitleBlock>
        <RecipientIdCardEmoji>
          <Emoji label="mantelpiece clock">🧾️</Emoji>
        </RecipientIdCardEmoji>
        <RecipientIdCardTitle>CNI destinataire</RecipientIdCardTitle>
      </RecipientIdCardTitleBlock>
      <RecipientIdCardContentBlock>
        <RecipientIdCardImageLink>
          <Zoom>
            <img onError={handleImageError} src={parcel.recipientIdCardUrl} alt="recipient-id-card" />
          </Zoom>
        </RecipientIdCardImageLink>
      </RecipientIdCardContentBlock>
    </RecipientIdCardContainer>
  )
}
