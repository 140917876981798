import { gql } from "@apollo/client"
import { KeeperChecking } from "types/keeper.types"
import { DocumentEntityType, DocumentStatus } from "../../../types/document.types"

export interface KeeperNoteInput {
  payload: { keeperId: string; content?: string; zendeskLink?: string }
}

export interface CreateKeeperNoteData {
  createKeeperNote: {
    note: {
      content?: string
      zendeskLink?: string
    }
  }
}

export const createKeeperNote = gql`
  mutation createKeeperNote($payload: CreateKeeperNoteInput!) {
    createKeeperNote(payload: $payload) {
      note {
        content
        zendeskLink
      }
    }
  }
`

export interface UpdateKeeperNoteData {
  updateKeeperNote: {
    content?: string
    zendeskLink?: string
  }
}

export const updateKeeperNote = gql`
  mutation updateKeeperNote($payload: UpdateKeeperNoteInput!) {
    updateKeeperNote(payload: $payload) {
      content
      zendeskLink
    }
  }
`

export interface SaveCheckingResultInput {
  checkings: KeeperChecking[]
}

export const saveCheckingResult = gql`
  mutation adminSaveCheckingResult($checkings: [KeeperChecking!]!) {
    adminSaveCheckingResult(checkings: $checkings)
  }
`

export interface UploadKeeperDocumentData {
  adminUploadDocumentV3ForKeeper: {
    id: string
  }
}

export interface UploadKeeperDocumentVariables {
  keeperId: string
  type: DocumentEntityType
  file: Blob
}

export const uploadDocumentV3ForKeeper = gql`
  mutation adminUploadDocumentV3ForKeeper($keeperId: String!, $type: DocumentType!, $file: Upload!) {
    adminUploadDocumentV3ForKeeper(keeperId: $keeperId, type: $type, file: $file) {
      id
    }
  }
`

export interface ChangeDocumentStatusData {
  adminChangeDocumentStatus: {
    id: string
  }
}

export interface ChangeDocumentStatusVariables {
  documentId: string
  status: DocumentStatus
}

export const changeDocumentStatus = gql`
  mutation adminChangeDocumentStatus($documentId: String!, $status: DocumentStatus!) {
    adminChangeDocumentStatus(documentId: $documentId, status: $status) {
      id
    }
  }
`

export interface ChangeBankAccountStatusVariables {
  keeperId: string
  isEnabled: boolean
}

export const changeBankAccountStatus = gql`
  mutation adminChangeBankAccountStatus($keeperId: String!, $isEnabled: Boolean!) {
    adminChangeBankAccountStatus(keeperId: $keeperId, isEnabled: $isEnabled)
  }
`
