import styled from "styled-components"

import { COLOR } from "utils/color"

export const KeeperContainer = styled.div`
  flex: 1;
  display: "flex";
  flexdirection: "column";
`
export const KeeperHeader = styled.div`
  width: 100%;
  background-color: ${COLOR.WHITE};
  display: flex;
  padding: 40px;
  flex-direction: row;
  min-height: 200px;
  border-bottom: 1px solid ${COLOR.WHITE_SMOKE};
`

export const KeeperInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

export const KeeperInfosTitle = styled.div`
  font-size: 3em;
  font-weight: 600;
`

export const KeeperInfosMail = styled.div`
  margin-bottom: 1em;
`

export const KeeperContent = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  background-color: ${COLOR.WHITE_SMOKE};
`

export const KeeperContentActionsButtons = styled.div`
  position: absolute;
  top: -17px;
  right: 20px;
`

export const PopupMessage = styled.span`
  cursor: "pointer";
`

export const PopupIcon = styled.span`
  fontsize: 24;
`
