import React from "react"
import styled from "styled-components"

const StatsLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
`

export default function StatsLine({ children }) {
  return <StatsLineContainer>{children}</StatsLineContainer>
}
