import { API_GET_RATINGS, RESET_RATINGS } from "actions/ratings"
import { RatingsData } from "types/ratings.types"
import { FctServiceReturn } from "./services.types"

export function apiGetRatings({
  take,
  skip,
  onlyLowRating,
}: {
  take: number
  skip: number
  onlyLowRating: boolean
}): FctServiceReturn<RatingsData> {
  return {
    type: API_GET_RATINGS,
    payload: {
      request: {
        url: `/admin/ratings?take=${take}&skip=${skip}${onlyLowRating ? "&onlyLowRating=true" : ""}`,
      },
    },
  }
}

export function resetRatings(): { type: string } {
  return {
    type: RESET_RATINGS,
  }
}
