import { toFailure, toSuccess } from "actions"
import { createReducer } from "@reduxjs/toolkit"
import { API_GET_RATINGS, RESET_RATINGS } from "actions/ratings"

const initialState = {
  list: {
    loading: false,
    error: false,
    data: { ratings: [] },
  },
  focus: {
    loading: false,
    error: false,
    data: {},
  },
}

const ratings = createReducer(initialState, {
  [RESET_RATINGS]: state => {
    state.list.data = { ratings: [] }
  },
  [API_GET_RATINGS]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_RATINGS)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data = { ...action.payload.data, ratings: state.list.data.ratings.concat(action.payload.data.ratings) }
  },
  [toFailure(API_GET_RATINGS)]: state => {
    state.list.loading = false
    state.list.error = true
  },
})

export default ratings
