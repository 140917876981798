import moment from "moment"
import { Availability, AvailabilitySlotType, AddressAvailability } from "types/agenda.types"

export function getKeyFromDate(date: string | moment.Moment): string {
  return moment(date).format("YYYY-MM-DD")
}

export function getDayFromDate(date: string): string {
  switch (moment(date).isoWeekday()) {
    case 1:
      return "MON"
    case 2:
      return "TUE"
    case 3:
      return "WED"
    case 4:
      return "THU"
    case 5:
      return "FRI"
    case 6:
      return "SAT"
    case 7:
      return "SUN"
    default:
      return ""
  }
}

export function getSlotTypeFromAvailability({ startHour, endHour }: AddressAvailability): AvailabilitySlotType {
  if (startHour === 9 && endHour === 12) {
    return AvailabilitySlotType.MORNING
  } else if (startHour === 12 && endHour === 15) {
    return AvailabilitySlotType.NOON
  } else if (startHour === 15 && endHour === 18) {
    return AvailabilitySlotType.AFTERNOON
  } else if (startHour === 18 && endHour === 21) {
    return AvailabilitySlotType.EVENING
  }
}

function getRecurringDateKeysFromAvailability({ startDate, endDate }: Availability) {
  const date = moment(startDate)
  const dates = []
  do {
    dates.push(getKeyFromDate(date))
    date.add(7, "day")
  } while (date.isSameOrBefore(endDate))
  return dates
}

export function filterInvalidAvailabilities(agenda: AddressAvailability[]): AddressAvailability[] {
  /**
   * Rule to filter availabilities to display :
   * 1 - PUNCTUAL : startDate is today or after today
   * 2 - RECURRING : no endDate OR endDate is after today
   */
  return agenda
    ? agenda.filter(availability => {
        const c1 = moment().subtract(7, "days").isSameOrBefore(availability.date, "day")
        return c1
      })
    : []
}

// export function mergeAvailabilities(
//   filteredAgenda: AddressAvailability[],
//   updatedAvailabilities: UpdatedAvailabilities,
// ): Availability[] {
//   const { created, updated, deleted } = updatedAvailabilities
//   return [
//     ...filteredAgenda
//       .filter(availability => !deleted.find(({ id }) => id === availability.id))
//       .map(availability => updated.find(({ id }) => id === availability.id) || availability),
//     ...created,
//   ]
// }
