import styled from "styled-components"
import { COLOR } from "utils/color"

export const KeeperScoringContainer = styled.div`
  display: flex;
`

export const PopupTrigger = styled.div<{ color?: string }>(
  ({ color }) => `
    ${color ? "background-color:" + color : ""};
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
`,
)

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
`

export const PopupTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`

export const PopupRate = styled.div<{ color?: string }>(
  ({ color }) => `
  ${color ? "color:" + color : ""};
  font-size: large;
  margin-bottom: 10px;
  text-align: center;
`,
)

export const PopupDescription = styled.div<{ color?: string }>(
  ({ color }) => `
  ${color ? "color:" + color : ""};
  margin-bottom: 10px;
  text-align: center;
  font-weight: 300;
  font-size: 0.9em;
`,
)

export const PopupRangeContainer = styled.div<{ color?: string }>(
  ({ color }) => `
  ${color ? "color:" + color : ""};
  display: flex;
  align-items: center;
  font-size: smaller;
  margin-bottom: 10px;
  font-weight: 300;
`,
)

export const PopupRedRange = styled.div<{ color?: string }>(
  ({ color }) => `
  width: 7px;
  height: 7px;
  ${color ? "border: 1px solid" + color : ""};
  border-radius: 50%;
  margin-right: 5px;
`,
)

export const PopupOrangeRange = styled.div<{ color?: string }>(
  ({ color }) => `
  width: 7px;
  height: 7px;
  ${color ? "border: 1px solid" + color : ""};
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 10px;
`,
)

export const PopupGreenRange = styled.div<{ color?: string }>(
  ({ color }) => `
  width: 7px;
  height: 7px;
  ${color ? "border: 1px solid" + color : ""};
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 10px;
`,
)

export const PopupCarrierDetail = styled.div`
  margin-right: auto;
  line-height: 12px;
`

export const PopupCarrierLine = styled.div`
  font-size: smaller;
`

export const NbParcelsContainer = styled.div<{ marginTop?: boolean; marginBottom?: boolean }>(
  ({ marginTop, marginBottom }) => `
  color: ${COLOR.GREY};
  font-size: 0.9em;
  ${marginBottom ? "margin-bottom: 5px;" : ""}
  ${marginTop ? "margin-top: 8px;" : ""};
`,
)

export const LiveStatsFlag = styled.div`
  color: ${COLOR.GREY};
  font-size: 0.9em;
  margin-bottom: 5px;
`
