import { Icon } from "semantic-ui-react"
import styled from "styled-components"

import { COLOR } from "utils/color"

export const CopyIcon = styled(Icon).attrs({ name: "copy outline" })`
  color: ${COLOR.MEDIUM_SUPER_LIGHT_GREY};
  padding-left: 5px;
  &:hover {
    color: ${COLOR.LIGHT_GREY};
  }
  &:active {
    color: ${COLOR.BLACK};
  }
`
