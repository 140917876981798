import React from "react"
import { FieldsRow, Section, SectionTitle, StyledInput, StyledLabelInput } from "./RequestReturnModal.styled"
import { Col } from "reactstrap"
import DateInput from "components/Form/DateInput"
import moment from "moment"
import Select from "components/Form/Select"
import { hoursOptions } from "utils/formik/hoursOptions"
import { CarriersName } from "types/carrier.types"
import { getHoursSlotFilter } from "./helpers"
import { ReturnRequestFieldKeys, ReturnRequestFormData } from "types/return-request.types"

interface DateFieldsProps {
  returnRequestFormData: ReturnRequestFormData
  changeData: (key: ReturnRequestFieldKeys, value) => void
  collectData: {
    maximumHour: string
    minimumHour: string
    minimumSlotDuration: number
  }
}

export const DateFields = ({ returnRequestFormData, changeData, collectData }: DateFieldsProps) => {
  const selectedReturningCarrierName = returnRequestFormData?.returningCarrier?.data?.name
  return (
    <Section>
      <SectionTitle>🗓️ Date</SectionTitle>
      <FieldsRow>
        <Col xs={3}>
          <div>
            <StyledLabelInput>Date de collecte</StyledLabelInput>
            <DateInput
              value={returnRequestFormData.pickupDate}
              min={moment().format("YYYY-MM-DD")}
              onChange={event => changeData(ReturnRequestFieldKeys.pickupDate, event.target.value)}
              containerStyles={{ width: "auto" }}
              inputStyles={{ margin: 0 }}
            />
          </div>
        </Col>
        <Col xs={3}>
          <div>
            <StyledLabelInput>Début du créneau</StyledLabelInput>
            <Select
              value={returnRequestFormData.timeIntervalStart}
              isDisabled={false}
              onChange={hour => changeData(ReturnRequestFieldKeys.timeIntervalStart, hour)}
              options={hoursOptions.filter(({ value }) =>
                getHoursSlotFilter(
                  ReturnRequestFieldKeys.timeIntervalStart,
                  value,
                  collectData,
                  returnRequestFormData?.timeIntervalStart?.value,
                ),
              )}
              placeholder=""
            />
          </div>
        </Col>
        <Col xs={3}>
          <div>
            <StyledLabelInput>Fin du créneau</StyledLabelInput>
            <Select
              value={returnRequestFormData.timeIntervalEnd}
              isDisabled={false}
              onChange={hour => changeData(ReturnRequestFieldKeys.timeIntervalEnd, hour)}
              options={hoursOptions.filter(({ value }) =>
                getHoursSlotFilter(
                  ReturnRequestFieldKeys.timeIntervalEnd,
                  value,
                  collectData,
                  returnRequestFormData?.timeIntervalStart?.value,
                ),
              )}
              placeholder=""
            />
          </div>
        </Col>

        {selectedReturningCarrierName === CarriersName.GLS && (
          <Col xs={3}>
            <StyledLabelInput>Le code de référence</StyledLabelInput>
            <StyledInput
              fluid
              required
              value={returnRequestFormData.referenceCode}
              onChange={e => changeData(ReturnRequestFieldKeys.referenceCode, e.target.value)}
            />
          </Col>
        )}

        {selectedReturningCarrierName === CarriersName.TUT_TUT && (
          <Col xs={3}>
            <StyledLabelInput>Le code de cotransporteur</StyledLabelInput>
            <StyledInput
              fluid
              required
              value={returnRequestFormData.cotransportationCode}
              onChange={e => changeData(ReturnRequestFieldKeys.cotransportationCode, e.target.value)}
            />
          </Col>
        )}
      </FieldsRow>
    </Section>
  )
}
