import React, { ReactElement } from "react"

import {
  Container,
  Data,
  GroupTitle,
  Subtitle,
  Title,
} from "components/SearchKeeperMonitoring/AnalyzedPickupPoints.styled"
import { Emoji } from "components/Emoji/Emoji"
import { CompetitorPickupPoints as CompetitorPickupPointsProps } from "types/competitor.types"
import { displayDistance } from "utils/distance.util"

function CompetitorsPickupPoints({
  pickupPoint,
  distance,
}: {
  pickupPoint: CompetitorPickupPointsProps
  distance?: number
}): ReactElement {
  const { competitorNetwork, formattedLineAddress, name } = pickupPoint

  return (
    <Container>
      <Title>{name}</Title>
      <Subtitle>{formattedLineAddress}</Subtitle>

      <GroupTitle>
        <Emoji label="lorry">🚚</Emoji> Réseau concurrent
      </GroupTitle>
      <Data>{competitorNetwork.name}</Data>

      {typeof distance === "number" && (
        <>
          <GroupTitle>
            <Emoji label="ruler">📏</Emoji> Distance au point
          </GroupTitle>
          <Data>{displayDistance(distance * 1000)}</Data>
        </>
      )}
    </Container>
  )
}

export default CompetitorsPickupPoints
