import styled from "styled-components"

import { COLOR } from "utils/color"

export const MiniButton = styled.div`
  padding: 3px 6px;
  margin-right: 5px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 9px;
  position: relative;
  background-color: ${COLOR.SUPER_LIGHT_GREEN};
  width: fit-content;

  &:hover {
    background-color: ${COLOR.LIGHT_GREEN};
  }
`

export const MiniButtonCancel = styled.div`
  padding: 3px 6px;
  margin-right: 5px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 9px;
  position: relative;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  width: fit-content;
  align-self: flex-start;

  &:hover {
    background-color: ${COLOR.MEDIUM_SUPER_LIGHT_GREY};
  }

  &:first-of-type {
    margin-left: 3px;
  }
`
