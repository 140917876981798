import { gql } from "@apollo/client"
import { CompanyBillingPayment, CompanyCurrentBill } from "types/company-billing.types"

export interface CompanyBillingPaymentsData {
  getCompanyBillingPayments: CompanyBillingPayment[]
}

export interface CompanyBillingPaymentsVariables {
  companyId: string
  year: string
}

export const getCompanyBillingPayments = gql`
  query getCompanyBillingPayments($companyId: String!, $year: String!) {
    getCompanyBillingPayments(companyId: $companyId, year: $year) {
      id
      amount
      month
      year
      status
      details {
        period {
          start
          end
        }
        deliveries {
          nbParcels
          total
          amount
        }
        returning {
          total
        }
      }
    }
  }
`

export interface CompanyCurrentBillData {
  getCompanyCurrentBill: CompanyCurrentBill
}

export interface CompanyCurrentBillVariables {
  companyId: string
}

export const getCompanyCurrentBill = gql`
  query getCompanyCurrentBill($companyId: String!) {
    getCompanyCurrentBill(companyId: $companyId) {
      id
      status
      amount
      month
      year
      details {
        deliveries {
          nbParcels
          total
          amount
        }
        returning {
          total
        }
      }
    }
  }
`

export interface CompanyBillingStatsData {
  getCompanyBillingStats: {
    total: number
    toBill: number
    waitingPayment: number
    paid: number
  }
}

export interface CompanyBillingStatsVariables {
  companyId: string
}

export const getCompanyBillingStats = gql`
  query getCompanyBillingStats($companyId: String!) {
    getCompanyBillingStats(companyId: $companyId) {
      total
      toBill
      waitingPayment
      paid
    }
  }
`
