import {
  API_ASK_KEEPER_WALLET_TRANSFER,
  API_CLEAR_AGENDA,
  API_GET_KEEPERS_BY_ZIPCODES,
  API_GET_KEEPER_WALLET_DETAILS,
  API_GET_KEEPER_PARCELS,
  API_REJECT_KEEPER,
  API_REACTIVATE_KEEPER,
  API_SEARCH_KEEPER,
  RESET_AVAILABILITIES,
  RESET_FOCUS_KEEPER,
  RESET_FOCUS_KEEPER_PARCELS,
  API_GET_KEEPER_PARCELS_STATS,
  API_UPDATE_ALLOWED_CARRIER,
} from "actions/keepers"
import { KeeperCarrier } from "types/carrier.types"
import { FctServiceReturn } from "services/services.types"

export function apiGetKeepersByZipcodes(_data) {
  return {
    type: API_GET_KEEPERS_BY_ZIPCODES,
    payload: {
      request: {
        url: `/admin/keepers/zipcodes`,
        method: "POST",
        data: _data,
      },
    },
  }
}

export function apiGetKeeperWalletDetails(walletId: string, take: number, skip: number) {
  return {
    type: API_GET_KEEPER_WALLET_DETAILS,
    payload: {
      request: {
        url: `/admin/keepers/wallet/${walletId}/details?take=${take}&skip=${skip}`,
      },
    },
  }
}

export function apiSearchKeeper(input: string): FctServiceReturn<any> {
  return {
    type: API_SEARCH_KEEPER,
    payload: {
      request: {
        url: `/admin/keepers/search`,
        method: "POST",
        data: { search: input },
      },
    },
  }
}

export function apiClearAgenda(_id) {
  return {
    type: API_CLEAR_AGENDA,
    payload: {
      request: {
        url: `/admin/keepers/${_id}/clearAgenda`,
        method: "POST",
        data: {},
      },
    },
  }
}

export function apiRejectKeeper(_id) {
  return {
    type: API_REJECT_KEEPER,
    payload: {
      request: {
        url: `/admin/keepers/${_id}/reject`,
        method: "POST",
        data: {},
      },
    },
  }
}

export function apiAskKeeperWalletTransfer(_id) {
  return {
    type: API_ASK_KEEPER_WALLET_TRANSFER,
    payload: {
      request: {
        url: `/admin/keepers/${_id}/transferBalance`,
        method: "POST",
        data: {},
      },
    },
  }
}

export function apiAskKeeperWalletDonation(_id, associationId) {
  return {
    type: API_ASK_KEEPER_WALLET_TRANSFER,
    payload: {
      request: {
        url: `/admin/keepers/${_id}/transferKeeperBalanceToAssociation/${associationId}`,
        method: "POST",
        data: {},
      },
    },
  }
}

export function apiAskKeeperWalletGiveaway(_id) {
  return {
    type: API_ASK_KEEPER_WALLET_TRANSFER,
    payload: {
      request: {
        url: `/admin/keepers/${_id}/transferBalanceToGiveAway`,
        method: "POST",
        data: {},
      },
    },
  }
}

export function resetFocusKeeper() {
  return {
    type: RESET_FOCUS_KEEPER,
  }
}

export function resetAvailabilities() {
  return {
    type: RESET_AVAILABILITIES,
  }
}

export const apiGetKeeperParcels =
  (id: string) =>
  (nbResults: number, offset: string = null, parcelStatus: string = null) => {
    return {
      type: API_GET_KEEPER_PARCELS,
      payload: {
        request: {
          url: `/admin/keepers/${id}/parcels?first=${nbResults}${offset ? `&after=${offset}` : ""}${
            parcelStatus ? `&filter=${parcelStatus}` : ""
          }`,
        },
        filter: parcelStatus,
      },
    }
  }

export function resetFocusKeeperParcels() {
  return {
    type: RESET_FOCUS_KEEPER_PARCELS,
  }
}

export function apiGetKeeperParcelsStats(id: string) {
  return {
    type: API_GET_KEEPER_PARCELS_STATS,
    payload: {
      request: {
        url: `/admin/keepers/${id}/parcelsStats`,
      },
    },
  }
}

export function apiUpdateAllowedCarrier(keeperId: string, carrier: KeeperCarrier) {
  return {
    type: API_UPDATE_ALLOWED_CARRIER,
    payload: {
      request: {
        url: `/admin/keepers/${keeperId}/allowed-carriers/${carrier.carrierId}`,
        method: "PUT",
        data: { allowed: !carrier.allowed },
      },
      carrierName: carrier.carrierName,
    },
  }
}

export function apiReactivateKeeper(keeperId: string) {
  return {
    type: API_REACTIVATE_KEEPER,
    payload: {
      request: {
        url: `/admin/keepers/${keeperId}/reactivate`,
        method: "PUT",
      },
    },
  }
}
