import { Parcel } from "types/parcel.types"

export enum CompanyBillingEntryType {
  CARRIER_DELIVERY = "CARRIER_DELIVERY",
  PARCEL_RETURN = "PARCEL_RETURN",
  PARCEL_DROPOFF = "PARCEL_DROPOFF",
  PARCEL_CANCELLED = "PARCEL_CANCELLED",
}

export enum CompanyBillingEntryStatus {
  NEW = "NEW",
  PROCESSING = "PROCESSING",
  DONE = "DONE",
}

export enum CompanyBillingPaymentStatus {
  NEW = "NEW",
  PROCESSING = "PROCESSING",
  ERROR = "ERROR",
  DONE = "DONE",
}

export interface CompanyAccountancy {
  id: string
  pricing: CompanyBillingPricing
  entries: CompanyBillingEntry[]
  payments: CompanyBillingPayment[]
}

export interface CompanyBillingEntry {
  id: string
  type: CompanyBillingEntryType
  parcels: Parcel[]
  accountancyId: string
  accountancy: CompanyAccountancy
  paymentId: string
  payment: CompanyBillingPayment | null
  status: CompanyBillingEntryStatus
  createdAt: Date
  updatedAt: Date
}

export interface CompanyBillingPayment {
  id: string
  amount: number
  pdfUrl: string
  month: number
  year: number
  period: string
  details: CompanyBillingPaymentDetails
  accountancyId: string
  accountancy: CompanyAccountancy
  entries: CompanyBillingEntry[]
  status: CompanyBillingPaymentStatus
  createdAt: Date
  updatedAt: Date
}

export interface CompanyBillingPaymentDetails {
  period: {
    start: string
    end: string
  }
  deliveries: {
    nbParcels: number
    total: number
    amount: number
  }[]
  returning: {
    total: number
  }
}

export interface CompanyBillingPricing {
  id: string
  details: CompanyBillingPricingDetails[]
  uniquePrice: number
  publicPrice: number
  accountancyId: string
  accountancy: CompanyAccountancy
}

export interface CompanyBillingPricingDetails {
  trancheMinimum?: number
  trancheMaximum?: number
  prices?: {
    nbParcels: number
    price: number
  }[]
}

export interface CompanyCurrentBill {
  id: string
  status: string | null
  amount: number
  month: number
  year: number
  details: CompanyBillingPaymentDetails
}
