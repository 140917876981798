import React, { ReactElement, useState } from "react"
import { Input } from "semantic-ui-react"

import ZendeskLogo from "assets/components/zendesk_logo.png"
import { MiniButton, MiniButtonCancel } from "components/Buttons/MiniButton"
import { NoteHandlerContainer, NoteText, Textarea, ZendeskLink } from "components/Notes/NoteHandler.styled"
import { NoteHandlerProps } from "types/notes.types"

const NoteHandler = ({
  note,
  createNote,
  updateNote,
  style,
  witdhEditableContainer,
}: NoteHandlerProps): ReactElement => {
  const [data, setData] = useState({
    text: note?.text || "",
    zendeskLink: note?.zendeskLink || "",
  })

  const [editable, setEditable] = useState(false)

  const _onChange = (key, value) => setData({ ...data, [key]: value })

  const _editNote = () => {
    setData({
      text: note?.text || "",
      zendeskLink: note?.zendeskLink || "",
    })
    setEditable(true)
  }

  const _createNote = async () => {
    await createNote(data)
    setEditable(true)
  }

  if (!note) {
    return (
      <MiniButton onClick={() => _createNote()} style={{ marginTop: 10, height: 20, ...style }}>
        Créer une note
      </MiniButton>
    )
  }

  if (!editable) {
    return (
      <NoteHandlerContainer style={{ ...style, ...witdhEditableContainer }}>
        <div className="title">📝 Note</div>
        <ZendeskLink
          onClick={() =>
            // eslint-disable-next-line no-undef
            note.zendeskLink !== "" && note.zendeskLink !== null && window.open(note.zendeskLink, "_blank")
          }
        >
          <img src={ZendeskLogo} style={{ width: 12, height: 10, marginRight: 10 }} />{" "}
          {note.zendeskLink === "" || (note.zendeskLink === null && <i>-</i>)}
          {note.zendeskLink !== "" && note.zendeskLink !== null && <u>{`${note.zendeskLink}`}</u>}
        </ZendeskLink>
        <NoteText>{note.text}</NoteText>
        <MiniButton onClick={() => _editNote()}>Modifier</MiniButton>
      </NoteHandlerContainer>
    )
  }

  if (editable) {
    return (
      <NoteHandlerContainer style={{ ...style, ...witdhEditableContainer }}>
        <div className="title">📝 Note</div>
        <Input
          fluid
          placeholder="Lien Zendesk"
          style={{ marginTop: "1em" }}
          required
          onChange={e => _onChange("zendeskLink", e.target.value)}
          className="inputText"
          value={data.zendeskLink}
        />
        <Textarea
          name="text"
          id=""
          cols={20}
          rows={3}
          onChange={event => _onChange("text", event.target.value)}
          value={data.text}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <MiniButton
            onClick={() => {
              setEditable(false)
              updateNote(data)
            }}
          >
            Valider
          </MiniButton>
          <MiniButtonCancel
            onClick={() => {
              setEditable(false)
            }}
          >
            Annuler
          </MiniButtonCancel>
        </div>
      </NoteHandlerContainer>
    )
  }
}

export default NoteHandler
