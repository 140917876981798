import React from "react"

export default function StatHeader({ title, data }) {
  return (
    <div className="statHeader">
      <div className="title">{title}</div>
      <div className="data">{data}</div>
    </div>
  )
}
