import styled from "styled-components"

import { COLOR } from "utils/color"

export const ZendeskLink = styled.div`
  margin-top: 1em;
  font-size: 0.8em;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

export const NoteText = styled.div`
  height: 72px;
  white-space: pre-wrap;
  overflow-y: auto;
`

export const Textarea = styled.textarea`
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.25em;
  padding: 0.65em 1em !important;
  font-size: 1em;
  outline: 0;

  &:focus {
    border-color: ${COLOR.MEDIUM_LIGHT_BLUE};
    background: ${COLOR.WHITE};
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

export const NoteHandlerContainer = styled.div`
  display: flex;
  flex-direction: column;
`
