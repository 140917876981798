import React, { ReactElement, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import "semantic-ui-css/semantic.min.css"
import { Route, Redirect } from "react-router-dom"
import { Loader } from "semantic-ui-react"
import { Slide, ToastContainer } from "react-toastify"
import * as jwt from "jsonwebtoken"

import client from "config/agent"
import { logOutUser } from "services/auth"
import Main from "components/AppStructure/Main"
import { getRoutingConfig } from "config/routing"
import AppHeader from "components/AppStructure/AppHeader"
import AppSidebar from "modules/AppSidebar"
import { ReduxState } from "types/reduxState.types"

interface HomeProps {
  history: any
}

export default function Home({ history }: HomeProps): ReactElement {
  const dispatch = useDispatch()

  const [isInit, setIsInit] = useState(false)
  const [isTokenSetup, setIsTokenSetup] = useState(false)
  const [redirectTo, setRedirectTo] = useState(null)
  const { token, isLogged, role } = useSelector((state: ReduxState) => state.auth)

  useEffect(() => {
    if (token !== null) {
      const decodedToken = jwt.decode(token, { complete: true })
      const dateNow = new Date()

      if (decodedToken.payload.expiredAt < dateNow.getTime()) {
        dispatch(logOutUser())
      } else {
        client.defaults.headers.common["x-pickme-authorization"] = `Bearer ${token}`
        // eslint-disable-next-line no-undef
        window.localStorage.setItem("API_TOKEN", token)

        setIsTokenSetup(true)
        setIsInit(true)
      }
    }
  }, [])

  const logout = () => dispatch(logOutUser())

  if (!isLogged) {
    return <Redirect to="/login" />
  }

  if (!isInit && !isTokenSetup) {
    return <Loader active />
  }

  return (
    <>
      <Main>
        <AppHeader logout={logout} />
        <div className="app-main">
          <AppSidebar redirectTo={setRedirectTo} />
          <div className="app-main__outer">
            <div className="app-main__inner">
              {getRoutingConfig(role).map(route => {
                return <RouteWithSubRoutes {...route} key={`route${route.path}`} />
              })}
              {history.location.pathname === "/" && <Redirect to="/parcels" />}
              {redirectTo && <Redirect to={redirectTo} />}
            </div>
          </div>
        </div>
      </Main>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        transition={Slide}
        style={{ top: "5rem" }}
      />
    </>
  )
}

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      {...route.props}
      render={matchProps => (
        // pass the sub-routes down to keep nesting
        <route.component {...matchProps} routes={route.routes} />
      )}
    />
  )
}
