import React, { ReactElement } from "react"
import SimpleTable from "components/Table/SimpleTable"
import { CompanyBillingPricing } from "types/company-billing.types"

const PricingTable = ({ pricing, loading }: { pricing: CompanyBillingPricing; loading: boolean }): ReactElement => {
  if (pricing === null) return <div className="no-content">Aucun pricing pour cette entreprise</div>

  // Only take the first tranche
  const prices = pricing.details[0].prices

  return (
    <SimpleTable
      headers={[
        {
          name: "# Colis",
          size: 6,
          value: "nbParcels",
        },
        {
          name: "Tarif HT",
          size: 6,
          value: "price",
          component: price => `${price / 100}€`,
        },
      ]}
      data={prices}
      loading={loading}
    />
  )
}

export default PricingTable
