import React, { ReactElement } from "react"

import { TextStatusContainer, TextStatusTitle } from "components/Keeper/Document/KeeperDocumentStatus.styled"
import { DocumentStatus } from "types/document.types"
import { COLOR } from "utils/color"

interface KeeperDocumentStatusProps {
  status: DocumentStatus
}

function KeeperDocumentStatus({ status }: KeeperDocumentStatusProps): ReactElement {
  const statusTitle = (status: DocumentStatus) => {
    switch (status) {
      case DocumentStatus.VALIDATED:
        return "Vérifié"
      case DocumentStatus.PENDING:
        return "À renvoyer"
      case DocumentStatus.NEW:
        return "Nouveau"
      case DocumentStatus.REJECTED:
        return "Rejeté"
    }
  }

  const statusColor = (status: DocumentStatus) => {
    switch (status) {
      case DocumentStatus.VALIDATED:
        return COLOR.MEDIUM_LIGHT_GREEN
      case DocumentStatus.PENDING:
        return "#f7c564"
      case DocumentStatus.NEW:
        return "#f7c564"
      case DocumentStatus.REJECTED:
        return COLOR.CORAIL
      default:
        return COLOR.BLACK
    }
  }

  return (
    <TextStatusContainer statusColor={statusColor(status)}>
      <TextStatusTitle>{statusTitle(status)}</TextStatusTitle>
    </TextStatusContainer>
  )
}

export default KeeperDocumentStatus
