import React, { useEffect, useState } from "react"
import {
  ActionType,
  AddBillingEntry,
  BillingEntry,
  CompanyBillingEntryDataItem,
  TargetEntryType,
  WalletEntryDataItem,
} from "types/billing-modal.types"
import {
  getBillingEntryName,
  getCompanyBillingEntryData,
  getWalletEntryData,
  getWalletEntryName,
} from "components/ParcelBilling/ParcelBillingModal/datas/BillingModalDatas"

type UseScanParcelsModalType = {
  billingUtils: {
    addBillingEntry: ({ id, actionType, targetEntry, entryText, entryType }: AddBillingEntry) => void
    removeBillingEntry: ({ id }: { id: string }) => void
    COMPANY_BILLING_ENTRY_DATA: CompanyBillingEntryDataItem[]
    WALLET_ENTRY_DATA: WalletEntryDataItem[]
    billingEntries: BillingEntry[]
  }
  modal: {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
  }
  reasonValue: string
  handleReasonChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const useParcelBillingModal = (companyBillingEntries = [], walletEntries = []): UseScanParcelsModalType => {
  const [open, setOpen] = useState<boolean>(false)
  const [reasonValue, setReasonValue] = useState<string>("")
  const [billingEntries, setBillingEntries] = useState<BillingEntry[]>([])

  const onClose = () => {
    setOpen(false)
    setBillingEntries([])
    setReasonValue("")
  }

  const onOpen = () => setOpen(true)

  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => setReasonValue(e.target.value)

  /** Function to add a billing entry */
  const addBillingEntry = ({ id, actionType, targetEntry, entryText, entryType }: AddBillingEntry) => {
    setBillingEntries([
      ...billingEntries,
      {
        id,
        actionType,
        targetEntry,
        entryType,
        entryText,
        updatedAt: new Date(),
      },
    ])
  }

  /**
   * Function to delete a billing entry
   * If the item was recently added (green background), it will be removed from the list
   * If the item already had the status ActionType.REMOVE (red background), its status will be changed to ActionType.NEUTRAL
   * If its status was ActionType.NEUTRAL (grey background), it will be changed to ActionType.REMOVE
   */
  const removeBillingEntry = ({ id }: { id: string }) => {
    const billingEntry: BillingEntry = billingEntries.find(billingEntry => billingEntry.id === id)

    if (billingEntry.actionType === ActionType.ADD) {
      setBillingEntries(billingEntries.filter(action => action.id !== id))
    } else if (billingEntry.actionType === ActionType.REMOVE) {
      setBillingEntries(
        billingEntries.map(action =>
          action.id === billingEntry.id ? { ...action, actionType: ActionType.NEUTRAL, updatedAt: new Date() } : action,
        ),
      )
    } else if (billingEntry.actionType === ActionType.NEUTRAL) {
      setBillingEntries(
        billingEntries.map(action =>
          action.id === billingEntry.id ? { ...action, actionType: ActionType.REMOVE, updatedAt: new Date() } : action,
        ),
      )
    }
  }

  useEffect(() => {
    if (!open) return

    /**
     * Creating a unified array to store all items from companyBillingEntries and walletEntries
     * If a billing entry was already attached to a parcel, its status will be ActionType.NEUTRAL, and can be changed to ActionType.REMOVE
     */
    const newBillingEntries = [...companyBillingEntries, ...walletEntries].map(billingEntry => ({
      id: billingEntry.id,
      actionType: ActionType.NEUTRAL,
      entryType: billingEntry.type,
      entryText: billingEntry.walletId ? getWalletEntryName(billingEntry.type) : getBillingEntryName(billingEntry.type),
      targetEntry: billingEntry.walletId ? TargetEntryType.WALLET : TargetEntryType.BILLING,
      updatedAt: new Date(),
      ...(billingEntry.walletId && { amount: billingEntry?.amount }),
    }))
    setBillingEntries(newBillingEntries)
  }, [open])

  /**
   * Array of "Facturation" objects that can be added for sending to the server
   */
  const COMPANY_BILLING_ENTRY_DATA = getCompanyBillingEntryData(
    billingEntries.filter(billingEntry => billingEntry.targetEntry === TargetEntryType.BILLING),
  )

  /**
   * Array of "Crédits" objects that can be added for sending to the server,
   */
  const WALLET_ENTRY_DATA = getWalletEntryData(
    billingEntries.filter(billingEntry => billingEntry.targetEntry === TargetEntryType.WALLET),
  )

  return {
    modal: {
      isOpen: open,
      onClose,
      onOpen,
    },
    billingUtils: {
      billingEntries,
      addBillingEntry,
      removeBillingEntry,
      COMPANY_BILLING_ENTRY_DATA,
      WALLET_ENTRY_DATA,
    },
    reasonValue,
    handleReasonChange,
  }
}
