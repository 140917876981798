import { CompetitorPickupPoints } from "types/competitor.types"
import { getCoordinateRangeAroundPoint } from "utils/distance.util"

const RADIUS_IN_KM = 3

export const getAllSelectedCompetitorsPickupPoints = (
  competitors: { pickupPoints: { data: CompetitorPickupPoints[] } },
  viewport: { latitude: number; longitude: number },
): CompetitorPickupPoints[] => {
  const allSelectedCompetitorsPickupPoints = []
  if (competitors.pickupPoints.data.length === 0) return allSelectedCompetitorsPickupPoints

  Object.keys(competitors.pickupPoints.data).forEach(competitor => {
    allSelectedCompetitorsPickupPoints.push(...competitors.pickupPoints.data[competitor])
  })

  const rangeCoord = getCoordinateRangeAroundPoint(viewport.latitude, viewport.longitude, RADIUS_IN_KM)
  const givenRadius = allSelectedCompetitorsPickupPoints.filter(
    pickupPoint =>
      pickupPoint.latitude > rangeCoord.latMin &&
      pickupPoint.latitude < rangeCoord.latMax &&
      pickupPoint.longitude > rangeCoord.longMin &&
      pickupPoint.longitude < rangeCoord.longMax,
  )
  return givenRadius
}
