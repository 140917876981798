import React, { ReactElement, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Col, Row } from "reactstrap"
import { Loader } from "semantic-ui-react"
import moment from "moment"

import Title from "components/Title"
import StatHeader from "components/StatHeader"
import LineCollapsableElement from "components/List/LineCollapsableElement"
import CollapsibleIntegrationUpdate from "components/IntegrationUpdate/IntegrationCollapsible"
import Select from "components/Form/Select"
import DateInput from "components/Form/DateInput"
import LineItemIntegrationUpdate from "components/IntegrationUpdate/IntegrationLineItem"
import { apiGetCompanies } from "services/companies"
import { apiGetIntegrationUpdates, resetIntegrationUpdates, apiGetIntegrationStats } from "services/system"
import { ReduxState } from "types/reduxState.types"
import generateCompaniesOptions from "utils/formik/generateCompaniesOptions"

export function IntegrationMonitoring(): ReactElement {
  const [pagination, setPagination] = useState({ take: 15, skip: 0 })
  const [collapseId, setCollapseId] = useState(null)
  const [company, setCompany] = useState({ value: "", label: "" })
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"))

  const integrationUpdates = useSelector((state: ReduxState) => state.integrationUpdates)
  const companies = useSelector((state: ReduxState) => state.companies)
  const dispatch = useDispatch()

  const { moreToLoad, data, loading } = integrationUpdates.list

  const fetchNext = async () => {
    // TODO: await not awaiting here
    await dispatch(apiGetIntegrationUpdates(company.value, date, pagination.skip, pagination.take))
    // Add +15 to skip param
    setPagination({ ...pagination, skip: data.length + 15 })
  }

  const collapseElt = (_collapseId: string) => {
    setCollapseId(collapseId === null ? _collapseId : null)
  }

  const reset = async () => {
    setPagination({ take: 15, skip: 0 })
    await dispatch(resetIntegrationUpdates())
  }

  const changeCompany = companySelected => {
    reset()
    setCompany(companySelected)
  }

  const changeDate = event => {
    reset()
    setDate(event.target.value)
  }

  useEffect(() => {
    reset()
    if (companies.list.data.length === 0) dispatch(apiGetCompanies())
    dispatch(apiGetIntegrationStats())
  }, [])

  return (
    <div style={{ flex: 1, padding: 10 }}>
      <div style={{ padding: 30 }}>
        <Title>🛰 Integration Updates</Title>

        <div className="header-stats">
          <StatHeader title="Total" data={integrationUpdates.stats.data.nb} />
          <StatHeader title="Moy. Exec time" data={`${integrationUpdates.stats.data.executionTime / 1000}s`} />
        </div>

        <Row style={{ marginTop: "1em", alignItems: "flex-end" }}>
          <Col xs={2}>
            <Select
              value={company}
              onChange={changeCompany}
              options={generateCompaniesOptions(companies.list.data)}
              placeholder="Choisir"
              label="Transporteur"
            />
          </Col>
          <Col xs={2}>
            <DateInput label={"Au"} value={date} onChange={changeDate} />
          </Col>
          <Col xs={2}>
            <Button className="search-button" onClick={() => fetchNext()}>
              Chercher
            </Button>
          </Col>
        </Row>
      </div>

      {data.map(update => (
        <LineCollapsableElement
          key={`collapsible${update.id}`}
          collapseId={collapseId}
          collapseElt={collapseElt}
          element={update}
          lineHeight="lg"
          lineData={<LineItemIntegrationUpdate element={update} />}
          collapsibleData={<CollapsibleIntegrationUpdate element={update} />}
        />
      ))}

      <br />
      {loading && <Loader active inline />}
      {moreToLoad && (
        <Button onClick={fetchNext} disabled={company.value === ""}>
          Charger les plus anciens
        </Button>
      )}
    </div>
  )
}

export default IntegrationMonitoring
