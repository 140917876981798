import React from "react"

const TabMenu = ({ title, active, onClick }) => {
  return (
    <div className={`tab-menu ${active ? "active" : ""}`} onClick={() => onClick()}>
      {title}
    </div>
  )
}

export default TabMenu
