export interface Meeting {
  id: string
  type: MeetingType
  orderId: string
  // order: Promise<Order>
  cancelled: boolean
  cancelledBy: MeetingCancelledBy
  eventStart: Date
  eventEnd: Date
  createdAt: Date
  updatedAt: Date
}

export enum MeetingCancelledBy {
  KEEPER = "KEEPER",
  ADMIN = "ADMIN",
  FINAL_CLIENT = "FINAL_CLIENT",
  CARRIER = "CARRIER",
}

export enum MeetingType {
  DROPOFF = "DROPOFF",
  COLLECT = "COLLECT",
}

export interface MeetingSlot {
  eventStart: Date
  eventEnd: Date
}
