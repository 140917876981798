import { CarriersName } from "../types/carrier.types"

export const getTranslatedCarrierName = (name: string) => {
  switch (name) {
    case CarriersName.POST_OFFICE:
      return "La Poste - Bureau"
    default:
      return name
  }
}
