const generateOrdersOptions = _options => {
  return _options
    .map(opt => ({
      value: opt["id"],
      label: `💳 ${opt.orderNo}`,
      data: opt,
      type: "ORDER",
      color: "#3250a8",
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export default generateOrdersOptions
