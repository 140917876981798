import React, { ReactElement } from "react"
import {
  Section,
  SectionsWrapper,
  SummaryItem,
  SummaryList,
  SummaryTitle,
} from "components/ParcelBilling/ParcelBillingModal/ParcelBillingModal.styled"
import { Name } from "components/ParcelBilling/ParcelBilling.styled"
import { ActionType, BillingEntry, TargetEntryType } from "types/billing-modal.types"
import { COLOR } from "utils/color"

interface SummarySectionProps {
  billingEntries: BillingEntry[]
  isActionChosen: boolean
}

export const SummarySection = ({ billingEntries, isActionChosen }: SummarySectionProps): ReactElement => {
  const getEntryBillingText = (billingEntry: BillingEntry): string => {
    const firstTextPart =
      billingEntry.targetEntry === TargetEntryType.BILLING
        ? billingEntry.actionType === ActionType.ADD
          ? "Ajout de la facturation"
          : "Suppression de la facturation"
        : billingEntry.actionType === ActionType.ADD
        ? "Ajout du crédit"
        : "Suppression du crédit"

    const secondTextPart =
      billingEntry.targetEntry === TargetEntryType.BILLING
        ? `“${billingEntry.entryText}“`
        : `“${billingEntry.entryText}“ ${
            billingEntry.actionType === ActionType.REMOVE ? `${billingEntry.amount / 100}€` : ""
          }`

    return `${firstTextPart} ${secondTextPart}`
  }

  return (
    <SectionsWrapper marginBottom={50}>
      <Section>
        <SummaryTitle>Récapitulatif des modifications</SummaryTitle>
        {!isActionChosen && <Name>-</Name>}
        <SummaryList>
          {billingEntries
            .filter((billingEntry: BillingEntry) => billingEntry.actionType !== ActionType.NEUTRAL)
            .sort(
              (firstBillingEntry, secondBillingEntry) =>
                Number(new Date(firstBillingEntry.updatedAt)) - Number(new Date(secondBillingEntry.updatedAt)),
            )
            .map((billingEntry: BillingEntry) => (
              <SummaryItem
                color={billingEntry.actionType === ActionType.ADD ? COLOR.GREEN : COLOR.RED}
                key={billingEntry.id}
              >
                {getEntryBillingText(billingEntry)}
              </SummaryItem>
            ))}
        </SummaryList>
      </Section>
    </SectionsWrapper>
  )
}
