import React, { ReactElement } from "react"
import { AddressAvailability, AvailabilitySlotType, AvailabilityType } from "types/agenda.types"

import { AvailabilitySlotContent, AvailabilitySlotWrapper, AvailibilitySlotParagraph } from "./AvailabilitySlot.styled"
import FeatherIcon from "feather-icons-react"
import { COLOR } from "utils/color"

const SLOT_TYPE_PROPS = {
  morning: {
    iconName: "bell",
    title: "Matin",
    subtitle: "9h - 12h",
  },
  noon: {
    iconName: "clock",
    title: "Midi",
    subtitle: "12h - 15h",
  },
  afternoon: {
    iconName: "sun",
    title: "Après-midi",
    subtitle: "15h - 18h",
  },
  evening: {
    iconName: "moon",
    title: "Soirée",
    subtitle: "18h - 21h",
  },
}

const AVAILABILITY_TYPE_PROPS = {
  [AvailabilityType.PUNCTUAL]: {
    color: COLOR.MEDIUM_LIGHT_GREEN,
    caption: "Cette semaine uniquement",
    selected: true,
  },
  [AvailabilityType.RECURRING]: {
    color: COLOR.YELLOW,
    caption: "Se répète toutes les semaines",
    selected: true,
  },
  [AvailabilityType.DISABLED]: {
    color: COLOR.MEDIUM_SUPER_LIGHT_GREY,
    caption: " ",
    selected: false,
  },
}

export const getAvailabilityTypeAndIdForSlot = (availability?: AddressAvailability): AvailabilityType => {
  if (!availability) return AvailabilityType.DISABLED // No availability = nothing created
  else if (availability.recurring) return AvailabilityType.RECURRING // Recurring availability
  else return AvailabilityType.PUNCTUAL // Punctual availability
}

export const AvailabilitySlot = ({
  slotType,
  availability,
}: {
  slotType: AvailabilitySlotType
  availability: AddressAvailability
}): ReactElement => {
  const { iconName, title, subtitle } = SLOT_TYPE_PROPS[slotType]
  const availabilityType = getAvailabilityTypeAndIdForSlot(availability)
  const { color, caption, selected } = AVAILABILITY_TYPE_PROPS[availabilityType]
  return (
    <AvailabilitySlotWrapper color={color} selected={selected}>
      <AvailabilitySlotContent>
        <FeatherIcon icon={iconName} size={32} color={color} />
        <AvailibilitySlotParagraph color={color} bold>
          {title}
        </AvailibilitySlotParagraph>
        <AvailibilitySlotParagraph>{subtitle}</AvailibilitySlotParagraph>
      </AvailabilitySlotContent>
      <AvailibilitySlotParagraph caption>{caption}</AvailibilitySlotParagraph>
    </AvailabilitySlotWrapper>
  )
}
