import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { resetFocusParcel } from "services/parcels"
import ParcelHeader from "modules/Ops/Parcel/ParcelHeader"
import ParcelEvents from "modules/Ops/Parcel/ParcelEvents"

interface ParcelDetailProps {
  resetFocusParcel: () => void
}

class Parcel extends Component<ParcelDetailProps> {
  componentDidMount() {
    this.props.resetFocusParcel()
  }

  componentWillUnmount() {
    this.props.resetFocusParcel()
  }

  render() {
    return (
      <Fragment>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <ParcelHeader />
          <ParcelEvents />
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  parcels: state.parcels,
})

const mapDispatchToProps = {
  resetFocusParcel,
}

export default connect(mapStateToProps, mapDispatchToProps)(Parcel)
