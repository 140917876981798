import * as yup from "yup"

export interface UpdateOrderRecipientForm {
  firstName: string
  lastName: string
  email: string
  phone: string
}

export const inputsText = {
  REQUIRED_FIELD: "Ce champ est obligatoire",
  INVALID_FIELD: "Ce champ n'est pas valide (ou manquant)",
  PHONE_INVALID: "Votre numéro de téléphone ne semble pas être valide",
  EMAIL_BAD_FORMAT: "Le format de l'email n'est pas bon",
  REQUIRED_AND_NUMBER: "Ce champ est obligatoire et doit être un nombre",
}

// eslint-disable-next-line no-control-regex, no-useless-escape
const emailRegex =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/

export const updateOrderRecipientSchema = yup.object({
  firstName: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
  lastName: yup.string().strict().typeError(inputsText.REQUIRED_FIELD),
  email: yup
    .string()
    .strict()
    .test("isEmailValid", inputsText.INVALID_FIELD, (value, textContext: { parent?: UpdateOrderRecipientForm }) => {
      if (value) {
        return emailRegex.test(value)
      } else if (textContext.parent.phone) {
        return true
      } else {
        return false
      }
    })
    .typeError(inputsText.INVALID_FIELD),
  phone: yup
    .string()
    .strict()
    .test("isPhoneValid", inputsText.INVALID_FIELD, (value, textContext: { parent?: UpdateOrderRecipientForm }) => {
      if (value) {
        return (
          !!value &&
          phoneRegex.test(value) &&
          value.length > 1 &&
          value.replace(/(\+33|0)([ -.]*[0-9]){9}/, "").trim().length === 0
        )
      } else if (textContext.parent.email) {
        return true
      } else {
        return false
      }
    })
    .typeError(inputsText.INVALID_FIELD),
})
