import React, { ReactElement, useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import CreatableSelect from "react-select/creatable"

import { QuickAccessChip, QuickAccessChipContainer } from "components/FilterBarZipcodes/FilterBarZipcodes.styled"
import { FilterBarZipcodesProps, ZipcodesData } from "types/filter-bar-zipcodes.types"
import { COLOR } from "utils/color"

function FilterBarZipcodes({
  onChangeZipcodes,
  defaultZipcodes = [],
  showQuickAccessChip = true,
}: FilterBarZipcodesProps): ReactElement {
  const [zipcodes, setZipcodes] = useState<ZipcodesData[]>(defaultZipcodes)
  const [allFranceSelected, setAllFranceSelected] = useState<boolean>(false)

  useEffect(() => {
    onChangeZipcodes(zipcodes)
  }, [zipcodes])

  const changeFilter = (filterName, e) => {
    const data = e === null ? [] : e

    if (filterName === "zipcodes") {
      setZipcodes(data)
    }
  }

  const handleCreate = value => {
    if (value.includes(" ")) {
      const values = value
        .trim()
        .split(" ")
        .map(zc => ({ value: zc, label: zc }))
      setZipcodes([...zipcodes, ...values])
    } else setZipcodes([...zipcodes, { label: value, value }])
  }

  const handleAllFranceSelection = () => {
    if (allFranceSelected) {
      setZipcodes([])
    } else {
      setZipcodes([{ label: "Toute France (Zones ouvertes)", value: "France", isFixed: true }])
    }
    setAllFranceSelected(!allFranceSelected)
  }

  return (
    <div className="filter-bar">
      <Row>
        <Col xs={12}>
          {!!showQuickAccessChip && (
            <QuickAccessChipContainer>
              <QuickAccessChip onClick={() => handleAllFranceSelection()} selected={allFranceSelected}>
                Toute France (Zones ouvertes) {allFranceSelected && <span>❌</span>}
              </QuickAccessChip>
            </QuickAccessChipContainer>
          )}
          <CreatableSelect
            defaultValue={[]}
            isMulti
            isDisabled={allFranceSelected}
            name="colors"
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="📍 Filtrer par code postal..."
            onChange={e => changeFilter("zipcodes", e)}
            onCreateOption={handleCreate}
            value={zipcodes}
            styles={{
              control: () => ({
                maxHeight: 230,
                overflowY: "auto",
                backgroundColor: COLOR.WHITE,
                display: "flex",
                flexWrap: "wrap",
              }),
              multiValueRemove: (base, state) => {
                return state.data.isFixed ? { ...base, display: "none" } : base
              },
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default FilterBarZipcodes
