import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { Redirect, useHistory, useLocation } from "react-router-dom"
import { useLazyQuery } from "@apollo/client"
import { find } from "lodash"
import ParcelsList from "components/Parcels/ParcelsList"
import Title from "components/Title"
import { PageWrapper } from "components/Layout/Structure"
import DynamicParcelsList from "components/Parcels/DynamicParcelList"
import { FilterChip } from "components/Chips/FilterChip"
import Search from "components/SearchBar/SearchBar"
import { apiGetParcelsStats, apiGetParcels, resetParcels } from "services/parcels"
import {
  SearchParcelsData,
  searchParcels,
  SearchKeepersData,
  searchKeepers,
  SearchAddressesData,
  searchAddresses,
  SearchVariables,
} from "services/graphql/queries/admin.queries"
import { getCountParcelsByFlag, getParcelsByFlag } from "services/graphql/queries/parcel.queries"
import { SystemType } from "types/system.types"
import { ParcelFlag } from "types/parcel.types"
import { ReduxState } from "types/reduxState.types"
import { displayParcelFlag } from "utils/displayParcelFlag"
import { debounce } from "lodash"
import { v1 as uuidV1 } from "uuid"
import {
  mapSearchAddressesResult,
  mapSearchKeepersResult,
  mapSearchParcelsResult,
  SearchResult,
} from "utils/mapSearchResults"

const NB_PARCELS_RESULTS = 25
const PARCELS_LIMIT = 100

const ExcludedParcelFlags = [
  ParcelFlag.RECIPIENT_DELIVERY_TO_CONFIRM,
  ParcelFlag.UNSCANNED,
  ParcelFlag.INFINITE_DELIVERY,
]

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  align-items: center;
`

interface FiltersDataInitialValue {
  flag: ParcelFlag
  label: string
  length: number
}

const getFiltersDataInitialValue = (): FiltersDataInitialValue[] => {
  return Object.keys(ParcelFlag)
    .filter((flag: ParcelFlag) => !ExcludedParcelFlags.includes(flag))
    .map((flag: ParcelFlag) => ({
      flag,
      label: displayParcelFlag(flag),
      length: 0,
    }))
}

function ParcelsManagement(): ReactElement {
  const { search } = useLocation()
  const dispatch = useDispatch()

  const [filter, setFilter] = useState(new URLSearchParams(search).get("filter") || "ALL")
  const [redirectTo, setRedirection] = useState(null)
  const [filtersData, setFiltersData] = useState(getFiltersDataInitialValue())
  const [countParcelsLoading, setCountParcelsLoading] = useState(true)

  const list = useSelector((state: ReduxState) => state.parcels.list)
  const [getCountParcelsByFlagQuery] = useLazyQuery(getCountParcelsByFlag, {
    fetchPolicy: "network-only",
  })
  const [getParcelsByFlagQuery, { data: parcels, loading: parcelsLoading, fetchMore: fetchMoreParcels }] =
    useLazyQuery(getParcelsByFlag)
  const history = useHistory()

  const [isSearching, setIsSearching] = useState(false)
  const abortController = useRef(new AbortController())

  const [searchParcelsQuery] = useLazyQuery<SearchParcelsData, SearchVariables>(searchParcels, {
    context: { fetchOptions: { signal: abortController.current.signal } },
  })

  const [searchKeepersQuery] = useLazyQuery<SearchKeepersData, SearchVariables>(searchKeepers, {
    context: { fetchOptions: { signal: abortController.current.signal } },
  })

  const [searchAddressesQuery] = useLazyQuery<SearchAddressesData, SearchVariables>(searchAddresses, {
    context: { fetchOptions: { signal: abortController.current.signal } },
  })

  const getFiltersCount = async () => {
    await getCountParcelsByFlagQuery().then(values => {
      if (values && values.data) {
        const flagsCount = values.data.countParcelsByFlag
        const filledFiltersData = filtersData.map(filter => {
          const selectedFlag = find(flagsCount, fc => fc.flag === filter.flag)

          return { ...filter, length: selectedFlag?.count || 0 }
        })

        setFiltersData(filledFiltersData)
      }
    })
  }

  useEffect(() => {
    const getInitialData = async () => {
      dispatch(apiGetParcelsStats())
      getFiltersCount().then(() => {
        setCountParcelsLoading(false)
      })
      if (filter !== "ALL") {
        await getParcelsByFlagQuery({
          variables: { payload: { flag: filter, limit: PARCELS_LIMIT } },
        })
      }
    }

    getInitialData()
  }, [search])

  const [searchInput, setSearchInput] = useState<string>("")
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const currentId = useRef<string>("")

  const onInputChange = (input: string) => {
    setSearchInput(input)
    currentId.current = uuidV1()
  }

  const _onSearch = async (inputString: string, uniqueId: string) => {
    setSearchResults(() => [])
    if (inputString?.length > 2) {
      setIsSearching(() => true)

      const { data: parcelsData } = await searchParcelsQuery({ variables: { input: inputString } })
      if (uniqueId === currentId.current) setSearchResults(() => mapSearchParcelsResult(parcelsData, 3))

      const { data: keepersData } = await searchKeepersQuery({ variables: { input: inputString } })
      if (uniqueId === currentId.current)
        setSearchResults(prevState => [...prevState, ...mapSearchKeepersResult(keepersData, 3)])

      const { data: addressData } = await searchAddressesQuery({ variables: { input: inputString } })
      if (uniqueId === currentId.current)
        setSearchResults(prevState => [...prevState, ...mapSearchAddressesResult(addressData, 3)])

      if (uniqueId === currentId.current) setIsSearching(() => false)

      return
    }
    setSearchResults([])
    setIsSearching(false)
  }

  const loadOptionsDebounced = useCallback(debounce(_onSearch, 500), [])

  useEffect(() => {
    loadOptionsDebounced.cancel()
    setSearchResults(() => [])
    loadOptionsDebounced(searchInput, currentId.current)
  }, [searchInput])

  const moreToLoad = useMemo(() => {
    if (filter === "ALL" || !parcels) return false
    const parcelsWithFlagNumber = filtersData.find(filterFlag => filterFlag.flag === filter)?.length
    return parcels?.getParcelsByFlag &&
        parcelsWithFlagNumber &&
        parcelsWithFlagNumber > parcels?.getParcelsByFlag?.length;
  }, [parcels, filtersData, filter])

  const _onChange = value => {
    const path = value.type === "PARCEL" ? `/parcels/${value.value}` : `/keepers/${value.value}`
    setRedirection(path)

    abortController.current.abort()
  }

  const loadMore = (parcelFlag: ParcelFlag) => {
    fetchMoreParcels({
      variables: {
        payload: {
          flag: parcelFlag,
          cursor: parcels?.getParcelsByFlag?.at(-1)?.cursor,
          limit: PARCELS_LIMIT,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult
        return {
          getParcelsByFlag: [...previousResult.getParcelsByFlag, ...fetchMoreResult.getParcelsByFlag],
        }
      },
    })
  }

  if (redirectTo) {
    abortController.current = new AbortController()
    return <Redirect to={redirectTo} />
  }

  return (
    <PageWrapper>
      <Title>📦 Gestion des colis</Title>

      <br />
      <Search
        onChange={_onChange}
        isLoading={isSearching}
        shouldUpdateOptions={true}
        isLoadingMessage={false}
        marginBottom
        options={searchResults}
        onInputChange={onInputChange}
      />

      <FiltersContainer>
        <FilterChip
          text={"Tous"}
          active={filter === "ALL"}
          onClick={() => {
            history.replace({ search: "" })
            setFilter("ALL")
          }}
        />

        {filtersData.map(filterFlag => (
          <FilterChip
            key={`filter-data-${filterFlag.flag}`}
            text={filterFlag.label}
            active={filter === filterFlag.flag}
            onClick={async () => {
              const params = new URLSearchParams({ filter: filterFlag.flag })
              history.replace({ search: params.toString() })
              setFilter(filterFlag.flag)
              await getParcelsByFlagQuery({
                variables: {
                  payload: {
                    flag: filterFlag.flag,
                    cursor: parcels?.getParcelsByFlag?.at(-1)?.cursor,
                    limit: PARCELS_LIMIT,
                  },
                },
              })
            }}
            number={filterFlag?.length}
            loading={countParcelsLoading}
          />
        ))}
      </FiltersContainer>

      {filter === "ALL" && (
        <DynamicParcelsList
          parcels={list.data.parcels}
          nbParcelsResults={NB_PARCELS_RESULTS}
          loading={list.loading || list.parcelsStatsLoading}
          moreToLoad={list.data.pageInfos?.hasNextPage}
          nextCursor={list.data.pageInfos?.endCursor}
          parcelsStats={list.parcelsStats}
          apiGetParcels={apiGetParcels}
          resetParcels={resetParcels}
          activeStatus={list.activeStatus}
          provider={SystemType.PARCEL}
        />
      )}
      {filter !== "ALL" && (
        <ParcelsList
          moreToLoad={moreToLoad}
          parcels={parcels?.getParcelsByFlag ?? []}
          loading={parcelsLoading}
          withNb
          withFilters={false}
          getNextParcels={loadMore}
          filter={filter}
        />
      )}
    </PageWrapper>
  )
}

export default ParcelsManagement
