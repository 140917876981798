import React, { ReactElement } from "react"
import {
  GreyCircle,
  QuickSearchResultLine,
  QuickSearchResultLineLeft,
  QuickSearchResultLineRight,
  QuickSearchResultLineRightDescription,
  QuickSearchResultLineRightTitle,
} from "components/QuickSearchBar/QuickSearchBar.styled"
import { SearchResult } from "utils/mapSearchResults";

interface SearchItemProps {
  searchData: SearchResult
  chooseResult: (result: SearchResult) => void
}

export const SearchItem = ({ searchData, chooseResult }: SearchItemProps): ReactElement => {
  return (
    <QuickSearchResultLine key={searchData.label + searchData.type} onClick={() => chooseResult(searchData)}>
      <QuickSearchResultLineLeft>
        <GreyCircle>{searchData.icon}</GreyCircle>
      </QuickSearchResultLineLeft>
      <QuickSearchResultLineRight>
        <QuickSearchResultLineRightTitle>{searchData.title}</QuickSearchResultLineRightTitle>
        <QuickSearchResultLineRightDescription>{searchData.description}</QuickSearchResultLineRightDescription>
      </QuickSearchResultLineRight>
    </QuickSearchResultLine>
  )
}
