import styled from "styled-components"

import { COLOR } from "utils/color"

export const LoginBackground = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
`

export const LoginContainer = styled.div`
  width: 500px;
`

export const LoginBox = styled.div`
  box-shadow: 0px 9px 35px -23px rgba(148, 148, 148, 1);
  background-color: ${COLOR.WHITE};
  border-radius: 4px;
  width: 100%;
`

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
`

export const LoginCopyright = styled.div`
  color: ${COLOR.WHITE};
  margin-top: 2em;
  font-size: 0.9em;
`

export const LoginLogo = styled.img`
  width: 400px;
  margin-bottom: 20px;
`
