import React, { ReactElement } from "react"
import {
  ActionButton,
  AttachedItem,
  AttachedItemsList,
  AttachedItemText,
  Section,
  SectionsWrapper,
  SectionTitle,
} from "components/ParcelBilling/ParcelBillingModal/ParcelBillingModal.styled"
import { ActionType, BillingEntry, TargetEntryType } from "types/billing-modal.types"
import { COLOR } from "utils/color"
import { convertHexToRGBA } from "utils/hexToRGBA"
import { WalletEntryType } from "types/wallet-entry.types"
import { Name } from "components/ParcelBilling/ParcelBilling.styled"

interface AttachedBillingsSectionProps {
  billingEntries: BillingEntry[]
  removeBillingEntry: ({ id }: { id: string }) => void
}

export const AttachedBillingsSection = ({
  billingEntries,
  removeBillingEntry,
}: AttachedBillingsSectionProps): ReactElement => {
  const companyBillingEntries = billingEntries.filter(
    (companyBillingEntry: BillingEntry) => companyBillingEntry.targetEntry === TargetEntryType.BILLING,
  )

  const walletEntries = billingEntries.filter(
    (walletEntry: BillingEntry) => walletEntry.targetEntry === TargetEntryType.WALLET,
  )

  return (
    <SectionsWrapper marginBottom={60}>
      <Section>
        <SectionTitle>Facturation</SectionTitle>
        <AttachedItemsList>
          {!companyBillingEntries.length && <Name>-</Name>}
          {companyBillingEntries?.map((companyBillingEntry: BillingEntry) => {
            const bgColor =
              companyBillingEntry.actionType === ActionType.REMOVE
                ? COLOR.RED
                : companyBillingEntry?.actionType === ActionType.ADD
                ? COLOR.GREEN
                : COLOR.MEDIUM_SUPER_LIGHT_GREY
            return (
              <AttachedItem bgColor={convertHexToRGBA(bgColor, 0.2)} key={companyBillingEntry.id}>
                <AttachedItemText>✅ {companyBillingEntry.entryText}</AttachedItemText>
                <ActionButton
                  onClick={() =>
                    removeBillingEntry({
                      id: companyBillingEntry.id,
                    })
                  }
                >
                  {companyBillingEntry.actionType === ActionType.REMOVE ? "Remettre" : "Supprimer"}
                </ActionButton>
              </AttachedItem>
            )
          })}
        </AttachedItemsList>
      </Section>

      <Section>
        <SectionTitle>Crédits Keeper</SectionTitle>
        <AttachedItemsList>
          {!walletEntries.length && <Name>-</Name>}
          {walletEntries?.map((walletEntry: BillingEntry) => {
            const bgColor =
              walletEntry.actionType === ActionType.REMOVE
                ? COLOR.RED
                : walletEntry.actionType === ActionType.ADD
                ? COLOR.GREEN
                : COLOR.MEDIUM_SUPER_LIGHT_GREY
            return (
              <AttachedItem bgColor={convertHexToRGBA(bgColor, 0.2)} key={walletEntry.id}>
                <AttachedItemText>
                  {walletEntry.entryType === WalletEntryType.PENDING_RECEPTION ? "⏳" : "✅"} {walletEntry.entryText}
                </AttachedItemText>
                <ActionButton
                  onClick={() =>
                    removeBillingEntry({
                      id: walletEntry.id,
                    })
                  }
                >
                  {walletEntry.actionType === ActionType.REMOVE ? "Remettre" : "Supprimer"}
                </ActionButton>
              </AttachedItem>
            )
          })}
        </AttachedItemsList>
      </Section>
    </SectionsWrapper>
  )
}
