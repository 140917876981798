import React, { ReactElement } from "react"
import { Loader } from "semantic-ui-react"
import styled from "styled-components"

import { COLOR } from "utils/color"

const Button = styled.button`
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 10px;
  border: none;
  background-color: ${COLOR.CORAIL};
  color: white;
`

const Wrapper = styled.div`
  &:hover ${Button} {
    background-color: ${COLOR.DARK_CORAIL};
  }
`

interface LoginConnectButtonProps {
  text: string
  disabled: boolean
  children?: ReactElement
  isSubmitting: boolean
}

const LoginConnectButton = ({ text, disabled, children, isSubmitting }: LoginConnectButtonProps): ReactElement => {
  return (
    <Wrapper>
      <Button type="submit" disabled={disabled}>
        {isSubmitting ? <Loader inline active size="tiny" /> : text} {children}
      </Button>
    </Wrapper>
  )
}

export default LoginConnectButton
