import React, { ReactElement } from "react"
import { Checkbox } from "semantic-ui-react"

interface FilterKeeperToReassignProps {
  isFilteredByKeeperAvailable: boolean
  handleFilterByKeeperAvailable: () => void
}

const FilterKeeperToReassign = ({
  isFilteredByKeeperAvailable,
  handleFilterByKeeperAvailable,
}: FilterKeeperToReassignProps): ReactElement => (
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
    Afficher seulement les keepers avec au moins 2 créneaux dans une journée
    <div style={{ marginTop: 5, marginLeft: 10 }}>
      <Checkbox checked={isFilteredByKeeperAvailable} onClick={handleFilterByKeeperAvailable} />
    </div>
  </div>
)

export default FilterKeeperToReassign
