import { API_CREATE_FAKE_ORDERS, API_CREATE_ORDER, API_UPDATE_ORDER_RECIPIENT } from "actions/orders"
import { ManualOrderPacman, Recipient } from "types/order.types"
import { FctServiceReturn } from "./services.types"

export function createFakeOrders(_data) {
  return {
    type: API_CREATE_FAKE_ORDERS,
    payload: {
      request: {
        url: `/admin/orders/fake`,
        method: "POST",
        data: _data,
      },
    },
  }
}

export function createOrder(_data: ManualOrderPacman) {
  return {
    type: API_CREATE_ORDER,
    payload: {
      request: {
        url: `/admin/orders/create`,
        method: "POST",
        data: _data,
      },
    },
  }
}

export function apiUpdateOrderRecipient(_id: string, data: Recipient): FctServiceReturn<Recipient> {
  return {
    type: API_UPDATE_ORDER_RECIPIENT,
    payload: {
      request: {
        url: `/admin/orders/${_id}/updateRecipient`,
        method: "POST",
        data: data,
      },
    },
  }
}
