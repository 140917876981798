import {
  API_GET_COMPANY,
  API_GET_COMPANIES,
  API_GET_CURRENT_CLIENT_BILL,
  API_UPDATE_BILLING_PAYMENT_STATUS,
  RESET_FOCUS_COMPANY,
  API_GET_DHL_PICKUP_HOURS,
} from "actions/companies"
import { FctServiceReturn } from "./services.types"

export function apiGetCompany(_id) {
  return {
    type: API_GET_COMPANY,
    payload: {
      request: {
        url: `/admin/companies/${_id}`,
      },
    },
  }
}

export function apiGetCompanies() {
  return {
    type: API_GET_COMPANIES,
    payload: {
      request: {
        url: `/admin/companies`,
      },
    },
  }
}

export function apiGenerateCompanyPayment(_id) {
  return {
    type: API_GET_COMPANY,
    payload: {
      request: {
        url: `/admin/companies/${_id}/payment`,
        type: "POST",
      },
    },
  }
}

export function apiUpdateBillingPaymentStatus(companyId, paymentId, _body) {
  return {
    type: API_UPDATE_BILLING_PAYMENT_STATUS,
    payload: {
      request: {
        method: "PATCH",
        url: `/admin/companies/${companyId}/payments/${paymentId}`,
        data: _body,
      },
    },
  }
}

export function apiGetCurrentClientBill(companyId) {
  return {
    type: API_GET_CURRENT_CLIENT_BILL,
    payload: {
      request: {
        url: `/admin/companies/${companyId}/currentBill`,
        type: "GET",
      },
    },
  }
}

export function resetFocusCompany() {
  return {
    type: RESET_FOCUS_COMPANY,
  }
}

export function apiGetDHLPickupHours({ city, zipcode }: { city: string; zipcode: string }): FctServiceReturn<any> {
  return {
    type: API_GET_DHL_PICKUP_HOURS,
    payload: {
      request: {
        method: "GET",
        url: `/admin/companies/DHL/pickupHours?city=${city}&zipcode=${zipcode}`,
      },
    },
  }
}
