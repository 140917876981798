import styled from "styled-components"

import { COLOR } from "utils/color"

export const FormError = styled.div`
  margin-top: -10px;
  margin-bottom: 10px;
  color: ${COLOR.BRIGHT_RED};
  font-size: 10px;
`
