import { toFailure, toSuccess } from "actions"
import { createReducer } from "@reduxjs/toolkit"
import { API_GET_ASSOCIATIONS, API_GET_ASSOCIATION_DETAIL } from "actions/associations"
import { Association } from "types/association.types"
import { WalletDonation } from "types/wallet-donation.types"

export interface AssociationInitialState {
  list: {
    loading: boolean
    error: boolean
    data: Association[]
  }
  detail: {
    loading: boolean
    error: boolean
    data: {
      association: {
        name: string
      }
      donations: WalletDonation[]
      take: number
      skip: number
      total: number
    }
  }
}

const initialState: AssociationInitialState = {
  list: {
    loading: false,
    error: false,
    data: [],
  },
  detail: {
    loading: false,
    error: false,
    data: {
      association: {
        name: null,
      },
      donations: [],
      take: 20,
      skip: 0,
      total: 0,
    },
  },
}

const associations = createReducer(initialState, {
  [API_GET_ASSOCIATIONS]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_ASSOCIATIONS)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data = action.payload.data
  },
  [toFailure(API_GET_ASSOCIATIONS)]: state => {
    state.list.loading = false
    state.list.error = true
  },

  [API_GET_ASSOCIATION_DETAIL]: state => {
    state.detail.loading = true
  },
  [toSuccess(API_GET_ASSOCIATION_DETAIL)]: (state, action) => {
    state.detail.loading = false
    state.detail.error = false
    state.detail.data = action.payload.data
  },
  [toFailure(API_GET_ASSOCIATION_DETAIL)]: state => {
    state.detail.loading = false
    state.detail.error = true
  },
})

export default associations
