import React, { ChangeEventHandler, CSSProperties, ReactElement } from "react"
import styled from "styled-components"

import { COLOR } from "utils/color"

interface DateInputProps {
  label?: string
  value: string
  min?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: ChangeEventHandler<any>
  containerStyles?: CSSProperties
  inputStyles?: CSSProperties
}

const DateInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`

const DateInputLabel = styled.div`
  color: ${COLOR.MEDIUM_GREY};
  font-size: 10px;
`

const DateInputComponent = styled.input`
  height: 34px;
  margin-right: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  color: ${COLOR.MEDIUM_GREY};
`

const DateInput = ({
  label,
  value,
  min,
  onChange,
  containerStyles = {},
  inputStyles = {},
}: DateInputProps): ReactElement => {
  return (
    <DateInputContainer style={containerStyles}>
      {label !== undefined && <DateInputLabel>{label}</DateInputLabel>}
      <DateInputComponent style={inputStyles} type="date" value={value} min={min} onChange={onChange} />
    </DateInputContainer>
  )
}

export default DateInput
