import styled from "styled-components"
import { Col } from "reactstrap"

export const RadiusInputContainer = styled(Col)`
  display: flex;
  align-items: baseline;
`
export const RadiusInputText = styled.p`
  margin-left: auto;
`
