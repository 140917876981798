import {
  API_GET_ANALYZED_SEARCH_LOG,
  API_GET_SEARCH_KEEPER_ENDPOINT_LOGS,
  RESET_SEARCH_KEEPER_LOGS,
  RESET_SEARCH_KEEPER_LOGS_FOCUS,
  API_GET_SEARCH_LOG_CSV,
} from "actions/search-keeper-monitoring"

const baseUrl = "/admin/search-keeper-endpoint-logs"

export function apiReturningSearchKeeperEndpointLogs(
  startingDate: Date,
  endingDate: Date,
  companyId: string,
  zipCodes: string[],
) {
  const stringifiedZipcodes = JSON.stringify(zipCodes)

  return {
    type: API_GET_SEARCH_KEEPER_ENDPOINT_LOGS,
    payload: {
      request: {
        url: `${baseUrl}?companyId=${companyId}&startingDate=${startingDate.toString()}&endingDate=${endingDate.toString()}&stringifiedZipcodes=${stringifiedZipcodes}`,
      },
    },
  }
}

export function resetSearchKeeperLogs() {
  return {
    type: RESET_SEARCH_KEEPER_LOGS,
  }
}

export function resetSearchKeeperLogsFocus() {
  return {
    type: RESET_SEARCH_KEEPER_LOGS_FOCUS,
  }
}

export function apiGetAnalysedSearchLog(searchKeeperLogId: string, listLength: number = null) {
  return {
    type: API_GET_ANALYZED_SEARCH_LOG,
    payload: {
      request: {
        url: `admin/search-keeper-log/${searchKeeperLogId}/analyze${listLength ? `?listLength=${listLength}` : ""}`,
      },
    },
  }
}

export function apiGetCSVAnalysedSearchLog(
  startingDate: Date,
  endingDate: Date,
  companyId: string,
  zipCodes: string[],
) {
  const stringifiedZipcodes = JSON.stringify(zipCodes)
  return {
    type: API_GET_SEARCH_LOG_CSV,
    payload: {
      request: {
        url: `admin/search-keeper-log/csv?companyId=${companyId}&startingDate=${startingDate.toString()}&endingDate=${endingDate.toString()}&stringifiedZipcodes=${stringifiedZipcodes}`,
      },
    },
  }
}
