import { toFailure, toSuccess } from "actions"
import { createReducer } from "@reduxjs/toolkit"
import { API_GET_LEADS, RESET_LEADS } from "actions/leads"

const initialState = {
  list: {
    loading: false,
    error: false,
    data: [],
    activeFilter: null,
  },
  focus: {
    loading: false,
    error: false,
    data: {},
  },
}

const leads = createReducer(initialState, {
  [RESET_LEADS]: state => {
    state.list.data = []
    state.list.activeFilter = null
  },
  [API_GET_LEADS]: (state, action) => {
    state.list.loading = true
    state.list.activeFilter = action.payload.filter
  },
  [toSuccess(API_GET_LEADS)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data = state.list.data.concat(action.payload.data)
  },
  [toFailure(API_GET_LEADS)]: state => {
    state.list.loading = false
    state.list.error = true
  },
})

export default leads
