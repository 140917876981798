import { MiniButton, MiniButtonCancel } from "components/Buttons/MiniButton"
import ClassicInput from "components/Form/ClassicInput"
import { Field, Form, Formik } from "formik"
import React, { Fragment, ReactElement, useState } from "react"
import { useDispatch } from "react-redux"
import { Col, Row } from "reactstrap"
import { updateOrderRecipientSchema } from "schema/updateOrderRecipient.schema"
import { Loader } from "semantic-ui-react"
import { apiUpdateOrderRecipient } from "services/orders"
import { Order, OrderRecipientContactType } from "types/order.types"
import {
  ParcelRecipientContainer,
  ParcelRecipientName,
  ParcelRecipientTitle,
} from "components/Parcels/ParcelInfos.styled"
import ParcelRecipientSkeleton from "components/Parcels/ParcelSkeleton/ParcelRecipientSkeleton"
import { ChatLink, CreatedAtText } from "./ParcelRecipient.styled"
import { ChatContainer } from "./ParcelRecipient.styled"
import { ContactModeContainer } from "./ParcelRecipient.styled"

interface ParcelRecipientProps {
  orderId: string
  order: Order
  loading: boolean
}

export const ParcelRecipient = ({ orderId, order, loading }: ParcelRecipientProps): ReactElement => {
  const dispatch = useDispatch()
  const [editModeActive, toggleEditMode] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)

  const recipient = order?.recipient
  const { chatId, chatCreatedAt, recipientContactType } = order

  const submit = async values => {
    setUpdateLoading(true)
    dispatch(apiUpdateOrderRecipient(orderId, values))

    setUpdateLoading(false)
    toggleEditMode(false)
  }

  const getTalkJsAppId = () => {
    if (process.env.REACT_APP_STAGE === "production") {
      return "qRaMLGHc"
    }
    return "tRmLe96i"
  }

  const labelRecipientContactType = (recipientContactType: OrderRecipientContactType) => {
    switch (recipientContactType) {
      case OrderRecipientContactType.PHONE_CALL:
        return "Appel tel"
      case OrderRecipientContactType.EMAIL:
        return "Email"
      case OrderRecipientContactType.SMS:
        return "SMS"
      case OrderRecipientContactType.SECURE_MESSAGING:
        return "Messagerie sécurisée"
      default:
        return "Non contacté"
    }
  }

  if (loading) return <ParcelRecipientSkeleton />

  return (
    <ParcelRecipientContainer>
      <ParcelRecipientTitle>▶️ Destinataire</ParcelRecipientTitle>
      {!editModeActive && (
        <Fragment>
          <ParcelRecipientName>{recipient?.name}</ParcelRecipientName>

          <div>{recipient?.phone}</div>
          <div>{recipient?.email}</div>
          <br />
          <MiniButton
            onClick={() => {
              toggleEditMode(true)
            }}
          >
            Modifier
          </MiniButton>
        </Fragment>
      )}
      {editModeActive && (
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            firstName: recipient.firstName,
            lastName: recipient.lastName,
            email: recipient.email,
            phone: recipient.phone,
          }}
          validationSchema={updateOrderRecipientSchema}
          onSubmit={values => submit(values)}
        >
          {({ submitForm }) => (
            <Form>
              <Row>
                <Col xs={12} md={12}>
                  <Field
                    type="text"
                    name="firstName"
                    label="Prénom"
                    placeholder="Jean"
                    required
                    component={ClassicInput}
                  />
                  <Field type="text" name="lastName" label="Nom" placeholder="Dupont" component={ClassicInput} />
                  <Field
                    type="text"
                    name="email"
                    label="Email"
                    placeholder="jean.dupont@mypickme.com"
                    component={ClassicInput}
                  />

                  <Field type="text" name="phone" label="Téléphone" placeholder="0601020304" component={ClassicInput} />

                  {updateLoading ? (
                    <Loader active inline />
                  ) : (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <MiniButton
                        onClick={() => {
                          submitForm()
                        }}
                      >
                        Valider
                      </MiniButton>
                      <MiniButtonCancel
                        onClick={() => {
                          toggleEditMode(false)
                        }}
                      >
                        Annuler
                      </MiniButtonCancel>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
      <ContactModeContainer>
        📲 <b>Mode de contact : </b>
        {labelRecipientContactType(recipientContactType)}
      </ContactModeContainer>
      {recipientContactType === OrderRecipientContactType.SECURE_MESSAGING && (
        <ChatContainer>
          <ChatLink
            href={`https://talkjs.com/dashboard/app/${getTalkJsAppId()}/activity/conversations/${chatId}/${order.id}`}
            target="_blank"
          >
            🔗 Voir le chat sur TalkJS 💬
          </ChatLink>
          <CreatedAtText>
            Créé le : {chatCreatedAt ? new Date(chatCreatedAt)?.toLocaleDateString() : "Inconnu"}
          </CreatedAtText>
        </ChatContainer>
      )}
    </ParcelRecipientContainer>
  )
}
