import { gql } from "@apollo/client"
import {
  CompanyBillingEntryType,
  CompanyBillingPayment,
  CompanyBillingPaymentStatus,
} from "types/company-billing.types"
import { WalletEntryType } from "types/wallet-entry.types"

export interface UpdateCompanyBillingPaymentData {
  updateCompanyBillingPayment: CompanyBillingPayment
}

export interface UpdateCompanyBillingPaymentVariables {
  id: string
  status: CompanyBillingPaymentStatus
}

export const updateCompanyBillingPayment = gql`
  mutation updateCompanyBillingPayment($id: String!, $status: String!) {
    updateCompanyBillingPayment(id: $id, status: $status) {
      id
      status
    }
  }
`

export interface UpdateParcelBillingData {
  walletEntriesAdded: string[]
  companyBillingEntriesAdded: string[]
  remainedBillingEntriesCount: number
  remainedWalletEntriesCount: number
}

export interface UpdateParcelBillingVariables {
  parcelBilling: {
    parcelId: string
    walletEntriesAdded: { type: WalletEntryType; amount: number; walletId: string }[]
    walletEntriesRemoved: string[]
    companyBillingEntriesAdded: { type: CompanyBillingEntryType; accountancyId: string }[]
    companyBillingEntriesRemoved: string[]
    reason: string
  }
}

export const updateParcelBilling = gql`
  mutation updateParcelBilling($parcelBilling: ParcelBillingToUpdateInput!) {
    updateParcelBilling(parcelBilling: $parcelBilling) {
      walletEntriesAddedCount
      companyBillingEntriesAddedCount
      remainedBillingEntriesCount
      remainedWalletEntriesCount
    }
  }
`
