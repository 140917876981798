import React from "react"
import { Loader } from "semantic-ui-react"
import styled from "styled-components"

import { COLOR } from "utils/color"

const StatContainer = styled.div`
  max-width: 25%;
  padding: 10px 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 10px;
  border: 1px solid ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 5px;
  background-color: ${COLOR.WHITE};
`

const StatTitle = styled.div`
  font-size: 0.9em;
  color: rgb(136, 136, 136);
`

const StatContent = styled.div`
  font-size: 2em;
  font-weight: 600;
`

export default function Stat({ title, content, loading = false }) {
  return (
    <StatContainer>
      <StatTitle>{title}</StatTitle>
      {!loading && <StatContent>{content}</StatContent>}
      {loading && (
        <StatContent>
          <Loader active inline />
        </StatContent>
      )}
    </StatContainer>
  )
}
