import React, { PureComponent } from "react"
import { Marker } from "react-map-gl"
import PinGreen from "assets/mapbox/pin_green.png"
import PinOrange from "assets/mapbox/pin_orange.png"
import PinRed from "assets/mapbox/pin_red.png"
import PinYellow from "assets/mapbox/pin_yellow.png"
import PinGrey from "assets/mapbox/pin_grey.png"
import { Popup } from "semantic-ui-react"
import KeeperPopup from "./KeeperPopup"
import { MapPin } from "components/Maps/MapPin"

class KeeperMarkers extends PureComponent {
  render() {
    const { data } = this.props
    return data.map(keeper => (
      <Marker key={keeper.addressId} longitude={keeper.address.longitude} latitude={keeper.address.latitude}>
        <Popup
          trigger={
            <span>
              {keeper.status === "VALIDATED" && <MapPin src={PinGreen} />}
              {keeper.status === "PENDING" && <MapPin src={PinYellow} />}
              {keeper.status === "IN_VALIDATION" && <MapPin src={PinOrange} />}
              {keeper.status === "NEW" && <MapPin src={PinRed} />}
              {!["VALIDATED", "PENDING", "IN_VALIDATION", "NEW"].includes(keeper.status) && <MapPin src={PinGrey} />}
            </span>
          }
          content={<KeeperPopup keeper={keeper} address={keeper.address} />}
          on="click"
          position="top center"
        />
      </Marker>
    ))
  }
}

export default KeeperMarkers
