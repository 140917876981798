import styled from "styled-components"
import { COLOR } from "utils/color"

export const CalendarWrapper = styled.div``

export const Paragraph = styled.div<{ bold?: boolean; padded?: boolean }>`
  font-family: ${({ bold = false }) => (bold ? "Poppins-Semibold" : "Poppins-Regular")};
  font-size: 14px;
  color: ${COLOR.DARK_GREY};
  line-height: 22px;
  margin-bottom: ${({ padded = true }) => (padded ? 32 : 0)}px;
`

export const MonthParagraph = styled(Paragraph).attrs({ padded: false })`
  color: ${COLOR.MEDIUM_SUPER_LIGHT_GREY};
  font-size: 12px;
  margin-horizontal: 32px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  /* Hides the scrollbar visually */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  overflow-anchor: auto;
  /* Hide scrollbar for Chrome, Safari, and newer Edge versions */
  &::-webkit-scrollbar {
    display: none;
  }
`
