import React from "react"
import { Button, Header, Icon, Modal } from "semantic-ui-react"
import { AdminParcelEventType } from "../../getParcelActions"
import { AdminParcelEventReason } from "types/parcel.types"
import { ReturnModalContent } from "./ReturnToPlanModal.styled"
import { SectionTitle } from "../ReturnRequestModal/RequestReturnModal.styled"
import Select from "components/Form/Select"
import { getReturningCarrierNameList } from "../ReturnRequestModal/data"
import { Company } from "types/company.types"
import { CarriersName } from "types/carrier.types"

interface ReturnToPlanModalProps {
  isOpened: boolean
  adminEvent: {
    opened: boolean
    type: AdminParcelEventType
    reason: { value: string; label: string }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any
  }
  changeAdminEventData: (key: string, value: string | object) => void
  collectionCarriers: Company[]
  openReturnToPlanModal: (type: AdminParcelEventType) => void
  cancelReturnToPlanModal: () => void
  submitAdminEvent: (reason?: AdminParcelEventReason) => Promise<void>
}

export const ReturnToPlanModal = ({
  isOpened,
  adminEvent,
  changeAdminEventData,
  collectionCarriers,
  openReturnToPlanModal,
  cancelReturnToPlanModal,
  submitAdminEvent,
}: ReturnToPlanModalProps) => {
  // For RETURN TO PLAN, we don't want to show La Poste and Relais Colis as returning carriers => return must be requested directly
  const returningCarrierOptions = getReturningCarrierNameList(collectionCarriers, [
    CarriersName.POST_OFFICE,
    CarriersName.RELAISCOLIS,
  ])

  // Auto set the returning carrier if there is only one
  if (!adminEvent.data.returningCarrier && returningCarrierOptions.length === 1) {
    changeAdminEventData("returningCarrier", returningCarrierOptions[0])
  }

  return (
    <Modal
      onClose={cancelReturnToPlanModal}
      onOpen={() => openReturnToPlanModal(AdminParcelEventType.RETURN_TO_PLAN)}
      open={isOpened}
    >
      <Header>En êtes vous sûr?</Header>
      <Modal.Content>
        <SectionTitle>🚚 Configuration du retour</SectionTitle>
        <Select
          value={adminEvent.data.returningCarrier}
          onChange={carrier => changeAdminEventData("returningCarrier", carrier)}
          options={returningCarrierOptions}
          placeholder="Quel transporteur de retour ?"
          isDisabled={false}
        />
        <ReturnModalContent>
          Nous enverrons une notification push et retournerons le questionnaire au keeper si vous confirmez.
        </ReturnModalContent>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={cancelReturnToPlanModal}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="green"
          onClick={async () => {
            await submitAdminEvent()
            cancelReturnToPlanModal()
          }}
          disabled={!adminEvent.data.returningCarrier}
        >
          <Icon name="checkmark" /> Confirmer
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
