import React, { ReactElement } from "react"
import moment from "moment"
import { ReassignmentLog } from "types/reassignment-log.types"
import LineElementRow from "components/Table/LineElementRow"
import LineElementCol from "components/Table/LineElementCol"
import ReassignmentLogType from "components/ReassignmentLog/ReassignmentLogType"

interface LineItemReassignmentLogProps {
  element: ReassignmentLog
}

const LineItemReassignmentLog = ({ element }: LineItemReassignmentLogProps): ReactElement => {
  return (
    <LineElementRow>
      <LineElementCol size={2} label={"Date"} content={moment(element.date).format("DD/MM - HH:mm")} />
      <LineElementCol size={2} label={"💳 Commande"} content={<b>{element.__order__?.orderNo}</b>} />
      <LineElementCol
        size={3}
        label={"👤 Keeper A"}
        content={
          <b>
            {element.__currentAddress__?.__keeper__?.firstName} {element.__currentAddress__?.__keeper__?.lastName}
          </b>
        }
      />
      <LineElementCol
        size={3}
        label={"👤 Keeper B"}
        content={
          <b>
            {element.newAddress.address.keeper.firstName} {element.newAddress.address.keeper.lastName}
          </b>
        }
      />
      <LineElementCol size={2} content={<ReassignmentLogType type={element.auto ? "AUTO" : "MANUAL"} />} />
    </LineElementRow>
  )
}

export default LineItemReassignmentLog
