import styled from "styled-components"
import { Icon, Image, Label } from "semantic-ui-react"

export const CompetitorsCheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 20px;
  padding: 12px;
  border-radius: 20px;
  z-index: 1;
`

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CompetitorSelectionButtonLabel = styled(Label)`
  && {
    cursor: pointer;
    display: flex;
    background-color: transparent;
    font-weight: 400;
    align-items: center;
  }
`

export const CompetitorSelectionButtonImage = styled(Image)`
  && {
    margin-right: 5px;
  }
`

export const CompetitorSelectionButtonIcon = styled(Icon)`
  &&& {
    margin-left: 5px;
  }
`
