import React from "react"
import { Icon } from "semantic-ui-react"

import SystemUpdates from "modules/Monitoring/System"
import IntegrationUpdates from "modules/Monitoring/Integration"
import AppVersionsList from "modules/Monitoring/AppVersions"
import Parcel from "modules/Ops/Parcel/Parcel"
import Keepers from "modules/Community/KeepersList"
import KeepersChecking from "modules/Community/KeepersChecking"
import Keeper from "modules/Keeper/Keeper"
import Events from "modules/Testing/Events"
import FakeOrders from "modules/Testing/Orders"
import Leads from "modules/Community/Leads"
import Returning from "modules/Ops/Returning"
import CreateOrder from "modules/Ops/CreateOrder"
import ParcelsManagement from "modules/Ops/Parcels"
import Ratings from "modules/Community/Ratings"
import Associations from "modules/Community/Associations/AssociationsList"
import AssociationDetails from "modules/Community/Associations/Association"
import Extracts from "modules/Community/Extracts"
import ReassignmentLogs from "modules/Ops/ReassignmentLogs"
import PushNotifications from "modules/Community/PushNotifications"
import ListCarrierMapLogs from "modules/Monitoring/CarrierMapLog"
import ListEndpointCallingLogs from "modules/Monitoring/SearchKeeperMonitoring/SearchKeeperEndPointMonitoring"
import Billing from "modules/Billing/Billing"
import Carriers from "modules/Ops/Carriers"
import EventPopupEdit from "modules/Community/EventPopups/EventPopupEdit"
import EventPopupsList from "modules/Community/EventPopups/EventPopupsList"
import { WireTransfers } from "modules/Community/WireTransfers"

import { AdminUserRole } from "types/admin-user.types"

const { ADMIN, MARKETING, TECH, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, CARE_EXTERNAL } = AdminUserRole
const ALL_ROLES = Object.values(AdminUserRole)

const routes = [
  {
    name: "👥 Communauté",
    inSidebar: true,
    authorizedRoles: ALL_ROLES,
    environments: ["development", "testing", "staging", "production"],
    elements: [
      {
        path: "/keepers",
        icon: <Icon name="users" className="metismenu-icon" />,
        exact: true,
        component: Keepers,
        inSidebar: true,
        label: "Keepers",
        authorizedRoles: [ADMIN, MARKETING, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH],
        children: [],
      },
      {
        path: "/keepers/:id",
        icon: "pe-7s-rocket",
        exact: true,
        component: Keeper,
        inSidebar: false,
        label: "Keeper",
        authorizedRoles: ALL_ROLES,
        children: [],
      },
      {
        path: "/keepers-checking",
        icon: <Icon name="check square outline" className="metismenu-icon" />,
        exact: true,
        component: KeepersChecking,
        inSidebar: true,
        label: "Keepers à valider",
        authorizedRoles: [ADMIN, MARKETING, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH, CARE_EXTERNAL],
        children: [],
      },
      {
        path: "/push-notifications",
        icon: <Icon name="mobile alternate" className="metismenu-icon" />,
        exact: true,
        component: PushNotifications,
        inSidebar: true,
        label: "Notifications push",
        authorizedRoles: [ADMIN, MARKETING, TECH],
        children: [],
      },
      {
        path: "/event-popups",
        icon: <Icon name="calendar alternate" className="metismenu-icon" />,
        exact: true,
        component: EventPopupsList,
        inSidebar: true,
        label: "Event Popups",
        authorizedRoles: [ADMIN, MARKETING, TECH],
        children: [],
      },
      {
        path: "/event-popup-edit",
        exact: true,
        component: EventPopupEdit,
        inSidebar: false,
        label: "Event Popup Edit",
        authorizedRoles: [ADMIN, MARKETING, TECH],
        children: [],
      },
      {
        path: "/wire-transfers",
        icon: <Icon name="money bill alternate" className="metismenu-icon" />,
        exact: true,
        component: WireTransfers,
        inSidebar: true,
        label: "Virements Keepers",
        authorizedRoles: [ADMIN, HEAD_OF_CARE],
        children: [],
      },
      {
        path: "/extract",
        icon: <Icon name="chart bar" className="metismenu-icon" />,
        exact: true,
        component: Extracts,
        inSidebar: true,
        label: "Extracts",
        authorizedRoles: [ADMIN, MARKETING, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH],
        children: [],
      },
      {
        path: "/leads",
        icon: <Icon name="lab" className="metismenu-icon" />,
        exact: true,
        component: Leads,
        inSidebar: true,
        label: "Leads",
        authorizedRoles: [ADMIN, MARKETING, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH],
        children: [],
      },
      {
        path: "/ratings",
        icon: <Icon name="star" className="metismenu-icon" />,
        exact: true,
        component: Ratings,
        inSidebar: true,
        label: "Ratings",
        authorizedRoles: [ADMIN, MARKETING, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH],
        children: [],
      },
      {
        path: "/associations",
        icon: <Icon name="heart" className="metismenu-icon" />,
        exact: true,
        component: Associations,
        inSidebar: true,
        label: "Associations",
        authorizedRoles: [ADMIN, MARKETING, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH],
        children: [],
      },
      {
        path: "/associations/:id",
        icon: "pe-7s-rocket",
        exact: true,
        component: AssociationDetails,
        inSidebar: false,
        label: "AssociationDetails",
        authorizedRoles: [ADMIN, MARKETING, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH],
        children: [],
      },
    ],
  },
  {
    name: "🏢 Entreprise",
    inSidebar: true,
    authorizedRoles: [ADMIN],
    environments: ["development", "testing", "staging", "production"],
    elements: [
      {
        path: "/billing",
        icon: <Icon name="euro" className="metismenu-icon" />,
        exact: true,
        component: Billing,
        inSidebar: true,
        label: "Facturation",
        children: [],
      },
    ],
  },
  {
    name: "📦 Colis",
    inSidebar: true,
    authorizedRoles: ALL_ROLES,
    environments: ["development", "testing", "staging", "production"],
    elements: [
      {
        path: "/parcels",
        icon: <Icon name="warehouse" className="metismenu-icon" />,
        exact: true,
        component: ParcelsManagement,
        inSidebar: true,
        label: "Gestion des colis",
        authorizedRoles: ALL_ROLES,
        children: [],
      },
      {
        path: "/returning",
        icon: <Icon name="undo" className="metismenu-icon" />,
        exact: true,
        component: Returning,
        inSidebar: true,
        label: "Retours",
        authorizedRoles: ALL_ROLES,
        children: [],
      },
      {
        path: "/createOrder",
        icon: <Icon name="edit" className="metismenu-icon" />,
        exact: true,
        component: CreateOrder,
        inSidebar: true,
        label: "Création commande",
        authorizedRoles: ALL_ROLES,
        children: [],
      },
      {
        path: "/carriers",
        icon: <Icon name="truck" className="metismenu-icon" />,
        exact: true,
        component: Carriers,
        inSidebar: true,
        label: "Transporteurs",
        children: [],
      },
      {
        path: "/parcels/:id",
        icon: "pe-7s-rocket",
        exact: true,
        component: Parcel,
        inSidebar: false,
        label: "Parcel",
        children: [],
      },
    ],
  },
  {
    name: "🔎 Monitoring Ops",
    inSidebar: true,
    authorizedRoles: [ADMIN, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH],
    environments: ["development", "testing", "staging", "production"],
    elements: [
      {
        path: "/reassignments",
        icon: <Icon name="exchange" className="metismenu-icon" />,
        exact: true,
        component: ReassignmentLogs,
        inSidebar: true,
        label: "Réassignations",
        authorizedRoles: [ADMIN, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH],
        children: [],
      },
      // TODO: Activate it when we want to add an IFRAME
      // {
      //   path: "/dashboard",
      //   icon: <Icon name="chart pie" className="metismenu-icon" />,
      //   exact: true,
      //   component: Dashboard,
      //   inSidebar: true,
      //   label: "Dashboard",
      //   authorizedRoles: [ADMIN, MARKETING, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH],
      //   children: [],
      // },
      {
        path: "/carrier-map-logs",
        exact: true,
        component: ListCarrierMapLogs,
        icon: <Icon name="history" className="metismenu-icon" />,
        inSidebar: true,
        label: "Historique de dispos",
        authorizedRoles: [ADMIN, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH],
        children: [],
      },
      {
        path: "/search-keepers-logs",
        exact: true,
        component: ListEndpointCallingLogs,
        icon: <Icon name="server" className="metismenu-icon" />,
        inSidebar: true,
        label: "Appels de WS",
        authorizedRoles: [ADMIN, HEAD_OF_CARE, CARE_SENIOR, CARE_MANAGER, CARE_JUNIOR, TECH],
        children: [],
      },
    ],
  },

  {
    name: "🛰 Monitoring",
    inSidebar: true,
    authorizedRoles: [ADMIN, TECH],
    environments: ["development", "testing", "staging", "production"],
    elements: [
      {
        path: "/system",
        exact: true,
        component: SystemUpdates,
        icon: <Icon name="disk" className="metismenu-icon" />,
        inSidebar: true,
        label: "System",
        children: [],
      },
      {
        path: "/integration",
        exact: true,
        component: IntegrationUpdates,
        icon: <Icon name="flag" className="metismenu-icon" />,
        inSidebar: true,
        label: "Integration",
        children: [],
      },
      {
        path: "/app_versions",
        exact: true,
        component: AppVersionsList,
        icon: <Icon name="mobile alternate" className="metismenu-icon" />,
        inSidebar: true,
        label: "App Versions",
        children: [],
      },
    ],
  },

  {
    name: "🏭 Testing",
    inSidebar: true,
    authorizedRoles: [ADMIN, TECH],
    environments: ["development", "testing", "staging"],
    elements: [
      {
        path: "/fakeOrders",
        icon: <Icon name="lab" className="metismenu-icon" />,
        exact: true,
        component: FakeOrders,
        inSidebar: true,
        label: "Create fake orders",
        children: [],
      },
      {
        path: "/events",
        exact: true,
        component: Events,
        icon: <Icon name="lab" className="metismenu-icon" />,
        inSidebar: true,
        label: "Push events",
        children: [],
      },
    ],
  },
]

export default routes
