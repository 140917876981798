import styled from "styled-components"

export const FieldContainer = styled.div`
  margin-bottom: 5px;
`

export const LabelInput = styled.div`
  font-size: 0.8em;
  margin-bottom: 3px;
`

export const SwapCheckboxContainer = styled.div`
  margin-top: 10px;
`
