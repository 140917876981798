import moment from "moment"
import { capitalize } from "lodash"

import { Meeting, MeetingType } from "types/meeting.types"

export function convertDateToMeetingDate(nextMeeting: Meeting | null | undefined): string | null {
  if (!nextMeeting) return null

  const startDate = moment(nextMeeting?.eventStart)
    .format("DD-MM")
    .replace("-", "/")

  switch (nextMeeting?.type) {
    case MeetingType.DROPOFF:
      return `${startDate} entre ${moment(nextMeeting?.eventStart)
        .format("HH[h]mm")
        .replace("00", "")} et ${moment(nextMeeting?.eventEnd)
        .format("HH[h]mm")
        .replace("00", "")}`
    case MeetingType.COLLECT:
      return `${startDate} entre ${moment(nextMeeting?.eventStart)
        .format("HH[h]mm")
        .replace("00", "")} et ${moment(nextMeeting?.eventEnd)
        .format("HH[h]mm")
        .replace("00", "")}`
    default:
      return null
  }
}

// input : 2023-08-07T10:16:48.890Z
// output : Lundi 07 Août 2023 - 12h16
export const formatDateToDisplayableString = (dateToFormat: string): string => {
  const date = new Date(dateToFormat).toLocaleString("fr-fr", {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  })

  const splitDate = date.split(" ")

  const dateRegex = /^[a-z]+[\s]{1}[\d]{2}[\s]{1}[a-zûé]+[\s]{1}[\d]{4}[\s]{1}[à]{1}[\s]{1}[\d]{2}[:]{1}[\d]{2}$/

  if (splitDate.length != 6 || !dateRegex.test(date)) return date

  splitDate[0] = capitalize(splitDate[0]) //weekday
  splitDate[2] = capitalize(splitDate[2]) //month
  splitDate[4] = "-" // 'à' replaced by '-'
  splitDate[5] = splitDate[5].replace(":", "h") // time

  return splitDate.join(" ")
}
