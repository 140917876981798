import React, { ReactElement, useRef, useState } from "react"
import Zoom from "react-medium-image-zoom"
import { Dropdown, Loader } from "semantic-ui-react"
import {
  KeeperController,
  KeeperControllersContainer,
  KeeperDocumentCard,
  KeeperDocumentContent,
  KeeperDocumentsContainer,
  KeeperDocumentTitle,
  KeeperEmptyCardContainer,
  KeeperEmptyCardText,
  KeeperStatusLoaderContainer,
} from "components/Keeper/Document/KeeperDocuments.styled"
import KeeperDocumentStatus from "components/Keeper/Document/KeeperDocumentStatus"
import { DocumentEntity, DocumentEntityType, DocumentStatus } from "types/document.types"
import { HiddenFileInput } from "components/HiddenFileInput/HiddenFileInput"
import Compressor from "compressorjs"
import { validateFileExtension } from "utils/formik/validateFileExtension"
import { useMutation } from "@apollo/client"
import {
  changeDocumentStatus,
  ChangeDocumentStatusData,
  ChangeDocumentStatusVariables,
  uploadDocumentV3ForKeeper,
  UploadKeeperDocumentData,
  UploadKeeperDocumentVariables,
} from "services/graphql/mutations/keeper.mutations"
import { statusOptions } from "../../../utils/statusOptions"

interface KeeperDocumentProps {
  name: string
  url: string
  status: DocumentStatus
  keeperId: string
  documentType: DocumentEntityType
  refetchKeeperData: () => void
  documentId: string
}

function KeeperDocument({
  name,
  url,
  status,
  keeperId,
  documentType,
  refetchKeeperData,
  documentId,
}: KeeperDocumentProps): ReactElement {
  const [uploadDocumentForKeeperMutation] = useMutation<UploadKeeperDocumentData, UploadKeeperDocumentVariables>(
    uploadDocumentV3ForKeeper,
  )

  const [changeDocumentStatusMutation] = useMutation<ChangeDocumentStatusData, ChangeDocumentStatusVariables>(
    changeDocumentStatus,
  )

  const [documentLoading, setDocumentLoading] = useState(false)
  const [statusLoading, setStatusLoading] = useState(false)

  const inputFileRef = useRef<HTMLInputElement>(null)

  const handleInputClick = () => inputFileRef.current.click()

  const handleFileChange = async e => {
    const file = e.target.files[0]

    if (!validateFileExtension(file)) return null

    setDocumentLoading(true)

    new Compressor(file, {
      quality: 0.8,
      height: 800,
      width: 1000,
      success: compressedFile => {
        uploadDocumentForKeeperMutation({
          variables: {
            keeperId: keeperId,
            type: documentType,
            file: compressedFile,
          },
          onCompleted: async () => {
            await refetchKeeperData()
            setDocumentLoading(prevState => !prevState)
          },
        })
      },
    })
  }

  const _changeDocumentStatus = (documentStatus: DocumentStatus) => {
    setStatusLoading(true)
    changeDocumentStatusMutation({
      variables: {
        documentId: documentId,
        status: documentStatus,
      },
      onCompleted: async () => {
        await refetchKeeperData()
        setStatusLoading(false)
      },
      onError: () => {
        setStatusLoading(false)
      },
    })
  }

  return (
    <>
      <KeeperDocumentCard>
        <KeeperDocumentTitle>{name}</KeeperDocumentTitle>
        {!!status && statusLoading ? (
          <KeeperStatusLoaderContainer>
            <Loader active inline size={"mini"} />
          </KeeperStatusLoaderContainer>
        ) : (
          <KeeperDocumentStatus status={status} />
        )}
        <KeeperDocumentContent>
          {!url && (
            <KeeperEmptyCardContainer>
              <KeeperEmptyCardText>Aucun document</KeeperEmptyCardText>
            </KeeperEmptyCardContainer>
          )}
          {url &&
            (documentLoading ? (
              <Loader active inline />
            ) : (
              <Zoom>
                <img alt="Document" src={url} width="182" height="116" style={{ borderRadius: 10 }} />
              </Zoom>
            ))}
          <KeeperControllersContainer>
            <KeeperController onClick={handleInputClick}>Upload un nouveau document</KeeperController>
            {url && (
              <KeeperController isDropdown>
                <Dropdown
                  fluid
                  button
                  labeled
                  className="dropdown-keeper-document-status"
                  text={"Modifier le statut"}
                  disabled={statusLoading}
                >
                  <Dropdown.Menu>
                    {statusOptions.map(element => (
                      <Dropdown.Item
                        key={element.key}
                        value={element.value}
                        onClick={() => _changeDocumentStatus(element.value)}
                        style={{ fontSize: "10px" }}
                      >
                        {element.text}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </KeeperController>
            )}
          </KeeperControllersContainer>
        </KeeperDocumentContent>
      </KeeperDocumentCard>
      <HiddenFileInput handleFileChange={handleFileChange} ref={inputFileRef} />
    </>
  )
}

interface KeeperDocumentsProps {
  documents: DocumentEntity[]
  keeperId: string
  refetchKeeperData: () => void
}

function KeeperDocuments({ documents, keeperId, refetchKeeperData }: KeeperDocumentsProps): ReactElement {
  const IDCard = documents.find(({ type }) => type === DocumentEntityType.IDCARD)
  const ProofOfAddress = documents.find(({ type }) => type === DocumentEntityType.PROOFOFADDRESS)
  return (
    <KeeperDocumentsContainer>
      <KeeperDocument
        name={"Carte d'identité"}
        url={IDCard?.url}
        status={IDCard?.status}
        keeperId={keeperId}
        documentType={DocumentEntityType.IDCARD}
        refetchKeeperData={refetchKeeperData}
        documentId={IDCard?.id}
      />
      <KeeperDocument
        name={"Justificatif de domicile"}
        url={ProofOfAddress?.url}
        status={ProofOfAddress?.status}
        keeperId={keeperId}
        documentType={DocumentEntityType.PROOFOFADDRESS}
        refetchKeeperData={refetchKeeperData}
        documentId={ProofOfAddress?.id}
      />
    </KeeperDocumentsContainer>
  )
}

export default KeeperDocuments
