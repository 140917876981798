import styled from "styled-components"
import { Button, Icon, Image } from "semantic-ui-react"

import { COLOR } from "utils/color"

export const AllowedCarriersContainer = styled.div`
  display: flex;
`

export const ButtonContainer = styled(Button)<{ cursor?: boolean }>`
  &&& {
    position: relative;
    width: 50px;
    height: 50px;
    padding: 3px;
    box-sizing: border-box;
    background-color: ${COLOR.WHITE};
    border: 1px solid #eaeaea;
    box-shadow: 0px 4px 4px rgb(149, 149, 149, 0.25);
    border-radius: 10px;
    margin-right: 8px;
    cursor: ${({ cursor }) => (cursor ? "pointer" : "unset")};
  }
`

export const CarrierLogo = styled(Image)`
  margin: auto;
  width: ${({ width }) => width + " !important" ?? "35px"};
`

export const CheckIconContainer = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: -6px;
  right: -2px;
  background: ${COLOR.BRIGHT_GREEN};
  border-radius: 10px;
`

export const CheckIcon = styled(Icon)`
  &&& {
    color: white;
    margin-left: 1px;
  }
`

export const CloseIconContainer = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: -6px;
  right: -2px;
  background: ${COLOR.MEDIUM_LIGHT_RED};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CloseIcon = styled(Icon)`
  &&& {
    color: white;
    margin-left: 4px;
    margin-bottom: 1px;
  }
`
export const LoaderIconContainer = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: -6px;
  right: -2px;
  background: ${COLOR.LIGHT_ORANGE};
  border-radius: 10px;
`
