import React, { ReactElement, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { apiGetReassignmentLogs, resetReassignmentLogs } from "services/reassignment-logs"
import { Button } from "semantic-ui-react"
import ReassignmentLogList from "components/ReassignmentLog/ReassignmentLogList"
import { LoadingComponent } from "components/LoadingComponent"
import { PageWrapper } from "components/Layout/Structure"
import PageDescriptionHeader from "components/PageStructure/PageDescriptionHeader"
import PageTitle from "components/Title"

const NB_REASSIGNMENTLOGS_RESULTS = 30

export function ReassignmentLogs(): ReactElement {
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reassignmentLogs = useSelector((state: any) => state.reassignmentLogs.list)

  const { data, pageInfos, loading } = reassignmentLogs

  useEffect(() => {
    dispatch(resetReassignmentLogs())
    dispatch(apiGetReassignmentLogs(NB_REASSIGNMENTLOGS_RESULTS))
  }, [])

  const getNext = async () => {
    await dispatch(apiGetReassignmentLogs(NB_REASSIGNMENTLOGS_RESULTS, pageInfos.endCursor))
  }

  return (
    <PageWrapper>
      <PageTitle>⚖️ Réassignations</PageTitle>
      <PageDescriptionHeader>Liste des réorientations proposées par le bot</PageDescriptionHeader>

      <ReassignmentLogList data={data.map(d => d.node)} />

      {loading && <LoadingComponent />}

      {pageInfos?.hasNextPage && (
        <Button style={{ marginTop: 10 }} onClick={() => getNext()}>
          Charger plus de logs
        </Button>
      )}
    </PageWrapper>
  )
}

export default ReassignmentLogs
