import React, { Fragment, ReactElement } from "react"
import { Row, Col } from "reactstrap"
import moment from "moment"
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon"
import { SystemUpdate } from "types/system.types"

const SystemUpdateLineItem = ({ element }: { element: SystemUpdate }): ReactElement => {
  const successes = element.logs.filter(i => i.status === "SUCCESS").length
  const errors = element.logs.filter(i => i.status === "ERROR").length

  return (
    <Row className="line-element-row">
      <Col xs={2}>
        <div className="line-element-info">
          <div className="label">Date</div>
          <div className="content">{moment(element.startDate).format("DD/MM/YYYY HH:mm")}</div>
        </div>
      </Col>
      <Col xs={2}>{element.details.action}</Col>
      <Col xs={2}>
        <div className="line-element-info">
          <div className="label">
            <Icon name="clock" color="grey" /> Exec time
          </div>
          <div className="content">{element.executionTime / 1000}s</div>
        </div>
      </Col>

      <Col xs={2}>
        <Icon circular name="check" size="small" inverted color="green" />
        {successes}
      </Col>
      <Col xs={2}>
        {errors > 0 && (
          <Fragment>
            <Icon circular name="stethoscope" size="small" inverted color="red" />
            {errors}
          </Fragment>
        )}
      </Col>
    </Row>
  )
}

export default SystemUpdateLineItem
