import React, { ReactElement, Fragment, useEffect } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { Button, Loader } from "semantic-ui-react"

import { apiGetAssociations } from "services/associations"
import { ReduxState } from "types/reduxState.types"
import { COLOR } from "utils/color"

const AssociationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 10px;
  flex-direction: row;
  flex: 1;
  border: 1px solid;
  align-items: center;
  border-color: ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 10px;
`

const AssociationTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface WalletDonationProps {
  askKeeperWalletDonation: (associationId: string) => void
}

const WalletDonation = ({ askKeeperWalletDonation }: WalletDonationProps): ReactElement => {
  const dispatch = useDispatch()
  const associations = useSelector((state: ReduxState) => state.associations)

  useEffect(() => {
    dispatch(apiGetAssociations())
  }, [])

  if (!associations || associations?.list?.loading) {
    return <Loader size="mini" className="loader-button" style={{ width: "100%" }} inline active />
  }

  return (
    <Fragment>
      {associations?.list?.data
        .filter(item => item.activated === true)
        .map((item, key) => {
          const { name, imageUrl } = item
          return (
            <AssociationContainer key={key}>
              <AssociationTitle>
                <img className="association-image" src={imageUrl} />
                <div style={{ marginLeft: 10, fontWeight: 600 }}>{name}</div>
              </AssociationTitle>
              <Button onClick={() => askKeeperWalletDonation(item.id)}>Donner la cagnotte</Button>
            </AssociationContainer>
          )
        })}
    </Fragment>
  )
}

export default WalletDonation
