import React, { ReactElement } from "react"
import styled from "styled-components"

import { DisplayableLog } from "components/SearchKeeperMonitoring/utils/createDisplayableLogs.util"
import { templateDistance } from "utils/distance.util"
import { COLOR } from "utils/color"

const Container = styled.div``

const Title = styled.div`
  font-family: Poppins-Semibold;
`

const Coordinates = styled.div`
  font-style: italic;
  font-size: 0.7em;
  color: ${COLOR.MEDIUM_GREY};
  margin-bottom: 10px;
`

const Subtitle = styled.div`
  color: ${COLOR.MEDIUM_GREY};
  font-size: 0.7em;
`

const Data = styled.div`
  font-size: 0.9em;
  margin-bottom: 10px;
`

const HelpText = styled.div`
  font-style: italic;
  color: ${COLOR.LIGHT_GREY};
  font-size: 0.6em;
`

export default function SearchedAddressLogPopup({ log }: { log: DisplayableLog }): ReactElement {
  return (
    <Container>
      <Title>{log.searchingAddress.formattedLine}</Title>
      <Coordinates>{`${log.searchingAddress.latitude} - ${log.searchingAddress.longitude}`}</Coordinates>

      <Subtitle>👤 Nb résultats</Subtitle>
      <Data>{log.nbKeepers}</Data>

      <Subtitle>📍 Distance + proche</Subtitle>
      <Data>{templateDistance(log.shortestDistance)}m</Data>

      <HelpText>Cliquez pour afficher les Keepers renvoyés</HelpText>
    </Container>
  )
}
