import React, { ReactElement } from "react"

import PinGreen from "assets/mapbox/pin_green.png"
import PinOrange from "assets/mapbox/pin_orange.png"
import PinRed from "assets/mapbox/pin_red.png"
import { MapPin } from "components/Maps/MapPin"
import SimpleTable from "components/Table/SimpleTable"
import { KeeperTableContainer } from "components/Keeper/KeeperTable.styled"
import { Keeper } from "types/keeper.types"

function KeepersTable({ keepers }: { keepers: Keeper[] }): ReactElement {
  if (keepers.length === 0) return <div className="no-content">Aucun keeper</div>

  const result = []
  keepers.forEach(k => {
    const resultIndex = result.findIndex(i => i.zipcode === k.address.zipcode)
    const { status } = k

    if (resultIndex > -1) {
      result[resultIndex].new = status === "NEW" ? result[resultIndex].new + 1 : result[resultIndex].new
      result[resultIndex].pending =
        status === "PENDING" || status === "IN_VALIDATION"
          ? result[resultIndex].pending + 1
          : result[resultIndex].pending
      result[resultIndex].validated =
        status === "VALIDATED" ? result[resultIndex].validated + 1 : result[resultIndex].validated
    } else {
      result.push({
        zipcode: k.address.zipcode,
        new: status === "NEW" ? 1 : 0,
        pending: status === "PENDING" || status === "IN_VALIDATION" ? 1 : 0,
        validated: status === "VALIDATED" ? 1 : 0,
      })
    }
  })
  result.forEach(result => (result["total"] = result.validated + result.pending + result.new))

  const total = result.reduce(
    (acc, item) => ({
      zipcode: "TOTAL",
      new: acc.new + item.new,
      pending: acc.pending + item.pending,
      validated: acc.validated + item.validated,
      total: acc.total + item.new + item.pending + item.validated,
    }),
    {
      zipcode: "TOTAL",
      new: 0,
      pending: 0,
      validated: 0,
      total: 0,
    },
  )

  return (
    <SimpleTable
      headers={[
        {
          name: "Code postal",
          size: 2,
          value: "zipcode",
        },
        {
          name: "Total",
          size: 1,
          value: "total",
        },
        {
          name: (
            <>
              <MapPin src={PinGreen} /> Validé{" "}
            </>
          ),
          size: 3,
          value: "validated",
        },
        {
          name: (
            <>
              <MapPin src={PinOrange} /> Validation en cours{" "}
            </>
          ),
          size: 3,
          value: "pending",
        },
        {
          name: (
            <>
              <MapPin src={PinRed} /> Nouveau{" "}
            </>
          ),
          size: 3,
          value: "new",
        },
      ]}
      data={result.slice().sort((a, b) => Number(a.zipcode) - Number(b.zipcode))}
      footer={total}
    />
  )
}

export default KeepersTable
