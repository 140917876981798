import styled from "styled-components"
import FeatherIcon from "feather-icons-react"

import { COLOR } from "utils/color"

export const QuickSearchBarContainer = styled.div`
  width: 100%;
`

export const QuickSearchInput = styled.input`
  background-color: ${COLOR.WHITE_SMOKE};
  border: 0;
  height: 35px;
  border-radius: 10px;
  padding: 0 10px 0 10px;
  font-size: 11px;
  color: ${COLOR.MEDIUM_GREY};
  width: 100%;

  &:focus {
    outline: none;
  }
`

export const QuickSearchInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
`

export const QuickSearchIcon = styled(FeatherIcon)`
  position: absolute;
  left: 11px;
`

export const QuickSearchCloseIcon = styled(FeatherIcon)<{ displayed: string }>(
  ({ displayed }) => `
    cursor: pointer; 
    display: ${displayed === "true" ? "flex" : "none !important"};
    position: absolute;
    right: 10px;
`,
)

export const QuickSearchResultLine = styled.div`
  border-radius: 10px;
  padding: 3px 5px;
  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 2px;
  overflow: hidden;

  &:hover {
    background-color: ${COLOR.SUPER_LIGHT_BLUE};
  }
`

export const QuickSearchResultLineLeft = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const GreyCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${COLOR.WHITE_SMOKE};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const QuickSearchResultLineRight = styled.div`
  padding-left: 10px;
`

export const QuickSearchResultLineRightTitle = styled.div`
  font-size: 11px;
  color: ${COLOR.DARK_BLUE};
  text-overflow: ellipsis;
  line-height: 11px;
`

export const QuickSearchResultLineRightDescription = styled.div`
  font-size: 10px;
  color: ${COLOR.GREY};
  text-overflow: ellipsis;
`

export const SearchResultSection = styled.div`
  margin-top: 12px;
`

export const Title = styled.p`
  font-size: 11px;
  margin: 0;
  font-weight: 600;
  font-family: "Poppins";
  text-transform: uppercase;
  color: ${COLOR.MEDIUM_DARK_GREY};
`
