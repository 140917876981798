// eslint-disable-next-line max-classes-per-file
import React, { Component } from "react"
import { connect } from "react-redux"
import { createFakeOrders } from "services/orders"
import { Form, Field, Formik } from "formik"
import { Row, Col } from "reactstrap"
import MainContainer from "components/PageStructure/MainContainer"
import ClassicInput from "components/Form/ClassicInput"
import Select from "components/Form/Select"
import generateCompaniesOptions from "utils/formik/generateCompaniesOptions"
import generateKeeperAddressOptions from "utils/formik/generateKeeperAddressOptions"
import FormSaveButton from "components/Buttons/FormSaveButton"
import _ from "lodash"
import Title from "components/Title"
import { OrderType } from "types/order.types"

class FakeOrders extends Component {
  componentDidMount() {}

  submit = values => {
    const data = {
      type: values.orderType.value,
      clientId: values.company.value,
      addressId: values.keeper.data.address.id,
      nbOrders: values.nbOrders,
      nbParcels: values.nbParcels,
      recipient: {
        firstName: values.clientFirstName,
        lastName: values.clientLastName,
        name: `${values.clientFirstName} ${values.clientLastName}`,
        phone: values.clientPhone,
        email: values.clientEmail,
      },
      dropoffEventStart: values.dropoffEventStart,
    }
    this.props.createFakeOrders(data)
  }

  render() {
    const { orders, companies, keepers } = this.props

    return (
      <div style={{ padding: 40 }}>
        <Formik
          enableReinitialize
          initialValues={{
            orderType: "",
            company: "",
            nbOrders: 0,
            nbParcels: 0,
            keeper: "",
            clientLastName: "",
            clientFirstName: "",
            clientEmail: "",
            clientPhone: "",
            dropoffEventStart: "",
          }}
          onSubmit={values => {
            this.submit(values)
          }}
        >
          {({ errors, values }) => {
            return (
              <Form>
                <Row>
                  <Col xs={12} md={6}>
                    <Title>Create fake order</Title>
                    <MainContainer title="Order">
                      <Field
                        component={Select}
                        label="Client"
                        required={false}
                        editable
                        name="company"
                        placeholder="Choose company"
                        options={[{ value: "", label: "Aucun" }].concat(generateCompaniesOptions(companies.list.data))}
                      />

                      <Field
                        component={Select}
                        label="Keeper"
                        required={false}
                        editable
                        name="keeper"
                        placeholder="Choose keeper"
                        options={[{ value: "", label: "Aucun" }].concat(
                          generateKeeperAddressOptions(keepers.list.data),
                        )}
                      />

                      <Field
                        component={Select}
                        label="Order Type"
                        required={false}
                        editable
                        name="orderType"
                        placeholder="Choose order type"
                        options={[
                          { value: OrderType.BtoC, label: "BtoC" },
                          { value: OrderType.CtoB, label: "CtoB" },
                        ]}
                      />

                      {values?.orderType?.value === OrderType.CtoB && (
                        <>
                          <Field
                            component={ClassicInput}
                            label="Dropoff Event Start"
                            required={false}
                            editable={true}
                            name="dropoffEventStart"
                            placeholder="Choose dropoff event start"
                            type="datetime-local"
                          />
                        </>
                      )}

                      <Field
                        type="text"
                        name="nbOrders"
                        label="How many orders"
                        editable
                        component={ClassicInput}
                        required
                      />
                      <Field
                        type="text"
                        name="nbParcels"
                        label="How many parcel(s) per order ?"
                        editable
                        component={ClassicInput}
                        required
                      />
                    </MainContainer>
                    <MainContainer title="Recipient">
                      <Field
                        type="text"
                        name="clientFirstName"
                        label="Firstname"
                        editable
                        component={ClassicInput}
                        required
                      />
                      <Field
                        type="text"
                        name="clientLastName"
                        label="Lastname"
                        editable
                        component={ClassicInput}
                        required
                      />
                      <Field type="text" name="clientEmail" label="Email" editable component={ClassicInput} />
                      <Field type="text" name="clientPhone" label="Phone" editable component={ClassicInput} />
                    </MainContainer>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormSaveButton
                      text="Créer"
                      type="submit"
                      isLoading={orders.list.loading}
                      disabled={!_.isEmpty(errors)}
                    />
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  keepers: state.keepers,
  orders: state.orders,
  companies: state.companies,
})

const mapDispatchToProps = {
  createFakeOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(FakeOrders)
