import styled from "styled-components"
import { Col } from "reactstrap"
import { Button } from "semantic-ui-react"

export const CSVContainer = styled(Col)`
  display: flex;
`

export const CSVButton = styled(Button)`
  &&& {
    margin-left: auto;
  }
`
