import React, { ReactElement } from "react"
import moment from "moment/moment"
import { ActivatedDot, CalendarDayItemWrapper, DateParagraph } from "./CalendarDateItem.styled"

interface CalendarDateItemProps {
  date: string
  selected: boolean
  activated: boolean
  onClick: (date: string) => void
}

export const CalendarDateItem = ({ date, selected, activated, onClick }: CalendarDateItemProps): ReactElement => {
  const momentDate = moment(date)
  return (
    <CalendarDayItemWrapper selected={selected} onClick={() => onClick(date)}>
      <DateParagraph selected={selected}>{momentDate.format("dd")}</DateParagraph>
      <DateParagraph bold selected={selected}>
        {momentDate.format("D")}
      </DateParagraph>
      <ActivatedDot activated={activated} selected={selected} />
    </CalendarDayItemWrapper>
  )
}
