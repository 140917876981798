const generateParcelsOptions = _options => {
  return _options
    .map(opt => ({
      value: opt["id"],
      label: `📦 ${opt.parcelNo}`,
      data: opt,
      type: "PARCEL",
      color: "#32a859",
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export default generateParcelsOptions
