import { Row } from "reactstrap"
import styled from "styled-components"

const LineElementRow = styled(Row)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5em 0;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
`

export default LineElementRow
