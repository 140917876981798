import React, { forwardRef, ReactElement } from "react"
import styled from "styled-components"

interface HiddenInputProps {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  accept?: string
}

const InputFile = styled.input`
  visibility: hidden;
  position: absolute;
  left: 1000px;
  z-index: -50;
  opacity: 0;
`

function HiddenFileInputComponent(
  { handleFileChange, accept = ".png,.jpg" }: HiddenInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
): ReactElement {
  return <InputFile onChange={handleFileChange} ref={ref} aria-hidden={true} type="file" accept={accept} />
}

export const HiddenFileInput = forwardRef(HiddenFileInputComponent)
