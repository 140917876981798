import axios from "axios"
import { BASE_API_URL } from "config/api"
import { toast } from "react-toastify"

const client = axios.create({
  baseURL: BASE_API_URL,
  responseType: "json",
})

export const middlewareConfig = {
  interceptors: {
    response: [
      {
        // eslint-disable-next-line no-empty-pattern
        success: ({}, res) => {
          if (res.headers["x-token-refresh"] !== undefined)
            client.defaults.headers.common.authorization = `Bearer ${res.headers["x-token-refresh"]}`
          return Promise.resolve(res)
        },
        // eslint-disable-next-line no-empty-pattern
        error: ({}, error) => {
          if (error.response.status === 500) {
            toast.error("Erreur serveur, veuillez contacter l'équipe tech")
          } else {
            toast.error(error.response.data.message)
          }
          return Promise.reject(error)
        },
      },
    ],
  },
}
export default client
