export const GET_NEW_PAYMENTS = "GET_NEW_PAYMENTS"

export const GET_ALL_PAYMENTS = "GET_ALL_PAYMENTS"

export const MOVE_NEW_PAYMENTS_TO_DONE = "MOVE_NEW_PAYMENTS_TO_DONE"

export const REVERT_PAYMENT = "MOVE_NEW_PAYMENT_TO_DONE"

export const GET_DONE_PAYMENTS = "GET_DONE_PAYMENTS"

export const RESET_PAYMENTS = "RESET_PAYMENTS"

export const UPDATE_PAYMENTS = "UPDATE_PAYMENTS"
