import React, { ReactElement, useState } from "react"
import LineCollapsableElement from "components/List/LineCollapsableElement"
import { ReassignmentLog } from "types/reassignment-log.types"
import CollapsibleReassignmentLog from "components/ReassignmentLog/CollapsibleReassignmentLog"
import LineItemReassignmentLog from "components/ReassignmentLog/LineItemReassignmentLog"

interface ReassignmentLogListProps {
  data: ReassignmentLog[]
}

const ReassignmentLogList = ({ data }: ReassignmentLogListProps): ReactElement => {
  const [collapseId, setCollapseId] = useState(null)

  const collapseElt = (_collapseId: string) => {
    setCollapseId(collapseId === null ? _collapseId : null)
  }

  return (
    <>
      {data.map(element => (
        <LineCollapsableElement
          key={`collapsible-reassignmentlog-${element.id}`}
          collapseId={collapseId}
          collapseElt={collapseElt}
          element={element}
          lineHeight="lg"
          lineData={<LineItemReassignmentLog element={element} />}
          collapsibleData={element.auto ? <CollapsibleReassignmentLog element={element} /> : null}
        />
      ))}
    </>
  )
}

export default ReassignmentLogList
