import React, { ReactElement } from "react"
import { CSVLink } from "react-csv"

import SimpleTable from "components/Table/SimpleTable"
import { AddressWithKeeper } from "types/address.types"
import { CarrierMapLogWithAddressesDto } from "types/carrier-map-log.types"

export interface TableLine {
  zipcode: string
  nbKeepers: number
  nbActiveKeepers: number
  nbOrders: number
}

export interface CarrierMapLogTableProps {
  data: CarrierMapLogWithAddressesDto
  generateFileName: () => string
}

export function mapAddressesToTable(
  addresses: AddressWithKeeper[],
  active: boolean,
  mappedLines: TableLine[] = [],
): TableLine[] {
  return addresses.reduce((acc, activeAddress: AddressWithKeeper) => {
    const index = acc.findIndex(i => i.zipcode === activeAddress.zipcode)
    if (index > -1) {
      const existingLine = acc[index]
      const changedLine: TableLine = {
        zipcode: existingLine.zipcode,
        nbKeepers: existingLine.nbKeepers + 1,
        nbActiveKeepers: existingLine.nbActiveKeepers + (active ? 1 : 0),
        nbOrders: existingLine.nbOrders + (active ? activeAddress.nbOrdersOfDates : 0),
      }
      acc[index] = changedLine
    } else {
      const newLine: TableLine = {
        zipcode: activeAddress.zipcode,
        nbKeepers: 1,
        nbActiveKeepers: active ? 1 : 0,
        nbOrders: activeAddress.nbOrdersOfDates ?? 0,
      }

      acc.push(newLine)
    }
    return acc
  }, mappedLines)
}

export function mapTableToCsv(colNames: string[], tableLines: TableLine[]): unknown[][] {
  const csvLines = tableLines.map(line => {
    return [line.zipcode, line.nbKeepers, line.nbActiveKeepers, line.nbOrders ?? 0]
  })

  return [colNames, ...csvLines]
}

export default function CarrierMapLogTable({ data, generateFileName }: CarrierMapLogTableProps): ReactElement {
  const activeLines: TableLine[] = mapAddressesToTable(data.activeAddresses, true, [])
  const tableLines: TableLine[] = mapAddressesToTable(data.inactiveAddresses, false, activeLines)

  const onClickDownload = () => {}

  const colNames = ["Code Postal", "Nombre de Keepers", "Keepers Actifs", "Nombre de Commandes"]

  return (
    <>
      <CSVLink
        data={mapTableToCsv(colNames, tableLines)}
        enclosingCharacter=""
        filename={generateFileName()}
        className="btn btn-primary"
        target="_blank"
        onClick={onClickDownload}
      >
        Télécharger la liste des Disponibilités
      </CSVLink>
      <SimpleTable
        headers={[
          {
            name: colNames[0],
            size: 2,
            value: "zipcode",
          },
          {
            name: colNames[1],
            size: 2,
            value: "nbKeepers",
          },
          {
            name: colNames[2],
            size: 2,
            value: "nbActiveKeepers",
          },
          {
            name: colNames[3],
            size: 2,
            value: "nbOrders",
          },
        ]}
        data={tableLines}
      />
    </>
  )
}
