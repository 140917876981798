import React from "react"
import { AdminUserRole } from "../types/admin-user.types"
import { useSelector } from "react-redux"
import { ReduxState } from "../types/reduxState.types"

interface Props extends React.PropsWithChildren {
  allowedRoles: AdminUserRole[]
  showToOthers?: React.ReactNode
}

const RolesGuard: React.FC<Props> = ({ allowedRoles, showToOthers = null, children }) => {
  const { role } = useSelector((state: ReduxState) => state.auth)
  if (allowedRoles.includes(role)) {
    return children
  }
  return showToOthers
}

export default RolesGuard
