import React, { ReactElement } from "react"
import { Form as SemForm } from "semantic-ui-react"
import styled from "styled-components"

import { COLOR } from "utils/color"

interface ClassicInputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: any
  label?: string
  required?: boolean
  placeholder: string
  form: { touched: string[]; errors: string[] }
}

const Container = styled.div`
  font-size: 0.8em;
  margin-bottom: 3px;
`

const InputLabel = styled.div`
  color: ${COLOR.MEDIUM_GREY};
  font-size: 10px;
`

const CrossRequired = styled.span`
  color: ${COLOR.DARK_RED};
  font-size: 1em;
  position: relative;
  bottom: 1px;
  left: 3px;
`

const InputError = styled.div`
  margin-bottom: 10px;
  color: ${COLOR.BRIGHT_RED};
  font-size: 0.7em;
`

const ClassicInput = ({
  field,
  label = null,
  required = false,
  placeholder,
  form: { touched, errors },
  ...props
}: ClassicInputProps): ReactElement => {
  return (
    <Container>
      <InputLabel>
        {label !== null && label}
        {label !== null && required && <CrossRequired>*</CrossRequired>}
      </InputLabel>

      <SemForm.Input
        {...field}
        {...props}
        fluid
        placeholder={placeholder}
        style={{ marginTop: 1, height: 32, fontSize: 12 }}
        error={touched[field.name] && errors[field.name]}
        required={required}
      />

      {touched[field.name] && required && errors[field.name] && <InputError>{errors[field.name]}</InputError>}
    </Container>
  )
}

export default ClassicInput
