import React, { ReactElement } from "react"

import { AddressInfosBlockContainer, AddressInfosBlockSpan } from "components/Address/AddressInfosBlock.styled"
import { AddressInfosBlockProps } from "types/address.types"

function AddressInfosBlock({ address, withNbColis = false }: AddressInfosBlockProps): ReactElement {
  return (
    <AddressInfosBlockContainer>
      <div>
        <AddressInfosBlockSpan>Type d&apos;habitation</AddressInfosBlockSpan>: {address?.housingType || "Aucun"}
      </div>
      <div>
        <AddressInfosBlockSpan>Batiment</AddressInfosBlockSpan>: {address?.building || "Aucun"}
      </div>
      <div>
        <AddressInfosBlockSpan>Étage</AddressInfosBlockSpan>: {address?.floor || "Aucun"}
      </div>
      <div>
        <AddressInfosBlockSpan>Digicode</AddressInfosBlockSpan>: {address?.digicode || "Aucun"}
      </div>
      <div>
        <AddressInfosBlockSpan>Instruction</AddressInfosBlockSpan>: {address?.instructions || "Aucun"}
      </div>
      {withNbColis && (
        <div>
          <AddressInfosBlockSpan>Nb colis</AddressInfosBlockSpan>: {address?.maxParcels || "Aucun"}
        </div>
      )}
    </AddressInfosBlockContainer>
  )
}

export default AddressInfosBlock
