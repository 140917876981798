const generateKeeperAddressOptions = _options => {
  return _options
    .map(opt => ({
      value: opt["id"],
      label: `😎 ${opt.firstName} ${opt.lastName}`,
      data: opt,
      type: "KEEPER",
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export default generateKeeperAddressOptions
