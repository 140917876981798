import styled from "styled-components"

export const AvailabilitySelectorWrapper = styled.div`
  margin-top: 2px;
`

export const AvailabilitySelectorRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const AvailabilitySelectorSpacer = styled.div<{ horizontal?: boolean }>`
  ${({ horizontal }) => (horizontal ? "height" : "width")}: 12px;
  align-self: stretch;
`
