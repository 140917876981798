/**
 * Keep only alphanumeric characters (A-Z, 0-9) from a string.
 * Will replace "-" with a blank space.
 *
 * @param str - String, example: "Mr. (Dark) Vador #OK?"
 *
 * @returns String, example: "Mr Dark Vador OK"
 *
 */
export function onlyAlphanumericCharacters(str: string): string {
  return str?.replace(/-/g, " ").replace(/[^0-9a-z]/gi, "")
}
