import React, { ReactElement } from "react"
import styled from "styled-components"

import { DayAgenda, TimeRange } from "types/keeper.types"
import { COLOR } from "utils/color"

const DayRow = styled.div`
  display: flex;
  flex-direction: row;
`

const DayContainer = styled.div`
  width: 60px;
  height: 50px;
  margin-right: 15px;
`

const DayLetter = styled.div`
  text-align: center;
  margin-top: 7px;
`

const DayAgendaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
`

const DayAgendaCircle = styled.div<{ isAvailable?: boolean }>(
  ({ isAvailable }) => `
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${isAvailable ? COLOR.MEDIUM_LIGHT_GREEN : COLOR.WHITE};
  border: 1px solid ${isAvailable ? COLOR.MEDIUM_LIGHT_GREEN : "grey"};
`,
)

const AgendaAvailabilities = ({ agenda }: { agenda: DayAgenda[] }): ReactElement => {
  const checkIsAvailable = (timeRanges: TimeRange[], startAt: string): boolean => {
    return timeRanges?.filter(t => t.start === startAt).length > 0
  }

  return (
    <DayRow>
      {agenda &&
        agenda.length > 0 &&
        agenda.map((element, index) => {
          const weekDay = new Date(element.date).toLocaleString("fr-fr", { weekday: "long" })
          const firstLetter = weekDay.substring(0, 1).toUpperCase()

          return (
            <DayContainer key={index}>
              <DayLetter>{firstLetter}</DayLetter>
              <DayAgendaContainer>
                <DayAgendaCircle isAvailable={checkIsAvailable(element.timeRanges, "09:00")} />
                <DayAgendaCircle isAvailable={checkIsAvailable(element.timeRanges, "12:00")} />
                <DayAgendaCircle isAvailable={checkIsAvailable(element.timeRanges, "15:00")} />
                <DayAgendaCircle isAvailable={checkIsAvailable(element.timeRanges, "18:00")} />
              </DayAgendaContainer>
            </DayContainer>
          )
        })}
    </DayRow>
  )
}

export default AgendaAvailabilities
