import styled from "styled-components"

export const KeeperAddress = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`

export const KeeperAddressTitle = styled.div`
  font-size: 1.5em;
  margin-bottom: 0;
`

export const KeeperAddressLine = styled.div`
  margin-bottom: 1em;
`

export const PopupCopyAddressID = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 0.8em;
  cursor: pointer;
`
