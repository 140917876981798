export enum CarriersName {
  GEODIS = "Geodis",
  COLISSIMO = "Colissimo",
  DHL = "DHL",
  GLS = "GLS",
  TUT_TUT = "Tut Tut",
  RELAISCOLIS = "Relais Colis",
  POST_OFFICE = "Post Office French Post",
  PAACK = "Paack",
  DPD = "DPD",
}

export interface KeeperCarrier {
  allowed: boolean
  carrierId: string
  carrierName: string
}

export interface AllowedCarrierProps {
  carrier: KeeperCarrier
  updateAllowedCarrier: () => void
  loading: boolean
}

export interface KeeperCarrierButtonProps {
  carrier: KeeperCarrier
  cursor?: boolean
  loading: boolean
}

export interface CarrierAPIProps {
  copyData?: (data: any, carrier: { value: string; label: string }) => void
}
