import React, { ReactElement } from "react"
import styled from "styled-components"

import { COLOR } from "utils/color"

const ReassignmentLogTypeContainer = styled.div<{ backgroundColor: string }>(
  ({ backgroundColor }) => `
    background-color: ${backgroundColor ? backgroundColor : COLOR.DARK_CORAIL};
    width: fit-content;
    border-radius: 7px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLOR.WHITE};
    font-weight: 600;
    text-align: center;
    float: right;
  `,
)

interface ReassignmentLogTypeProps {
  type: string
}

function ReassignmentLogType({ type }: ReassignmentLogTypeProps): ReactElement {
  const bgColor = type === "AUTO" ? COLOR.YELLOW : COLOR.MEDIUM_LIGHT_GREY
  const text = type === "AUTO" ? "Auto" : "Manuel"

  return <ReassignmentLogTypeContainer backgroundColor={bgColor}>{text}</ReassignmentLogTypeContainer>
}

export default ReassignmentLogType
