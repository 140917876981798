import styled from "styled-components"

export const ProfilePictureContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const ValidationReasonChip = styled.div`
  display: flex;
  justify-content: center;
  font-size: 9px;
  border: 1px solid;
  border-radius: 4px;
`
