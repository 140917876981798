import styled from "styled-components"

import { COLOR } from "utils/color"

const LineElementLabel = styled.div`
  font-size: 0.8em;
  color: ${COLOR.LIGHT_GREY};
`

export default LineElementLabel
