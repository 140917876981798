import { Loader } from "semantic-ui-react"
import React, { ReactElement, ReactNode, Ref } from "react"
import { CustomLoader, CustomMenuSection } from "components/SearchBar/SearchBar.styled"
import { LoaderPosition } from "types/search.types"

interface CustomMenuProps {
  innerRef: Ref<HTMLDivElement>
  innerProps: {
    onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void
    onMouseMove: (event: React.MouseEvent<HTMLDivElement>) => void
  }
  selectProps: {
    selectProps: {
      isLoading: boolean
      loaderPosition?: LoaderPosition
    }
  }
  children: ReactNode
}

export const CustomMenu = ({ innerRef, innerProps, selectProps, children }: CustomMenuProps): ReactElement => {
  const { isLoading, loaderPosition } = selectProps.selectProps
  return (
    <CustomMenuSection ref={innerRef} {...innerProps}>
      {children}
      <CustomLoader loaderPosition={loaderPosition}>{isLoading && <Loader active inline />}</CustomLoader>
    </CustomMenuSection>
  )
}
