import React, { ReactElement } from "react"
import Skeleton from "react-loading-skeleton"
import { ParcelKeeperContainer } from "components/Parcels/ParcelInfos.styled"

const ParcelKeeperSkeleton = (): ReactElement => {
  return (
    <ParcelKeeperContainer>
      <Skeleton count={14} width={"60%"} height={"20px"} style={{ lineHeight: 3 }} />
    </ParcelKeeperContainer>
  )
}

export default ParcelKeeperSkeleton
