import React, { Fragment, ReactElement } from "react"
import ReactSelect from "react-select"
import styled from "styled-components"

import { COLOR } from "utils/color"

interface SelectProps {
  label?: string
  value?: { label: string; value: string | number }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (data: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: { label: string; value: string; data?: any }[]
  placeholder: string
  isDisabled?: boolean
  isMulti?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any
  menuPosition?: string
  name?: string
  required?: boolean
  field?: {
    name: string
    value: string
    onBlur: () => void
  }
  form?: {
    touched: string[]
    setFieldValue: (name: string, value: string) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: any[]
  }
}

const InputLabel = styled.div`
  color: ${COLOR.MEDIUM_GREY};
  font-size: 10px;
`

const SelectComponent = styled(ReactSelect)`
  .pickme-select__control {
    border: 1px solid #dededf;
    min-height: 26px !important;
  }
  .pickme-select__control:hover {
    border-color: ${COLOR.MEDIUM_LIGHT_BLUE} !important;
  }
  .pickme-select__control--is-focused {
    box-shadow: 0 0 0 1px ${COLOR.MEDIUM_LIGHT_BLUE} !important;
  }
  .pickme-select__multi-value {
    padding: 3px;
    font-size: 11px;
    display: flex;
    margin: 0 2px 0 0 !important;
    font-weight: 400;
    align-items: center;
    border-radius: 3.5px !important;
    width: fit-content;
    background-color: transparent !important;
  }
  .pickme-select__indicator {
    padding: 4px !important;
  }
  .pickme-select__menu {
    margin-top: 2px !important;
    z-index: 10 !important;
  }
`

const CrossRequired = styled.span`
  color: ${COLOR.DARK_RED};
  font-size: 1em;
  position: relative;
  bottom: 1px;
  left: 3px;
`

const InputError = styled.div`
  margin-bottom: 10px;
  color: ${COLOR.BRIGHT_RED};
  font-size: 0.7em;
`

const Select = ({
  label,
  value,
  onChange,
  options,
  placeholder,
  isDisabled,
  isMulti,
  defaultValue,
  menuPosition,
  name,
  required = false,
  field, // Formik field
  form, // Formik form
}: SelectProps): ReactElement => {
  const _onChange = _value => {
    if (!!form && !!field) form.setFieldValue(field?.name, _value)
    if (!!onChange) onChange(_value)
  }

  return (
    <Fragment>
      {label !== undefined && (
        <InputLabel>
          {label}
          {label !== null && required && <CrossRequired>*</CrossRequired>}
        </InputLabel>
      )}

      <SelectComponent
        isMulti={isMulti ?? false}
        defaultValue={defaultValue}
        menuPosition={menuPosition ?? undefined}
        value={value}
        name={name || field?.name}
        isDisabled={isDisabled ?? false}
        onChange={_onChange}
        onBlur={field?.onBlur ? field.onBlur : () => true}
        options={options}
        placeholder={placeholder}
        classNamePrefix="pickme-select"
        error={form?.touched[name] && form?.errors[name]}
      />
      {form?.touched[name]?.value && required && value.value === "" && (
        <InputError>{form?.touched[name]?.value && form?.errors[name]?.value}</InputError>
      )}
    </Fragment>
  )
}

export default Select
