import { API_GET_ASSOCIATIONS, API_GET_ASSOCIATION_DETAIL } from "actions/associations"

export function apiGetAssociations() {
  return {
    type: API_GET_ASSOCIATIONS,
    payload: {
      request: {
        url: `/admin/associations`,
      },
    },
  }
}

export function getAssociationDetails(id: string, take: number, skip: number) {
  return {
    type: API_GET_ASSOCIATION_DETAIL,
    payload: {
      request: {
        url: `/admin/associations/${id}?take=${take}&skip=${skip}`,
      },
    },
  }
}
