import React, { ReactElement, useState } from "react"
import LineCollapsableElement from "components/List/LineCollapsableElement"
import { LoadingComponent } from "components/LoadingComponent"
import LineItemParcel from "components/Parcels/LineItemParcel"
import { ParcelStatusFilterChip } from "components/Chips/ParcelStatusFilterChip"
import { Parcel, ParcelFlag, ParcelsListProps, ParcelStatus } from "types/parcel.types"
import {
  ButtonContainer,
  FiltersContainer,
  ParcelsListContainer,
  TableHeader,
  TableStats,
} from "components/Parcels/ParcelsList.styled"
import { COLOR } from "utils/color"
import moment from "moment/moment"
import { Button } from "semantic-ui-react"

function ParcelsList({
  parcels,
  loading = false,
  total = null,
  withNb = false,
  withFilters = true,
  style = {},
  sorting = null,
  moreToLoad = false,
  filter = "",
  getNextParcels,
}: ParcelsListProps): ReactElement {
  const [activeStatus, setActiveStatus] = useState(null)

  if (parcels.length === 0) return <div className="no-content">Aucun colis</div>

  const sortParcels = (a: Parcel, b: Parcel) => {
    if (filter === ParcelFlag.RETURN_TO_ORGANIZE) {
      const targetDateA = moment.utc(a.maxHoldingDate).startOf("day")
      const targetDateB = moment.utc(b.maxHoldingDate).startOf("day")

      const daysRemainingForDayA = targetDateA.diff(targetDateB, "days")
      const daysRemainingForDayB = targetDateB.diff(targetDateA, "days")

      return daysRemainingForDayA - daysRemainingForDayB
    } else if (sorting === "DESC") {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    } else if (sorting === "ASC") {
      return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
    }
  }

  return (
    <ParcelsListContainer style={style}>
      <TableHeader>
        {withFilters && (
          <FiltersContainer>
            <ParcelStatusFilterChip
              text={"Tous"}
              inactiveColor={COLOR.WHITE_SMOKE}
              activeColor={COLOR.LIGHT_GREY}
              active={activeStatus === null}
              onClick={() => setActiveStatus(null)}
            />

            <ParcelStatusFilterChip
              text={"A venir "}
              inactiveColor={`${COLOR.MEDIUM_BLUE}1a`}
              activeColor={COLOR.MEDIUM_BLUE}
              active={activeStatus === ParcelStatus.IN_TRANSIT}
              onClick={() => setActiveStatus(ParcelStatus.IN_TRANSIT)}
              number={withNb ? parcels.filter(i => i.status === ParcelStatus.IN_TRANSIT).length : undefined}
            />

            <ParcelStatusFilterChip
              text={"En livraison "}
              inactiveColor={`${COLOR.PURPLE}1a`}
              activeColor={COLOR.PURPLE}
              active={activeStatus === ParcelStatus.IN_DELIVERY}
              onClick={() => setActiveStatus(ParcelStatus.IN_DELIVERY)}
              number={withNb ? parcels.filter(i => i.status === ParcelStatus.IN_DELIVERY).length : undefined}
            />

            <ParcelStatusFilterChip
              text={"Chez le Keeper "}
              inactiveColor={`${COLOR.DARK_CORAIL}1a`}
              activeColor={COLOR.DARK_CORAIL}
              active={activeStatus === ParcelStatus.DELIVERED_TO_KEEPER}
              onClick={() => setActiveStatus(ParcelStatus.DELIVERED_TO_KEEPER)}
              number={withNb ? parcels.filter(i => i.status === ParcelStatus.DELIVERED_TO_KEEPER).length : undefined}
            />
            <ParcelStatusFilterChip
              text={"Livré "}
              inactiveColor={`${COLOR.CITRUS_YELLOW}1a`}
              activeColor={COLOR.CITRUS_YELLOW}
              active={activeStatus === ParcelStatus.DELIVERED_TO_RECIPIENT}
              onClick={() => setActiveStatus(ParcelStatus.DELIVERED_TO_RECIPIENT)}
              number={withNb ? parcels.filter(i => i.status === ParcelStatus.DELIVERED_TO_RECIPIENT).length : undefined}
            />
          </FiltersContainer>
        )}
        <TableStats>{total !== null && `${parcels.length}/${total} colis chargés`}</TableStats>
      </TableHeader>

      {parcels
        .slice()
        .sort(sortParcels)
        .filter(parcel => {
          if (!activeStatus) return true
          return parcel.status === activeStatus
        })
        .map(parcel => (
          <LineCollapsableElement
            key={`linecollapsible-parcel-${parcel.id}`}
            collapseId={null}
            element={parcel}
            lineHeight="sm"
            lineData={<LineItemParcel element={parcel} />}
            collapsibleData={null}
          />
        ))}

      {loading && <LoadingComponent />}

      {moreToLoad && (
        <ButtonContainer>
          <Button onClick={() => getNextParcels(filter)}>Charger plus</Button>
        </ButtonContainer>
      )}
    </ParcelsListContainer>
  )
}

export default ParcelsList
