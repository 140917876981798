import React, { ReactElement, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Checkbox, Popup } from "semantic-ui-react"
import { Field, Form, Formik } from "formik"
import { Col, Row } from "reactstrap"

import Title from "components/Title"
import Select from "components/Form/Select"
import { PageWrapper } from "components/Layout/Structure"
import ClassicInput from "components/Form/ClassicInput"
import FormSaveButton from "components/Buttons/FormSaveButton"
import Search from "components/SearchBar/SearchBar"
import { FieldContainer, LabelInput, SwapCheckboxContainer } from "components/Ops/CreateOrder.styled"
import CarrierAPI from "modules/Ops/CarrierAPI"
import { createBtoCOrderSchema, createCtoBOrderSchema, FormikState, inputsText } from "schema/createOrder.schema"
import { apiSearchKeeper } from "services/keepers"
import { apiGetCompanies } from "services/companies"
import { createOrder } from "services/orders"
import {
  ManualBtoCOrderPacmanAuthorizedStatus,
  ManualCtoBOrderPacmanAuthorizedStatus,
  ManualOrderLabel,
  ManualOrderPacmanReason,
  OrderType,
} from "types/order.types"
import { Keeper } from "types/keeper.types"
import { ReduxState } from "types/reduxState.types"
import generateCompaniesOptions from "utils/formik/generateCompaniesOptions"
import { onlyAlphanumericCharacters } from "utils/onlyAlphanumericCharacters"
import { COLOR } from "utils/color"
import { HorizontalSelector, HorizontalSelectorChip } from "components/HorizontalSelector/HorizontalSelector"

export function CreateOrder(): ReactElement {
  const [company, setCompany] = useState({ value: null, label: null })
  const [keeperId, setKeeperId] = useState(null)
  const [orderType, setOrderType] = useState(OrderType.BtoC)
  const [isSwap, setIsSwap] = useState(false)

  const order = useSelector((state: ReduxState) => state.orders.focus)
  const companies = useSelector((state: ReduxState) => state.companies.list)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(apiGetCompanies())
  }, [])

  const changeCompany = companySelected => {
    setCompany(companySelected)
  }

  const _onSearchKeepers = async (input: string) => {
    if (input.length > 2) {
      const r = await dispatch(apiSearchKeeper(input))
      return transformDataKeepers(r?.payload.data)
    }
    return []
  }

  // Companies (carriers) available for manual creation
  const _getCompanies = () => {
    const options = generateCompaniesOptions(companies.data)

    // For CtoB, return only Relais Colis for the moment
    if (orderType === OrderType.CtoB) {
      return options.filter(o => o.label === "🚛 Relais Colis")
    }

    return options
  }

  // Authorized parcel statuses for parcel creation
  const _getAuthorizedStatuses = () => {
    if (orderType === OrderType.BtoC) {
      return Object.keys(ManualBtoCOrderPacmanAuthorizedStatus).map(status => ({
        value: status,
        label: ManualOrderLabel[status] || "",
      }))
    } else {
      return Object.keys(ManualCtoBOrderPacmanAuthorizedStatus).map(status => ({
        value: status,
        label: ManualOrderLabel[status] || "",
      }))
    }
  }

  const _onChangeKeepers = (keeper: { label: string; type: string; value: string }) => {
    setKeeperId(keeper.value)
  }

  const transformDataKeepers = (keepersArray: Keeper[]) => {
    return keepersArray.map(k => ({
      value: k.id,
      label: `😎 ${k.firstName} ${k.lastName} (${k.email})`,
      type: "KEEPER",
    }))
  }

  const prefillForm = (
    data,
    carrier: { value: string; label: string },
    values: FormikState,
    setValues: (values: FormikState) => void,
  ) => {
    setCompany(carrier)
    setValues({
      ...values,
      company_id: carrier.value,
      parcel_no: data.parcelNo || "",
      swap_order_no: "",
      shipment_no: data.shipmentNo || "",
      shipper_name: data.shipperName || "",
      client_first_name: data.recipient.firstName || "",
      client_last_name: data.recipient.lastName || "",
      client_email: data.recipient.email || "",
      client_phone: data.recipient.phone || "",
    })
  }

  const submit = async (values: FormikState) => {
    const data = {
      parcelNo: values.parcel_no,
      type: orderType,
      shipmentNo: values.shipment_no,
      shipperName: orderType === OrderType.BtoC ? values.shipper_name : null,
      keeperId: keeperId,
      clientId: company.value,
      swapOrderNo: isSwap ? values.swap_order_no : null,
      parcelStatus:
        orderType === OrderType.BtoC
          ? ManualBtoCOrderPacmanAuthorizedStatus[values.parcel_status?.value]
          : ManualCtoBOrderPacmanAuthorizedStatus[values.parcel_status?.value],
      reason: ManualOrderPacmanReason[values.reason?.value],
      details: !values.details ? null : values.details,
      recipient:
        orderType === OrderType.BtoC
          ? {
              firstName: onlyAlphanumericCharacters(values.client_first_name),
              lastName: onlyAlphanumericCharacters(values.client_last_name),
              email: values.client_email,
              phone: values.client_phone,
            }
          : null,
    }

    // Phones starting with +33 or 0033 are changed to 06 format for DB registration
    if (data?.recipient?.phone) {
      const formattedPhone = data?.recipient.phone.replace(/^(\+|00)33/, "0")
      data.recipient.phone = formattedPhone
    }

    await dispatch(createOrder(data))
  }

  return (
    <PageWrapper>
      <Title>Création de commande</Title>

      <HorizontalSelector>
        <HorizontalSelectorChip selected={orderType === OrderType.BtoC} onClick={() => setOrderType(OrderType.BtoC)}>
          BtoC
        </HorizontalSelectorChip>
        <HorizontalSelectorChip selected={orderType === OrderType.CtoB} onClick={() => setOrderType(OrderType.CtoB)}>
          CtoB
        </HorizontalSelectorChip>
      </HorizontalSelector>

      <div>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            parcel_no: "",
            shipment_no: "",
            shipper_name: "",
            keeper_id: "",
            swap_order_no: "",
            company_id: null,
            parcel_status: {
              value: undefined,
              label: undefined,
            },
            reason: {
              value: undefined,
              label: undefined,
            },
            details: "",
            client_first_name: "",
            client_last_name: "",
            client_email: "",
            client_phone: "",
          }}
          validationSchema={orderType === OrderType.BtoC ? createBtoCOrderSchema : createCtoBOrderSchema}
          validate={() => {
            const errors: {
              keeper_id?: string
              company_id?: string
            } = {}

            if (!keeperId) {
              errors.keeper_id = inputsText.REQUIRED_FIELD
            }

            if (!company.value) {
              errors.company_id = inputsText.REQUIRED_FIELD
            }

            return errors
          }}
          onSubmit={values => submit(values)}
        >
          {({ errors, values, setValues }) => {
            return (
              <Form>
                <Row>
                  <Col xs={12} md={6}>
                    <Field
                      type="text"
                      name="parcel_no"
                      label="Numéro de colis"
                      placeholder="AMC123OD"
                      component={ClassicInput}
                      required
                    />

                    <Field
                      type="text"
                      name="shipment_no"
                      label="Numéro de transport"
                      placeholder="9384733384"
                      component={ClassicInput}
                    />

                    {orderType === OrderType.BtoC && (
                      <Field
                        type="text"
                        name="shipper_name"
                        label="Nom du chargeur"
                        placeholder="FNAC"
                        component={ClassicInput}
                      />
                    )}

                    <FieldContainer>
                      <LabelInput>
                        Keeper <span style={{ color: COLOR.DARK_RED }}>*</span>
                      </LabelInput>
                      <Search
                        onSearch={_onSearchKeepers}
                        onChange={_onChangeKeepers}
                        placeholder={"🔍 Rechercher un Keeper"}
                      />
                      {errors.keeper_id && <div className="ui pointing above prompt label">{errors.keeper_id}</div>}
                    </FieldContainer>

                    <FieldContainer>
                      <LabelInput>
                        Client <span style={{ color: COLOR.DARK_RED }}>*</span>
                      </LabelInput>
                      <Select
                        value={company}
                        onChange={changeCompany}
                        options={_getCompanies()}
                        placeholder="Client"
                        menuPosition="fixed"
                      />
                      {errors.company_id && (
                        <div className="ui pointing above prompt label">{errors.company_id as string}</div>
                      )}
                    </FieldContainer>

                    <FieldContainer>
                      <Field
                        component={Select}
                        label="Statut du nouveau colis"
                        required
                        name="parcel_status"
                        placeholder="🏷 Choisir un statut"
                        options={_getAuthorizedStatuses()}
                      />
                    </FieldContainer>

                    <FieldContainer>
                      <Field
                        component={Select}
                        label="Raison de la création manuelle"
                        required
                        name="reason"
                        placeholder="💬 Choisir une raison"
                        options={Object.keys(ManualOrderPacmanReason).map(status => ({
                          value: status,
                          label: ManualOrderLabel[status] || "",
                        }))}
                      />
                    </FieldContainer>

                    <Field
                      type="text"
                      name="details"
                      label="Détails de la raison (optionnel)"
                      placeholder="xxx"
                      component={ClassicInput}
                    />

                    {orderType === OrderType.BtoC && company.label === "🚛 Relais Colis" && (
                      <>
                        <SwapCheckboxContainer>
                          <Checkbox
                            label="Le colis est un SWAP"
                            onClick={() => setIsSwap(!isSwap)}
                            checked={isSwap}
                            style={{ fontSize: 12 }}
                          />
                        </SwapCheckboxContainer>

                        {isSwap && (
                          <Field
                            type="text"
                            name="swap_order_no"
                            label="SWAP - Numéro du colis CtoB associé"
                            placeholder="90392839112343"
                            component={ClassicInput}
                            required
                          />
                        )}
                      </>
                    )}

                    {orderType === OrderType.BtoC && (
                      <>
                        <h5 style={{ marginTop: 20, marginBottom: 10 }}>Destinataire</h5>
                        <Field
                          type="text"
                          name="client_first_name"
                          label="Prénom"
                          placeholder="Michel"
                          component={ClassicInput}
                          required
                        />
                        <Field
                          type="text"
                          name="client_last_name"
                          label="Nom"
                          placeholder="Dupont"
                          component={ClassicInput}
                          required
                        />
                        <FieldContainer>
                          <LabelInput>
                            Email{" "}
                            <Popup
                              content={
                                "Dans le cas d'un 'Retour à planifier', l'email et le numéro de téléphone ne sont pas obligatoire"
                              }
                              size="small"
                              position="bottom center"
                              trigger={<span>ℹ️</span>}
                            />
                          </LabelInput>
                          <Field
                            type="text"
                            name="client_email"
                            placeholder="michel@dupont.fr"
                            component={ClassicInput}
                          />
                        </FieldContainer>
                        <FieldContainer>
                          <LabelInput>
                            Numéro de téléphone{" "}
                            <Popup
                              content={
                                "Dans le cas d'un 'Retour à planifier', l'email et le numéro de téléphone ne sont pas obligatoire"
                              }
                              size="small"
                              position="bottom center"
                              trigger={<span>ℹ️</span>}
                            />
                          </LabelInput>
                          <Field type="text" name="client_phone" placeholder="0612345678" component={ClassicInput} />
                        </FieldContainer>
                      </>
                    )}
                  </Col>
                  <Col xs={12} md={6}>
                    {orderType === OrderType.BtoC && (
                      <CarrierAPI copyData={(data, carrier) => prefillForm(data, carrier, values, setValues)} />
                    )}
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={6}>
                    <FormSaveButton
                      text="Créer"
                      type="submit"
                      isLoading={order.loading}
                      style={{ width: 240, height: 40 }}
                      // disabled={!_.isEmpty(errors)}
                    />
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </div>
    </PageWrapper>
  )
}

export default CreateOrder
