import React, { Fragment, ReactElement, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import cx from "classnames"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import PerfectScrollbar from "react-perfect-scrollbar"

import Logo from "assets/logo-horizontal.png"
import Nav from "components/AppStructure/AppNav/VerticalNavWrapper"
import QuickSearchBar from "components/QuickSearchBar/QuickSearchBar"
import { setEnableClosedSidebar, setEnableMobileMenu } from "reducers/ThemeOptions"
import { ReduxState } from "types/reduxState.types"

interface AppSidebarProps {
  redirectTo: (to: string) => void
}

function AppSidebar({ redirectTo }: AppSidebarProps): ReactElement {
  const dispatch = useDispatch()

  const { enableMobileMenu, enableClosedSidebar } = useSelector((state: ReduxState) => state.ThemeOptions)
  const auth = useSelector((state: ReduxState) => state.auth)
  const [searchBarActive, setSearchBarActive] = useState(false)

  const toggleLeftSidebar = (enable: boolean) => {
    dispatch(setEnableClosedSidebar(enable))
  }

  const toggleMobileSidebar = () => {
    // eslint-disable-next-line no-shadow
    dispatch(setEnableMobileMenu(!enableMobileMenu))
  }

  return (
    <Fragment>
      <div className="sidebar-mobile-overlay" onClick={() => toggleMobileSidebar()} />
      <ReactCSSTransitionGroup
        component="div"
        className={cx("app-sidebar", "sidebar-shadow", { "force-opened-sidebar": searchBarActive })}
        transitionName="SidebarAnimation"
        transitionAppear
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PerfectScrollbar style={{ position: "relative", paddingRight: 0 }}>
          <div className="app-sidebar__inner" style={{ position: "relative", paddingBottom: 50 }}>
            <div style={{ height: 29, overflow: "hidden", marginBottom: "1.5em" }}>
              <img src={Logo} alt="" className="logo-solo" style={{ height: "100%" }} />
            </div>

            <QuickSearchBar
              searchBarActive={searchBarActive}
              setSearchBarActive={setSearchBarActive}
              redirectTo={redirectTo}
            />

            {!searchBarActive && (
              <Nav
                toggleSidebar={enable => toggleLeftSidebar(enable)}
                enableClosedSidebar={enableClosedSidebar}
                auth={auth}
              />
            )}
          </div>
        </PerfectScrollbar>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}

export default AppSidebar
