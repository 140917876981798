import React, { ReactElement, useState } from "react"
import { useSelector } from "react-redux"
import cx from "classnames"

import ResizeDetector from "react-resize-detector"
import { ReduxState } from "types/reduxState.types"

interface MainProps {
  children: ReactElement[]
}

function Main({ children }: MainProps): ReactElement {
  const [width, setWidth] = useState(undefined)
  const [closedSmallerSidebar] = useState(false)

  const onResize = width => setWidth(width)

  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    enableMobileMenu,
  } = useSelector((state: ReduxState) => state.ThemeOptions)

  return (
    <div
      className={cx(
        `app-container app-theme-${colorScheme}`,
        { "fixed-header": enableFixedHeader },
        { "fixed-sidebar": enableFixedSidebar || width < 1250 },
        { "fixed-footer": enableFixedFooter },
        { "closed-sidebar": enableClosedSidebar || width < 1250 },
        { "closed-sidebar-mobile": closedSmallerSidebar || width < 1250 },
        { "sidebar-mobile-open": enableMobileMenu },
      )}
    >
      {children}
      <ResizeDetector handleWidth onResize={onResize} />
    </div>
  )
}

export default Main
