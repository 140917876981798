import { gql } from "@apollo/client"
import { Company } from "types/company.types"

export const getCompanies = gql`
  query getCompanies {
    getCompanies {
      id
      name
    }
  }
`

export interface GetCompanyData {
  getCompany: Company
}
export const getCompany = gql`
  query getCompany($id: String!) {
    getCompany(id: $id) {
      id
      name
      accountancy {
        pricing {
          uniquePrice
          publicPrice
          details {
            trancheMinimum
            trancheMaximum
            prices {
              nbParcels
              price
            }
          }
        }
      }
    }
  }
`

export const getCompanyWithAddresses = gql`
  query getCompanyWithAddresses($id: String!) {
    getCompany(id: $id) {
      id
      name
      companyAddresses {
        id
        name
        completeAddress
        contact
        companyAddressZipcodes {
          zipcode
        }
      }
    }
  }
`
