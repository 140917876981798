import styled from "styled-components"
import { Row } from "reactstrap"

export const TopForm = styled(Row)`
  margin-top: 10px;
  margin-bottom: 10px;
`
export const LowForm = styled(Row)`
  margin-top: 10px;
  margin-bottom: 10px;
`
export const Tabs = styled(Row)`
  margin-top: 10px;
  margin-bottom: 10px;
`
