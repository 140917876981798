import React, { ReactElement, useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "semantic-ui-react"
import MapGL from "react-map-gl"

import MAPBOX_TOKEN from "config/MAPBOX_TOKEN"
import { PageWrapper } from "components/Layout/Structure"
import Title from "components/Title"
import DateInput from "components/Form/DateInput"
import Select from "components/Form/Select"
import CarrierMapLogsMarkers from "components/CarrierMapLog/CarrierMapLogMarkers"
import FilterBarZipcodes from "components/FilterBarZipcodes/FilterBarZipcodes"
import { LoadingComponent } from "components/LoadingComponent"
import { FilterChip, FiltersContainer } from "components/Chips/FilterChip"
import CarrierMapLogTable from "components/CarrierMapLog/CarrierMapLogTable"
import { getAllSelectedCompetitorsPickupPoints } from "components/Competitors/Competitors.util"
import { resetCompetitors, apiGetCompetitors } from "services/competitors"
import { apiGetReturningOneCarrierMapLog, resetCarrierMapLog } from "services/carrier-map-logs"
import { apiGetCompanies } from "services/companies"
import { ReduxState } from "types/reduxState.types"
import generateCompaniesOptions from "utils/formik/generateCompaniesOptions"

function ListCarrierMapLogs(): ReactElement {
  const [day, setDay] = useState(moment().format("YYYY-MM-DD"))
  const [company, setCompany] = useState(null)
  const [zipcodes, setZipcodes] = useState([])
  const [viewport, setViewPort] = useState({
    latitude: 46,
    longitude: 2,
    zoom: 5,
    bearing: 0,
    pitch: 0,
  })
  const [haveSearched, setHaveSearched] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const { data, loading } = useSelector((state: ReduxState) => state.carrierMapLogs.stats)
  const companies = useSelector((state: ReduxState) => state.companies)
  const competitors = useSelector((state: ReduxState) => state.competitors)
  const dispatch = useDispatch()

  const _onChangeCompany = async target => {
    setCompany(target)
  }

  const _onChangeDay = event => {
    setDay(event.target.value)
  }

  const _onChangeZipCodes = zipcodes => {
    setZipcodes(zipcodes)
  }

  const _onViewportChange = viewport => setViewPort(viewport)

  const _getCursor = ({ isHovering }) => {
    return isHovering ? "pointer" : "default"
  }

  const _onClick = () => {
    return true
  }

  const _onSubmit = async () => {
    if (isSubmittable()) {
      await search()
    }
  }

  const isSubmittable = (): boolean => {
    return !!company && zipcodes.length !== 0 && !!day
  }

  /**
   * Search for a carrier map log
   */
  const search = async () => {
    await dispatch(resetCarrierMapLog())

    await dispatch(
      apiGetReturningOneCarrierMapLog(
        day,
        company.value,
        zipcodes.map(code => code.value),
      ),
    )

    setHaveSearched(true)
  }

  // init
  useEffect(() => {
    console.log("ON RIGHT PAGE")
    dispatch(resetCarrierMapLog())
    dispatch(resetCompetitors())
    if (companies.list.data.length === 0) {
      dispatch(apiGetCompanies())
    }
    dispatch(apiGetCompetitors())
  }, [])

  const zipcodesToMatch = zipcodes.map(zipcode => zipcode.value)
  // match addresses that are in the selected zipcode area
  const activeKeeperNumber = (data?.activeAddresses ?? []).filter(activeAddress =>
    zipcodesToMatch.includes(activeAddress.zipcode),
  ).length
  const allKeeperNumber =
    activeKeeperNumber +
    (data?.inactiveAddresses ?? []).filter(inactiveAddress => zipcodesToMatch.includes(inactiveAddress.zipcode)).length

  const generateFileName = (): string => {
    const momentDate = moment(day)
    if (!momentDate.isValid()) {
      throw new Error("invalid date")
    }

    const dateStr = momentDate.format("YYYY-MM-DD")

    let companyName = (company?.label ?? "").toLowerCase()
    // remove emoji prefix
    const FIRST_CHAR_AFTER_EMOJI = 3
    companyName = companyName.substring(FIRST_CHAR_AFTER_EMOJI, companyName.length)

    const fileName = `extract-carrier-map-logs-${companyName}-${dateStr}`

    return fileName
  }

  return (
    <PageWrapper>
      <Title>📖 Historique de disponibilité</Title>
      <Row>
        <Col xs={4}>
          <Select
            value={company}
            onChange={_onChangeCompany}
            options={generateCompaniesOptions(companies.list.data)}
            placeholder="Choisir Transporteur"
            menuPosition="fixed"
            label="Transporteur"
          />
        </Col>
        <Col xs={8}>
          <DateInput label={"Jour"} value={day} onChange={_onChangeDay} />{" "}
        </Col>
      </Row>
      <FilterBarZipcodes onChangeZipcodes={_onChangeZipCodes} />
      <Row>
        <Col xs={4}>
          <Button className="search-button" disabled={!isSubmittable()} onClick={_onSubmit}>
            Chercher
          </Button>
        </Col>
      </Row>
      {loading && <LoadingComponent />}

      {!loading && data && (
        <>
          <Row style={{ marginTop: 10 }}>
            <Col xs={12}>
              <div>
                {`${allKeeperNumber} keepers dont `}
                <b>{`${activeKeeperNumber} actifs`}</b>
              </div>
              <br />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col xs={6}>
              <div>
                🗺 Etat de la carte entre le <b>{moment(data.startDate).format("DD/MM/YYYY à HH:mm")}</b> et le{" "}
                <b>{moment(data.endDate).format("DD/MM/YYYY à HH:mm")}</b>
              </div>
              <div>
                🚛 Filtres: <b>{data.opsFilters.numberOfAvailabilitiesNeeded}</b> créneaux entre{" "}
                <b>{data.opsFilters.startHour}</b> et <b>{data.opsFilters.endHour}</b> à partir du{" "}
                <b>{moment(data.opsFilters.startDate).format("DD/MM/YYYY à HH:mm")}</b> sur{" "}
                <b>{data.opsFilters.numberExtraDaysToCheck + 1}</b> jour(s) consécutif(s)
                <br />
              </div>
            </Col>
            <Col xs={6} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
              🕰 Généré le {moment(data.generationDate).format("DD/MM/YYYY à HH:mm")}{" "}
              <a href={data.fileLink} download>
                🗂 Télécharger le fichier
              </a>
            </Col>
          </Row>

          <FiltersContainer>
            <FilterChip text="Tableau" active={activeTab === 0} onClick={() => setActiveTab(0)} />

            <FilterChip text="Carte" active={activeTab === 1} onClick={() => setActiveTab(1)} />
          </FiltersContainer>
          {activeTab === 0 && <CarrierMapLogTable data={data} generateFileName={generateFileName} />}

          {activeTab === 1 && (
            <>
              <MapGL
                {...viewport}
                width="100%"
                height="100%"
                style={{ minHeight: 500 }}
                clickRadius={2}
                onClick={_onClick}
                getCursor={_getCursor}
                interactiveLayerIds={[]}
                onViewportChange={_onViewportChange}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                reuseMaps
              >
                <CarrierMapLogsMarkers
                  data={data.activeAddresses}
                  competitorsPickupPoints={getAllSelectedCompetitorsPickupPoints(competitors, viewport)}
                />
              </MapGL>
            </>
          )}
        </>
      )}

      {!loading && !data && haveSearched && <div style={{ marginTop: 10 }}>Aucune donnée trouvée</div>}
    </PageWrapper>
  )
}

export default ListCarrierMapLogs
