import styled from "styled-components"

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const DynamicParcelListContainer = styled.div`
  padding: 10px 0;
`
export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  padding: 0 10px;
`
