import React, { ReactElement } from "react"
import moment from "moment"
import { Col, Row } from "reactstrap"
import { Input } from "semantic-ui-react"

import {
  RadiusInputContainer,
  RadiusInputText,
} from "components/SearchKeeperMonitoring/WebServiceMonitoringHeader.styled"
import { WebServiceMonitoringHeaderProps } from "types/search-keeper-log.types"

export default function WebServiceMonitoringHeader({
  company,
  startDay,
  endDay,
  startRange,
  endRange,
  logs,
  setRadiusFilter,
}: WebServiceMonitoringHeaderProps): ReactElement {
  return (
    <Row>
      <Col md={7}>
        <p>
          Le Webservice a été appelé <b>{logs.length}</b> fois par {company?.label} entre le{" "}
          <b>
            {moment(startDay).format("DD/MM/YYYY à")} {startRange.label}
          </b>{" "}
          et le{" "}
          <b>
            {moment(endDay).format("DD/MM/YYYY à")} {endRange.label}
          </b>
        </p>
      </Col>
      <RadiusInputContainer md={5} style={{ display: "flex", alignItems: "baseline" }}>
        <RadiusInputText style={{ marginLeft: "auto" }}>
          Filtrer par distance (keeper le plus proche) :{" "}
          <Input placeholder="distance" type="number" onChange={event => setRadiusFilter(event.target.value)} /> m.
        </RadiusInputText>
      </RadiusInputContainer>
    </Row>
  )
}
