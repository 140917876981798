import styled from "styled-components"
import { Icon } from "semantic-ui-react"

import { COLOR } from "utils/color"

export const CopyIcon = styled(Icon)`
  color: ${COLOR.MEDIUM_SUPER_LIGHT_GREY};
  padding-left: 5px;
  &:hover {
    color: ${COLOR.LIGHT_GREY};
  }
  &:active {
    color: ${COLOR.BLACK};
  }
`

export const OrderType = styled.div`
  display: flex;
  margin-right: 10px;
  border: 1px solid ${COLOR.SUPER_LIGHT_GREY};
  padding: 2px 4px;
  border-radius: 10px;
  align-items: center;
  font-size: 0.8em;
  color: ${COLOR.MEDIUM_GREY};
`

// This component for all columns
export const TextEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
`

// This component for the case where Instructions are too long
export const TextEllipsisForLongInstructions = styled.div`
  max-height: 4.5em;
  line-height: 1.5em;
  overflow: hidden;
  position: relative;

  &::before {
    content: "...";
    position: absolute;
    inset-block-end: 0;
    inset-inline-end: 0;
  }
`
