import styled from "styled-components"
import { COLOR } from "utils/color"

export const AvailabilitySlotWrapper = styled.div<{ selected?: boolean; color?: string }>`
  flex: 1;
  height: 160px;
  max-width: 160px;
  border-width: ${({ selected }) => (selected ? "2px" : "1px")};
  border-style: solid;
  border-radius: 25px;
  border-color: ${({ color, selected }) => (selected ? color : COLOR.SUPER_LIGHT_GREY)};
  align-items: center;
  padding-top: 20px;
  background-color: white;
`
export const Paragraph = styled.div<{ bold?: boolean; padded?: boolean }>`
  font-family: ${({ bold = false }) => (bold ? "Poppins-Semibold" : "Poppins-Regular")};
  font-size: 14px;
  color: ${COLOR.DARK_GREY};
  line-height: 22px;
  margin-bottom: ${({ padded = true }) => (padded ? 32 : 0)}px;
`

export const AvailabilitySlotContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const AvailibilitySlotParagraph = styled(Paragraph).attrs({ padded: false })<{
  color?: string
  caption?: boolean
}>`
  text-align: center;
  color: ${({ color, caption }) => (caption ? COLOR.MEDIUM_SUPER_LIGHT_GREY : color || "#848484")};
  font-size: ${({ caption }) => (caption ? "10px" : "12px")};
  line-height: ${({ caption }) => (caption ? "12px" : "18px")};
  ${({ caption }) => (caption ? "margin-bottom: 8px" : "")}
  margin: 8px 0;
`
