import { createReducer } from "@reduxjs/toolkit"
import { API_GET_RETURNED_ADDRESSES, RESET_RETURNED_ADDRESSES } from "actions/returned-addresses"
import { toFailure, toSuccess } from "actions"

const initialState = {
  stats: {
    loading: false,
    error: false,
    data: null,
  },
}

const returnedAddresses = createReducer(initialState, {
  [API_GET_RETURNED_ADDRESSES]: state => {
    state.stats.loading = true
  },
  [toSuccess(API_GET_RETURNED_ADDRESSES)]: (state, action) => {
    state.stats.loading = false
    state.stats.error = false

    state.stats.data = action.payload.data
  },
  [toFailure(API_GET_RETURNED_ADDRESSES)]: state => {
    state.stats.loading = false
    state.stats.error = true
  },

  // RESET STATE
  [RESET_RETURNED_ADDRESSES]: state => {
    state.stats.data = null
    state.stats.loading = false
  },
})

export default returnedAddresses
