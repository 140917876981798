import React, { ReactElement, useEffect } from "react"
import LineElementRow from "components/Table/LineElementRow"
import LineElementCol from "components/Table/LineElementCol"
import {
  BlockBackground,
  ButtonContainer,
  Container,
  DetailInfo,
  LineElementContainerStyled,
  SeeDetailButton,
  SemiBoldText,
  TransfersNumber,
} from "../WireTransfers.styled"
import { TotalSum, TransferDate } from "./PerformedTransfersHistory.styled"
import { DetailLine } from "./DetailLine"
import { DetailLineHeader } from "./DetailLineHeader"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "types/reduxState.types"
import { LoadingComponent } from "components/LoadingComponent"
import { PaymentTransfer, PaymentExtended } from "types/payments.types"
import { apiGetDonePayments } from "services/payments"
import { Button } from "semantic-ui-react"
import { WIRE_TRANSFERS_LIMIT } from "../data/constants"

interface TransferLineProps {
  transfer: PaymentTransfer
  handleOpenedTransfer: (transferId: string) => void
  isOpened: boolean
}

export const TransferLine = ({ transfer, handleOpenedTransfer, isOpened }: TransferLineProps): ReactElement => {
  const dispatch = useDispatch()

  const loadingRevert = useSelector((state: ReduxState) => state.payments.listAll.loadingRevert)
  const formattedDate = moment(transfer?.batchProcessedAt).format("DD/MM/YYYY")

  const packmanBatchId = transfer.packmanBatchId

  const listDone = useSelector((state: ReduxState) => state.payments.listDone)
  const listDoneData = listDone.data
  const listDoneLoading = listDone.loading

  const paymentsOfCurrentBatch = listDoneData[packmanBatchId] || []

  const loadMorePaymentsForBatch = () => {
    if (!paymentsOfCurrentBatch?.length) {
      return
    }
    const cursor = paymentsOfCurrentBatch.at(-1).cursor
    dispatch(apiGetDonePayments({ limit: WIRE_TRANSFERS_LIMIT, packmanBatchId: transfer?.packmanBatchId, cursor }))
  }

  useEffect(() => {
    if (!paymentsOfCurrentBatch?.length && isOpened) {
      dispatch(apiGetDonePayments({ limit: WIRE_TRANSFERS_LIMIT, packmanBatchId: transfer?.packmanBatchId }))
    }
  }, [paymentsOfCurrentBatch, isOpened])

  const moreToLoad = transfer?.totalCount > paymentsOfCurrentBatch.length

  return (
    <Container>
      <LineElementContainerStyled onClick={() => handleOpenedTransfer(packmanBatchId)} lineHeight="lg">
        <LineElementRow>
          <LineElementCol
            width={"10%"}
            size={2}
            content={
              <div>
                <TransferDate>{formattedDate}</TransferDate>
              </div>
            }
          />
          <LineElementCol
            width={"20%"}
            size={2}
            content={<TransfersNumber>{transfer?.totalCount || 0} virements effectués ✅</TransfersNumber>}
          />
          <LineElementCol
            size={2}
            content={
              <TotalSum>
                Total : <SemiBoldText>{transfer?.totalSum || 0}€</SemiBoldText>
              </TotalSum>
            }
          />
          <LineElementCol size={2} content={<></>} />
          <LineElementCol
            size={2}
            content={
              <ButtonContainer>
                <SeeDetailButton>Voir le détail</SeeDetailButton>
              </ButtonContainer>
            }
          />
        </LineElementRow>
      </LineElementContainerStyled>
      <BlockBackground isOpened={isOpened} />
      {
        <DetailInfo isOpened={isOpened}>
          <DetailLineHeader />
          {paymentsOfCurrentBatch?.map((paymentFromBatch: PaymentExtended) => (
            <DetailLine
              key={paymentFromBatch.id}
              packmanBatchId={packmanBatchId}
              paymentFromBatch={paymentFromBatch}
              totalCount={paymentsOfCurrentBatch.length}
              canRevertPayment
            />
          ))}
          {!listDoneLoading && moreToLoad && (
            <ButtonContainer>
              <Button onClick={loadMorePaymentsForBatch}>Charger plus</Button>
            </ButtonContainer>
          )}
          {(loadingRevert || (isOpened && listDoneLoading)) && <LoadingComponent />}
        </DetailInfo>
      }
    </Container>
  )
}
