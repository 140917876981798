import { LoadingComponent } from "components/LoadingComponent"
import React, { ReactElement, Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { EventEntity } from "types/event.types"
import { apiGetParcelEvents, apiSimulateParcelEvent } from "services/parcels"
import ParcelEventsSkeleton from "components/Parcels/ParcelSkeleton/ParcelEventsSkeleton"
import ParcelEvent from "components/ParcelEvent/ParcelEvent"
import styled from "styled-components"
import ParcelTesting from "components/Parcels/ParcelTesting"

interface ParcelEvents {
  focus: {
    events: {
      loading: boolean
      error: string
      data: EventEntity[]
    }
  }
}

const ParcelEvents = (): ReactElement => {
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parcels: ParcelEvents = useSelector((state: any) => state.parcels)
  const history = useParams()

  useEffect(() => {
    const { id: parcelId } = history
    dispatch(apiGetParcelEvents(parcelId))
  }, [])

  /** Reset parcel data if params changed */
  useEffect(() => {
    const { id: parcelId } = history
    dispatch(apiGetParcelEvents(parcelId))
  }, [history.id])

  const { events } = parcels.focus
  const { data, loading } = events

  if (data.length === 0) {
    return <ParcelEventsSkeleton />
  }

  /** "Copying and sorting data by date */
  const dataCopy = data.slice().sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime())

  return (
    <Fragment>
      <div className="parcel-content parcel-content-events">
        {process.env.REACT_APP_STAGE !== "production" && (
          <ParcelTesting
            simulateParcelEvent={data => dispatch(apiSimulateParcelEvent({ parcelId: history?.id, ...data }))}
          />
        )}
        <div className="history-events">
          <div className="subtitle">Évènements</div>
          {loading && <LoadingComponent />}
          <EventsWrap>
            {dataCopy.map((event, index) => (
              <ParcelEvent
                key={index + event.eventCode}
                event={event}
                prevEventDate={index < data.length - 1 ? dataCopy[index + 1]?.eventDate : null}
              />
            ))}
          </EventsWrap>
        </div>
      </div>
    </Fragment>
  )
}

const EventsWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export default ParcelEvents
