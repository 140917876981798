import { createReducer } from "@reduxjs/toolkit"

import { toFailure, toSuccess } from "actions"
import {
  API_GET_COMPETITORS,
  RESET_COMPETITORS,
  API_GET_COMPETITOR_PICKUP_POINTS,
  REMOVE_COMPETITOR_PICKUP_POINTS,
} from "actions/competitors"

interface initialStateProps {
  list: {
    loading: boolean
    error: boolean
    data: []
  }
  pickupPoints: {
    loading: boolean
    error: boolean
    data: {}
  }
}

const initialState: initialStateProps = {
  list: {
    loading: false,
    error: false,
    data: [],
  },
  pickupPoints: {
    loading: false,
    error: false,
    data: {},
  },
}

const competitors = createReducer(initialState, {
  [RESET_COMPETITORS]: state => {
    state = initialState
  },

  [API_GET_COMPETITORS]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_COMPETITORS)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data = action.payload.data
  },
  [toFailure(API_GET_COMPETITORS)]: state => {
    state.list.loading = false
    state.list.error = true
  },

  [API_GET_COMPETITOR_PICKUP_POINTS]: state => {
    state.pickupPoints.loading = true
  },
  [toSuccess(API_GET_COMPETITOR_PICKUP_POINTS)]: (state, action) => {
    state.pickupPoints.loading = false
    state.pickupPoints.error = false
    state.pickupPoints.data[action.meta.previousAction.payload.competitorId] = action.payload.data
  },
  [toFailure(API_GET_COMPETITOR_PICKUP_POINTS)]: state => {
    state.pickupPoints.loading = false
    state.pickupPoints.error = true
  },

  [REMOVE_COMPETITOR_PICKUP_POINTS]: (state, action) => {
    delete state.pickupPoints.data[action.competitorId]
  },
})

export default competitors
