import { API_GET_RETURNED_ADDRESSES, RESET_RETURNED_ADDRESSES } from "actions/returned-addresses"

const baseUrl = "/admin/addresses-by-ids"

export function apiGetReturnedAddresses(addressIds: string[]) {
  const stringifiedIds = JSON.stringify(addressIds)

  return {
    type: API_GET_RETURNED_ADDRESSES,
    payload: {
      request: {
        url: `${baseUrl}?stringifiedIds=${stringifiedIds}`,
      },
    },
  }
}

export function resetReturnedAddresses() {
  return {
    type: RESET_RETURNED_ADDRESSES,
  }
}
