import React, { ReactElement } from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import KeeperRating from "components/Keeper/KeeperRating"
import AgendaAvailabilities from "components/Keeper/Calendar/AgendaAvailabilities"
import { KeeperAvailabilitiesNextDays } from "types/keeper.types"
import { templateDistance } from "utils/distance.util"
import { COLOR } from "utils/color"

const DistanceData = styled.span`
  margin-left: 5px;
`

const ButtonChooseContainer = styled.div`
  text-align: right;
`

const ButtonChoose = styled.button<{ color?: string; marginRight?: number }>(
  ({ color, marginRight }) => `
  background: ${color ? color : COLOR.CORAIL};
  color: white;
  font-size: 0.9em;
  padding: 8px 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  ${marginRight && `margin-right: ${marginRight}px`}
`,
)

const LineItemReassign = ({
  address,
  confirmationAction,
}: {
  address: KeeperAvailabilitiesNextDays["address"]
  confirmationAction?: (
    addressId: string,
    newKeeperInfos: {
      name: string
      address: string
      instructions: string
      phone: string
    },
  ) => void
}): ReactElement => {
  const { distance, keeper, agenda } = address
  const { keeperScoring } = keeper

  const reassignChooseKeeper = () => {
    if (!!confirmationAction) {
      const keeperName = `Keeper : ${keeper.firstName} ${keeper.lastName}`
      const formattedAddress = `Adresse : ${address.streetLine} ${address.zipcode} ${address.city}`

      const addressInstructions = `Instructions : ${address.building !== null ? address.building + "," : ""}${
        address.floor !== null ? "Étage" + address.floor + "," : ""
      }${address.digicode !== null ? address.digicode + "," : ""}${
        address.instructions !== null ? address.instructions : ""
      }`

      const phone = `Téléphone: ${keeper.phone}`

      confirmationAction(address.id, {
        name: keeperName,
        address: formattedAddress,
        instructions: addressInstructions,
        phone: phone,
      })
    }
  }

  return (
    <Row className="line-element-row">
      <Col xs={1}>
        <div className="line-element-info">
          <div className="content">
            📍 <DistanceData>{templateDistance(distance || 0)}</DistanceData>
          </div>
        </div>
      </Col>
      <Col xs={3}>
        <div className="line-element-info">
          <div className="content">
            {keeper.firstName} {keeper.lastName}
          </div>
          <div className="content reassign-formattedLine">{address.formattedLine}</div>
        </div>
      </Col>
      <Col xs={2}>
        <div className="line-element-info">
          <KeeperRating
            keeperScoring={keeperScoring}
            nbOngoingParcels={keeper?.nbOngoingParcels}
            nbTotalDeliveredParcels={keeper?.nbTotalDeliveredParcels}
            nbMaxParcels={address.maxParcels}
          />
        </div>
      </Col>
      <Col xs={4}>
        <div className="line-element-info">
          <AgendaAvailabilities agenda={agenda} />
        </div>
      </Col>
      {!!confirmationAction && (
        <Col xs={2}>
          <div className="line-element-info">
            <div className="content">
              <ButtonChooseContainer>
                <ButtonChoose onClick={() => reassignChooseKeeper()}>Choisir ce Keeper</ButtonChoose>
              </ButtonChooseContainer>
            </div>
          </div>
        </Col>
      )}
    </Row>
  )
}

export default LineItemReassign
