import { toFailure, toSuccess } from "actions"
import { createReducer } from "@reduxjs/toolkit"
import { API_GET_INTEGRATION_STATS, API_GET_INTEGRATION_UPDATES, RESET_INTEGRATION_UPDATES } from "actions/system"
import { IntegrationUpdate } from "types/system.types"

interface initiaStateInterface {
  list: {
    loading: boolean
    error: boolean
    moreToLoad: boolean
    data: IntegrationUpdate[]
  }
  stats: {
    loading: boolean
    error: boolean
    data: any
  }
}

const initialState: initiaStateInterface = {
  list: {
    loading: false,
    error: false,
    moreToLoad: true,
    data: [],
  },
  stats: {
    loading: false,
    error: false,
    data: {},
  },
}

const integrationUpdates = createReducer(initialState, {
  [API_GET_INTEGRATION_UPDATES]: state => {
    state.list.loading = true
    state.list.moreToLoad = true
  },
  [toSuccess(API_GET_INTEGRATION_UPDATES)]: (state, action) => {
    // TODO: Use take/skip from the request
    if (action.payload.data.length < 15) state.list.moreToLoad = false

    state.list.loading = false
    state.list.error = false
    state.list.data = state.list.data.concat(action.payload.data)
  },
  [toFailure(API_GET_INTEGRATION_UPDATES)]: state => {
    state.list.loading = false
    state.list.error = true
  },
  [RESET_INTEGRATION_UPDATES]: state => {
    state.list.data = []
  },
  [API_GET_INTEGRATION_STATS]: state => {
    state.stats.loading = true
  },
  [toSuccess(API_GET_INTEGRATION_STATS)]: (state, action) => {
    state.stats.loading = false
    state.stats.error = false
    state.stats.data = action.payload.data
  },
  [toFailure(API_GET_INTEGRATION_STATS)]: state => {
    state.stats.loading = false
    state.stats.error = true
  },
})

export default integrationUpdates
