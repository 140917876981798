import React, { ReactElement, Fragment } from "react"
import { Button } from "semantic-ui-react"

interface WalletGiveawayProps {
  askKeeperWalletGiveaway: () => void
}

const WalletGiveaway = ({ askKeeperWalletGiveaway }: WalletGiveawayProps): ReactElement => {
  return (
    <Fragment>
      <div style={{ marginBottom: 10 }}>
        En effectuant ce transfert le Keeper accepte de renoncer définitivement à sa cagnotte Pickme
      </div>
      <Button onClick={askKeeperWalletGiveaway}>Renoncer à la cagnotte</Button>
    </Fragment>
  )
}

export default WalletGiveaway
