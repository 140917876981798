const generateCompaniesOptions = (_options, type = null) => {
  let options = _options
  if (type === "CARRIER") options = _options.filter(i => i.role === "CARRIER")
  if (type === "RETAILER") options = _options.filter(i => i.role === "RETAILER")

  return options
    .map(opt => ({
      value: opt["id"],
      label: `${opt.role === "RETAILER" ? "👕" : "🚛"} ${opt.name}`,
      type: opt.role,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export default generateCompaniesOptions
