import React, { ReactElement, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"

import { LoadingComponent } from "components/LoadingComponent"
import Title from "components/Title"
import Select from "components/Form/Select"
import SimpleTable from "components/Table/SimpleTable"
import { SelectContainer, LoadMoreButton } from "components/Lead/Lead.styled"
import { apiGetLeads, resetLeads } from "services/leads"
import { LeadType } from "types/leads.types"
import { ReduxState } from "types/reduxState.types"

const options = [
  { value: LeadType.LANDING, label: "Leads venant de la landing page" },
  { value: LeadType.APP_KEEPER, label: "Leads venant de l'application mobile" },
  { value: "", label: "Tous les types de leads" },
]

function Leads(): ReactElement {
  const [take, setTake] = useState<number>(25)
  const [skip, setSkip] = useState<number>(0)
  const [moreToLoad, setMoreToLoad] = useState<boolean>(true)

  const list = useSelector((state: ReduxState) => state.leads.list)
  const { activeFilter } = list

  const dispatch = useDispatch()

  const fetchNext = async filter => {
    await dispatch(apiGetLeads({ skip, take, filter: filter || null }))

    if (list.data.length === skip || list.data.length < take) {
      setMoreToLoad(false)
    } else {
      setSkip(list.data.length)
    }
  }

  useEffect(() => {
    dispatch(resetLeads())
    fetchNext(null)
  }, [])

  const handleFilter = async item => {
    await dispatch(resetLeads())
    setTake(25)
    setSkip(0)
    setMoreToLoad(true)
    await fetchNext(item.value)
  }

  return (
    <div style={{ padding: 40 }}>
      <Title>Leads</Title>
      <SelectContainer>
        <Select
          value={options.find(item => item.value === list.activeFilter)}
          placeholder={"🔍  Filtrer par"}
          onChange={handleFilter}
          options={options}
        />
      </SelectContainer>
      <br />
      {list.data.length === 0 && <div className="no-content">Aucun lead</div>}

      {list.data.length > 0 && (
        <SimpleTable
          headers={[
            {
              name: "Créé le",
              size: 2,
              value: "createdAt",
              component: createdAt => moment(createdAt).format("DD/MM/YY HH:mm"),
            },
            {
              name: "Email",
              size: 2,
              value: "email",
            },
            {
              name: "Origine",
              size: 2,
              value: "type",
            },
            {
              name: "Devenu keeper",
              size: 2,
              value: "isKeeper",
              component: isKeeper => (isKeeper ? <span>✅</span> : <span>❌</span>),
            },
          ]}
          data={list.data}
        />
      )}

      {list.loading && <LoadingComponent />}
      {moreToLoad && <LoadMoreButton onClick={() => this.fetchNext(activeFilter)}>Charger plus</LoadMoreButton>}
    </div>
  )
}

export default Leads
