import React, { ReactElement } from "react"
import Skeleton from "react-loading-skeleton"
import { ParcelRecipientContainer } from "components/Parcels/ParcelInfos.styled"

const ParcelRecipientSkeleton = (): ReactElement => {
  return (
    <ParcelRecipientContainer>
      <Skeleton count={3} width={"60%"} height={"20px"} style={{ lineHeight: 3 }} />
    </ParcelRecipientContainer>
  )
}

export default ParcelRecipientSkeleton
