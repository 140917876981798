import styled from "styled-components"
import { COLOR } from "utils/color"

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 20px;
  padding: 2px;
  margin-bottom: 7px;
`

export const Title = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.9em;
  font-weight: 600;
  color: ${COLOR.DARK_GREY};
`

export const EmojiBlock = styled.div`
  font-size: 15px;
  width: 25px;
  height: 25px;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PartnerBlock = styled.div`
  display: flex;
  column-gap: 49px;
`

export const Partners = styled.div`
  display: flex;
  flex-direction: column;
`

export const Partner = styled.div`
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  max-width: 50%;
`

export const Type = styled.div`
  font-size: 0.9em;
  color: ${COLOR.LIGHT_GREY};
  margin-bottom: 4px;
`

export const Name = styled.div`
  font-size: 1em;
`

export const SmallName = styled.div`
  font-size: 0.9em;
`
