import Title from "components/Title"
import MAPBOX_TOKEN from "config/MAPBOX_TOKEN"
import React, { Fragment, ReactElement, useState } from "react"
import MapGL from "react-map-gl"
import Select from "components/Form/Select"
import { apiGetKeepersByZipcodes } from "services/keepers"
import { Col, Row } from "reactstrap"
import PinGreen from "assets/mapbox/pin_green.png"
import PinOrange from "assets/mapbox/pin_orange.png"
import PinRed from "assets/mapbox/pin_red.png"
import PinYellow from "assets/mapbox/pin_yellow.png"
import PinGrey from "assets/mapbox/pin_grey.png"
import { LoadingComponent } from "components/LoadingComponent"
import { FilterChip, FiltersContainer } from "components/Chips/FilterChip"
import Markers from "components/Keeper/KeeperMarkers"
import KeepersTable from "components/Keeper/KeepersTable"
import FilterBarZipcodes from "components/FilterBarZipcodes/FilterBarZipcodes"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "types/reduxState.types"
import { MapPin } from "components/Maps/MapPin"
import { ZipcodesData } from "types/filter-bar-zipcodes.types"

function Keepers(): ReactElement {
  const dispatch = useDispatch()
  const { filteredList } = useSelector((state: ReduxState) => state.keepers)

  const [viewport, setViewport] = useState({
    latitude: 46,
    longitude: 2,
    zoom: 5,
    bearing: 0,
    pitch: 0,
  })
  const [filters, setFilters] = useState({
    zipcodes: [],
    statuses: [],
  })
  const [activeTab, setActiveTab] = useState(0)

  const _onViewportChange = viewport => {
    setViewport(viewport)
  }

  const _onClick = event => {
    const feature = event.features && event.features[0]

    if (feature) {
      // eslint-disable-next-line no-undef
      window.alert(`Clicked layer ${feature.layer.id}`) // eslint-disable-line no-alert
    }
  }

  const _getCursor = ({ isHovering }) => {
    return isHovering ? "pointer" : "default"
  }

  const search = (zipcodes?: ZipcodesData[]) => {
    dispatch(
      apiGetKeepersByZipcodes({
        zipcodes: zipcodes?.map(({ value }) => value) || filters.zipcodes.map(({ value }) => value),
      }),
    )
  }

  const changeFilter = (filterName, e) => {
    const data = e === null ? [] : e

    if (filterName === "zipcodes") {
      setFilters(prevState => ({ zipcodes: data, statuses: prevState.statuses }))
      dispatch(apiGetKeepersByZipcodes({ zipcodes: this.state.filters.zipcodes.map(({ value }) => value) }))
    }
    if (filterName === "statuses") {
      setFilters(prevState => ({ zipcodes: prevState.zipcodes, statuses: data }))
    }
  }

  const applyFilters = data => {
    return data
      .filter(item => item.address !== null)
      .filter(item => {
        let condition1 = true
        let condition2 = true

        if (filters.zipcodes.length > 0) {
          condition1 = filters.zipcodes.map(i => i.value).includes(item.address.zipcode)
        }
        if (filters.statuses.length > 0) {
          condition2 = filters.statuses.map(i => i.value).includes(item.status)
        }
        return condition1 && condition2
      })
  }

  const setKeepersZipcodes = (zipcodesInput: ZipcodesData[]) => {
    setFilters(prevState => ({
      ...prevState,
      zipcodes: zipcodesInput,
    }))

    if (zipcodesInput.length > 0) {
      search(zipcodesInput)
    }
  }

  const keepersStatuses = Array.from(new Set(filteredList.data.map(k => k.status))) as string[]
  const filteredData = applyFilters(filteredList.data)

  return (
    <div style={{ flex: 1, padding: 40 }}>
      <Title>👥 Keepers </Title>

      <div className="filter-bar">
        <Row>
          <Col xs={12}>
            <FilterBarZipcodes onChangeZipcodes={zipcodes => setKeepersZipcodes(zipcodes)} defaultZipcodes={[]} />
          </Col>
        </Row>
      </div>

      {filteredList.loading && <LoadingComponent />}

      {!filteredList.loading && filteredList.data.length > 0 && (
        <FiltersContainer>
          <FilterChip text="Tableau" active={activeTab === 0} onClick={() => setActiveTab(0)} />
          <FilterChip text="Carte" active={activeTab === 1} onClick={() => setActiveTab(1)} />
        </FiltersContainer>
      )}

      {activeTab === 0 && filteredList.data !== null && <KeepersTable keepers={filteredList.data} />}

      {activeTab === 1 && (
        <Fragment>
          <Row style={{ marginBottom: "1em" }}>
            <Col xs={4}>
              <Select
                defaultValue={[]}
                isMulti
                name="colors"
                options={keepersStatuses.map(i => ({ label: i, value: i }))}
                placeholder="Filtrer par statut..."
                onChange={e => changeFilter("statuses", e)}
              />
            </Col>
          </Row>
          <div className="map-top">
            <div className="map-total-elements">
              {filteredData.length} keepers ({filteredList.data.filter(i => i.address === null).length} sans adresse)
            </div>
            <div className="map-legend">
              <div className="map-legend-item">
                <MapPin src={PinGreen} /> Validé
              </div>
              <div className="map-legend-item">
                <MapPin src={PinOrange} /> En cours de validation
              </div>
              <div className="map-legend-item">
                <MapPin src={PinYellow} /> En attente
              </div>
              <div className="map-legend-item">
                <MapPin src={PinRed} /> Nouveau
              </div>
              <div className="map-legend-item">
                <MapPin src={PinGrey} /> <i>Autre</i>
              </div>
            </div>
          </div>

          <MapGL
            {...viewport}
            width="100%"
            height="100%"
            clickRadius={2}
            onClick={_onClick}
            getCursor={_getCursor}
            interactiveLayerIds={[]}
            onViewportChange={_onViewportChange}
            mapboxApiAccessToken={MAPBOX_TOKEN}
          >
            <Markers data={filteredData} />
          </MapGL>
        </Fragment>
      )}
    </div>
  )
}

export default Keepers
