import moment from "moment"
import React from "react"
import { Row, Col } from "reactstrap"

const LineItemRating = ({ element }) => {
  const keeper = element?.order?.__address__?.__keeper__

  return (
    <Row className="line-element-row">
      <Col xs={1}>
        <div className="line-element-info">
          <div className="content">{moment(element.createdAt).format("DD/MM/YY HH:mm")}</div>
        </div>
      </Col>
      <Col xs={1}>
        <div className="line-element-info">
          <div className="content">💳 {element.order?.orderNo}</div>
        </div>
      </Col>
      <Col xs={2}>
        <div className="line-element-info">
          <div className="content">{element.order?.recipient?.firstName}</div>
        </div>
      </Col>
      <Col xs={2}>
        <div className="line-element-info">
          <div className="content">
            👤 {keeper?.firstName} {keeper?.lastName}
          </div>
        </div>
      </Col>
      <Col xs={1}>
        <div className="line-element-info">
          <div className="content">
            <b>{element.rate}</b> <span style={{ fontSize: "0.8em" }}>/ 10</span>
          </div>
        </div>
      </Col>
      <Col xs={5}>
        <div className="line-element-info">
          <div className="content">{element.feedback}</div>
        </div>
      </Col>
    </Row>
  )
}

export default LineItemRating
