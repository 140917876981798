import { Parcel } from "./parcel.types"
import { WalletEntryType, WalletFeesType } from "./wallet-entry.types"
import { WalletPaymentStatus } from "./wallet-payment.types"
import { Recipient } from "./order.types"

// PACMAN TYPES

export interface WalletPacman {
  bankAccountId: string
  id: string
  keeperId: string
  bankAccount: BankAccount
  balance: number
  balanceDetails: WalletEntriesDetail[]
}

export interface WalletEntriesPacman {
  amount: number
  createdAt: string
  id: string
  parcels: Parcel[]
  paymentId: string | null
  status: WalletPaymentStatus
  type: WalletEntryType | WalletFeesType
  walletId: string
}

export interface WalletPaymentsPacman {
  amount: number
  createdAt: string
  id: string
  status: WalletPaymentStatus
  walletId: string
}

// MOBILE TYPES

export type BankAccount = {
  entitled: string
  bic: string
  iban: string
  id: string
  isStripeValidated: boolean
  updatedAt: Date
}

export type Wallet = {
  id: string
  balance: number
  bankAccount?: BankAccount
}

export interface GetMyWallet {
  balance: number
  balanceDetails: WalletEntriesDetail[]
  id: string
  payments: WalletEntries[]
}

export interface WalletEntries {
  amount: number
  createdAt: string
  details: WalletEntriesDetail[]
  status: WalletPaymentStatus
  id: string
}

export interface WalletEntriesDetail {
  nbParcels: number
  processing: boolean
  amount: number
  type: WalletEntryType | WalletFeesType | null
  date: string
  entity: WalletDetailEntity
  associationName: string | null
  parcels: Parcel[]
  recipient?: Recipient
}

export enum WalletDetailEntity {
  WALLET_ENTRY = "WALLET_ENTRY",
  WALLET_PAYMENT = "WALLET_PAYMENT",
  WALLET_DONATION = "WALLET_DONATION",
  WALLET_FEES = "WALLET_FEES",
  WALLET_GIVEAWAY = "WALLET_GIVEAWAY",
  WALLET_TIPS = "WALLET_TIPS",
}
