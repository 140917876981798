import React, { ReactElement } from "react"
import moment from "moment"
import Cell from "components/List/Cell"
import { ListRow } from "components/List/Row"
import styled from "styled-components"
import { SearchKeeperLog, ReducedAddress } from "types/search-keeper-log.types"
import { templateDistance } from "utils/distance.util"

export interface SearchKeeperLogLineItemProps {
  log: SearchKeeperLog
}

const CoordinatesWrapper = styled.div`
  font-style: italic;
`

const RadiusWrapper = styled.div`
  font-style: italic;
`

export default function SearchKeeperLogLineItem({ log }: SearchKeeperLogLineItemProps): ReactElement {
  const address: ReactElement = (
    <>
      {log.searchingAddress.formattedLine}
      <br />
      <CoordinatesWrapper>
        {log.searchingAddress.latitude} - {log.searchingAddress.longitude}
      </CoordinatesWrapper>
      {log.searchingAddress.radius && <RadiusWrapper>Rayon de recherche: {log.searchingAddress.radius}m</RadiusWrapper>}
    </>
  )

  return (
    <ListRow>
      <Cell label={"Appel du WS"} content={moment(log.wsCallDate).format("DD/MM/YYYY HH:mm")} width={2} />
      <Cell label={"Addresse"} content={address} width={2} />
      <Cell label={"Nombre de Keeper renvoyés"} content={log.nbKeepers} width={2} />
      <Cell label={"Distance la plus proche"} content={`${templateDistance(log.shortestDistance)}m`} width={2} />
    </ListRow>
  )
}
