import {
  API_SEND_EVENTS,
  API_GET_TRACKING,
  RESET_TRACKING,
  API_GET_SYSTEM_UPDATES,
  API_GET_INTEGRATION_UPDATES,
  API_GET_SYSTEM_STATS,
  API_GET_INTEGRATION_STATS,
  RESET_SYSTEM_UPDATES,
  RESET_INTEGRATION_UPDATES,
} from "actions/system"
import { IntegrationUpdate, SystemUpdate } from "types/system.types"
import { FctServiceReturn } from "./services.types"

export function apiSendEvents(_data) {
  return {
    type: API_SEND_EVENTS,
    payload: {
      request: {
        url: `/admin/parcels/events`,
        method: "POST",
        data: _data,
      },
    },
  }
}

export function apiGetTracking(_id, _type) {
  const url = _type === "PARCEL" ? `/admin/parcels/${_id}` : `/admin/orders/${_id}`

  return {
    type: API_GET_TRACKING,
    payload: {
      request: {
        url,
      },
    },
  }
}

export function resetTracking(): FctServiceReturn<null> {
  return {
    type: RESET_TRACKING,
  }
}

export function apiGetSystemUpdates(date: string, skip: number, take: number): FctServiceReturn<SystemUpdate[]> {
  return {
    type: API_GET_SYSTEM_UPDATES,
    payload: {
      request: {
        url: `/admin/logs/system/${date}/${skip}/${take}`,
        method: "GET",
      },
    },
  }
}

export function apiGetIntegrationUpdates(
  companyId: string,
  date: string,
  skip: number,
  take: number,
): FctServiceReturn<IntegrationUpdate[]> {
  return {
    type: API_GET_INTEGRATION_UPDATES,
    payload: {
      request: {
        url: `/admin/logs/integration/${companyId}/${date}/${skip}/${take}`,
        method: "GET",
      },
    },
  }
}

export function resetSystemUpdates(): FctServiceReturn<null> {
  return {
    type: RESET_SYSTEM_UPDATES,
  }
}

export function resetIntegrationUpdates(): FctServiceReturn<null> {
  return {
    type: RESET_INTEGRATION_UPDATES,
  }
}

export function apiGetSystemStats() {
  return {
    type: API_GET_SYSTEM_STATS,
    payload: {
      request: {
        url: `/admin/logs/system/stats`,
        method: "GET",
      },
    },
  }
}

export function apiGetIntegrationStats() {
  return {
    type: API_GET_INTEGRATION_STATS,
    payload: {
      request: {
        url: `/admin/logs/integration/stats`,
        method: "GET",
      },
    },
  }
}
