import React, { ReactElement, useRef } from "react"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { Button } from "semantic-ui-react"

import { PageWrapper } from "components/Layout/Structure"
import Title from "components/Title"
import SimpleTable from "components/Table/SimpleTable"
import { LoadingComponent } from "components/LoadingComponent"
import { HiddenFileInput } from "components/HiddenFileInput/HiddenFileInput"

import {
  ContentContainer,
  EventPopupsTableWrapper,
  Subtitle,
  EditMiniButton,
  ExportMiniButton,
  RemoveMiniButton,
  ActionsContainer,
  ImageComponent,
  ZipcodesContainer,
} from "components/EventPopups/EventPopups.styled"
import { useEventPopupsList } from "hooks/useEventPopupsList"

const dateComponent = (date: Date | string) => moment(date).format("DD/MM/YYYY HH:mm")

const ZipcodesComponent = (zipcodes: string[] = []) => {
  const zipcodesString = zipcodes?.join(", ")
  return (
    <ZipcodesContainer title={zipcodesString}>
      ({zipcodes.length}) {zipcodesString}
    </ZipcodesContainer>
  )
}

export function EventPopupsList(): ReactElement {
  const history = useHistory()

  const {
    handleFileChange,
    redirectToAddEventPopup,
    exportEventPopup,
    redirectToEditEventPopup,
    removeEventPopup,
    eventPopups,
    loading,
  } = useEventPopupsList(history)

  const inputFileRef = useRef<HTMLInputElement>(null)

  const importEventPopup = () => inputFileRef?.current.click()

  if (loading) return <LoadingComponent />

  return (
    <PageWrapper>
      <Title>📣 Event Popups</Title>
      <Subtitle>Paramétrez des Event Popup à afficher directement sur l’application mobile</Subtitle>
      <EventPopupsTableWrapper id="scrollableEventPopupsTableWrapper">
        {eventPopups && eventPopups.length !== -1 ? (
          <SimpleTable
            headers={[
              {
                name: "Image",
                size: 1,
                value: "image",
                component: img => (img ? <ImageComponent className="association-image" src={img} /> : "Aucune image"),
              },
              {
                name: "Titre",
                size: 2,
                value: "title",
              },
              {
                name: "Contenu",
                size: 3,
                value: "content",
                component: content => <ContentContainer>{content}</ContentContainer>,
              },
              {
                name: "Du",
                size: 1,
                value: "startDate",
                component: dateComponent,
              },
              {
                name: "Au",
                size: 1,
                value: "endDate",
                component: dateComponent,
              },
              {
                name: "Codes postaux",
                size: 2,
                value: "zipcodes",
                component: ZipcodesComponent,
              },
              {
                name: "Action",
                size: 2,
                value: "id",
                component: id => (
                  <ActionsContainer>
                    <ExportMiniButton onClick={() => exportEventPopup(id)}>Exporter</ExportMiniButton>
                    <EditMiniButton onClick={() => redirectToEditEventPopup(id)}>Modifier</EditMiniButton>
                    <RemoveMiniButton onClick={() => removeEventPopup(id)}>Supprimer</RemoveMiniButton>
                  </ActionsContainer>
                ),
              },
            ]}
            data={eventPopups}
            lineHeight="unset"
          />
        ) : (
          <div className="no-content">Aucune association</div>
        )}
      </EventPopupsTableWrapper>
      <ActionsContainer style={{ marginTop: 20 }}>
        <Button onClick={redirectToAddEventPopup}>Nouveau</Button>
        <Button primary onClick={importEventPopup}>
          Importer
        </Button>
        <HiddenFileInput handleFileChange={handleFileChange} ref={inputFileRef} accept=".json" />
      </ActionsContainer>
    </PageWrapper>
  )
}

export default EventPopupsList
