import React, { ReactElement } from "react"
import { Button, Input, Modal } from "semantic-ui-react"
import Select from "components/Form/Select"
import { AdminKeeperEventType, AdminKeeperAction } from "modules/Keeper/KEEPER_ACTIONS"

export interface AdminEventModalProps {
  opened: boolean
  type: AdminKeeperEventType
  reason: { label: string; value: string }
  details: string
  associationId?: string
}

interface AdminKeeperEventModalProps {
  adminEventModal: AdminEventModalProps
  submitAdminEvent: () => void
  cancelAdminEventModal: () => void
  actions: AdminKeeperAction[]
  changeReason: (reason: string) => void
  changeDetails: (details: string) => void
}

const AdminKeeperEventModal = ({
  adminEventModal,
  submitAdminEvent,
  cancelAdminEventModal,
  actions,
  changeReason,
  changeDetails,
}: AdminKeeperEventModalProps): ReactElement => {
  const action = actions.find(i => i.value === adminEventModal.type)

  const modalContent = () => {
    switch (adminEventModal.type) {
      case AdminKeeperEventType.CLEAR_AGENDA:
        return <div>Vous allez supprimer toutes les disponibilités de ce Keeper. En êtes vous sûr ?</div>
      case AdminKeeperEventType.REJECT_KEEPER:
        return <div>Vous allez désactiver ce Keeper. En êtes vous sûr ?</div>
      case AdminKeeperEventType.REACTIVATE_KEEPER:
        return <div>Vous allez réactiver ce Keeper. En êtes vous sûr.e ?</div>
      case AdminKeeperEventType.ASK_TRANSFER:
        return <div>Vous allez transférer la cagnotte de ce Keeper. En êtes vous sûr ?</div>
      case AdminKeeperEventType.ASK_DONATION:
        return <div>Vous allez transférer la cagnotte de ce Keeper à une associaton. En êtes vous sûr ?</div>
      case AdminKeeperEventType.ASK_GIVEAWAY:
        return (
          <div>
            Vous allez transférer la cagnotte de ce Keeper à Pickme et ainsi lui faire renoncer à sa cagnotte. En êtes
            vous sûr ?
          </div>
        )
    }
  }

  return (
    <Modal open={adminEventModal.opened}>
      <Modal.Header>En êtes vous sûr ?</Modal.Header>
      <Modal.Content>
        {action?.reasons.length > 0 && (
          <Select
            menuPosition="fixed"
            value={adminEventModal.reason}
            onChange={changeReason}
            options={action?.reasons}
            placeholder="Que s'est il passé ?"
          />
        )}

        {adminEventModal.reason?.value === "OTHER" && (
          <Input
            fluid
            placeholder="Préciser"
            style={{ marginTop: "1em" }}
            required
            onChange={e => changeDetails(e.target.value)}
            className="inputText"
          />
        )}

        {modalContent()}
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => cancelAdminEventModal()}>
          Annuler
        </Button>
        <Button content="Valider" labelPosition="right" icon="checkmark" onClick={() => submitAdminEvent()} positive />
      </Modal.Actions>
    </Modal>
  )
}

export default AdminKeeperEventModal
