import React, { ReactElement } from "react"
import {
  ParcelOrderInfosBloc,
  ParcelOrderInfosContainer,
  ParcelOrderInfosData,
  ParcelOrderInfosLabel,
} from "components/Parcels/ParcelOrderInfos/ParcelOrderInfos.styled"
import moment from "moment"
import { OrderStatus } from "types/order.types"

interface ParcelOrderInfosProps {
  orderNo: string
  orderStatus: OrderStatus
  orderType: string
  deliveryCode: number
  shipper: string | null
  maxHoldingDate?: Date
}

const ParcelMaxHoldingDate = ({ maxHoldingDate, orderStatus }: { maxHoldingDate?: Date; orderStatus: OrderStatus }) => {
  if (
    !maxHoldingDate ||
    [OrderStatus.DELIVERED_TO_RECIPIENT, OrderStatus.RETURN_COMPLETED, OrderStatus.CANCELLED].includes(orderStatus)
  )
    return

  const targetDate = moment.utc(maxHoldingDate).startOf("day")
  const today = moment.utc().startOf("day")
  const daysRemaining = targetDate.diff(today, "days")
  const formattedDate = targetDate.format("D MMMM")
  const result =
    daysRemaining >= 0
      ? `${daysRemaining}j restants (${formattedDate})`
      : `Dépassé de ${Math.abs(daysRemaining)}j (${formattedDate})`

  return (
    <ParcelOrderInfosBloc>
      <ParcelOrderInfosLabel>⌛ Delai d’instance</ParcelOrderInfosLabel>
      <ParcelOrderInfosData>{result}</ParcelOrderInfosData>
    </ParcelOrderInfosBloc>
  )
}

function ParcelOrderInfos({
  orderNo,
  orderStatus,
  orderType,
  deliveryCode,
  shipper,
  maxHoldingDate,
}: ParcelOrderInfosProps): ReactElement {
  return (
    <ParcelOrderInfosContainer>
      <ParcelOrderInfosBloc>
        <ParcelOrderInfosLabel>💳 N° Commande</ParcelOrderInfosLabel>
        <ParcelOrderInfosData>{orderNo}</ParcelOrderInfosData>
      </ParcelOrderInfosBloc>
      <ParcelOrderInfosBloc>
        <ParcelOrderInfosLabel>📱 Code desti</ParcelOrderInfosLabel>
        <ParcelOrderInfosData>{deliveryCode}</ParcelOrderInfosData>
      </ParcelOrderInfosBloc>
      <ParcelOrderInfosBloc>
        <ParcelOrderInfosLabel>🏷 Type</ParcelOrderInfosLabel>
        <ParcelOrderInfosData>{orderType}</ParcelOrderInfosData>
      </ParcelOrderInfosBloc>
      <ParcelOrderInfosBloc>
        <ParcelOrderInfosLabel>👕 Marchand</ParcelOrderInfosLabel>
        <ParcelOrderInfosData>{shipper}</ParcelOrderInfosData>
      </ParcelOrderInfosBloc>
      <ParcelMaxHoldingDate maxHoldingDate={maxHoldingDate} orderStatus={orderStatus} />
    </ParcelOrderInfosContainer>
  )
}

export default ParcelOrderInfos
