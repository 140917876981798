import styled from "styled-components"

export const TextStatusContainer = styled.div<{ statusColor: string }>(
  ({ statusColor }) => `
    ${statusColor ? "background:" + statusColor : ""};
    width: fit-content;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
)

export const TextStatusTitle = styled.div`
  font-size: 0.8em;
  font-weight: 600;
  padding: 2px 6px;
  color: #fff;
`
