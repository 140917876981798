import React, { ReactElement, useEffect, useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import { Button, Loader } from "semantic-ui-react"

import Title from "components/Title"
import { PageWrapper } from "components/Layout/Structure"
import KeeperToCheckLine from "components/KeeperChecking/KeeperToCheckLine"
import { ValidationButtonContainer } from "components/KeeperChecking/KeepersChecking.styled"
import { getKeepersToCheck } from "services/graphql/queries/keeper.queries"
import { saveCheckingResult, SaveCheckingResultInput } from "services/graphql/mutations/keeper.mutations"
import { GetKeeperToCheckQuery, KeeperCheckResult, KeeperInChecking } from "types/keeper.types"
import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeList } from "react-window"

export function KeepersChecking(): ReactElement {
  const [keepersToCheck, setKeepersToCheck] = useState<KeeperInChecking[]>([])

  const [getKeepersToCheckQuery, { loading }] = useLazyQuery<GetKeeperToCheckQuery>(getKeepersToCheck, {
    fetchPolicy: "network-only",
  })
  const [saveCheckingResultMutation] = useMutation<boolean, SaveCheckingResultInput>(saveCheckingResult)

  /**
   * On first load, we need to add a "checkResult" variable to all Keepers to check.
   */
  useEffect(() => {
    getKeepersToCheckQuery().then(values => {
      const keepersInit = values.data?.adminGetKeepersToCheck.map(k => ({
        ...k,
        checkResult: null,
      }))

      setKeepersToCheck(keepersInit)
    })
  }, [])

  /**
   * Update the state and set the check result to the Keeper.
   *
   * @param _id - ID of the Keeper
   * @param checkResult - Check result calculated by the KeeperCheckChips
   */
  const updateKeeperCheckResult = (_id: string, checkResult: KeeperCheckResult) => {
    const updatedArray = keepersToCheck.slice()
    const index = updatedArray.findIndex(({ id }) => id === _id)

    if (index > -1) {
      updatedArray[index].checkResult = checkResult

      setKeepersToCheck(updatedArray)
    }
  }

  /**
   * Validate the checking process and send the results to the API.
   */
  const validateKeepersChecking = async () => {
    const keepersChecked = keepersToCheck
      .filter(({ checkResult }) => checkResult !== null)
      .map(({ id, checkResult }) => ({ id, checkResult }))

    saveCheckingResultMutation({
      variables: { checkings: keepersChecked },
    }).then(() => {
      // Update UI and Keepers to check after
      getKeepersToCheckQuery().then(values => {
        const keepersInit = values.data?.adminGetKeepersToCheck.map(k => ({
          ...k,
          checkResult: null,
        }))

        setKeepersToCheck(keepersInit)
      })
    })
  }

  const getValidationButton = () => (
    <ValidationButtonContainer>
      <Button
        disabled={!keepersToCheck?.some(({ checkResult }) => checkResult !== null)}
        onClick={validateKeepersChecking}
      >
        Valider
      </Button>
    </ValidationButtonContainer>
  )

  const Row = ({
    data,
    index,
    style,
  }: {
    data: KeeperInChecking[]
    index: number
    style: React.CSSProperties
  }): ReactElement => {
    const keeper = data[index]
    return (
      <div style={style}>
        <KeeperToCheckLine key={keeper.id} keeper={keeper} updateKeeperCheckResult={updateKeeperCheckResult} />
      </div>
    )
  }

  return (
    <PageWrapper>
      <Title>📝 Keepers à valider</Title>

      {loading && <Loader active inline />}
      {!loading && keepersToCheck?.length === 0 && <p>Aucun Keeper à valider ✅</p>}
      {!loading && keepersToCheck?.length > 0 && (
        <>
          <p>{keepersToCheck?.length} Keepers demandent une validation</p>

          {!!keepersToCheck && (
            <AutoSizer>
              {({ height, width }: { width: number; height: number }) => (
                <FixedSizeList
                  height={height - 150}
                  width={width}
                  itemCount={keepersToCheck.length}
                  itemSize={100}
                  itemData={keepersToCheck}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          )}

          {getValidationButton()}
        </>
      )}
    </PageWrapper>
  )
}

export default KeepersChecking
