import { Order } from "types/order.types"
import { PickColor } from "components/SearchKeeperMonitoring/PinColorsCheckboxes"
import { DisplayableLog } from "components/SearchKeeperMonitoring/utils/createDisplayableLogs.util"
import { GeneratedOrder, SearchKeeperLog } from "types/search-keeper-log.types"

export const createDisplayableLogsReducer =
  (style: PickColor) =>
  (displayableLogs: DisplayableLog[], currentLog: SearchKeeperLog): DisplayableLog[] => {
    const index = displayableLogs.findIndex(displayableLog => {
      const condition =
        displayableLog.searchingAddress?.latitude &&
        displayableLog.searchingAddress.latitude === currentLog.searchingAddress.latitude &&
        displayableLog.searchingAddress?.longitude &&
        displayableLog.searchingAddress.longitude === currentLog.searchingAddress.longitude
      return condition
    })

    if (index === -1) {
      const log: DisplayableLog = {
        style,
        id: currentLog.id,
        searchingAddress: currentLog.searchingAddress,
        nbKeepers: currentLog.nbKeepers,
        shortestDistance: currentLog.shortestDistance,
        addressIds: currentLog.addressIds,
        numberOfAddress: 1,
      }
      displayableLogs.push(log)
    } else {
      const existingLog = displayableLogs[index]
      const changedLog: DisplayableLog = { ...existingLog, numberOfAddress: existingLog.numberOfAddress + 1 }
      displayableLogs[index] = changedLog
    }

    return displayableLogs
  }

export const displayableGreenPinsReducer = (
  generatedOrders: GeneratedOrder[],
  currentOrder: Order,
): GeneratedOrder[] => {
  const index = generatedOrders.findIndex(accOrder => {
    const condition =
      accOrder.address.latitude &&
      accOrder.address.latitude === currentOrder.address.latitude &&
      accOrder.address.longitude &&
      accOrder.address.longitude === currentOrder.address.longitude

    return condition
  })

  if (index === -1) {
    const generatedOrder: GeneratedOrder = {
      ...currentOrder,
      numberOfAddress: 1,
      orderNos: [currentOrder.orderNo],
      statuses: [currentOrder.status],
    }
    generatedOrders.push(generatedOrder)
  } else {
    const existingGeneratedOrder = generatedOrders[index]
    const changedGeneratedOrder = {
      ...existingGeneratedOrder,
      numberOfAddress: existingGeneratedOrder.numberOfAddress + 1,
      orderNos: [...existingGeneratedOrder.orderNos, currentOrder.orderNo],
      statuses: [...existingGeneratedOrder.statuses, currentOrder.status],
    }
    generatedOrders[index] = changedGeneratedOrder
  }

  return generatedOrders
}
