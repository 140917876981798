import { toFailure, toSuccess } from "actions"
import { API_CREATE_FAKE_ORDERS, API_CREATE_ORDER } from "actions/orders"
import { createReducer } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

const initialState = {
  list: {
    loading: false,
    error: false,
    data: [],
  },
  focus: {
    loading: false,
    error: false,
    data: {},
  },
}

const orders = createReducer(initialState, {
  [API_CREATE_FAKE_ORDERS]: state => {
    state.list.loading = true
  },
  [toSuccess(API_CREATE_FAKE_ORDERS)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data = state.list.data.concat(action.payload.data)

    toast.success("Orders created")
  },
  [toFailure(API_CREATE_FAKE_ORDERS)]: state => {
    state.list.loading = false
    state.list.error = true
  },

  [API_CREATE_ORDER]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_CREATE_ORDER)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data

    toast.success("La commande à été crée")
  },
  [toFailure(API_CREATE_ORDER)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },
})

export default orders
