import { Form } from "formik"
import styled from "styled-components"

import { COLOR } from "utils/color"

export const LoginBoxForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const LoginBoxTop = styled.div`
  padding: 40px 30px 30px 30px;
  background-color: ${COLOR.WHITE};
  border-radius: 20px;
  box-shadow: 1px 2px 3px rgba(196, 196, 196, 0.2);
`
