import styled from "styled-components"
import { COLOR } from "utils/color"
import { LoadingComponent } from "../../LoadingComponent"

export const InProgressText = styled.p`
  font-size: 12px;
  font-style: italic;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`

export const ButtonStyled = styled.button<{ isDownload?: string }>`
  color: ${COLOR.WHITE};
  font-weight: 600;
  font-size: 13px;
  background-color: ${({ isDownload }: { isDownload?: string }) => (isDownload ? COLOR.LIGHT_GREY : COLOR.BRIGHT_BLUE)};
  border-radius: 5px;
  max-width: 393px;
  width: 100%;
  padding: 8px 30px;
  margin-top: 16px;
  border: 0;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${COLOR.BLUE};
    background-color: ${({ isDownload }: { isDownload?: string }) => (isDownload ? COLOR.GREY : COLOR.BLUE)};
  }
`

export const ItalicText = styled.span`
  font-style: italic;
`

export const Wrapper = styled.div`
  position: relative;
`

export const LoadingComponentStyled = styled(LoadingComponent)`
  margin-top: 30px;
`
