import styled from "styled-components"

import { COLOR } from "utils/color"

export const Container = styled.div`
  width: 200px;
`

export const Title = styled.div`
  font-family: Poppins-Semibold;
`

export const Subtitle = styled.div`
  font-style: italic;
  font-size: 0.7em;
  color: ${COLOR.MEDIUM_GREY};
  margin-bottom: 10px;
`

export const GroupTitle = styled.div`
  color: ${COLOR.MEDIUM_GREY};
  font-size: 0.7em;
`

export const Data = styled.div`
  font-size: 0.9em;
  margin-bottom: 10px;
`
