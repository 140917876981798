import React, { ReactElement } from "react"
import { Col, Row } from "reactstrap"
import { DetailText, HeaderLineContainer } from "./PerformedTransfersHistory.styled"
import { SemiBoldText } from "../WireTransfers.styled"

export const DetailLineHeader = (): ReactElement => {
  return (
    <HeaderLineContainer>
      <Row>
        <Col xs={3} sm={3} md={2}>
          <DetailText>
            <SemiBoldText>Keeper</SemiBoldText>
          </DetailText>
        </Col>
        <Col xs={3} sm={2} md={2} lg={1}>
          <DetailText>
            <SemiBoldText>Montant</SemiBoldText>
          </DetailText>
        </Col>
        <Col xs={4} sm={4} lg={3}>
          <SemiBoldText>iban</SemiBoldText>
        </Col>
        <Col xs={3} sm={3} lg={2}>
          <SemiBoldText>bic</SemiBoldText>
        </Col>
        <Col sm={3} />
      </Row>
    </HeaderLineContainer>
  )
}
