export enum AdminUserRole {
  MARKETING = "MARKETING",
  TECH = "TECH",
  ADMIN = "ADMIN",
  HEAD_OF_CARE = "HEAD_OF_CARE",
  CARE_MANAGER = "CARE_MANAGER",
  CARE_SENIOR = "CARE_SENIOR",
  CARE_JUNIOR = "CARE_JUNIOR",
  CARE_EXTERNAL = "CARE_EXTERNAL",
}
