import React, { ReactElement } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import Form from "components/Login/LoginForm"
import { authAdmin } from "services/auth"
import { ReduxState } from "types/reduxState.types"
import {
  LoginBackground,
  LoginBox,
  LoginContainer,
  LoginCopyright,
  LoginLogo,
  LogoContainer,
} from "components/Login/Login.styled"
import Logo from "assets/logo-horizontal.png"

export default function Login(): ReactElement {
  const dispatch = useDispatch()
  const { loading, isLogged, error } = useSelector((state: ReduxState) => state.auth)

  const login = values => dispatch(authAdmin(values))

  if (isLogged) {
    return <Redirect to="/" />
  }

  return (
    <LoginBackground>
      <LoginContainer>
        <LogoContainer>
          <LoginLogo src={Logo} />
        </LogoContainer>

        <LoginBox>
          <Form submitAuth={login} authError={error} loading={loading} />
        </LoginBox>
        <LoginCopyright>© 2022 Pickme Tous droits réservés</LoginCopyright>
      </LoginContainer>
    </LoginBackground>
  )
}
