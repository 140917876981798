import LineElementContainer from "components/Table/LineElementContainer"
import React, { ReactElement } from "react"

import { ReassignmentLog } from "types/reassignment-log.types"
import CollapsibleContainer from "components/Table/CollapsibleContainer"
import LineItemReassign from "components/Parcels/Reassignment/LineItemReassign"

const CollapsibleReassignmentLog = ({ element }: { element: ReassignmentLog }): ReactElement => {
  const data = element.data

  return (
    <CollapsibleContainer>
      {data.map(d => (
        <LineElementContainer key={d.address.id} lineHeight={"md"}>
          <LineItemReassign address={d.address} />
        </LineElementContainer>
      ))}
    </CollapsibleContainer>
  )
}

export default CollapsibleReassignmentLog
