import React, { ReactElement } from "react"
import { Col } from "reactstrap"
import styled from "styled-components"

import { COLOR } from "utils/color"

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Label = styled.div`
  font-size: 0.8em;
  color: ${COLOR.LIGHT_GREY};
`
const Content = styled.div`
  font-size: 0.8em;
`

interface CellProps {
  label: string
  content: string | number | ReactElement
  width: number
}

export default function Cell({ label, content, width = 2 }: CellProps): ReactElement {
  return (
    <Col xs={width}>
      <ContentWrapper>
        <Label>{label}</Label>
        <Content>{content}</Content>
      </ContentWrapper>
    </Col>
  )
}
