import React from "react"
import { Link } from "react-router-dom"
import KeeperStatus from "components/Keeper/KeeperStatus"

export default function KeeperPopup({ keeper, address }) {
  return (
    <div className="keeper-popup">
      <b>
        {keeper.firstName} {keeper.lastName}
      </b>
      <br />
      <KeeperStatus status={keeper.status} />
      <br />
      {address.streetLine}, {address.zipcode} {address.city}
      <br />
      <div className="popup-link">
        <Link to={`/keepers/${keeper.id}`}>📋 Fiche keeper</Link>
      </div>
    </div>
  )
}
