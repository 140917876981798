import styled from "styled-components"
import { LoaderPosition } from "types/search.types"

export const Container = styled.div<{ marginBottom?: boolean }>(
  ({ marginBottom }) => `
  ${marginBottom && "margin-bottom: 2em;"}
`,
)

export const CustomLoader = styled.div<{ loaderPosition?: LoaderPosition }>`
  text-align: ${({ loaderPosition }) => (loaderPosition ? loaderPosition : LoaderPosition.START)};
  padding: 5px;
`

export const CustomMenuSection = styled.div``
