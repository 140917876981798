import React, { ReactElement } from "react"
import { useSelector, useDispatch } from "react-redux"

import SquareLogoMondialRelay from "assets/carriers/squareLogos/mondialrelay.png"
import SquareLogoRelaiColis from "assets/carriers/squareLogos/rc.png"
import SquareLogoPickup from "assets/carriers/squareLogos/pickup.png"
import SquareLogoLaPoste from "assets/carriers/squareLogos/laposte.png"
import {
  CompetitorSelectionButtonLabel,
  CompetitorSelectionButtonImage,
  CompetitorSelectionButtonIcon,
} from "components/Competitors/CompetitorsCheckboxes.styled"
import { apiGetCompetitorPickupPoints, removeCompetitorPickupPoints } from "services/competitors"
import { ReduxState } from "types/reduxState.types"
import { CompetitorsName } from "types/competitor.types"

function CompetitorsCheckboxes(): ReactElement {
  const competitors = useSelector((state: ReduxState) => state.competitors)
  const dispatch = useDispatch()

  const manageCompetitors = (selectedCompetitorId: string): void => {
    if (!competitors.pickupPoints.data[selectedCompetitorId]) {
      dispatch(apiGetCompetitorPickupPoints(selectedCompetitorId))
    }
    if (competitors.pickupPoints.data[selectedCompetitorId]) {
      dispatch(removeCompetitorPickupPoints(selectedCompetitorId))
    }
  }

  const CompetitorSelectionButton = ({ name, id, src, active }) => {
    return (
      <CompetitorSelectionButtonLabel onClick={() => manageCompetitors(id)}>
        <CompetitorSelectionButtonImage src={src} size="mini" />
        {name}
        <CompetitorSelectionButtonIcon name={active ? "eye" : "eye slash"} />
      </CompetitorSelectionButtonLabel>
    )
  }

  return (
    <>
      {competitors.list.data.map(
        competitor =>
          ({
            [CompetitorsName.MONDIAL_RELAY]: (
              <CompetitorSelectionButton
                key={`competitor-selection-${competitor.id}`}
                name={CompetitorsName.MONDIAL_RELAY}
                id={competitor.id}
                src={SquareLogoMondialRelay}
                active={competitors.pickupPoints.data[competitor.id]}
              />
            ),
            [CompetitorsName.RELAIS_COLIS]: (
              <CompetitorSelectionButton
                key={`competitor-selection-${competitor.id}`}
                name={CompetitorsName.RELAIS_COLIS}
                id={competitor.id}
                src={SquareLogoRelaiColis}
                active={competitors.pickupPoints.data[competitor.id]}
              />
            ),
            [CompetitorsName.PICKUP]: (
              <CompetitorSelectionButton
                key={`competitor-selection-${competitor.id}`}
                name={CompetitorsName.PICKUP}
                id={competitor.id}
                src={SquareLogoPickup}
                active={competitors.pickupPoints.data[competitor.id]}
              />
            ),
            [CompetitorsName.LA_POSTE]: (
              <CompetitorSelectionButton
                key={`competitor-selection-${competitor.id}`}
                name={CompetitorsName.LA_POSTE}
                id={competitor.id}
                src={SquareLogoLaPoste}
                active={competitors.pickupPoints.data[competitor.id]}
              />
            ),
          }[competitor.name]),
      )}
    </>
  )
}

export default CompetitorsCheckboxes
