import { toFailure, toSuccess } from "actions"
import { createReducer } from "@reduxjs/toolkit"
import { API_LIST_APP_VERSION } from "actions/app-version"

const initialState = {
  list: {
    loading: false,
    error: false,
    data: [],
  },
}

const appVersions = createReducer(initialState, {
  [API_LIST_APP_VERSION]: state => {
    state.list.loading = true
  },
  [toSuccess(API_LIST_APP_VERSION)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data = action.payload.data
  },
  [toFailure(API_LIST_APP_VERSION)]: state => {
    state.list.loading = false
    state.list.error = true
  },
})

export default appVersions
