import React from "react"
import {
  FieldsRow,
  Section,
  SectionTitle,
  StyledInput,
  StyledLabelInput,
  StyledTextArea,
} from "./RequestReturnModal.styled"
import { Col } from "reactstrap"
import { ReturnRequestFieldKeys, ReturnRequestFormData } from "types/return-request.types"

interface DestinationFieldsProps {
  returnRequestFormData: ReturnRequestFormData
  changeData: (key: ReturnRequestFieldKeys, value) => void
}

export const DestinationFields = ({ returnRequestFormData, changeData }: DestinationFieldsProps) => {
  return (
    <Section>
      <SectionTitle>📍 Destination</SectionTitle>
      <FieldsRow>
        <Col xs={3}>
          <div>
            <StyledLabelInput>Nom</StyledLabelInput>
            <StyledInput
              fluid
              required
              value={returnRequestFormData.deliveryAddressName}
              onChange={e => changeData(ReturnRequestFieldKeys.deliveryAddressName, e.target.value)}
            />
          </div>
          <div>
            <StyledLabelInput>Adresse</StyledLabelInput>
            <StyledInput
              fluid
              required
              value={returnRequestFormData.deliveryAddress}
              onChange={e => changeData(ReturnRequestFieldKeys.deliveryAddress, e.target.value)}
            />
          </div>
          <div>
            <StyledLabelInput>Telephone</StyledLabelInput>
            <StyledInput
              fluid
              required
              value={returnRequestFormData.deliveryPhone}
              onChange={e => changeData(ReturnRequestFieldKeys.deliveryPhone, e.target.value)}
            />
          </div>
        </Col>
        <Col xs={9}>
          <StyledLabelInput>Information supplemantaire</StyledLabelInput>
          <StyledTextArea
            rows={5}
            fluid
            required
            value={returnRequestFormData.deliveryInstructions}
            onChange={e => changeData(ReturnRequestFieldKeys.deliveryInstructions, e.target.value)}
          />
        </Col>
      </FieldsRow>
    </Section>
  )
}
