import { AdminParcelEventReason } from "types/parcel.types"
import { Company } from "types/company.types"
import { BtocReturnRequestForm, ReturnRequestFormData } from "types/return-request.types"
import { getTranslatedCarrierName } from "utils/getTranslatedCarrierName"
import { hoursOptions } from "utils/formik/hoursOptions"
import moment from "moment"
import { extractHour } from "./helpers"

export const dimensionOptions = [
  { value: "S", label: "Petit : Rentre dans un sac à dos" },
  { value: "M", label: "Moyen : Rentre dans un coffre de voiture" },
  { value: "L", label: "Grand : Rentre dans une voiture sièges baissés" },
]

export const reasonOptions = [
  {
    value: AdminParcelEventReason.ALREADY_COLLECTED_BY_CARRIER,
    label: "Déjà collecté par le transporteur",
    data: {
      isActive: false,
    },
  },
  {
    value: AdminParcelEventReason.REFUSED_BY_RECIPIENT,
    label: "Colis refusé par le destinataire",
    data: {
      isActive: true,
    },
  },
  {
    value: AdminParcelEventReason.RECIPIENT_ABSENT,
    label: "Destinataire injoignable",
    data: {
      isActive: true,
    },
  },
  {
    value: AdminParcelEventReason.OTHER,
    label: "Autre",
    data: {
      isActive: true,
    },
  },
]

/**
 * Generates an array of options for selecting a returning carrier.
 * @returns Array of objects for returning carrier selection in the format:
 *  { label: returningCarrier.name, value: returningCarrier.id, data: Company }[]
 */
export const getReturningCarrierNameList = (collectionCarriers: Company[], excludedCarrierNames: string[] = []) => {
  if (!collectionCarriers?.length) return []

  return collectionCarriers
    .filter(carrier => !excludedCarrierNames.includes(carrier.name))
    .map(carrier => ({
      label: getTranslatedCarrierName(carrier.name),
      value: carrier.id,
      data: carrier,
    }))
}

interface PreparePrefilledDataForRRF {
  btocReturnRequestForm: BtocReturnRequestForm
  collectionCarriers: Company[]
}

/** Prepares data pre-filled according to RRF */
export const preparePrefilledDataForRRF = ({
  btocReturnRequestForm,
  collectionCarriers,
}: PreparePrefilledDataForRRF) => {
  let prefilledData = {} as ReturnRequestFormData

  const returningCarrierNameList = getReturningCarrierNameList(collectionCarriers)

  const returningCarrierFromBtoCForm = btocReturnRequestForm?.returningCarrier
  const returningCarrierOption = returningCarrierNameList.find(
    carrierOption =>
      carrierOption?.value === (returningCarrierFromBtoCForm?.id || btocReturnRequestForm.returningCarrierId),
  )
  prefilledData.returningCarrier = returningCarrierOption ?? null

  const { companyAddress, pickupDate, referenceCode, cotransportationCode, reason, dimensions } =
    btocReturnRequestForm || {}

  prefilledData = {
    ...prefilledData,
    deliveryAddressName: companyAddress?.deliveryAddressName ?? "",
    deliveryAddress: companyAddress?.deliveryAddress ?? "",
    deliveryPhone: companyAddress?.deliveryPhone ?? "",
    deliveryInstructions: companyAddress?.deliveryInstructions ?? "",
    deliveryAddressZipcode: companyAddress?.deliveryAddressZipcode ?? "",
    deliveryAddressCity: companyAddress?.deliveryAddressCity ?? "",
    deliveryAddressLatitude: companyAddress?.latitude ?? "",
    deliveryAddressLongitude: companyAddress?.longitude ?? "",
    cotransportationCode: cotransportationCode ?? "",
    referenceCode: referenceCode ?? "",
    pickupDate: pickupDate ? moment(pickupDate).format("YYYY-MM-DD") : "",
    returnReason: reasonOptions.find(reasonOption => reasonOption.value === reason) ?? null,
    timeIntervalStart:
      hoursOptions.find(
        hourOption =>
          extractHour(hourOption.value) ===
          extractHour(
            btocReturnRequestForm?.interval?.start
              ? moment(btocReturnRequestForm.interval.start).local().format("HH:mm")
              : null ||
                  btocReturnRequestForm.collectOptionsStartHour ||
                  returningCarrierFromBtoCForm?.collectionOptions?.minimumHour,
          ),
      ) ?? null,
    timeIntervalEnd:
      hoursOptions.find(
        hourOption =>
          extractHour(hourOption.value) ===
          extractHour(
            btocReturnRequestForm?.interval?.end
              ? moment(btocReturnRequestForm?.interval?.end)
                  .local()
                  .format("HH:mm")
              : null ||
                  btocReturnRequestForm.collectOptionsEndHour ||
                  returningCarrierFromBtoCForm?.collectionOptions?.maximumHour,
          ),
      ) ?? null,
    parcelDimensions: dimensionOptions.find(dimensionOption => dimensionOption.value === dimensions) ?? null,
  }

  return prefilledData
}
