import { API_GET_LEADS, RESET_LEADS } from "actions/leads"
import { LeadType, Lead } from "types/leads.types"

type FctServiceReturn = {
  type: string
  payload: {
    request: {
      method?: string
      url: string
      data?: Lead[]
    }
    filter: LeadType | null
  }
}

export function apiGetLeads({
  take,
  skip,
  filter,
}: {
  take: number
  skip: number
  filter: LeadType | null
}): FctServiceReturn {
  return {
    type: API_GET_LEADS,
    payload: {
      request: {
        url: `/admin/leads?take=${take}&skip=${skip}${filter === null ? "" : `&filter=${filter}`}`,
      },
      filter,
    },
  }
}

export function resetLeads(): { type: string } {
  return {
    type: RESET_LEADS,
  }
}
