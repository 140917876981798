import moment from "moment"
import React, { ReactElement, useCallback } from "react"
import { Col, Row } from "reactstrap"
import { EventEntity } from "types/event.types"
import { KeeperEventReason } from "types/keeper-event.types"

interface EventsListProps {
  events: EventEntity[]
}

export default function EventsList({ events }: EventsListProps): ReactElement {
  const renderListItem = useCallback((event: EventEntity) => {
    let eventReasonLabel = ""

    if (event?.eventReason === KeeperEventReason.REJECTED_FOR_CARRIER && event.data?.eventDetails?.carrierName) {
      eventReasonLabel = `_${event.data?.eventDetails?.carrierName.toUpperCase()}`
    }

    return (
      <Row key={event.id} className="systemLog-row">
        <Col xs={2}>{moment(event.eventDate).format("DD/MM/YYYY HH:mm")}</Col>
        <Col xs={4}>
          [{event.entity?.toUpperCase()}] {event.title}
        </Col>
        <Col xs={2}>{event.eventCode}</Col>
        <Col xs={2}>{`${event.eventReason}${eventReasonLabel}`}</Col>
        <Col xs={2}>
          <i>{event.description}</i>
        </Col>
      </Row>
    )
  }, [])

  return (
    <div className="history-events">
      {events
        .slice()
        .sort((a, b) => moment(b.eventDate).diff(moment(a.eventDate)))
        .map(event => renderListItem(event))}
    </div>
  )
}
