import { Dispatch, SetStateAction } from "react"
import { Keeper, KeeperAvailabilitiesNextDays, KeeperScoring, KeeperAdminStats } from "types/keeper.types"
import { MeetingSlot } from "types/meeting.types"
import { Order } from "types/order.types"
import { Connection, Edge } from "types/pagination.types"
import { Shipment } from "types/shipment.types"
import { SystemType } from "types/system.types"
import { WalletEntry } from "types/wallet-entry.types"
import { CompanyBillingEntry } from "types/company-billing.types"
import { ParcelEventCode, ParcelEventReason } from "./parcel-event.types"
import { BtocReturnRequestForm } from "./return-request.types"

export enum ParcelStatus {
  /** CTOB & BTOC */
  CREATED = "CREATED",
  /** ***** */
  IN_PREPARATION = "IN_PREPARATION",
  READY_TO_SHIP = "READY_TO_SHIP",
  /** BTOC STATUSES */
  IN_TRANSIT = "IN_TRANSIT",
  IN_DELIVERY = "IN_DELIVERY",
  KEEPER_DELIVERY_FAILURE = "KEEPER_DELIVERY_FAILURE",
  DELIVERED_TO_KEEPER = "DELIVERED_TO_KEEPER",
  RECIPIENT_DELIVERY_FAILURE = "RECIPIENT_DELIVERY_FAILURE",
  DELIVERED_TO_RECIPIENT = "DELIVERED_TO_RECIPIENT",
  RETURN_TO_PLAN = "RETURN_TO_PLAN",
  RETURN_REQUESTED = "RETURN_REQUESTED",
  RETURNING = "RETURNING",
  RETURN_READY_TO_PICKUP = "RETURN_READY_TO_PICKUP",
  RETURN_COMPLETED = "RETURN_COMPLETED",
  /** CTOB STATUSES */
  WAITING_FOR_DROPOFF = "WAITING_FOR_DROPOFF",
  DROPPED_TO_KEEPER = "DROPPED_TO_KEEPER",
  DROPOFF_DELIVERY_FAILED = "DROPOFF_DELIVERY_FAILED",
  DROPOFF_DELIVERED = "DROPOFF_DELIVERED",
  /** OTHERS */
  LOST = "LOST",
  CANCELLED = "CANCELLED",
}

export enum ParcelFlag {
  ID_CARD_DELIVERY_TO_CHECK = "ID_CARD_DELIVERY_TO_CHECK",
  REASSIGNMENT_NEEDED = "REASSIGNMENT_NEEDED",
  RECIPIENT_REFUSED = "RECIPIENT_REFUSED",
  RETURN_TO_ORGANIZE = "RETURN_TO_ORGANIZE",
  RETURN_TO_ORDER = "RETURN_TO_ORDER",
  KEEPER_NOT_AVAILABLE_FOR_PICKUP = "KEEPER_NOT_AVAILABLE_FOR_PICKUP",
  KEEPER_DROPOFF_FAILED = "KEEPER_DROPOFF_FAILED",
  KEEPER_COLLECT_FAILED = "KEEPER_COLLECT_FAILED",
  CARRIER_LONG_RETURN = "CARRIER_LONG_RETURN",
  DECLARED_REFUSED_BY_KEEPER = "DECLARED_REFUSED_BY_KEEPER",
  SLEEPING = "SLEEPING",
  NO_RECIPIENT_CONTACT_DETAILS = "NO_RECIPIENT_CONTACT_DETAILS",
  // removed from parcel filter
  INFINITE_DELIVERY = "INFINITE_DELIVERY",
  RECIPIENT_DELIVERY_TO_CONFIRM = "RECIPIENT_DELIVERY_TO_CONFIRM",
  UNSCANNED = "UNSCANNED",
}

export enum AdminParcelEventReason {
  KEEPER_DID_NOT_SCAN = "KEEPER_DID_NOT_SCAN",
  DELIVERED_TO_PICKUP_POINT = "DELIVERED_TO_PICKUP_POINT",
  DELIVERED_TO_RECIPIENT = "DELIVERED_TO_RECIPIENT",
  PARCEL_TRACKING_LOST = "PARCEL_TRACKING_LOST",

  MANUAL_CREATION_OTHER = "MANUAL_CREATION_OTHER",
  RETURN_TO_SENDER = "RETURN_TO_SENDER",
  RETURN_TO_SHOP = "RETURN_TO_SHOP",
  NO_ANNOUNCEMENT = "NO_ANNOUNCEMENT",
  RETURNED_TO_BP = "RETURNED_TO_BP",

  // Update Event (IN_TRANSIT)
  KEEPER_ABSENT = "KEEPER_ABSENT",
  MISSING_ADDRESS_DATA = "MISSING_ADDRESS_DATA",
  REFUSED_BY_KEEPER = "REFUSED_BY_KEEPER",

  // Reasons for parcel return (RETURN_REQUESTED)
  REFUSED_BY_RECIPIENT = "REFUSED_BY_RECIPIENT",
  RECIPIENT_ABSENT = "RECIPIENT_ABSENT",
  CARRIER_DID_NOT_SHOW_UP = "CARRIER_DID_NOT_SHOW_UP",
  KEEPER_MISSED_APPOINTMENT = "KEEPER_MISSED_APPOINTMENT",
  CARRIER_CHANGED_AVAILABILITY = "CARRIER_CHANGED_AVAILABILITY",
  KEEPER_ASKED_RESCHEDULE = "KEEPER_ASKED_RESCHEDULE",

  // REASSIGNMENT
  DELIVERY_OUT_OF_KEEPER_AVAILABILITIES = "DELIVERY_OUT_OF_KEEPER_AVAILABILITIES",
  KEEPER_CHANGED_AVAILABILITIES = "KEEPER_CHANGED_AVAILABILITIES",
  DELIVERED_TO_PREVIOUSLY_ASSIGNED_KEEPER = "DELIVERED_TO_PREVIOUSLY_ASSIGNED_KEEPER",

  //
  CANCELLED_ON_CARRIERS_SIDE = "CANCELLED_ON_CARRIERS_SIDE",
  ALREADY_COLLECTED_BY_CARRIER = "ALREADY_COLLECTED_BY_CARRIER",
  COLLECTED_BY_CLIENT = "COLLECTED_BY_CLIENT",
  COLLECTED_BY_CARRIER = "COLLECTED_BY_CARRIER",
  NO_AVAILABILITY_ON_GEMA = "NO_AVAILABILITY_ON_GEMA",
  AUTO_CLOSE_NO_CLIENT_COMPLETION = "AUTO_CLOSE_NO_CLIENT_COMPLETION",
  OTHER = "OTHER",
}

export interface HazardParcels {
  RECIPIENT_DELIVERY_TO_CONFIRM: Parcel[]
  UNSCANNED: Parcel[]
  SLEEPING: Parcel[]
  RECIPIENT_REFUSED: Parcel[]
  INFINITE_DELIVERY: Parcel[]
  REASSIGNMENT_NEEDED: Parcel[]
  KEEPER_DROPOFF_FAILED: Parcel[]
  KEEPER_COLLECT_FAILED: Parcel[]
  CARRIER_LONG_RETURN: Parcel[]
  KEEPER_NOT_AVAILABLE_FOR_PICKUP: Parcel[]
}

export type ParcelNote = {
  id: string
  text: string
  zendeskLink: string
  updatedAt?: string
}

export type ParcelReassign = {
  cursor: string
  node: {
    address: KeeperAvailabilitiesNextDays["address"]
  }
}

export interface DynamicParcelsListProps {
  parcels: Parcel[]
  activeStatus: string
  loading: boolean
  nbParcelsResults: number
  moreToLoad: boolean
  nextCursor: string
  style?: any
  parcelsStats: {
    [ParcelStatus.IN_TRANSIT]: number
    [ParcelStatus.IN_DELIVERY]: number
    [ParcelStatus.DELIVERED_TO_KEEPER]: number
    [ParcelStatus.DELIVERED_TO_RECIPIENT]: number
  }
  apiGetParcels: (nbResults: number, offset?: string, parcelStatus?: string) => void
  resetParcels: () => void
  provider: SystemType.KEEPER | SystemType.PARCEL
}

export interface DynamicParcelsListGQLProps {
  parcels: any
  activeStatus: string
  totalCount: number
  nbParcelsResults: number
  setPreviousParcelResultsCount: Dispatch<SetStateAction<number>>
  loading: boolean
  moreToLoad: boolean
  nextCursor: string
  style?: any
  fetchPreviousAddressesParcels: (nbResults?: number, parcelStatus?: string) => void
  provider: SystemType.KEEPER | SystemType.PARCEL
}

export interface Parcel {
  id: string
  status: ParcelStatus
  // retailerStatus?: ParcelRetailerStatus
  parcelNo: string
  // labels?: LabelEntity[]
  order: Order
  // shipments: Shipment[]
  // walletEntries?: WalletEntry[]
  // companyBillingEntries?: CompanyBillingEntry[]
  createdAt: Date
  updatedAt: Date
  trackingPublicUrl: string
  firstKeeperReception?: boolean
  outOfAvailabilities?: boolean
  flag: ParcelFlag | null

  notes: ParcelNote[]
  __notes__?: ParcelNote[]

  __shipments__: Shipment[]
  companyBillingEntries: CompanyBillingEntry[]
  walletEntries: WalletEntry[]
  btocReturnRequestForms: BtocReturnRequestForm[]

  retailerStatus: string
  shipments?: Shipment[]
  archived: boolean
  maxHoldingDate?: Date
  /* Is the keeper for this parcel a new keeper ? */
  // public firstKeeperReception: boolean
  // public outOfAvailabilities: boolean
  // public events: EventEntity[]
  recipientIdCardUrl?: string
  carrierName?: string
}

export class AskReturnParcelInput {
  public parcelId: string
  public returningCarrierId: string
  public reason?: AdminParcelEventReason | null
  public details?: string | null
  public meetingSlot: MeetingSlot
}

export interface ParcelsListProps {
  parcels: Parcel[]
  loading?: boolean
  total?: number | null
  withNb?: boolean
  withFilters?: boolean
  style?: any
  sorting?: "ASC" | "DESC" | null
  getNextParcels?: (parcelStatus: string) => void
  moreToLoad?: boolean
  withNbPaginated?: {}
  filter?: string
}

export interface ParcelInfos {
  focus: {
    infos: {
      loading: boolean
      error: string
      data: Parcel
    }
  }
  reassign: {
    loading: boolean
    error: boolean
    data: ParcelReassign[]
    pageInfos: {
      hasNextPage: boolean
      endCursor: string
    }
  }
}

export interface ParcelKeeper {
  keeper: Keeper
  keeperStats: KeeperAdminStats
  keeperScoring: KeeperScoring
}

export interface GetParcelKeeperQuery {
  adminParcelKeeper: ParcelKeeper
}

export interface GetParcelReturnRequestQuery {
  getReturnRequestDetails: {
    id: string
    returnBtocRequest: {
      dimensions: string
      id: string
      pickupDate: string
      parcelId: string
      interval: {
        start: string
        end: string
      }
      reason: string
      weight: number
    }
    order: Order
    shipments: Shipment[]
  }
}

export type ParcelReturnBtocRequest = keyof GetParcelReturnRequestQuery["getReturnRequestDetails"]["returnBtocRequest"]

export interface KeeperOldAddressesParcelsInputs {
  payload: {
    id: string
    queryParams: {
      first: number
      after: number | null
      filter?: string
    }
  }
}

export interface KeeperOldAddressesParcelsData {
  getPreviousAddressesParcels: Connection<Edge<Parcel>>
}

export interface AdminParcelEventToSimulate {
  parcelId: Parcel["id"]
  eventCode: ParcelEventCode
  eventReason?: ParcelEventReason
}
