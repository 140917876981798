import { createReducer } from "@reduxjs/toolkit"

import { toFailure, toSuccess } from "actions"
import {
  API_ASK_KEEPER_WALLET_TRANSFER,
  API_CLEAR_AGENDA,
  API_GET_KEEPER_PARCELS,
  API_GET_KEEPERS_BY_ZIPCODES,
  API_REJECT_KEEPER,
  API_REACTIVATE_KEEPER,
  RESET_AVAILABILITIES,
  RESET_FOCUS_KEEPER,
  RESET_FOCUS_KEEPER_PARCELS,
  API_GET_KEEPER_WALLET_DETAILS,
  API_GET_KEEPER_PARCELS_STATS,
  API_UPDATE_ALLOWED_CARRIER,
} from "actions/keepers"
import { Availability } from "types/agenda.types"
import { Parcel, ParcelStatus } from "types/parcel.types"
import { WalletPacman } from "types/wallet.types"
import { KeeperCarrier } from "types/carrier.types"

interface initiaStateFocusProps {
  loading: boolean
  loadingCarrier: string
  loadingDetails: boolean
  noMoreDetails: boolean
  error: boolean
  data: {
    availabilities: Availability[]
    events: unknown
    keeper: unknown
    wallet: WalletPacman
    carriers: KeeperCarrier[]
  }
  parcels: {
    data: Parcel[]
    pageInfos: {
      endCursor: string
      hasNextPage: boolean
    }
    parcelsStats: {
      [ParcelStatus.IN_TRANSIT]: number
      [ParcelStatus.IN_DELIVERY]: number
      [ParcelStatus.DELIVERED_TO_KEEPER]: number
      [ParcelStatus.DELIVERED_TO_RECIPIENT]: number
    }
    loading: boolean
    activeStatus: string
  }
}

const initialState = {
  list: {
    loading: false,
    error: false,
    data: [],
  },
  search: {
    loading: false,
    error: false,
    data: [],
  },
  filteredList: {
    loading: false,
    error: false,
    data: [],
  },
  availabilities: {
    loading: false,
    error: false,
    data: [],
  },
  focus: {
    loading: false,
    loadingCarrier: null,
    loadingDetails: false,
    noMoreDetails: false,
    error: false,
    data: {
      wallet: {
        balanceDetails: [],
      },
    },
    parcels: {
      data: [],
      pageInfos: {},
      loading: false,
      activeStatus: null,
    },
  } as initiaStateFocusProps,
}

const keepers = createReducer(initialState, {
  [API_GET_KEEPERS_BY_ZIPCODES]: state => {
    state.filteredList.loading = true
  },
  [toSuccess(API_GET_KEEPERS_BY_ZIPCODES)]: (state, action) => {
    state.filteredList.loading = false
    state.filteredList.error = false
    state.filteredList.data = action.payload.data
  },
  [toFailure(API_GET_KEEPERS_BY_ZIPCODES)]: state => {
    state.filteredList.loading = false
    state.filteredList.error = true
  },

  [API_GET_KEEPER_WALLET_DETAILS]: state => {
    state.focus.loadingDetails = true
  },
  [toSuccess(API_GET_KEEPER_WALLET_DETAILS)]: (state, action) => {
    state.focus.loadingDetails = false
    state.focus.error = false

    if (action.payload.data && action.payload.data.length === 0) {
      state.focus.noMoreDetails = true
    }
    state.focus.data.wallet.balanceDetails = [...state.focus.data.wallet.balanceDetails, ...action.payload.data]
  },
  [toFailure(API_GET_KEEPER_WALLET_DETAILS)]: state => {
    state.focus.loadingDetails = false
    state.focus.error = true
  },
  [API_CLEAR_AGENDA]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_CLEAR_AGENDA)]: state => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = {
      ...state.focus.data,
      availabilities: [],
    }
  },
  [toFailure(API_CLEAR_AGENDA)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },
  [API_REJECT_KEEPER]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_REJECT_KEEPER)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
  },
  [toFailure(API_REJECT_KEEPER)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  [API_REACTIVATE_KEEPER]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_REACTIVATE_KEEPER)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
  },
  [toFailure(API_REACTIVATE_KEEPER)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  [API_ASK_KEEPER_WALLET_TRANSFER]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_ASK_KEEPER_WALLET_TRANSFER)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = {
      ...state.focus.data,
      wallet: action.payload.data,
    }
  },
  [toFailure(API_ASK_KEEPER_WALLET_TRANSFER)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },
  [RESET_AVAILABILITIES]: state => {
    state.availabilities.data = initialState.availabilities.data
  },
  [RESET_FOCUS_KEEPER]: state => {
    state.focus.data = initialState.focus.data
    state.focus.parcels = initialState.focus.parcels
  },
  [RESET_FOCUS_KEEPER_PARCELS]: state => {
    state.focus.parcels = {
      ...initialState.focus.parcels,
      parcelsStats: state.focus.parcels.parcelsStats,
    }
  },

  [API_GET_KEEPER_PARCELS]: (state, action) => {
    state.focus.parcels.loading = true
    state.focus.parcels.activeStatus = action.payload.filter
  },
  [toSuccess(API_GET_KEEPER_PARCELS)]: (state, action) => {
    state.focus.parcels.loading = false
    state.focus.error = false
    state.focus.parcels = {
      ...state.focus.parcels,
      pageInfos: action.payload.data.pageInfos,
      data: state.focus.parcels.data.concat(action.payload.data.edges.map(edge => edge.node)),
    }
  },
  [toFailure(API_GET_KEEPER_PARCELS)]: state => {
    state.focus.parcels.loading = false
    state.focus.error = true
  },

  [API_GET_KEEPER_PARCELS_STATS]: state => {
    state.focus.parcels.loading = true
  },
  [toSuccess(API_GET_KEEPER_PARCELS_STATS)]: (state, action) => {
    state.focus.parcels.loading = false
    state.focus.error = false
    state.focus.parcels = {
      ...state.focus.parcels,
      parcelsStats: action.payload.data,
    }
  },
  [toFailure(API_GET_KEEPER_PARCELS_STATS)]: state => {
    state.focus.parcels.loading = false
    state.focus.error = true
  },

  [API_UPDATE_ALLOWED_CARRIER]: (state, action) => {
    state.focus.loadingCarrier = action.payload.carrierName
  },
  [toSuccess(API_UPDATE_ALLOWED_CARRIER)]: (state, action) => {
    state.focus.loadingCarrier = null
    state.focus.error = false
    state.focus.data = action.payload.data
  },
  [toFailure(API_UPDATE_ALLOWED_CARRIER)]: state => {
    state.focus.loadingCarrier = null
    state.focus.error = true
  },
})

export default keepers
