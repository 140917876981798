import React, { ReactElement } from "react"
import { Icon } from "semantic-ui-react"

import PinPickup from "assets/mapbox/carriers/pickup_pin.png"
import PinLaPoste from "assets/mapbox/carriers/laposte_pin.png"
import PinPickme from "assets/mapbox/carriers/pickme_pin.png"
import { Emoji } from "components/Emoji/Emoji"
import { MapPin } from "components/Maps/MapPin"
import {
  Break,
  CloseElement,
  DetailSearchLogContainer,
  DetailSearchLogLoaderContainer,
  DetailSearchKeeperLogTitle,
  DetailSearchKeeperLogSubTitle,
  LineContainer,
  ListContainer,
  NetworkNameContainer,
  RightElement,
} from "components/SearchKeeperMonitoring/DetailSearchKeeperLog.styled"
import { DetailSearchKeeperLogSkeleton } from "components/SearchKeeperMonitoring/DetailSearchKeeperLogSkeleton"
import { CompetitorsName } from "types/competitor.types"
import { DetailSearchKeeperLogProps } from "types/search-keeper-log.types"
import { Pickme } from "utils/pickmeInfos"
import { displayDistance } from "utils/distance.util"

function DetailSearchKeeperLog({ loading, log, onClose }: DetailSearchKeeperLogProps): ReactElement {
  return (
    <>
      {loading && (
        <DetailSearchLogLoaderContainer>
          <DetailSearchKeeperLogSkeleton />
        </DetailSearchLogLoaderContainer>
      )}

      {log && (
        <DetailSearchLogContainer>
          <>
            <LineContainer>
              <DetailSearchKeeperLogTitle>Détails de l&apos;appel</DetailSearchKeeperLogTitle>
              <CloseElement onClick={onClose}>
                <Icon name="close" />
              </CloseElement>
            </LineContainer>
            <p>{log.searchingAddress.formattedLine}</p>
            <LineContainer>
              <Emoji label="bust in silhouette" style={{ marginRight: 5 }}>
                👤
              </Emoji>
              Nb Keepers renvoyés <RightElement bold>{log.nbKeepers}</RightElement>
            </LineContainer>
            <LineContainer>
              <Emoji label="pushpin" style={{ marginRight: 5 }}>
                📍
              </Emoji>
              Distance du plus proche <RightElement bold>{displayDistance(log.shortestDistance)}</RightElement>
            </LineContainer>
            <Break />
            <DetailSearchKeeperLogSubTitle>Liste des points</DetailSearchKeeperLogSubTitle>
            <ListContainer>
              {log.list.map(network => (
                <LineContainer key={network.name} title={network.name}>
                  {
                    {
                      [Pickme.NAME]: <MapPin src={PinPickme} style={{ marginRight: 5 }} />,
                      [CompetitorsName.LA_POSTE]: <MapPin src={PinLaPoste} style={{ marginRight: 5 }} />,
                      [CompetitorsName.PICKUP]: <MapPin src={PinPickup} style={{ marginRight: 5 }} />,
                    }[network.network]
                  }{" "}
                  <NetworkNameContainer>{network.name}</NetworkNameContainer>{" "}
                  <RightElement>{displayDistance(network.distance)}</RightElement>
                </LineContainer>
              ))}
            </ListContainer>
          </>
        </DetailSearchLogContainer>
      )}
    </>
  )
}

export default DetailSearchKeeperLog
