import { Address } from "./address.types"
import { Company } from "./company.types"
import { Keeper } from "./keeper.types"
import { Meeting } from "./meeting.types"
import { Parcel } from "./parcel.types"

export enum OrderStatus {
  CREATED = "CREATED",
  IN_PREPARATION = "IN_PREPARATION",
  READY_TO_SHIP = "READY_TO_SHIP",
  /** BTOC STATUSES */
  IN_TRANSIT = "IN_TRANSIT",
  IN_DELIVERY = "IN_DELIVERY",
  RETURN_REQUESTED = "RETURN_REQUESTED",
  RETURNING = "RETURNING",
  RETURN_COMPLETED = "RETURN_COMPLETED",
  KEEPER_DELIVERY_FAILURE = "KEEPER_DELIVERY_FAILURE",
  DELIVERED_TO_KEEPER = "DELIVERED_TO_KEEPER",
  RECIPIENT_DELIVERY_FAILURE = "RECIPIENT_DELIVERY_FAILURE",
  DELIVERED_TO_RECIPIENT = "DELIVERED_TO_RECIPIENT",
  /** CTOB STATUSES */
  WAITING_FOR_DROPOFF = "WAITING_FOR_DROPOFF",
  DROPPED_TO_KEEPER = "DROPPED_TO_KEEPER",
  DROPOFF_DELIVERY_FAILED = "DROPOFF_DELIVERY_FAILED",
  DROPOFF_DELIVERED = "DROPOFF_DELIVERED",
  /** OTHERS */
  LOST = "LOST",
  CANCELLED = "CANCELLED",
}

export enum OrderType {
  BtoC = "BtoC",
  CtoB = "CtoB",
}

export enum OrderRecipientContactType {
  SMS = "SMS",
  PHONE_CALL = "PHONE_CALL",
  EMAIL = "EMAIL",
  SECURE_MESSAGING = "SECURE_MESSAGING",
}

export interface Order {
  id: string
  orderNo: string
  type: OrderType
  deliveryCode: number
  address?: Address
  __address__?: Address
  clientId: string
  parcels: Parcel[]
  recipient?: Recipient
  // retailer?: Company
  __retailer__: Company
  __client__?: Company
  client?: Company
  // rating?: Rating
  status: OrderStatus
  __meetings__: Meeting[]
  shipperName: string
  carrierDeliveryHubName: string | null
  // progressionStatus: OrderProgressionStatus
  chatId: string
  chatTalkJsStatus: string
  chatCreatedAt: Date
  chatArchivedAt: Date
  recipientContactType: OrderRecipientContactType
  createdAt: Date
  updatedAt: Date
}

export interface Recipient {
  firstName: string
  lastName: string
  name: string
  phone: string
  email: string
}

export enum ManualBtoCOrderPacmanAuthorizedStatus {
  IN_TRANSIT = "IN_TRANSIT",
  DELIVERED_TO_KEEPER = "DELIVERED_TO_KEEPER",
  DELIVERED_TO_RECIPIENT = "DELIVERED_TO_RECIPIENT",
}

export enum ManualCtoBOrderPacmanAuthorizedStatus {
  DROPPED_TO_KEEPER = "DROPPED_TO_KEEPER",
}

export enum ManualOrderPacmanReason {
  RETURN_TO_SHOP = "RETURN_TO_SHOP",
  NO_ANNOUNCEMENT = "NO_ANNOUNCEMENT",
  MANUAL_CREATION_OTHER = "MANUAL_CREATION_OTHER",
}
export interface ManualOrderPacman {
  parcelNo: Parcel["parcelNo"]
  type: Order["type"]
  clientId: Company["id"]
  keeperId: Keeper["id"]
  recipient?: RecipientInput
  parcelStatus: ManualBtoCOrderPacmanAuthorizedStatus | ManualCtoBOrderPacmanAuthorizedStatus
  reason: ManualOrderPacmanReason
  details?: string
}

export interface RecipientInput {
  firstName: string
  lastName: string
  email?: string
  phone?: string
  name?: string
}

export enum ManualOrderLabel {
  IN_TRANSIT = "En transit",
  DELIVERED_TO_KEEPER = "Chez le Keeper",
  DELIVERED_TO_RECIPIENT = "Livré",
  NO_ANNOUNCEMENT = "Annonce du colis non reçue par Pickme",
  RETURN_TO_SHOP = "Dépôt pour retour magasin",
  MANUAL_CREATION_OTHER = "Autre",
  DROPPED_TO_KEEPER = "Déposé chez le Keeper",
}

export enum NextMeetingType {
  DROPOFF = "DROPOFF",
  COLLECT = "COLLECT",
}

export interface NextMeetingObject {
  id: string
  eventStart: Date
  eventEnd: Date
  type: NextMeetingType
}
