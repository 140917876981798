import React, { ReactElement } from "react"
import styled from "styled-components"

import { COLOR } from "utils/color"

const CardIcon = styled.div`
  font-size: 25px;
  height: 40px;
  padding-top: 1px;
  padding-bottom: 5px;
`

const CardDescriptionContainer = styled.div`
  height: 30px;
  justify-content: center;
  align-items: center;
`

const ChoiceCard = styled.div<{ selected?: boolean }>(
  ({ selected }) => `
  display: flex;
  cursor: pointer;
  flex-direction: column;
  height: 90px;
  width: 33%;
  flex: 1;
  border: 1px solid;
  padding: 0px 5px 0 5px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  ${selected ? `border-color: ${COLOR.MEDIUM_LIGHT_GREEN}` : `border-color: ${COLOR.SUPER_LIGHT_GREY}`}
`,
)

const CardDescription = styled.p<{ selected?: boolean }>(
  ({ selected }) => `
  display: flex;
  flex-direction: column;
  font-weight: 600;
  text-align: center;
  align-items: center;
  line-height: 15px;
  font-size: 12px;
  ${selected ? `color: ${COLOR.MEDIUM_LIGHT_GREEN}` : `color: ${COLOR.MEDIUM_SUPER_LIGHT_GREY}`}
`,
)

interface CardChoiceProps {
  emoji: string
  title: string
  selected: boolean
  onPress: () => void
  isLast: boolean
}

const CardWalletChoice = ({ emoji, title, selected, onPress, isLast }: CardChoiceProps): ReactElement => {
  return (
    <ChoiceCard onClick={onPress} style={{ marginRight: !isLast ? 5 : 0 }} selected={selected}>
      <CardIcon>{emoji}</CardIcon>
      <CardDescriptionContainer>
        <CardDescription selected={selected}>{title}</CardDescription>
      </CardDescriptionContainer>
    </ChoiceCard>
  )
}

export default CardWalletChoice
