import React, { ReactElement } from "react"
import { Col, Row } from "reactstrap"
import moment from "moment"
import { SystemUpdate } from "types/system.types"
import CollapsibleContainer from "components/Table/CollapsibleContainer"

const SystemUpdateCollapsible = ({ element }: { element: SystemUpdate }): ReactElement => {
  const logs = element.logs

  return (
    <CollapsibleContainer>
      <Row className="collapsible-first-row">
        <Col xs={2}>
          <Row>
            <Col xs={12}>
              <div className="collapsible-element-info">
                <div className="label">Emetteur</div>
                <div className="content">{element.details.emitter}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="collapsible-element-info">
                <div className="label">Interface</div>
                <div className="content">{element.details.interface}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="collapsible-element-info">
                <div className="label">Action</div>
                <div className="content">{element.details.action}</div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={10}>
          {logs
            .slice()
            .sort((a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix())
            .map(systemLog => (
              <Row className="systemLog-row" key={systemLog.id}>
                <Col xs={2}>{moment(systemLog.createdAt).format("DD/MM/YYYY HH:mm:ss:SSS")}</Col>
                <Col xs={1}>
                  <div className={`chip ${systemLog.status === "SUCCESS" ? "success" : "error"}`}>
                    {systemLog.status}
                  </div>
                </Col>
                <Col xs={1}>{systemLog.entityType === "PARCEL" ? "📦 PARCEL" : "💳 ORDER"}</Col>
                <Col xs={2}>{systemLog.entityNo}</Col>
                <Col xs={6}>{systemLog.details}</Col>
              </Row>
            ))}
        </Col>
      </Row>
    </CollapsibleContainer>
  )
}

export default SystemUpdateCollapsible
