import { API_GET_ONE_CARRIER_MAP_LOG, RESET_CARRIER_MAP_LOG } from "actions/carrier-map-logs"

const baseUrl = "/admin/carrier-map-log"

export function apiGetReturningOneCarrierMapLog(day: string, companyId: string, zipCodes: string[]) {
  const stringifiedZipcodes = JSON.stringify(zipCodes)
  return {
    type: API_GET_ONE_CARRIER_MAP_LOG,
    payload: {
      request: {
        url: `${baseUrl}?companyId=${companyId}&day=${day}&stringifiedZipcodes=${stringifiedZipcodes}`,
      },
      data: {
        day,
        companyId,
        zipCodes,
      },
    },
  }
}

export function resetCarrierMapLog() {
  return {
    type: RESET_CARRIER_MAP_LOG,
  }
}
