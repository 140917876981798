import { gql } from "@apollo/client"

export const getParcelKeeper = gql`
  query parcelKeeper($id: String!) {
    adminParcelKeeper(id: $id) {
      keeper {
        id
        email
        phone
        firstName
        lastName
        walletId
        address {
          streetLine
          zipcode
          city
          housingType
          digicode
          building
          floor
          instructions
          maxParcels
          status
          agenda {
            date
            timeRanges {
              start
            }
          }
        }
      }
      keeperStats {
        nbTotalDeliveredParcels
        nbCurrentSleepingParcels
        nbCurrentUnscannedParcels
      }
      keeperScoring {
        rating
        deliveryFailureRate {
          totalRate
          colissimo
          geodis
          gls
          relaisColis
          dhl
        }
        deliveryTime
        contactTime
        commitment7Days
        commitment30Days
      }
    }
  }
`

export const getCountParcelsByFlag = gql`
  query getCountParcelsByFlag {
    countParcelsByFlag {
      flag
      count
    }
  }
`

export const getParcelsByFlag = gql`
  query getParcelsByFlag($payload: GetParcelsByFlagInput!) {
    getParcelsByFlag(payload: $payload) {
      id
      cursor
      flag
      status
      updatedAt
      archived
      parcelNo
      firstKeeperReception
      outOfAvailabilities
      maxHoldingDate
      carrierName
      notes {
        text
        updatedAt
      }
      order {
        carrierDeliveryHubName
        recipient {
          firstName
          name
          phone
        }
        address {
          zipcode
          keeper {
            phone
            firstName
            lastName
          }
        }
        type
      }
      shipments {
        carrier {
          name
        }
        type
        shipmentNo
      }
    }
  }
`

export const getPreviousAddressesParcels = gql`
  query getPreviousAddressesParcels($payload: PaginatedParcelsInput!) {
    getPreviousAddressesParcels(input: $payload) {
      totalCount
      edges {
        cursor
        node {
          id
          status
          retailerStatus
          parcelNo
          orderId
          flag
          createdAt
          updatedAt
          length
          width
          firstKeeperReception
          outOfAvailabilities
          order {
            id
            status
            orderNo
            deliveryCode
            addressId
            clientId
            type
          }
        }
      }
      pageInfos {
        hasNextPage
        endCursor
      }
    }
  }
`

export const getReturnRequestDetails = gql`
  query getReturnRequestDetails($parcelId: String!) {
    getReturnRequestDetails(parcelId: $parcelId) {
      id
      returnBtocRequest {
        dimensions
        id
        parcelId
        interval {
          start
          end
        }
        reason
        dimensions
        pickupDate
        weight
      }
      shipments {
        type
        carrierDeliveryHub {
          name
          zipcodes
          contact
          completeAddress
          phone
          companyAddressContacts {
            fullName
            email
            phone
          }
        }
      }
      order {
        address {
          formattedLine
        }
        client {
          name
        }
      }
    }
  }
`
