export enum DocumentEntityType {
  IDCARD = "IDCARD",
  PROOFOFADDRESS = "PROOFOFADDRESS",
  PROFILEPICTURE = "PROFILEPICTURE",
}

export enum DocumentStatus {
  NEW = "NEW",
  VALIDATED = "VALIDATED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export type DocumentEntity = {
  id: string
  fileName: string
  description?: string
  locationKey: string
  type: DocumentEntityType
  status: DocumentStatus
  url: string
}
