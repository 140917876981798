import React, { ReactElement } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Marker } from "react-map-gl"
import { Popup } from "semantic-ui-react"

import PinOrange from "assets/mapbox/pin_orange.png"
import PinRed from "assets/mapbox/pin_red.png"
import PinGrey from "assets/mapbox/pin_grey.png"
import PinBlue from "assets/mapbox/pin_blue.png"
import PinGreen from "assets/mapbox/pin_green.png"
import PinMondialRelay from "assets/mapbox/carriers/mondialrelay_pin.png"
import PinRelaiColis from "assets/mapbox/carriers/rc_pin.png"
import PinPickup from "assets/mapbox/carriers/pickup_pin.png"
import PinLaPoste from "assets/mapbox/carriers/laposte_pin.png"
import PinPickme from "assets/mapbox/carriers/pickme_pin.png"
import KeeperPopup from "components/Keeper/KeeperPopup"
import SearchedAddressPopup from "components/SearchKeeperMonitoring/SearchedAddressLogPopup"
import NumberOfAddress from "components/SearchKeeperMonitoring/NumberOfAddress"
import OrderPopup from "components/SearchKeeperMonitoring/OrderPopup"
import { PickColor } from "components/SearchKeeperMonitoring/PinColorsCheckboxes"
import { MapPin } from "components/Maps/MapPin"
import CompetitorPickupPoints from "components/Competitors/CompetitorsPickupPoints"
import CompetitorsCheckboxes from "components/Competitors/CompetitorsCheckboxes"
import { CheckboxContainer, CompetitorsCheckboxesContainer } from "components/Competitors/CompetitorsCheckboxes.styled"
import KeeperPickupPoints from "components/SearchKeeperMonitoring/KeeperPickupPoints"
import { apiGetReturnedAddresses } from "services/returned-addresses"
import { apiGetAnalysedSearchLog } from "services/search-keepers-monitoring"
import { ReduxState } from "types/reduxState.types"
import { GeneratedOrder, SearchKeeperLogMarkersProps } from "types/search-keeper-log.types"
import { CompetitorsName } from "types/competitor.types"
import { CarriersName } from "types/carrier.types"
import { getDistanceFromLatLonInKm } from "utils/distance.util"

export default function SearchKeeperLogMarkers({
  logs,
  generatedOrders,
  pinsToDisplay,
  competitorsPickupPoints,
  displayDetailSearchKeeperLog,
  setDisplayDetailSearchKeeperLog,
}: SearchKeeperLogMarkersProps): ReactElement {
  const { data, loading } = useSelector((state: ReduxState) => state.returnedAddresses.stats)
  const { companyName } = useSelector((state: ReduxState) => state.searchKeeperLogs.stats)
  const searchKeeperLogDetail = useSelector((state: ReduxState) => state.searchKeeperLogs.focus)

  const dispatch = useDispatch()
  const onDisplayAddresses = async (id, style, addressIds) => {
    if (companyName === CarriersName.COLISSIMO) {
      setDisplayDetailSearchKeeperLog(true)
      await dispatch(apiGetAnalysedSearchLog(id))
    }
    if (style === PickColor.ORANGE_PICK) {
      // Only the orange pick must launch the request
      await dispatch(apiGetReturnedAddresses(addressIds))
    }
  }

  if (!searchKeeperLogDetail.loading && searchKeeperLogDetail.data) {
    return (
      <>
        <Marker
          key={`searching-address-${searchKeeperLogDetail.data.id}`}
          longitude={Number(searchKeeperLogDetail.data.searchingAddress.longitude)}
          latitude={Number(searchKeeperLogDetail.data.searchingAddress.latitude)}
        >
          <MapPin src={PinOrange} />
        </Marker>
        {searchKeeperLogDetail.data.list &&
          searchKeeperLogDetail.data.list.map(competitorNetworkAddress => {
            const pickupPoint = competitorNetworkAddress.pickupPoint
            const keeperInfos = competitorNetworkAddress.keeperInfos
            if (keeperInfos) {
              return (
                <Marker
                  key={`searching-address-keeper-address-${keeperInfos.id}`}
                  longitude={Number(keeperInfos.longitude)}
                  latitude={Number(keeperInfos.latitude)}
                >
                  <Popup
                    trigger={
                      <span>
                        <MapPin src={PinPickme} />
                      </span>
                    }
                    content={
                      <KeeperPickupPoints
                        keeper={keeperInfos.keeper}
                        address={keeperInfos}
                        distance={getDistanceFromLatLonInKm(
                          {
                            lat1: Number(searchKeeperLogDetail.data.searchingAddress.latitude),
                            lon1: Number(searchKeeperLogDetail.data.searchingAddress.longitude),
                          },
                          { lat2: keeperInfos.latitude, lon2: keeperInfos.longitude },
                        )}
                      />
                    }
                    on="hover"
                    position="top center"
                  />
                </Marker>
              )
            } else if (pickupPoint) {
              return (
                <Marker
                  key={`searching-address-competitor-${pickupPoint.id}`}
                  longitude={Number(pickupPoint.longitude)}
                  latitude={Number(pickupPoint.latitude)}
                >
                  <Popup
                    trigger={
                      <span>
                        {
                          {
                            [CompetitorsName.MONDIAL_RELAY]: <MapPin src={PinMondialRelay} />,
                            [CompetitorsName.RELAIS_COLIS]: <MapPin src={PinRelaiColis} />,
                            [CompetitorsName.PICKUP]: <MapPin src={PinPickup} />,
                            [CompetitorsName.LA_POSTE]: <MapPin src={PinLaPoste} />,
                          }[competitorNetworkAddress.network]
                        }
                      </span>
                    }
                    content={
                      <CompetitorPickupPoints
                        pickupPoint={pickupPoint}
                        distance={getDistanceFromLatLonInKm(
                          {
                            lat1: Number(searchKeeperLogDetail.data.searchingAddress.latitude),
                            lon1: Number(searchKeeperLogDetail.data.searchingAddress.longitude),
                          },
                          { lat2: pickupPoint.latitude, lon2: pickupPoint.longitude },
                        )}
                      />
                    }
                    on="hover"
                    position="top center"
                  />
                </Marker>
              )
            }
          })}
      </>
    )
  }
  return (
    <>
      {competitorsPickupPoints.map(pickupPoint => (
        <Marker
          key={`competitor-pickup-point-${pickupPoint.id}`}
          longitude={Number(pickupPoint.longitude)}
          latitude={Number(pickupPoint.latitude)}
        >
          <Popup
            trigger={
              <span>
                {
                  {
                    [CompetitorsName.MONDIAL_RELAY]: <MapPin src={PinMondialRelay} />,
                    [CompetitorsName.RELAIS_COLIS]: <MapPin src={PinRelaiColis} />,
                    [CompetitorsName.PICKUP]: <MapPin src={PinPickup} />,
                    [CompetitorsName.LA_POSTE]: <MapPin src={PinLaPoste} />,
                  }[pickupPoint.competitorNetwork.name]
                }
              </span>
            }
            content={<CompetitorPickupPoints pickupPoint={pickupPoint} />}
            on="hover"
            position="top center"
          />
        </Marker>
      ))}
      {logs.map((log, index) => (
        <Marker
          key={`log-searched-address-${index}`}
          longitude={Number(log.searchingAddress.longitude)}
          latitude={Number(log.searchingAddress.latitude)}
        >
          <Popup
            trigger={
              <span
                onClick={() => {
                  if (!searchKeeperLogDetail.loading && !loading) onDisplayAddresses(log.id, log.style, log.addressIds)
                }}
              >
                {log.style === PickColor.ORANGE_PICK && <MapPin src={PinOrange} />}
                {log.style === PickColor.RED_PICK && <MapPin src={PinRed} />}
                {!Object.values(PickColor).includes(log.style) && <MapPin src={PinGrey} />}
              </span>
            }
            content={<SearchedAddressPopup log={log} />}
            on="hover"
            position="top center"
          />
          <NumberOfAddress number={log.numberOfAddress} />
        </Marker>
      ))}
      {!loading &&
        pinsToDisplay.includes(PickColor.BLUE_PICK) &&
        Array.isArray(data) &&
        data.map((returnedAddress, index) => {
          return (
            <Marker
              key={`log-returned-address-${index}`}
              longitude={Number(returnedAddress.longitude)}
              latitude={Number(returnedAddress.latitude)}
            >
              <Popup
                trigger={
                  <span>
                    <MapPin src={PinBlue} />
                  </span>
                }
                content={<KeeperPopup keeper={returnedAddress.keeper} address={returnedAddress} />}
                on="hover"
                position="top center"
              />
            </Marker>
          )
        })}
      {pinsToDisplay.includes(PickColor.GREEN_PICK) &&
        Array.isArray(generatedOrders) &&
        generatedOrders.map((generatedOrder: GeneratedOrder, index) => {
          return (
            <Marker
              key={`generated-order-${index}`}
              longitude={Number(generatedOrder.address.longitude)}
              latitude={Number(generatedOrder.address.latitude)}
            >
              <Popup
                trigger={
                  <span>
                    <MapPin src={PinGreen} />
                  </span>
                }
                content={<OrderPopup order={generatedOrder} />}
                on="hover"
                position="top center"
              />
              <NumberOfAddress number={generatedOrder.numberOfAddress} />
            </Marker>
          )
        })}

      {!displayDetailSearchKeeperLog && (
        <CheckboxContainer>
          <CompetitorsCheckboxesContainer>
            <CompetitorsCheckboxes />
          </CompetitorsCheckboxesContainer>
        </CheckboxContainer>
      )}
    </>
  )
}
