import React, { ReactElement } from "react"
import styled from "styled-components"

interface PageTitleProps {
  children: ReactElement | string
}

const Title = styled.div`
  font-size: 2em;
  font-weight: 400;
  margin-bottom: 1em;
`

const PageTitle = ({ children }: PageTitleProps): ReactElement => {
  return <Title>{children}</Title>
}

export default PageTitle
