import { CarriersName } from "types/carrier.types"
import { ReturnRequestFieldKeys, ReturnRequestFormData } from "types/return-request.types"
import { AdminParcelEventReason } from "types/parcel.types"
import moment from "moment"

type GetDisplayBlocks = {
  isHelpSection: boolean
  isDateFields: boolean
  isParcelFields: boolean
  isDestinationFields: boolean
}

/**
 * Determines the visibility of specific sections of the form based on the selected carrier
 * and the status of the parcel.
 *
 * @param selectedReturningCarrierName - The name of the selected returning carrier (e.g., GLS, DHL..).
 * @param isAlreadyCollected - Boolean indicating whether the parcel has already been collected.
 * @returns An object indicating whether different form sections (help section, date fields, parcel fields, destination fields)
 * should be displayed.
 */
export const getDisplayBlocks = (
  selectedReturningCarrierName: CarriersName | null,
  isAlreadyCollected: boolean,
): GetDisplayBlocks => {
  const isHelpSection =
    !isAlreadyCollected &&
    [CarriersName.GLS, CarriersName.DHL, CarriersName.TUT_TUT].includes(selectedReturningCarrierName)

  const isDateFields =
    !isAlreadyCollected &&
    [CarriersName.GLS, CarriersName.DHL, CarriersName.TUT_TUT].includes(selectedReturningCarrierName)

  const isParcelFields = !isAlreadyCollected && selectedReturningCarrierName === CarriersName.TUT_TUT

  const isDestinationFields = !isAlreadyCollected && selectedReturningCarrierName === CarriersName.TUT_TUT
  return {
    isHelpSection,
    isDateFields,
    isParcelFields,
    isDestinationFields,
  }
}

export const extractHour = (time: string): number => {
  if (!time) return 0

  const [hours, minutes] = time.split(":").map(Number)

  return (hours + minutes) / 60
}

export const extractMinutes = (time: string): number => {
  if (!time) return 0

  const [hours, minutes] = time.split(":").map(Number)
  return hours * 60 + minutes
}

/**
 * Retrieves available options for meeting slot hours based on provided constraints.
 * @param type - Type of meeting slot ("start" or "end")
 * @param valueFromOptions - Current time value from the list of options in the format "HH:mm:ss", example: "07:00:00"
 * @param collectData - Object with collect data, example: { maximumHour: "15:00", minimumHour: "09:00", minimumSlotDuration: 2 }
 * @param selectedTimeIntervalStartHour - Selected start time for the interval in "HH:mm" format, example: "09:00"
 *
 * @returns Possible options for the start or end time of a meeting slot.
 */
export const getHoursSlotFilter = (
  type: ReturnRequestFieldKeys.timeIntervalStart | ReturnRequestFieldKeys.timeIntervalEnd,
  valueFromOptions: string,
  collectData: { maximumHour: string; minimumHour: string; minimumSlotDuration: number },
  selectedTimeIntervalStartHour: string,
): boolean => {
  const { minimumHour, maximumHour, minimumSlotDuration } = collectData || {}

  if (type === ReturnRequestFieldKeys.timeIntervalStart) {
    const timeIntervalStartHourOption = extractMinutes(valueFromOptions)
    return (
      timeIntervalStartHourOption >= extractMinutes(minimumHour) &&
      timeIntervalStartHourOption <= extractMinutes(maximumHour)
    )
  }

  if (type === ReturnRequestFieldKeys.timeIntervalEnd) {
    const timeIntervalEndHourOption = extractMinutes(valueFromOptions)
    const selectedTimeIntervalStartHourNum = extractMinutes(selectedTimeIntervalStartHour || "")
    const minimumSlotDurationMinutes = Number(minimumSlotDuration) * 60
    const maximumHourNum = extractMinutes(maximumHour)
    return (
      timeIntervalEndHourOption >= selectedTimeIntervalStartHourNum + minimumSlotDurationMinutes &&
      timeIntervalEndHourOption <= maximumHourNum
    )
  }

  return true
}

/** Checks if the selected time intervals are valid */
const checkIsIntervalValid = (timeIntervalStart: string, timeIntervalEnd: string, minimumSlotDuration: number) => {
  if (!timeIntervalStart || !timeIntervalStart) return false

  return (
    Number(timeIntervalEnd?.substring(0, 2)) >= Number(timeIntervalStart?.substring(0, 2)) + Number(minimumSlotDuration)
  )
}

/** Checks if the submit button should be enabled based on the selected data */
export const checkIsSubmitEnabled = (returnRequestFormData: ReturnRequestFormData, minimumSlotDuration: number) => {
  if (
    !returnRequestFormData?.returningCarrier?.data?.name ||
    !returnRequestFormData?.returnReason?.value ||
    (returnRequestFormData?.returnReason?.value === AdminParcelEventReason.OTHER &&
      !returnRequestFormData.returnReasonDetails)
  )
    return false

  if (returnRequestFormData.isAlreadyCollected) return true

  const selectedReturningCarrierName = returnRequestFormData.returningCarrier.data.name

  if ([CarriersName.RELAISCOLIS, CarriersName.POST_OFFICE].includes(selectedReturningCarrierName)) {
    return true
  }

  const isIntervalValid = checkIsIntervalValid(
    returnRequestFormData?.timeIntervalStart?.value,
    returnRequestFormData?.timeIntervalEnd?.value,
    minimumSlotDuration,
  )

  const isPickupDateValid =
    returnRequestFormData?.pickupDate && moment(returnRequestFormData.pickupDate).isAfter(moment())

  if (selectedReturningCarrierName === CarriersName.GLS) {
    return isPickupDateValid && isIntervalValid && returnRequestFormData.referenceCode
  }

  if (selectedReturningCarrierName === CarriersName.DHL) {
    return isPickupDateValid && isIntervalValid
  }

  if (selectedReturningCarrierName === CarriersName.TUT_TUT) {
    return (
      isPickupDateValid &&
      isIntervalValid &&
      returnRequestFormData.cotransportationCode &&
      returnRequestFormData.parcelDimensions &&
      returnRequestFormData.deliveryAddressName &&
      returnRequestFormData.deliveryAddress &&
      returnRequestFormData.deliveryPhone &&
      returnRequestFormData.deliveryInstructions
    )
  }
}
