import React, { ReactElement, useEffect, useState } from "react"
import { Button } from "semantic-ui-react"
import { useDispatch } from "react-redux"

import LineCollapsableElement from "components/List/LineCollapsableElement"
import { LoadingComponent } from "components/LoadingComponent"
import LineItemParcel from "components/Parcels/LineItemParcel"
import { ParcelStatusFilterChip } from "components/Chips/ParcelStatusFilterChip"
import { DynamicParcelListContainer, FiltersContainer, TableHeader } from "components/Parcels/DynamicParcelList.styled"
import { PARCEL_STATUS_LIST_FILTERS } from "components/Parcels/parcelStatusListFilters"
import { DynamicParcelsListProps } from "types/parcel.types"
import { SystemType } from "types/system.types"

function DynamicParcelsList({
  parcels,
  activeStatus,
  loading,
  nbParcelsResults,
  moreToLoad,
  nextCursor,
  parcelsStats,
  style,
  apiGetParcels,
  resetParcels,
  provider,
}: DynamicParcelsListProps): ReactElement {
  const dispatch = useDispatch()
  const [activeFilter, setActiveFilter] = useState(activeStatus)

  useEffect(() => {
    dispatch(resetParcels())
    setActiveFilter(null)
    if (provider === SystemType.KEEPER) {
      dispatch(apiGetParcels(nbParcelsResults))
    }
  }, [])

  const fetchNext = async (filter, withCursor = true) => {
    await dispatch(apiGetParcels(nbParcelsResults, withCursor ? nextCursor : null, filter))
  }

  const setActiveStatus = async status => {
    setActiveFilter(status)
    await dispatch(resetParcels())
    await fetchNext(status, false)
  }

  return (
    <DynamicParcelListContainer style={style}>
      <TableHeader>
        <FiltersContainer>
          {PARCEL_STATUS_LIST_FILTERS.filter(status => status.displayFor.includes(provider)).map(filter => (
            <ParcelStatusFilterChip
              key={`parcel-status-filter-chip-${filter.text.toLowerCase().replace(/\s+/g, "-")}`}
              text={filter.text}
              inactiveColor={filter.inactiveColor}
              activeColor={filter.activeColor}
              active={activeFilter === filter.status}
              onClick={() => {
                setActiveStatus(filter.status)
              }}
              number={filter.status ? parcelsStats[filter.status] : undefined}
            />
          ))}
        </FiltersContainer>
      </TableHeader>

      {parcels.length === 0 && !loading && activeFilter !== null && <div className="no-content">Aucun colis</div>}

      {parcels
        .filter(p => p)
        .map(parcel => (
          <LineCollapsableElement
            key={`linecollapsible-parcel-${parcel.id}`}
            collapseId={null}
            element={parcel}
            lineHeight="sm"
            lineData={<LineItemParcel element={parcel} />}
            collapsibleData={null}
          />
        ))}

      {loading && <LoadingComponent />}

      {moreToLoad && (
        <Button style={{ marginTop: 10 }} onClick={() => fetchNext(activeFilter)}>
          Charger plus
        </Button>
      )}
    </DynamicParcelListContainer>
  )
}

export default DynamicParcelsList
