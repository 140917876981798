import styled from "styled-components"

export const Container = styled.div`
  align-items: center;
  color: grey;
  font-size: 1em;
  font-weight: 400;
  margin-top: 0;
  line-height: 1em;
`

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: grey;
  font-weight: 400;
  margin-bottom: 2px;
  font-size: 1em;
`

export const ParcelNbContainer = styled.div`
  font-size: 0.8em;
  font-weight: lighter;
  margin-bottom: 5px;
`

export const ParcelNbBold = styled.span`
  font-weight: 600;
`
