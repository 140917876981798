import { DayAgenda, Keeper } from "types/keeper.types"

export enum HousingType {
  HOUSE = "HOUSE",
  APARTMENT = "APARTMENT",
}

export enum AddressStatus {
  NEW = "NEW",
  IN_VALIDATION = "IN_VALIDATION",
  VALIDATED = "VALIDATED",
  PENDING = "PENDING",
  INACTIVE = "INACTIVE",
}

export type Address = {
  id: string
  formattedLine: string
  city: string
  streetLine: string
  keeper?: Keeper
  zipcode: string
  housingType?: HousingType
  digicode?: string
  building?: string
  instructions?: string
  floor?: string
  maxParcels?: number
  isCovered: boolean
  status: AddressStatus
  distance?: number
  agenda?: DayAgenda[]
  latitude: number
  longitude: number

  __keeper__?: Keeper
}

export interface AddressInfosBlockProps {
  address: Address
  withNbColis?: boolean
}

export interface AddressWithKeeper {
  id: string
  formattedLine: string
  streetLine: string
  zipcode: string
  city: string
  country: string
  vendorId?: string
  latitude: number
  longitude: number
  keeperId: string
  digicode?: string
  building?: string
  floor?: string
  instructions?: string
  maxParcels?: number
  distance?: number
  nbOrders: number
  nbOrdersOfDates?: number
}
