import React, { ReactElement, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import moment from "moment"
import { Dropdown, Menu, Popup } from "semantic-ui-react"
import { useLazyQuery, useMutation } from "@apollo/client"

import { KEEPER_ACTIONS, AdminKeeperEventType } from "modules/Keeper/KEEPER_ACTIONS"
import { LoadingComponent } from "components/LoadingComponent"
import EventsList from "components/EventsList"
import TabsMenu from "components/TabsMenu/TabsMenu"
import TabMenu from "components/TabsMenu/TabMenu"
import KeeperStatus from "components/Keeper/KeeperStatus"
import { Agenda } from "components/Keeper/Calendar"
import AllowedCarrier from "components/Keeper/AllowedCarriers"
import { AllowedCarriersContainer } from "components/Keeper/AllowedCarriers.styled"
import { CopyIcon } from "components/Icons/CopyIcon"
import { Emoji } from "components/Emoji/Emoji"
import {
  KeeperContainer,
  KeeperHeader,
  KeeperInfos,
  KeeperInfosTitle,
  KeeperInfosMail,
  KeeperContent,
  KeeperContentActionsButtons,
  PopupMessage,
  PopupIcon,
} from "components/Keeper/Keeper.styled"
import KeeperAddress from "components/Keeper/Address/KeeperAddressBlock"
import DynamicParcelsList from "components/Parcels/DynamicParcelList"
import DynamicParcelsListGQL from "components/Parcels/DynamicParcelsListGQL"
import KeeperScoring from "components/KeeperScoring/KeeperScoring"
import { NbParcelsContainer } from "components/KeeperScoring/KeeperScoring.styled"
import NoteHandler from "components/Notes/NoteHandler"
import KeeperDocuments from "components/Keeper/Document/KeeperDocuments"
import AdminKeeperEventModal, { AdminEventModalProps } from "modules/Keeper/modals/AdminKeeperEventModal"
import KeeperSponsorInfo from "modules/Keeper/KeeperSponsorInfo"
import Wallet from "modules/Keeper/KeeperWallet"
import {
  apiGetKeeperWalletDetails,
  resetFocusKeeper,
  apiClearAgenda,
  apiRejectKeeper,
  apiReactivateKeeper,
  apiUpdateAllowedCarrier,
  apiAskKeeperWalletTransfer,
  apiAskKeeperWalletDonation,
  apiAskKeeperWalletGiveaway,
  apiGetKeeperParcelsStats,
  apiGetKeeperParcels,
  resetFocusKeeperParcels,
} from "services/keepers"
import { getKeeper } from "services/graphql/queries/keeper.queries"
import * as parcelQuery from "services/graphql/queries/parcel.queries"
import {
  createKeeperNote,
  KeeperNoteInput,
  CreateKeeperNoteData,
  updateKeeperNote,
  UpdateKeeperNoteData,
} from "services/graphql/mutations/keeper.mutations"
import { ReduxState } from "types/reduxState.types"
import { GetKeeperQuery, KeeperTabs } from "types/keeper.types"
import { AdminUserRole } from "types/admin-user.types"
import { SystemType } from "types/system.types"
import { KeeperOldAddressesParcelsData, KeeperOldAddressesParcelsInputs } from "types/parcel.types"
import { AddressStatus } from "types/address.types"

const NB_PARCELS_RESULTS = 30
const AuthorizedRolesForKeeperNote = [
  AdminUserRole.HEAD_OF_CARE,
  AdminUserRole.CARE_MANAGER,
  AdminUserRole.CARE_SENIOR,
  AdminUserRole.CARE_JUNIOR,
  AdminUserRole.MARKETING,
  AdminUserRole.TECH,
  AdminUserRole.ADMIN,
  AdminUserRole.CARE_EXTERNAL,
]

function Keeper(): ReactElement {
  const { state } = useLocation()
  const { id: keeperId } = useParams()

  const [isInit, setIsInit] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<KeeperTabs>(KeeperTabs.PARCELS)
  const [adminEventModal, setAdminEventModal] = useState<AdminEventModalProps>({
    opened: false,
    type: null,
    reason: null,
    details: null,
  })
  const [isFullKeeperDataCopied, setIsFullKeeperDataCopied] = useState<boolean>(false)
  const [note, setNote] = useState(null)

  const keepers = useSelector((state: ReduxState) => state.keepers)
  const parcels = useSelector((state: ReduxState) => state.keepers.focus.parcels)
  const { role } = useSelector((state: ReduxState) => state.auth)
  const dispatch = useDispatch()

  const [getKeeperQuery, { data: keeperData, loading: keeperLoading, refetch: refetchKeeperData }] =
    useLazyQuery<GetKeeperQuery>(getKeeper)
  const [getPreviousAddressesParcelsQuery, { data: previousParcelsData, loading: oldParcelsLoading }] = useLazyQuery<
    KeeperOldAddressesParcelsData,
    KeeperOldAddressesParcelsInputs
  >(parcelQuery.getPreviousAddressesParcels)

  const [previousParcelActiveStatus, setPreviousParcelActiveStatus] = useState("")
  const [previousParcelResultsCount, setPreviousParcelResultsCount] = useState(NB_PARCELS_RESULTS)

  const [createKeeperNoteMutation] = useMutation<CreateKeeperNoteData, KeeperNoteInput>(createKeeperNote)
  const [updateKeeperNoteMutation] = useMutation<UpdateKeeperNoteData, KeeperNoteInput>(updateKeeperNote)

  const fetchPreviousAddressesParcels = async (nbResult = NB_PARCELS_RESULTS, filter?: string) => {
    if (filter) {
      setPreviousParcelActiveStatus(filter)
    }

    await getPreviousAddressesParcelsQuery({
      variables: {
        payload: {
          id: keeperId,
          queryParams: {
            first: nbResult,
            after: null,
            filter,
          },
        },
      },
    })
  }

  useEffect(() => {
    const focusTab: KeeperTabs = state?.focusTab
    if (focusTab) {
      setActiveTab(focusTab)
    }

    if (!isInit) {
      dispatch(resetFocusKeeper())
      dispatch(apiGetKeeperParcelsStats(keeperId))
      getKeeperQuery({ variables: { id: keeperId } }).then(values => {
        setNote(values.data.adminGetKeeper.note)
      })
      setIsInit(true)
    }
  }, [dispatch])

  /** Reset page if Keeper ID changed in params */
  useEffect(() => {
    dispatch(resetFocusKeeper())
    dispatch(apiGetKeeperParcelsStats(keeperId))
    getKeeperQuery({ variables: { id: keeperId } }).then(values => {
      setNote(values.data.adminGetKeeper.note)
    })
    fetchPreviousAddressesParcels()
    setIsInit(true)
  }, [keeperId])

  const _setTabMenu = (index: KeeperTabs) => {
    setActiveTab(index)
  }

  // ADMIN EVENTS
  const _openAdminEventModal = (type, associationId = null) => {
    setAdminEventModal({ opened: true, type, reason: null, details: null, associationId })
  }

  const _changeReason = reason => {
    setAdminEventModal(prevState => ({ ...prevState, reason }))
  }

  const _changeDetails = details => {
    setAdminEventModal(prevState => ({ ...prevState, details }))
  }

  const _cancelAdminEventModal = () => {
    setAdminEventModal({ type: null, reason: null, details: null, opened: false })
  }

  const _submitAdminEvent = async () => {
    setAdminEventModal(prevState => ({ ...prevState, opened: false }))

    if (adminEventModal.type === AdminKeeperEventType.CLEAR_AGENDA) await dispatch(apiClearAgenda(keeperId))
    if (adminEventModal.type === AdminKeeperEventType.REJECT_KEEPER) await dispatch(apiRejectKeeper(keeperId))
    if (adminEventModal.type === AdminKeeperEventType.REACTIVATE_KEEPER) await dispatch(apiReactivateKeeper(keeperId))
    if (adminEventModal.type === AdminKeeperEventType.ASK_TRANSFER) await dispatch(apiAskKeeperWalletTransfer(keeperId))
    if (adminEventModal.type === AdminKeeperEventType.ASK_DONATION)
      await dispatch(apiAskKeeperWalletDonation(keeperId, adminEventModal.associationId))
    if (adminEventModal.type === AdminKeeperEventType.ASK_GIVEAWAY) await dispatch(apiAskKeeperWalletGiveaway(keeperId))

    getKeeperQuery({ variables: { id: keeperId }, fetchPolicy: "network-only" }).then(values => {
      setNote(values.data.adminGetKeeper.note)
    })
  }

  const _copyFullKeeperData = (keeper, address) => {
    setIsFullKeeperDataCopied(true)

    // Basic Keeper data formatted
    const keeperName = `${keeper.firstName} ${keeper.lastName}`
    const formattedAddress = `${address.streetLine} ${address.zipcode} ${address.city}`
    const addressInstructions = `${address.building !== null ? address.building + "," : ""}${
      address.floor !== null ? "Étage" + address.floor + "," : ""
    }${address.digicode !== null ? address.digicode + "," : ""}${
      address.instructions !== null ? address.instructions : ""
    }`

    const formattedBasicKeeperInfos = `${keeperName}\n${formattedAddress}\n${addressInstructions}\n${keeper.phone}`

    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(formattedBasicKeeperInfos)

    setTimeout(() => {
      setIsFullKeeperDataCopied(false)
    }, 1000)
  }

  const { loading, loadingDetails, loadingCarrier, noMoreDetails, data: dataKeeperFocus } = keepers.focus

  const balanceDetails = dataKeeperFocus?.wallet?.balanceDetails

  if (keeperLoading || keeperData === undefined || loading) return <LoadingComponent />

  const { data: dataParcels, pageInfos, parcelsStats, loading: loadingParcels, activeStatus } = parcels
  const { keeper, documents, events, wallet, availabilities, carriers, keeperScoring, listAddresses } =
    keeperData.adminGetKeeper
  const { address } = keeper

  const onCreateKeeperNote = async ({ text, zendeskLink }: { text: string; zendeskLink: string }): Promise<void> => {
    createKeeperNoteMutation({
      variables: {
        payload: {
          keeperId,
          content: text,
          zendeskLink,
        },
      },
    }).then(values => {
      setNote(values.data.createKeeperNote.note)
    })
  }

  const onUpdateKeeperNote = ({ text, zendeskLink }) => {
    updateKeeperNoteMutation({
      variables: {
        payload: {
          keeperId,
          content: text,
          zendeskLink,
        },
      },
    }).then(values => {
      setNote(values.data.updateKeeperNote)
    })
  }

  const isKeeperHasInactiveAddress = () => {
    return listAddresses.find(address => address.status === AddressStatus.INACTIVE) ? true : false
  }

  return (
    <KeeperContainer>
      <KeeperHeader>
        <KeeperInfos>
          <KeeperInfosTitle>
            <Popup
              content={isFullKeeperDataCopied ? "Copié ✅" : `Copier les infos du Keeper`}
              size="small"
              trigger={
                <PopupMessage onClick={() => _copyFullKeeperData(keeper, address)}>
                  <span>
                    {keeper.firstName} {keeper.lastName}
                  </span>
                  <PopupIcon>
                    <CopyIcon name="copy outline" />
                  </PopupIcon>
                </PopupMessage>
              }
            />
          </KeeperInfosTitle>
          <KeeperStatus status={keeper.status} />
          <div>
            <NbParcelsContainer marginTop={true}>{keeper.nbTotalDeliveredParcels} colis livrés</NbParcelsContainer>
            <KeeperScoring keeperScoring={keeperScoring} />
          </div>
          <br />
          Inscrit depuis le {moment(keeper.createdAt).format("DD-MM-YYYY")}
          <br />
          <div>
            <Emoji label="phone">📞</Emoji> {keeper.phone}
          </div>
          <KeeperInfosMail>
            <Emoji label="envelope">✉️</Emoji> {keeper.email}
          </KeeperInfosMail>
          <AllowedCarriersContainer>
            {carriers.map(carrier => (
              <AllowedCarrier
                carrier={carrier}
                key={`carriers-${carrier.carrierId}`}
                loading={carrier.carrierName === loadingCarrier}
                updateAllowedCarrier={async () => {
                  await dispatch(apiUpdateAllowedCarrier(keeperId, carrier))
                  await getKeeperQuery({ variables: { id: keeperId }, fetchPolicy: "network-only" }).then(values => {
                    setNote(values.data.adminGetKeeper.note)
                  })
                }}
              />
            ))}
          </AllowedCarriersContainer>
        </KeeperInfos>
        <KeeperAddress address={address} />
        {AuthorizedRolesForKeeperNote.includes(role) && (
          <NoteHandler
            note={note ? { ...note, text: note.content } : null}
            createNote={onCreateKeeperNote}
            updateNote={onUpdateKeeperNote}
            style={{ height: "fit-content" }}
            witdhEditableContainer={{ width: "33%" }}
          />
        )}
      </KeeperHeader>
      <KeeperContent>
        <KeeperContentActionsButtons>
          {KEEPER_ACTIONS.filter(i => i.statuses.includes(keeper.status)).length > 0 && (
            <Menu compact>
              <Dropdown
                text="Actions"
                direction="left"
                className={"dropdown-menu-text"}
                options={KEEPER_ACTIONS.filter(i => i.statuses.includes(keeper.status)).map(i => ({
                  key: i.key,
                  text: i.text,
                  value: i.value,
                }))}
                onChange={(e, item) => _openAdminEventModal(item.value)}
                simple
                item
              />
            </Menu>
          )}
        </KeeperContentActionsButtons>

        <TabsMenu>
          <TabMenu
            title="Colis"
            active={activeTab === KeeperTabs.PARCELS}
            onClick={() => _setTabMenu(KeeperTabs.PARCELS)}
          />
          <TabMenu
            title="Agenda"
            active={activeTab === KeeperTabs.AGENDA}
            onClick={() => _setTabMenu(KeeperTabs.AGENDA)}
          />
          <TabMenu
            title="Portefeuille"
            active={activeTab === KeeperTabs.WALLET}
            onClick={() => _setTabMenu(KeeperTabs.WALLET)}
          />
          <TabMenu
            title="Évènements"
            active={activeTab === KeeperTabs.EVENTS}
            onClick={() => _setTabMenu(KeeperTabs.EVENTS)}
          />
          <TabMenu
            title="Parrainage"
            active={activeTab === KeeperTabs.SPONSOR}
            onClick={() => _setTabMenu(KeeperTabs.SPONSOR)}
          />
          <TabMenu
            title="Documents"
            active={activeTab === KeeperTabs.DOCUMENTS}
            onClick={() => _setTabMenu(KeeperTabs.DOCUMENTS)}
          />
          {isKeeperHasInactiveAddress() && (
            <TabMenu
              title="Anciens colis"
              active={activeTab === KeeperTabs.PREVIOUS_PARCELS}
              onClick={() => _setTabMenu(KeeperTabs.PREVIOUS_PARCELS)}
            />
          )}
        </TabsMenu>

        {activeTab === KeeperTabs.PARCELS && parcelsStats && (
          <DynamicParcelsList
            parcels={dataParcels}
            nbParcelsResults={NB_PARCELS_RESULTS}
            loading={loadingParcels}
            moreToLoad={pageInfos.hasNextPage}
            nextCursor={pageInfos.endCursor}
            parcelsStats={parcelsStats}
            apiGetParcels={apiGetKeeperParcels(keeperId)}
            resetParcels={resetFocusKeeperParcels}
            activeStatus={activeStatus}
            provider={SystemType.KEEPER}
          />
        )}
        {activeTab === KeeperTabs.AGENDA && <Agenda agendaList={availabilities} />}
        {activeTab === KeeperTabs.WALLET && (
          <Wallet
            keeperId={keeper.id}
            wallet={wallet}
            refetchKeeperData={refetchKeeperData}
            askKeeperWalletTransfer={() => _openAdminEventModal("ASK_TRANSFER")}
            askKeeperWalletDonation={associationId => _openAdminEventModal("ASK_DONATION", associationId)}
            askKeeperWalletGiveaway={() => _openAdminEventModal("ASK_GIVEAWAY")}
            apiGetKeeperWalletDetails={async (walletId, take, skip) =>
              await dispatch(apiGetKeeperWalletDetails(walletId, take, skip))
            }
            loadingDetails={loadingDetails}
            noMoreDetails={noMoreDetails}
            balanceDetails={balanceDetails}
          />
        )}
        {activeTab === KeeperTabs.EVENTS && <EventsList events={events} />}
        {activeTab === KeeperTabs.SPONSOR && <KeeperSponsorInfo keeper={keeper} />}
        {activeTab === KeeperTabs.DOCUMENTS && (
          <KeeperDocuments documents={documents} keeperId={keeperId} refetchKeeperData={refetchKeeperData} />
        )}
        {activeTab === KeeperTabs.PREVIOUS_PARCELS && previousParcelsData?.getPreviousAddressesParcels && (
          <DynamicParcelsListGQL
            parcels={previousParcelsData.getPreviousAddressesParcels.edges}
            nbParcelsResults={previousParcelResultsCount}
            setPreviousParcelResultsCount={setPreviousParcelResultsCount}
            totalCount={previousParcelsData.getPreviousAddressesParcels.totalCount}
            loading={oldParcelsLoading}
            moreToLoad={previousParcelsData.getPreviousAddressesParcels.pageInfos.hasNextPage}
            nextCursor={previousParcelsData.getPreviousAddressesParcels.pageInfos.endCursor}
            fetchPreviousAddressesParcels={fetchPreviousAddressesParcels}
            activeStatus={previousParcelActiveStatus}
            provider={SystemType.KEEPER}
          />
        )}
      </KeeperContent>

      <AdminKeeperEventModal
        adminEventModal={adminEventModal}
        cancelAdminEventModal={_cancelAdminEventModal}
        submitAdminEvent={_submitAdminEvent}
        actions={KEEPER_ACTIONS}
        changeReason={_changeReason}
        changeDetails={_changeDetails}
      />
    </KeeperContainer>
  )
}

export default Keeper
