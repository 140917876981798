import { createReducer } from "@reduxjs/toolkit"
import {
  API_GET_ANALYZED_SEARCH_LOG,
  API_GET_SEARCH_KEEPER_ENDPOINT_LOGS,
  RESET_SEARCH_KEEPER_LOGS,
  RESET_SEARCH_KEEPER_LOGS_FOCUS,
  API_GET_SEARCH_LOG_CSV,
} from "actions/search-keeper-monitoring"
import { toFailure, toSuccess } from "actions"
import { AnalysedSearchKeeperLog, SearchKeeperLog } from "types/search-keeper-log.types"

interface initialStateProps {
  stats: {
    loading: boolean
    error: boolean
    companyName: string
    data: SearchKeeperLog
  }
  focus: {
    loading: boolean
    error: boolean
    data: AnalysedSearchKeeperLog
  }
  csv: {
    loading: boolean
    error: boolean
    data: []
  }
}

const initialState: initialStateProps = {
  stats: {
    loading: false,
    error: false,
    companyName: null,
    data: null,
  },
  focus: {
    loading: false,
    error: false,
    data: null,
  },
  csv: {
    loading: false,
    error: false,
    data: [],
  },
}

const searchKeeperEndpointMonitoring = createReducer(initialState, {
  // Search Several Logs
  [API_GET_SEARCH_KEEPER_ENDPOINT_LOGS]: state => {
    state.stats.loading = true
  },

  [toSuccess(API_GET_SEARCH_KEEPER_ENDPOINT_LOGS)]: (state, action) => {
    state.stats.loading = false
    state.stats.error = false

    state.stats.companyName = action.payload.data.company.name
    state.stats.data = action.payload.data.logs
  },
  [toFailure(API_GET_SEARCH_KEEPER_ENDPOINT_LOGS)]: state => {
    state.stats.loading = false
    state.stats.error = true
  },

  // RESET SEARCH KEEPER LOGS
  [RESET_SEARCH_KEEPER_LOGS]: state => {
    delete state.csv.data
    delete state.focus.data
    delete state.stats.data
    state = initialState
  },

  // RESET FOCUS
  [RESET_SEARCH_KEEPER_LOGS_FOCUS]: state => {
    delete state.focus.data
    state.focus = initialState.focus
  },

  // Get Detailed Search Log
  [API_GET_ANALYZED_SEARCH_LOG]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_GET_ANALYZED_SEARCH_LOG)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false

    state.focus.data = action.payload.data
  },
  [toFailure(API_GET_ANALYZED_SEARCH_LOG)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  // Get CSV Search Log
  [API_GET_SEARCH_LOG_CSV]: state => {
    state.csv.loading = true
  },
  [toSuccess(API_GET_SEARCH_LOG_CSV)]: (state, action) => {
    state.csv.loading = false
    state.csv.error = false

    state.csv.data = action.payload.data
  },
  [toFailure(API_GET_SEARCH_LOG_CSV)]: state => {
    state.csv.loading = false
    state.csv.error = true
  },
})

export default searchKeeperEndpointMonitoring
