import React, { ReactElement } from "react"
import { Col, Row } from "reactstrap"
import moment from "moment"
import { IntegrationUpdate } from "types/system.types"
import CollapsibleContainer from "components/Table/CollapsibleContainer"

const CollapsibleIntegrationUpdate = ({ element }: { element: IntegrationUpdate }): ReactElement => {
  const { logs } = element

  return (
    <CollapsibleContainer>
      <Row className="collapsible-first-row">
        <Col xs={2}>
          <Row>
            <Col xs={12}>
              <div className="collapsible-element-info">
                <div className="label">Event</div>
                <div className="content">{element.event}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="collapsible-element-info">
                <div className="label">Interface</div>
                <div className="content">{element.interface}</div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={10}>
          {logs
            .slice()
            .sort((a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf())
            .map(log => (
              <Row className="systemLog-row" key={log.id}>
                <Col xs={2}>{moment(log.startDate).format("DD/MM/YYYY HH:mm:ss:SSS")}</Col>
                <Col xs={1}>
                  <div className={`chip ${log.status === "SUCCESS" ? "success" : "error"}`}>{log.status}</div>
                </Col>
                <Col xs={3}>{log.action}</Col>
                <Col xs={6}>{log.details}</Col>
              </Row>
            ))}
        </Col>
      </Row>
    </CollapsibleContainer>
  )
}

export default CollapsibleIntegrationUpdate
