import React, { ReactElement } from "react"

import { TextStatusContainer, TextStatusTitle } from "./AddressStatusShip.styled"
import { AddressStatus } from "types/address.types"
import { COLOR } from "utils/color"

interface AddressStatusBlockProps {
  status: AddressStatus
}

const statusTitle = (status: AddressStatus) => {
  switch (status) {
    case AddressStatus.NEW:
      return "Nouveau"
    case AddressStatus.IN_VALIDATION:
      return "Validation en cours"
    case AddressStatus.PENDING:
      return "En attente"
    case AddressStatus.VALIDATED:
      return "Validée"
    case AddressStatus.INACTIVE:
      return "Inactive"
    default:
      return "OTHER"
  }
}

const statusColor = (status: AddressStatus) => {
  switch (status) {
    case AddressStatus.NEW:
    case AddressStatus.IN_VALIDATION:
      return COLOR.ORANGE
    case AddressStatus.PENDING:
      return COLOR.CITRUS_YELLOW
    case AddressStatus.VALIDATED:
      return COLOR.BRIGHT_MEDIUM_GREEN
    case AddressStatus.INACTIVE:
      return COLOR.DARK_CORAIL
    default:
      return COLOR.MEDIUM_SUPER_LIGHT_GREY
  }
}

export default function AddressStatusShip({ status }: Pick<AddressStatusBlockProps, "status">): ReactElement {
  return (
    <TextStatusContainer statusColor={statusColor(status)}>
      <TextStatusTitle>{statusTitle(status)}</TextStatusTitle>
    </TextStatusContainer>
  )
}
