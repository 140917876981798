import React, { ReactElement } from "react"
import { GeneratedOrder } from "types/search-keeper-log.types"

export interface OrderPopupProps {
  order: GeneratedOrder
}

export default function OrderPopup({ order }: OrderPopupProps): ReactElement {
  return (
    <div className="keeper-popup">
      {order.orderNos.length === 1 && <b>Commande générée</b>}
      {order.orderNos.length > 1 && <b>Commandes générées</b>}
      {order.orderNos.map((orderNo, index) => (
        <div key={`generated-order-${order.id}-${index}`}>
          n°{orderNo} - état: {order.statuses[index]}
        </div>
      ))}
      <br />
      <br />
      {order.address.streetLine}, {order.address.zipcode} {order.address.city}
      <br />
    </div>
  )
}
