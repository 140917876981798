import { WalletEntryType } from "types/wallet-entry.types"
import { CompanyBillingEntryType } from "types/company-billing.types"

export interface BillingEntry {
  id: string
  actionType: ActionType
  entryType?: WalletEntryType | CompanyBillingEntryType
  entryText: string
  targetEntry: TargetEntryType
  amount?: number
  updatedAt: Date
}

export interface WalletEntryDataItem {
  key: number
  entryText: string
  entryType: WalletEntryType | CompanyBillingEntryType
  isDisabled: boolean
}

export interface CompanyBillingEntryDataItem {
  key: number
  entryText: string
  entryType: WalletEntryType | CompanyBillingEntryType
  isDisabled: boolean
}

export enum ActionType {
  NEUTRAL = "NEUTRAL",
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export enum TargetEntryType {
  WALLET = "WALLET",
  BILLING = "BILLING",
}

export interface AddBillingEntry {
  id: string
  actionType: ActionType
  targetEntry: TargetEntryType
  entryText: string
  entryType?: WalletEntryType | CompanyBillingEntryType
}
