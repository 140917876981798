import styled from "styled-components"

const CollapsibleContainer = styled.div`
  padding: 1em 0;
  height: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  /* for Firefox */
  min-height: 0;
`

export default CollapsibleContainer
