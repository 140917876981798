import * as yup from "yup"

export interface FormikStateExtacts {
  data_type: { value: string; label: string }

  keepers_statuses: { value: string; label: string }[]
  keepers_zipcodes: string
  keepers_agenda: { value: string; label: string }

  leads_types: { value: string; label: string }[]
  leads_zipcodes: string
  leads_status: { value: string; label: string }
}

export const inputsText = {
  REQUIRED_FIELD: "Ce champ est obligatoire",
  INVALID_FIELD: "Ce champ n'est pas valide (ou manquant)",
}

export const extractsSchemaLeads = yup.object({
  leads_types: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
        label: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
      }),
    )
    .required(inputsText.REQUIRED_FIELD)
    .typeError(inputsText.INVALID_FIELD),
  leads_status: yup
    .object()
    .required(inputsText.REQUIRED_FIELD)
    .shape({
      value: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
      label: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
    }),
})

export const extractsSchemaKeepers = yup.object({
  keepers_statuses: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
        label: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
      }),
    )
    .required(inputsText.REQUIRED_FIELD)
    .typeError(inputsText.INVALID_FIELD),
  keepers_agenda: yup
    .object()
    .required(inputsText.REQUIRED_FIELD)
    .shape({
      value: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
      label: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
    }),
})

export const extractsSchemaTopKeepersAreas = yup.object({
  keepers_statuses: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
        label: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
      }),
    )
    .required(inputsText.REQUIRED_FIELD)
    .typeError(inputsText.INVALID_FIELD),
})

export const extractsSchemaRelaisColisKeepers = yup.object({
  keepers_statuses: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
        label: yup.string().required(inputsText.REQUIRED_FIELD).strict().typeError(inputsText.REQUIRED_FIELD),
      }),
    )
    .required(inputsText.REQUIRED_FIELD)
    .typeError(inputsText.INVALID_FIELD),
})
