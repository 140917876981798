import { AUTH_USER, LOGOUT_USER } from "actions/users"
import client from "config/agent"

export function authAdmin(_data) {
  return {
    type: AUTH_USER,
    payload: {
      request: {
        url: `/admin/users/login`,
        method: "POST",
        data: _data,
      },
    },
  }
}

export function logOutUser() {
  client.defaults.headers.common.authorization = ""

  return {
    type: LOGOUT_USER,
  }
}
