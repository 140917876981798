import React, { ReactElement } from "react"
import styled from "styled-components"
import { Col, Row } from "reactstrap"

import LineCollapsableElement from "components/List/LineCollapsableElement"
import LineItemKeeperSponsored from "components/Sponsorship/LineItemKeeperSponsored"
import { Keeper } from "types/keeper.types"
import { COLOR } from "utils/color"

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TableStats = styled.div`
  font-size: 10px;
  color: ${COLOR.LIGHT_GREY};
`

const Container = styled.div`
  padding: 10px 0;
`

interface KeeperSponsoredListProps {
  sponsored: [
    {
      node: Keeper
    },
  ]
  total: number
}

const KeeperSponsoredList = ({ sponsored, total }: KeeperSponsoredListProps): ReactElement => {
  return (
    <Container>
      <TableHeader>
        <TableStats>{total && `${sponsored.length}/${total} parrainages chargés`}</TableStats>
      </TableHeader>
      <Row className="line-element-row" style={{ height: "unset" }}>
        <Col xs={5}>
          <b>Nom du Keeper</b>
        </Col>
        <Col xs={5}>
          <b>À validé sa première commande</b>
        </Col>
      </Row>

      {sponsored.map((sponsoredData, key) => (
        <LineCollapsableElement
          key={key}
          collapseId={null}
          element={sponsoredData}
          lineData={<LineItemKeeperSponsored element={sponsoredData.node} />}
          collapsibleData={null}
        />
      ))}
    </Container>
  )
}

export default KeeperSponsoredList
