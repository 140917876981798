import styled from "styled-components"

import { COLOR } from "utils/color"

export const AssociationButtonDetail = styled.div`
  color: white;
  background: ${COLOR.CORAIL};
  padding: 5px;
  border-radius: 20px;
  text-align: center;
  font-family: Poppins-Semibold;

  &:hover {
    opacity: 0.7;
  }
`
