import { toFailure, toSuccess } from "actions"
import { createReducer } from "@reduxjs/toolkit"
import { API_GET_SYSTEM_STATS, API_GET_SYSTEM_UPDATES, RESET_SYSTEM_UPDATES } from "actions/system"

const initialState = {
  list: {
    loading: false,
    error: false,
    data: [],
  },
  stats: {
    loading: false,
    error: false,
    data: {},
  },
  focus: {
    loading: false,
    error: false,
    data: {},
  },
}

const systemUpdates = createReducer(initialState, {
  [API_GET_SYSTEM_UPDATES]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_SYSTEM_UPDATES)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data = state.list.data.concat(action.payload.data)
  },
  [toFailure(API_GET_SYSTEM_UPDATES)]: state => {
    state.list.loading = false
    state.list.error = true
  },
  [RESET_SYSTEM_UPDATES]: state => {
    state.list.data = []
  },
  [API_GET_SYSTEM_STATS]: state => {
    state.stats.loading = true
  },
  [toSuccess(API_GET_SYSTEM_STATS)]: (state, action) => {
    state.stats.loading = false
    state.stats.error = false
    state.stats.data = action.payload.data
  },
  [toFailure(API_GET_SYSTEM_STATS)]: state => {
    state.stats.loading = false
    state.stats.error = true
  },
})

export default systemUpdates
