export function templateDistance(distance?: number): string {
  if (distance === null) {
    return ""
  }

  if (distance < 1) {
    // In meters (ex: 250m)
    return `${Math.round(distance * 1000)}m`
  }
  // In kilometers (1.2km or 1km) (and avoid 1.0km with modulo)
  return `${distance.toFixed(2)}km`
}

/*
 * Given two points with coordinates
 * this function will return the distance between those two points.
 */
export function getDistanceFromLatLonInKm(
  point1: { lat1: number; lon1: number },
  point2: { lat2: number; lon2: number },
): number {
  const convertDegToRad = (deg: number): number => (deg * Math.PI) / 180.0
  const { lat1, lon1 } = point1
  const { lat2, lon2 } = point2
  const earthRadius = 6371
  const dLat = convertDegToRad(lat2 - lat1)
  const dLon = convertDegToRad(lon2 - lon1)
  const squarehalfChordLength =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(convertDegToRad(lat1)) * Math.cos(convertDegToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const angularDistance = 2 * Math.atan2(Math.sqrt(squarehalfChordLength), Math.sqrt(1 - squarehalfChordLength))
  const distance = earthRadius * angularDistance
  return distance
}

const getDistanceFromKmtoLatLon = (dist: number) => {
  return [dist / 80, dist / 60]
}

/*
 * Converts radius into lat and long
 * Returns range of lat and long around given point
 */
export const getCoordinateRangeAroundPoint = (lat: number, long: number, radius: number) => {
  const [radiusLat, radiusLong] = getDistanceFromKmtoLatLon(radius)
  return {
    latMin: lat - radiusLat,
    latMax: lat + radiusLat,
    longMin: long - radiusLong,
    longMax: long + radiusLong,
  }
}

/*
 * Gets a distance (number) in meter
 * Returns a nicely formatted distance string
 */
export const displayDistance = (distance: number): string => {
  if (distance / 1000 < 1) {
    return `${distance % 1 === 0 ? distance : distance.toFixed(2)} m`
  }
  return `${(distance / 1000).toFixed(2)} km`
}
