import React, { ReactElement, useState } from "react"
import { useQuery, useLazyQuery } from "@apollo/client"

import Select from "components/Form/Select"
import generateCompaniesOptions from "utils/formik/generateCompaniesOptions"
import { getCompanies, GetCompanyData, getCompanyWithAddresses } from "services/graphql/queries/company.queries"
import { PageWrapper } from "components/Layout/Structure"
import PageTitle from "components/Title"
import { BillingHeader } from "components/Companies/Billing/Billing.styled"
import SimpleTable from "components/Table/SimpleTable"
import { ZipcodeChip, ZipcodeChipsContainer } from "components/Companies/Address/CompanyAddress.styled"

export function Carriers(): ReactElement {
  const [selectedCompany, setSelectedCompany] = useState({ value: null, label: "" })

  /** GQL QUERIES */
  const { data: companies } = useQuery(getCompanies)
  const [getCompanyQuery, { data: companyData, loading: companyLoading }] =
    useLazyQuery<GetCompanyData>(getCompanyWithAddresses)

  const _onChange = e => {
    setSelectedCompany(e)

    getCompanyQuery({ variables: { id: e.value } })
  }

  const colNames = ["Agence", "Contact", "Adresse", "Codes postaux"]

  return (
    <PageWrapper>
      <PageTitle>Transporteurs</PageTitle>

      <BillingHeader>
        <Select
          value={selectedCompany}
          onChange={_onChange}
          options={!!companies ? generateCompaniesOptions(companies.getCompanies) : []}
          placeholder="Choisir"
          label="Transporteur"
          menuPosition="fixed"
        />
      </BillingHeader>

      {selectedCompany.value && (
        <SimpleTable
          headers={[
            {
              name: colNames[0],
              size: 2,
              value: "name",
            },
            {
              name: colNames[1],
              size: 2,
              value: "contact",
            },
            {
              name: colNames[2],
              size: 2,
              value: "completeAddress",
            },
            {
              name: colNames[3],
              size: 6,
              value: "zipcodes",
              // eslint-disable-next-line react/display-name
              component: (zipcodes: string[]) => {
                return (
                  <ZipcodeChipsContainer>
                    {zipcodes.map(zipcode => (
                      <ZipcodeChip key={zipcode}>{zipcode}</ZipcodeChip>
                    ))}
                  </ZipcodeChipsContainer>
                )
              },
            },
          ]}
          data={companyData?.getCompany.companyAddresses}
          lineHeight="unset"
          loading={companyLoading}
        />
      )}
    </PageWrapper>
  )
}

export default Carriers
