import React, { ReactElement, useState } from "react"
import { CSVLink } from "react-csv"
import { toast } from "react-toastify"
import { connect } from "react-redux"
import Title from "components/Title"
import { PageWrapper } from "components/Layout/Structure"
import styled from "styled-components"
import { Button, Col, Row } from "reactstrap"
import { Field, Form, Formik } from "formik"
import Select from "components/Form/Select"
import {
  extractsSchemaKeepers,
  extractsSchemaLeads,
  extractsSchemaRelaisColisKeepers,
  extractsSchemaTopKeepersAreas,
  FormikStateExtacts,
  inputsText,
} from "schema/extracts.schema"
import FormSaveButton from "components/Buttons/FormSaveButton"
import FilterBarZipcodes from "components/FilterBarZipcodes/FilterBarZipcodes"
import { apiGetExtracts } from "services/extracts"
import {
  ApiGetExtractsData,
  ApiGetExtractsInput,
  DataTypeDefault,
  ExtractKeeperStatus,
  ExtractKeeperAgenda,
  ExtractKeeperAgendaValueLabel,
  KeeperCSV,
  LeadCSV,
  LeadsStatusValueLabel,
  ExtractLeadType,
  RelaisColisKeeperExtract,
  TopKeepersAreasCSV,
} from "types/extracts.types"
import { Keeper } from "types/keeper.types"
import { Lead } from "types/lead.types"
import { ZipcodesData } from "types/filter-bar-zipcodes.types"
import { COLOR } from "utils/color"

const FieldContainer = styled.div`
  margin-bottom: 5px;
`

const LabelInput = styled.div`
  font-size: 0.8em;
  margin-bottom: 3px;
`

const defaultSelect = {
  value: "",
  label: "",
}

interface ExtractsProps {
  apiGetExtracts: (data: ApiGetExtractsInput) => ApiGetExtractsData
}

export function Extracts({ apiGetExtracts }: ExtractsProps): ReactElement {
  const [dataType, setDataType] = useState(null)

  const [keepersZipcodes, setKeepersZipcodes] = useState<ZipcodesData[]>([])
  const [leadsZipcodes, setleadsZipcodes] = useState<ZipcodesData[]>([])

  const [keepersExtractsData, setKeepersExtractsData] = useState<KeeperCSV[]>([])
  const [leadsExtractsData, setLeadsExtractsData] = useState<LeadCSV[]>([])
  const [topKeepersAreasExtractsData, setTopKeepersAreasExtractsData] = useState<TopKeepersAreasCSV[]>([])
  const [relaisColisKeepersExtractsData, setRelaisColisKeepersExtractsData] = useState<RelaisColisKeeperExtract[]>([])

  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeDataType = (value: string, setFieldValue: any) => {
    setDataType(value)

    setKeepersZipcodes([])
    setleadsZipcodes([])
    setKeepersExtractsData([])
    setLeadsExtractsData([])
    setTopKeepersAreasExtractsData([])

    setFieldValue("keepers_statuses", defaultSelect)
    setFieldValue("keepers_agenda", defaultSelect)
    setFieldValue("leads_types", defaultSelect)
    setFieldValue("leads_status", defaultSelect)
  }

  const submit = async (values: FormikStateExtacts) => {
    let data
    setLoading(true)

    if (values.data_type.value === DataTypeDefault.KEEPERS) {
      data = {
        dataType: values.data_type.value,
        keepersStatuses: values.keepers_statuses.map(status => status.value).join(","),
        keepersZipcodes: keepersZipcodes.map(keeperZipcode => keeperZipcode.value).join(","),
        keepersAgenda: values.keepers_agenda.value,
      }
      const apiGetExtractsData = await apiGetExtracts(data)
      const keepersExtractsData = apiGetExtractsData?.payload?.data as Keeper[]
      const convertExtractsData = keepersExtractsData?.reduce((acc: KeeperCSV[], data) => {
        acc.push({
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          zipcode: data?.address?.zipcode,
          status: data?.status,
        })
        return acc
      }, [])
      if (convertExtractsData?.length > 0) {
        setKeepersExtractsData(convertExtractsData)
      } else {
        toast.error("Aucun résultat")
      }
    } else if (values.data_type.value === DataTypeDefault.LEADS) {
      data = {
        dataType: values.data_type.value,
        leadsTypes: values.leads_types.map(status => status.value).join(","),
        leadsZipcodes: leadsZipcodes.map(leadZipcode => leadZipcode.value).join(","),
        leadsStatus: values.leads_status.value,
      }
      const apiGetExtractsData = await apiGetExtracts(data)
      const leadsExtactsData = apiGetExtractsData?.payload?.data as Lead[]
      const convertExtractsData = leadsExtactsData?.reduce((acc: LeadCSV[], data) => {
        acc.push({
          email: data.email,
          zipcode: data.zipcode,
          createdAt: data.createdAt,
          isKeeper: `${data.isKeeper}`,
        })
        return acc
      }, [])
      if (convertExtractsData?.length > 0) {
        setLeadsExtractsData(convertExtractsData)
      } else {
        toast.error("Aucun résultat")
      }
    } else if (values.data_type.value === DataTypeDefault.TOP_KEEPERS_AREAS) {
      data = {
        dataType: values.data_type.value,
        keepersStatuses: values.keepers_statuses.map(status => status.value).join(","),
        keepersZipcodes: keepersZipcodes.map(keeperZipcode => keeperZipcode.value).join(","),
      }
      const apiGetExtractsData = await apiGetExtracts(data)
      const keepersExtractsData = apiGetExtractsData?.payload?.data as Keeper[]
      const convertExtractsData = keepersExtractsData?.reduce((acc: TopKeepersAreasCSV[], data) => {
        acc.push({
          keeper: `${data.firstName} ${data.lastName}`,
          createdAt: data.createdAt,
          email: data.email,
          birthdate: data.birthday,
          phone: data.phone,
          address: data.address.formattedLine,
          floor: data.address.floor,
          zipcode: data.address.zipcode,
          maxParcels: data.address.maxParcels,
          orders_nb: data.orders_nb,
          ratings_nb: data.ratings_nb,
          ratings_avg: data.ratings_avg,
        })
        return acc
      }, [])
      if (convertExtractsData?.length > 0) {
        setTopKeepersAreasExtractsData(convertExtractsData)
      } else {
        toast.error("Aucun résultat")
      }
    } else if (values.data_type.value === DataTypeDefault.RELAISCOLIS_KEEPERS) {
      data = {
        dataType: values.data_type.value,
        keepersStatuses: values.keepers_statuses.map(status => status.value).join(","),
        keepersZipcodes: keepersZipcodes.map(keeperZipcode => keeperZipcode.value).join(","),
      }
      const apiGetExtractsData = await apiGetExtracts(data)
      const keepersExtractsData = apiGetExtractsData?.payload?.data as RelaisColisKeeperExtract[]

      if (keepersExtractsData?.length > 0) {
        setRelaisColisKeepersExtractsData(keepersExtractsData)
      } else {
        toast.error("Aucun résultat")
      }
    }

    setLoading(false)
  }

  const getSchema = (type: DataTypeDefault) => {
    switch (type) {
      case DataTypeDefault.KEEPERS:
        return extractsSchemaKeepers
      case DataTypeDefault.LEADS:
        return extractsSchemaLeads
      case DataTypeDefault.TOP_KEEPERS_AREAS:
        return extractsSchemaTopKeepersAreas
      case DataTypeDefault.RELAISCOLIS_KEEPERS:
        return extractsSchemaRelaisColisKeepers
    }
  }

  return (
    <PageWrapper>
      <Title>Extracts</Title>

      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          data_type: defaultSelect,

          keepers_statuses: [],
          keepers_zipcodes: "",
          keepers_agenda: defaultSelect,

          leads_types: [],
          leads_zipcodes: "",
          leads_status: defaultSelect,
        }}
        validationSchema={getSchema(dataType)}
        validate={() => {
          const errors: {
            keepers_zipcodes?: string
          } = {}
          if (
            [DataTypeDefault.TOP_KEEPERS_AREAS, DataTypeDefault.RELAISCOLIS_KEEPERS].includes(dataType) &&
            keepersZipcodes.length === 0
          ) {
            errors.keepers_zipcodes = inputsText.REQUIRED_FIELD
          }
          return errors
        }}
        onSubmit={values => submit(values)}
      >
        {({ errors, setFieldValue, values, resetForm, setErrors }) => {
          return (
            <Form>
              <Row>
                <Col xs={12} md={6}>
                  <FieldContainer>
                    <Field
                      component={Select}
                      label="Quel type de données ?"
                      required
                      editable
                      name="data_type"
                      placeholder=""
                      options={Object.keys(DataTypeDefault).map(dataType => ({
                        value: dataType,
                        label: DataTypeDefault[dataType] || "",
                      }))}
                      onChange={e => (onChangeDataType(e.value, setFieldValue), setErrors({}))}
                    />
                  </FieldContainer>

                  {dataType === DataTypeDefault.KEEPERS && (
                    <>
                      <FieldContainer style={{ marginTop: 20 }}>
                        <LabelInput>
                          Statut des Keepers ? <span style={{ color: COLOR.DARK_RED }}>*</span>
                        </LabelInput>
                        <Select
                          menuPosition="fixed"
                          defaultValue={[]}
                          isMulti
                          name="keepers_statuses"
                          options={Object.keys(ExtractKeeperStatus).map(i => ({ label: i, value: i }))}
                          placeholder="Filtrer par statut..."
                          onChange={e => setFieldValue("keepers_statuses", e)}
                        />
                        {errors.keepers_statuses && (
                          <div className="ui pointing above prompt label">{errors?.keepers_statuses as string}</div>
                        )}
                      </FieldContainer>

                      <FieldContainer style={{ marginTop: 20 }}>
                        <LabelInput>Zone des Keepers ?</LabelInput>
                        <FilterBarZipcodes
                          onChangeZipcodes={(zipcodes: ZipcodesData[]) => setKeepersZipcodes(zipcodes)}
                        />
                        {errors.keepers_zipcodes && (
                          <div className="ui pointing above prompt label">{errors?.keepers_zipcodes}</div>
                        )}
                      </FieldContainer>

                      <FieldContainer>
                        <Field
                          component={Select}
                          required
                          label="Agenda des Keepers ?"
                          editable
                          name="keepers_agenda"
                          placeholder=""
                          options={ExtractKeeperAgendaValueLabel}
                        />
                        {!!values.keepers_agenda.value &&
                          values.keepers_agenda.value === ExtractKeeperAgenda.NO_AVAILABILITIES_NEXT_WEEK && (
                            <div>
                              Pour information, les Keepers sélectionnés possèdent entre 0 et 2 créneaux pour la semaine
                              prochaine
                            </div>
                          )}
                        {errors.keepers_agenda && (
                          <div className="ui pointing above prompt label">{errors?.keepers_agenda.label}</div>
                        )}
                      </FieldContainer>

                      {keepersExtractsData && keepersExtractsData.length > 0 ? (
                        <FieldContainer style={{ marginTop: 20 }}>
                          <CSVLink
                            data={keepersExtractsData}
                            enclosingCharacter=""
                            filename="extracts-keepers.csv"
                            className="btn btn-primary"
                            target="_blank"
                            onClick={() => (resetForm(), setDataType(null))}
                          >
                            Télécharger la liste des Keepers
                          </CSVLink>
                          <Button
                            className="search-button"
                            onClick={() => (resetForm(), setDataType(null))}
                            style={{ marginLeft: 20 }}
                          >
                            Reset
                          </Button>
                        </FieldContainer>
                      ) : (
                        <Row style={{ marginTop: 20 }}>
                          <Col xs={6}>
                            <FormSaveButton
                              text="Envoyer"
                              type="submit"
                              isLoading={loading}
                              style={{ width: 240, height: 40 }}
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}

                  {dataType === DataTypeDefault.LEADS && (
                    <>
                      <FieldContainer style={{ marginTop: 20 }}>
                        <LabelInput>
                          Type des leads ? <span style={{ color: COLOR.DARK_RED }}>*</span>
                        </LabelInput>
                        <Select
                          defaultValue={[]}
                          isMulti
                          menuPosition="fixed"
                          name="leads_types"
                          options={Object.keys(ExtractLeadType).map(i => ({ label: i, value: i }))}
                          placeholder="Filtrer par type..."
                          onChange={e => setFieldValue("leads_types", e)}
                        />
                        {errors.leads_types && (
                          <div className="ui pointing above prompt label">{errors?.leads_types as string}</div>
                        )}
                      </FieldContainer>

                      <FieldContainer style={{ marginTop: 20 }}>
                        <LabelInput>Zone des leads ?</LabelInput>
                        <FilterBarZipcodes
                          onChangeZipcodes={(zipcodes: ZipcodesData[]) => setleadsZipcodes(zipcodes)}
                        />
                        {errors.leads_zipcodes && (
                          <div className="ui pointing above prompt label">{errors?.leads_zipcodes}</div>
                        )}
                      </FieldContainer>

                      <FieldContainer style={{ marginTop: 20 }}>
                        <Field
                          component={Select}
                          required
                          label="Devenu Keeper ?"
                          editable
                          name="leads_status"
                          placeholder=""
                          options={LeadsStatusValueLabel}
                        />
                        {errors.leads_status && (
                          <div className="ui pointing above prompt label">{errors?.leads_status.label}</div>
                        )}
                      </FieldContainer>

                      {leadsExtractsData && leadsExtractsData.length > 0 ? (
                        <FieldContainer style={{ marginTop: 20 }}>
                          <CSVLink
                            data={leadsExtractsData}
                            enclosingCharacter=""
                            filename="extracts-leads.csv"
                            className="btn btn-primary"
                            target="_blank"
                            onClick={() => (resetForm(), setDataType(null))}
                          >
                            Télécharger la liste des Leads
                          </CSVLink>
                          <Button
                            className="search-button"
                            onClick={() => (resetForm(), setDataType(null))}
                            style={{ marginLeft: 20 }}
                          >
                            Reset
                          </Button>
                        </FieldContainer>
                      ) : (
                        <Row style={{ marginTop: 20 }}>
                          <Col xs={6}>
                            <FormSaveButton
                              text="Envoyer"
                              type="submit"
                              isLoading={loading}
                              style={{ width: 240, height: 40 }}
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}

                  {dataType === DataTypeDefault.TOP_KEEPERS_AREAS && (
                    <>
                      <FieldContainer style={{ marginTop: 20 }}>
                        <LabelInput>
                          Statut des Keepers ? <span style={{ color: COLOR.DARK_RED }}>*</span>
                        </LabelInput>
                        <Select
                          defaultValue={[]}
                          isMulti
                          menuPosition="fixed"
                          name="keepers_statuses"
                          options={Object.keys(ExtractKeeperStatus).map(i => ({ label: i, value: i }))}
                          placeholder="Filtrer par statut..."
                          onChange={e => setFieldValue("keepers_statuses", e)}
                        />
                        {errors.keepers_statuses && (
                          <div className="ui pointing above prompt label">{errors?.keepers_statuses as string}</div>
                        )}
                      </FieldContainer>

                      <FieldContainer style={{ marginTop: 20 }}>
                        <LabelInput>
                          Zone des Keepers ? <span style={{ color: COLOR.DARK_RED }}>*</span>
                        </LabelInput>
                        <FilterBarZipcodes
                          onChangeZipcodes={(zipcodes: ZipcodesData[]) => setKeepersZipcodes(zipcodes)}
                        />
                        {errors.keepers_zipcodes && (
                          <div className="ui pointing above prompt label">{errors?.keepers_zipcodes}</div>
                        )}
                      </FieldContainer>

                      {topKeepersAreasExtractsData && topKeepersAreasExtractsData.length > 0 ? (
                        <FieldContainer style={{ marginTop: 20 }}>
                          <CSVLink
                            data={topKeepersAreasExtractsData}
                            enclosingCharacter=""
                            filename="extracts-top-keepers-areas.csv"
                            className="btn btn-primary"
                            target="_blank"
                            onClick={() => (resetForm(), setDataType(null))}
                          >
                            Télécharger la liste des Keepers
                          </CSVLink>
                          <Button
                            className="search-button"
                            onClick={() => (resetForm(), setDataType(null))}
                            style={{ marginLeft: 20 }}
                          >
                            Reset
                          </Button>
                        </FieldContainer>
                      ) : (
                        <Row style={{ marginTop: 20 }}>
                          <Col xs={6}>
                            <FormSaveButton
                              text="Envoyer"
                              type="submit"
                              isLoading={loading}
                              style={{ width: 240, height: 40 }}
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}

                  {dataType === DataTypeDefault.RELAISCOLIS_KEEPERS && (
                    <>
                      <FieldContainer style={{ marginTop: 20 }}>
                        <LabelInput>
                          Statut des Keepers ? <span style={{ color: COLOR.DARK_RED }}>*</span>
                        </LabelInput>
                        <Select
                          defaultValue={[]}
                          isMulti
                          menuPosition="fixed"
                          name="keepers_statuses"
                          options={Object.keys(ExtractKeeperStatus).map(i => ({ label: i, value: i }))}
                          placeholder="Filtrer par statut..."
                          onChange={e => setFieldValue("keepers_statuses", e)}
                        />
                        {errors.keepers_statuses && (
                          <div className="ui pointing above prompt label">{errors?.keepers_statuses as string}</div>
                        )}
                      </FieldContainer>

                      <FieldContainer style={{ marginTop: 20 }}>
                        <LabelInput>
                          Zone des Keepers ? <span style={{ color: COLOR.DARK_RED }}>*</span>
                        </LabelInput>
                        <FilterBarZipcodes
                          onChangeZipcodes={(zipcodes: ZipcodesData[]) => setKeepersZipcodes(zipcodes)}
                        />
                        {errors.keepers_zipcodes && (
                          <div className="ui pointing above prompt label">{errors?.keepers_zipcodes}</div>
                        )}
                      </FieldContainer>

                      {relaisColisKeepersExtractsData && relaisColisKeepersExtractsData.length > 0 ? (
                        <FieldContainer style={{ marginTop: 20 }}>
                          <CSVLink
                            data={relaisColisKeepersExtractsData}
                            enclosingCharacter=""
                            filename="extracts-top-keepers-areas.csv"
                            className="btn btn-primary"
                            target="_blank"
                            onClick={() => (resetForm(), setDataType(null))}
                          >
                            Télécharger la liste des Keepers
                          </CSVLink>
                          <Button
                            className="search-button"
                            onClick={() => (resetForm(), setDataType(null))}
                            style={{ marginLeft: 20 }}
                          >
                            Reset
                          </Button>
                        </FieldContainer>
                      ) : (
                        <Row style={{ marginTop: 20 }}>
                          <Col xs={6}>
                            <FormSaveButton
                              text="Envoyer"
                              type="submit"
                              isLoading={loading}
                              style={{ width: 240, height: 40 }}
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </PageWrapper>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  apiGetExtracts,
}

export default connect(mapStateToProps, mapDispatchToProps)(Extracts)
