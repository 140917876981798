import React, { Component, Fragment } from "react"

export class LineCollapsableElement extends Component {
  render() {
    const { element, collapseId, lineHeight, selectable, selection, select } = this.props
    const isCollapsed = element.id === collapseId
    let isNoteHere = element?.__notes__ !== undefined && element?.__notes__[0] !== undefined

    if (!isNoteHere) {
      isNoteHere = element?.notes !== undefined && element?.notes[0] !== undefined
    }

    return (
      <Fragment>
        <div
          className={`line-element-container ${lineHeight} ${
            this.props.collapsibleData !== null ? "cursor-pointer" : ""
          } ${selectable && selection.includes(element.id) ? "line-selected" : ""} ${isNoteHere && "note-height"}`}
          onClick={() => {
            if (this.props.collapsibleData !== null) this.props.collapseElt(element.id)
            if (selectable) select(element.id)
          }}
        >
          {this.props.lineData}
        </div>
        {this.props.collapsibleData !== null && (
          <div className={`line-element-content ${isCollapsed ? "collapsed" : ""}`}>{this.props.collapsibleData}</div>
        )}
      </Fragment>
    )
  }
}

export default LineCollapsableElement
