import { ParcelFlag } from "types/parcel.types"

export function displayParcelFlag(flag: ParcelFlag): string {
  switch (flag) {
    case ParcelFlag.RECIPIENT_DELIVERY_TO_CONFIRM:
      return "Liv. desti à confirmer"
    case ParcelFlag.UNSCANNED:
      return "Colis non scanné"
    case ParcelFlag.SLEEPING:
      return "Colis dormant"
    case ParcelFlag.RECIPIENT_REFUSED:
      return "Refus desti"
    case ParcelFlag.INFINITE_DELIVERY:
      return "Livraison infinie"
    case ParcelFlag.REASSIGNMENT_NEEDED:
      return "À réassigner"
    case ParcelFlag.KEEPER_DROPOFF_FAILED:
      return "Échec dropoff Keeper"
    case ParcelFlag.KEEPER_COLLECT_FAILED:
      return "Échec collecte Keeper"
    case ParcelFlag.CARRIER_LONG_RETURN:
      return "Retour long transporteur"
    case ParcelFlag.KEEPER_NOT_AVAILABLE_FOR_PICKUP:
      return "Keeper non dispo collecte"
    case ParcelFlag.DECLARED_REFUSED_BY_KEEPER:
      return "Refusé par le keeper"
    case ParcelFlag.RETURN_TO_ORDER:
      return "Retour à commander"
    case ParcelFlag.RETURN_TO_ORGANIZE:
      return "Retour à organiser"
    case ParcelFlag.NO_RECIPIENT_CONTACT_DETAILS:
      return "Pas de coordonnées destinataire"
    case ParcelFlag.ID_CARD_DELIVERY_TO_CHECK:
      return "Livraison CNI à vérifier"
  }
  return ""
}
