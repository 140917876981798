import styled from "styled-components"

import { COLOR } from "utils/color"

export const ZipcodeChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const ZipcodeChip = styled.div`
  padding: 2px 6px;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  margin-right: 2px;
  border-radius: 10px;
  color: ${COLOR.MEDIUM_DARK_GREY};
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
`
