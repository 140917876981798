export enum LeadType {
  LANDING = "LANDING",
  APP_KEEPER = "APP_KEEPER",
}

export interface Lead {
  id: string
  email: string
  isKeeper: boolean
  type: LeadType
  zipcode: number
  nbTimesAdded: number
  createdAt: Date
  updatedAt: Date
}
