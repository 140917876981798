import styled from "styled-components"

export const ContactModeContainer = styled.div`
  margin-top: 20px;
  font-size: 10px;
`

export const ChatContainer = styled.div`
  margin-top: 10px;
  font-size: 10px;
`

export const ChatLink = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const CreatedAtText = styled.div``
