import { gql } from "@apollo/client"

export const createOrderAppointment = gql`
  mutation createAdminRecipientAppointment($orderId: String!, $date: DateTime!) {
    createAdminRecipientAppointment(orderId: $orderId, date: $date) {
      id
      order {
        id
      }
      status
      date
      createdAt
    }
  }
`

export const cancelOrderAppointments = gql`
  mutation cancelOrderAppointments($orderId: String!) {
    cancelOrderAppointments(orderId: $orderId)
  }
`
