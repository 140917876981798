export enum TutTutCustomErrorCodes {
  TT_ORDER_CREATION_ERROR = "TT_ORDER_CREATION_ERROR",
  TT_ORDER_CANCELLATION_ERROR = "TT_ORDER_CANCELLATION_ERROR",
  TT_ORDER_UPDATING_ERROR = "TT_ORDER_UPDATING_ERROR",
  TT_ORDER_DELETION_ERROR = "TT_ORDER_DELETION_ERROR",
  TT_UNEXPECTED_NEW_ORDER_STATUS_API = "TT_UNEXPECTED_NEW_ORDER_STATUS_API",
}

export enum ParcelCollectionErrorCodes {
  PARCEL_NOT_FOUND = "PARCEL_NOT_FOUND",
  RRF_NOT_FOUND = "RRF_NOT_FOUND",
  PARCEL_ALREADY_RETURNING = "PARCEL_ALREADY_RETURNING",
  DIFFERENT_ORDER = "DIFFERENT_ORDER",
  UNACCEPTABLE_PARCEL_STATUS = "UNACCEPTABLE_PARCEL_STATUS",
  NOT_BTOC_ORDER = "NOT_BTOC_ORDER",
  NO_CARRIER_ID = "NO_CARRIER_ID",
  NOT_A_CARRIER = "NOT_A_CARRIER",
  NO_AUTO_RETURN = "NO_AUTO_RETURN",
  CARRIER_NOT_FOUND = "CARRIER_NOT_FOUND",
  CARRIER_CANT_COLLECT = "CARRIER_CANT_COLLECT",
  CARRIER_IRRELEVANT_FOR_ADDRESS = "CARRIER_IRRELEVANT_FOR_ADDRESS",
  CARRIER_IRRELEVANT_FOR_COMPANY = "CARRIER_IRRELEVANT_FOR_COMPANY",
  UNACCEPTABLE_SHIPMENT_TYPE = "UNACCEPTABLE_SHIPMENT_TYPE",
  DETAILS_MISSING = "DETAILS_MISSING",
}

export const getErrorMessage = (errorCode: TutTutCustomErrorCodes | ParcelCollectionErrorCodes | string) => {
  switch (errorCode) {
    // TutTutCustomErrorCodes cases
    case TutTutCustomErrorCodes.TT_ORDER_CREATION_ERROR:
      return "Erreur lors de la création de la commande Tut Tut"
    case TutTutCustomErrorCodes.TT_ORDER_CANCELLATION_ERROR:
      return "Erreur lors de l’annulation de la commande Tut Tut"
    case TutTutCustomErrorCodes.TT_ORDER_UPDATING_ERROR:
      return " Erreur lors de la mise à jour de la commande Tut Tut"
    case TutTutCustomErrorCodes.TT_ORDER_DELETION_ERROR:
      return "Error deleting the order in TutTut"
    case TutTutCustomErrorCodes.TT_UNEXPECTED_NEW_ORDER_STATUS_API:
      return "Statut de commande inattendu dans l’API Tut Tut"

    // ParcelCollectionErrorCodes cases
    case ParcelCollectionErrorCodes.PARCEL_NOT_FOUND:
      return "Colis introuvable"
    case ParcelCollectionErrorCodes.RRF_NOT_FOUND:
      return "Formulaire de demande de retour introuvable. Veuillez vérifier l’ID RRF fourni"
    case ParcelCollectionErrorCodes.PARCEL_ALREADY_RETURNING:
      return "Le colis est déjà en cours de retour. L’action ne peut pas être traitée"
    case ParcelCollectionErrorCodes.DIFFERENT_ORDER:
      return "Les colis doivent appartenir à la même commande. Veuillez vérifier les détails de la commande et réessayer"
    case ParcelCollectionErrorCodes.UNACCEPTABLE_PARCEL_STATUS:
      return "Le colis n’est pas éligible pour la planification du retour. Veuillez vérifier son statut"
    case ParcelCollectionErrorCodes.NOT_BTOC_ORDER:
      return "Commande non compatible B2C"
    case ParcelCollectionErrorCodes.NO_CARRIER_ID:
      return "Identifiant transporteur manquant"
    case ParcelCollectionErrorCodes.NOT_A_CARRIER:
      return "Échec de la vérification du transporteur. Veuillez confirmer l’éligibilité du transporteur"
    case ParcelCollectionErrorCodes.NO_AUTO_RETURN:
      return "Le retour automatique n’est actuellement pas pris en charge pour cette demande"
    case ParcelCollectionErrorCodes.CARRIER_NOT_FOUND:
      return "Transporteur introuvable"
    case ParcelCollectionErrorCodes.CARRIER_CANT_COLLECT:
      return "Le transporteur ne peut pas collecter le colis"
    case ParcelCollectionErrorCodes.CARRIER_IRRELEVANT_FOR_ADDRESS:
      return "Transporteur inadapté pour cette adresse"
    case ParcelCollectionErrorCodes.CARRIER_IRRELEVANT_FOR_COMPANY:
      return "Transporteur inadapté pour cette entreprise"
    case ParcelCollectionErrorCodes.UNACCEPTABLE_SHIPMENT_TYPE:
      return "Type d’expédition invalide pour le retour. Veuillez vérifier les détails de l’expédition"
    case ParcelCollectionErrorCodes.DETAILS_MISSING:
      return "Des détails supplémentaires sont requis lorsque ‘AUTRE’ est sélectionné comme motif de retour"

    default:
      return errorCode
  }
}
