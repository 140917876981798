import { COLOR } from "utils/color"
import styled from "styled-components"
import { Paragraph } from "../HorizontalCalendar/HorizontalCalendar.styled"

export const CalendarDayItemWrapper = styled.div<{ selected: boolean }>`
  min-width: 36px;
  padding: 8px;
  margin: 3px 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? COLOR.MEDIUM_LIGHT_GREEN : "transparent")};
`

export const DateParagraph = styled(Paragraph).attrs({ padded: false })<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? COLOR.WHITE : COLOR.MEDIUM_SUPER_LIGHT_GREY)};
`

export const ActivatedDot = styled.div<{ selected: boolean; activated: boolean }>`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ selected }) => (selected ? COLOR.WHITE : COLOR.LIGHT_GREY)};
  opacity: ${({ activated }) => (activated ? 1 : 0)};
`
