import React, { ReactElement, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Loader } from "semantic-ui-react"

import Title from "components/Title"
import SimpleTable from "components/Table/SimpleTable"
import { PageWrapper } from "components/Layout/Structure"
import { TitleContainer } from "components/AppVersions/AppVersions.styled"
import { getListAppVersions } from "services/app-version"
import { ReduxState } from "types/reduxState.types"

function AppVersionsList(): ReactElement {
  const appVersions = useSelector((state: ReduxState) => state.appVersions)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getListAppVersions())
  }, [])

  return (
    <PageWrapper>
      <TitleContainer>
        <Title>🛰 Liste app versions</Title>
      </TitleContainer>

      {appVersions.list.data.length === 0 && <div className="no-content">Aucune version d&apos;app</div>}

      {appVersions.list.data.length > 0 && (
        <SimpleTable
          headers={[
            {
              name: "Version",
              size: 2,
              value: "versionNumber",
            },
            {
              name: "Autorisée",
              size: 2,
              value: "authorized",
              component: authorized => (authorized ? "✅" : "❌"),
            },
          ]}
          data={appVersions.list.data}
        />
      )}

      <br />
      {appVersions.list.loading && <Loader active inline />}
    </PageWrapper>
  )
}

export default AppVersionsList
