import React, { ReactElement } from "react"
import styled from "styled-components"

import { ParcelStatus } from "types/parcel.types"
import { COLOR } from "utils/color"

const ParcelStatusContainer = styled.div<{ backgroundColor: string }>(
  ({ backgroundColor }) => `
  background-color: ${backgroundColor ? backgroundColor : COLOR.DARK_CORAIL};
  width: fit-content;
  border-radius: 7px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR.WHITE};
  font-weight: 600;
  text-align: center;
`,
)

styled.div<{ backgroundColor: string }>(
  ({ backgroundColor }) => `
  ${backgroundColor ? "background:" + backgroundColor : ""};
  padding: 0px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
`,
)

export default function ParcelStatusChip({ status }: { status: ParcelStatus }): ReactElement {
  const statuses = [
    // [BtoC]
    { value: ParcelStatus.CREATED, color: COLOR.DARK_CORAIL, text: "Nouveau" },
    { value: ParcelStatus.IN_PREPARATION, color: COLOR.DARK_CORAIL, text: "En préparation" },
    { value: ParcelStatus.READY_TO_SHIP, color: COLOR.DARK_CORAIL, text: "Prêt à l'expédition" },
    { value: ParcelStatus.IN_TRANSIT, color: COLOR.MEDIUM_BLUE, text: "En transit" },
    { value: ParcelStatus.IN_DELIVERY, color: COLOR.PURPLE, text: "En cours de livraison" },
    { value: ParcelStatus.KEEPER_DELIVERY_FAILURE, color: COLOR.DARK_CORAIL, text: "Échec livraison Keeper" },
    { value: ParcelStatus.DELIVERED_TO_KEEPER, color: COLOR.DARK_CORAIL, text: "Chez le keeper" },
    { value: ParcelStatus.RECIPIENT_DELIVERY_FAILURE, color: COLOR.DARK_CORAIL, text: "Échec livraison Destinataire" },
    { value: ParcelStatus.DELIVERED_TO_RECIPIENT, color: COLOR.CITRUS_YELLOW, text: "Livré" },
    { value: ParcelStatus.RETURN_TO_PLAN, color: COLOR.TURQUOISE, text: "Retour à planifier" },
    { value: ParcelStatus.RETURN_REQUESTED, color: COLOR.TURQUOISE, text: "Retour demandé" },
    { value: ParcelStatus.RETURNING, color: COLOR.TURQUOISE, text: "Retour pris en charge" },
    { value: ParcelStatus.RETURN_COMPLETED, color: COLOR.CITRUS_YELLOW, text: "Retour terminé" },

    // [CtoB]
    { value: ParcelStatus.WAITING_FOR_DROPOFF, color: COLOR.MEDIUM_BLUE, text: "Dépôt à venir" },
    { value: ParcelStatus.DROPPED_TO_KEEPER, color: COLOR.DARK_CORAIL, text: "Déposé chez le Keeper" },
    { value: ParcelStatus.DROPOFF_DELIVERED, color: COLOR.CITRUS_YELLOW, text: "Dépôt remis au transporteur" },
    { value: ParcelStatus.DROPOFF_DELIVERY_FAILED, color: COLOR.DARK_CORAIL, text: "Échec collecte dépôt" },

    // [Others]
    { value: ParcelStatus.LOST, color: COLOR.BLACK, text: "Perdu" },
    { value: ParcelStatus.CANCELLED, color: COLOR.BLACK, text: "Annulé" },
  ]

  const parcelStatus = statuses.find(i => i.value === status)

  return <ParcelStatusContainer backgroundColor={parcelStatus?.color}>{parcelStatus?.text}</ParcelStatusContainer>
}
