import React from "react"
import { Col } from "reactstrap"
import { FieldsRow, SectionTitle, StyledInput } from "./RequestReturnModal.styled"
import Select from "components/Form/Select"
import { Company } from "types/company.types"
import { getReturningCarrierNameList, reasonOptions } from "./data"
import { ReturnRequestFieldKeys, ReturnRequestFormData } from "types/return-request.types"
import { AdminParcelEventReason } from "types/parcel.types"

interface DefaultFieldsProps {
  returnRequestFormData: ReturnRequestFormData
  collectionCarriers: Company[]
  changeData: (key: ReturnRequestFieldKeys, value) => void
  isBtocRRFCreatedPreviously: boolean
}

export const DefaultFields = ({
  returnRequestFormData,
  changeData,
  collectionCarriers = [],
  isBtocRRFCreatedPreviously = false,
}: DefaultFieldsProps) => {
  const returningCarrierOptions = getReturningCarrierNameList(collectionCarriers)
  return (
    <FieldsRow>
      {/** Select Return Reason */}
      <Col xs={6}>
        <SectionTitle>Raison</SectionTitle>
        <Select
          value={returnRequestFormData.returnReason}
          onChange={reason => changeData(ReturnRequestFieldKeys.returnReason, reason)}
          options={reasonOptions.filter(reasonOption => reasonOption.data.isActive)}
          placeholder="Que s'est il passé ?"
          isDisabled={returnRequestFormData.isAlreadyCollected}
        />

        {returnRequestFormData.returnReason?.value === AdminParcelEventReason.OTHER && (
          <StyledInput
            fluid
            placeholder="Préciser"
            style={{ marginTop: "1em" }}
            required
            value={returnRequestFormData.returnReasonDetails}
            onChange={e => changeData(ReturnRequestFieldKeys.returnReasonDetails, e.target.value)}
          />
        )}
      </Col>

      {/** Select Return Carrier */}
      <Col xs={6}>
        <SectionTitle>🚚 Configuration du retour</SectionTitle>
        <Select
          value={returnRequestFormData.returningCarrier}
          onChange={carrier => changeData(ReturnRequestFieldKeys.returningCarrier, carrier)}
          options={returningCarrierOptions}
          placeholder="Quel transporteur de retour ?"
          isDisabled={isBtocRRFCreatedPreviously}
        />
      </Col>
    </FieldsRow>
  )
}
