export const API_GET_ALL_PARCEL = "API_GET_ALL_PARCEL"
export const RESET_PARCELS = "RESET_PARCELS"
export const API_GET_PARCELS_STATS = "API_GET_PARCELS_STATS"
export const RESET_FOCUS_PARCEL = "RESET_FOCUS_PARCEL"
export const API_SEARCH_PARCEL = "API_SEARCH_PARCEL"
export const API_GET_PROBLEMS_PARCEL = "API_GET_PROBLEMS_PARCEL"
export const API_RETURN_COMPLETED_PARCEL = "API_RETURN_COMPLETED_PARCEL"
export const API_GET_RETURNING_PARCELS = "API_GET_RETURNING_PARCELS"
export const API_GET_IN_DELIVERY_PARCELS = "API_GET_IN_DELIVERY_PARCELS"
export const API_SEND_PARCEL_ADMIN_EVENT = "API_SEND_PARCEL_ADMIN_EVENT"
export const API_SIMULATE_PARCEL_EVENT = "API_SIMULATE_PARCEL_EVENT"
// One Parcel
export const API_GET_PARCEL_INFOS = "API_GET_PARCEL_INFOS"
export const API_GET_PARCEL_EVENTS = "API_GET_PARCEL_EVENTS"

// Flags
export const API_CREATE_PARCEL_FLAG = "API_CREATE_PARCEL_FLAG"
export const API_REMOVE_PARCEL_FLAG = "API_REMOVE_PARCEL_FLAG"
// Notes
export const API_CREATE_PARCEL_NOTE = "API_CREATE_PARCEL_NOTE"
export const API_UPDATE_PARCEL_NOTE = "API_UPDATE_PARCEL_NOTE"
export const API_DELETE_PARCEL_NOTE = "API_DELETE_PARCEL_NOTE"
// Reassign
export const API_GET_KEEPERS_TO_REASSIGN = "API_GET_KEEPERS_TO_REASSIGN"
export const API_SEARCH_KEEPERS_TO_REASSIGN = "API_SEARCH_KEEPERS_TO_REASSIGN"
export const RESET_KEEPERS_TO_REASSIGN = "RESET_KEEPERS_TO_REASSIGN"

// Carrier API
export const API_GET_DATA_FROM_CARRIER_API = "API_GET_DATA_FROM_CARRIER_API"

// Parcel collect
export const API_GET_AVAILABLE_COLLECTION_CARRIERS = "API_GET_AVAILABLE_COLLECTION_CARRIERS"
export const API_ASK_RETURN_PARCEL = "API_ASK_RETURN_PARCEL"
