import styled from "styled-components"
import { COLOR } from "utils/color"
import { Modal } from "semantic-ui-react"
import {MiniButtonCancel} from "components/Buttons/MiniButton";

export const BillingModal = styled(Modal)`
  width: 800px;
  padding: 10px;
  border-radius: 20px;
`

export const ButtonContainer = styled.div`
  margin-top: 12px;
  align-self: end;
`

export const Header = styled.h3`
  font-size: 1.7em;
  font-weight: 600;
  color: #192335;
  margin-bottom: 44px;
`

export const SectionsWrapper = styled.div<{ marginBottom?: number }>`
  display: flex;
  column-gap: 115px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : "0px")};
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`

export const SectionTitle = styled.div`
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 16px;
`

export const AttachedItemsList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`

export const AttachedItem = styled.div<{ bgColor?: string }>`
  width: 240px;
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  align-items: center;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "rgba(223, 223, 223, 0.24)")};
  border-radius: 5px;
  padding: 5px;
`

export const ActionButton = styled(MiniButtonCancel)`
  margin: 0;
  width: 60px;
  text-align: center;
`

export const AttachedItemText = styled.p`
  width: 70%;
  font-size: 0.9em;
  margin: 0;
`

export const EntryItem = styled.button`
  font-size: 0.9em;
  color: ${COLOR.MEDIUM_LIGHT_GREY};
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  text-align: start;

  &:hover {
    color: ${COLOR.MEDIUM_GREY};
  }

  &:disabled {
    color: ${COLOR.SUPER_LIGHT_GREY};
    cursor: auto;
  }
`

export const SummaryTitle = styled.p`
  font-weight: 700;
  color: #5f5f5f;
  font-size: 0.9em;
  margin-bottom: 9px;
`

export const SummaryList = styled.div`
  display: flex;
  flex-direction: column;
`

export const SummaryItem = styled.p<{ color?: string }>`
  color: ${({ color }) => (color ? color : COLOR.GREEN)};
  font-size: 0.9em;
  margin: 0;
`

export const ReasonBlock = styled.div`
  margin-bottom: 95px;
`

export const InputReason = styled.input`
  width: 100%;
  height: 33px;
  border-radius: 5px;
  border: 1px solid ${COLOR.MEDIUM_SUPER_LIGHT_GREY};
  padding: 5px;

  &:focus-visible {
    outline: none;
    border-color: ${COLOR.MEDIUM_SUPER_LIGHT_GREY};
  }
`

export const ReasonTitle = styled.p`
  margin: 0 0 2px 0;
  color: #5f5f5f;
  font-size: 0.8em;
  font-weight: 300;
`

export const ActionsSection = styled.p`
  display: flex;
  column-gap: 10px;
  justify-content: end;
`

export const Button = styled.button<{ cancel?: boolean }>`
  color: white;
  border: none;
  cursor: pointer;
  padding: 6px 36px;
  border-radius: 10px;
  background-color: ${({ cancel }) => (cancel ? COLOR.MEDIUM_SUPER_LIGHT_GREY : COLOR.GREEN)};

  &:hover {
    background-color: ${({ cancel }) => (cancel ? COLOR.LIGHT_GREY : COLOR.MEDIUM_LIGHT_GREEN)}};
  }

  &:disabled {
    background-color: ${COLOR.MEDIUM_LIGHT_GREY};
    cursor: auto;
  }
`
