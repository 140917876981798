import React, { ReactElement } from "react"
import { Row, Col } from "reactstrap"
import { Keeper } from "types/keeper.types"

interface LineItemKeeperSponsoredProps {
  element: Keeper
}

const LineItemKeeperSponsored = ({ element }: LineItemKeeperSponsoredProps): ReactElement => {
  const { firstName, lastName, didKeeperDeliverHisFirstOrder, email } = element

  return (
    <Row className="line-element-row">
      <Col xs={5}>
        <div className="line-element-info">
          <div className="content">{firstName && lastName ? `👤 ${firstName} ${lastName}` : `👤 ${email}`}</div>
        </div>
      </Col>
      <Col xs={5}>
        <div className="line-element-info">
          <div className="content">{didKeeperDeliverHisFirstOrder ? "✅" : "❌"}</div>
        </div>
      </Col>
    </Row>
  )
}

export default LineItemKeeperSponsored
