import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react"
import { useLazyQuery } from "@apollo/client"

import {
  searchAddresses,
  SearchAddressesData,
  searchKeepers,
  SearchKeepersData,
  searchParcels,
  SearchParcelsData,
  SearchVariables,
} from "services/graphql/queries/admin.queries"
import { SystemType } from "types/system.types"
import {
  QuickSearchBarContainer,
  QuickSearchInputContainer,
  QuickSearchIcon,
  QuickSearchInput,
  QuickSearchCloseIcon,
  SearchResultSection,
  Title,
} from "components/QuickSearchBar/QuickSearchBar.styled"
import { Loader } from "semantic-ui-react"
import { SearchItem } from "components/QuickSearchBar/SearchItem"
import {
  mapSearchAddressesResult,
  mapSearchKeepersResult,
  mapSearchParcelsResult,
  SearchResult,
} from "utils/mapSearchResults"
import { SearchLoaders, SearchResults } from "types/quickSearchBar.types"
import { debounce } from "lodash"

interface QuickSearchBarProps {
  searchBarActive: boolean
  setSearchBarActive: (status: boolean) => void
  redirectTo: (to: string) => void
}

const QuickSearchBar = ({ searchBarActive, setSearchBarActive, redirectTo }: QuickSearchBarProps): ReactElement => {
  const [text, setText] = useState("")

  const [searchResults, setSearchResults] = useState<SearchResults>({
    parcels: [],
    keepers: [],
    addresses: [],
  })

  const [loaders, setLoaders] = useState<SearchLoaders>({
    isLoadingParcels: false,
    isLoadingKeepers: false,
    isLoadingAddresses: false,
  })

  const abortController = useRef(new AbortController())

  const [searchParcelsQuery] = useLazyQuery<SearchParcelsData, SearchVariables>(searchParcels, {
    context: { fetchOptions: { signal: abortController.current.signal } },
  })

  const [searchKeepersQuery] = useLazyQuery<SearchKeepersData, SearchVariables>(searchKeepers, {
    context: { fetchOptions: { signal: abortController.current.signal } },
  })

  const [searchAddressesQuery] = useLazyQuery<SearchAddressesData, SearchVariables>(searchAddresses, {
    context: { fetchOptions: { signal: abortController.current.signal } },
  })

  /** When the user click on the search bar, consider it as active */
  const onInputFocus = () => {
    setSearchBarActive(true)
  }

  /** On blur, if no search text provided, we unactivate the search bar */
  const onInputBlur = () => {
    if (text.length === 0) setSearchBarActive(false)
  }

  const clearSearch = () => {
    setText("")
    setSearchResults({ parcels: [], keepers: [], addresses: [] })
    setSearchBarActive(false)
  }

  const _onSearch = async (input: string) => {
    abortController.current = new AbortController()
    if (input.length > 2) {
      setLoaders(() => ({ isLoadingParcels: true, isLoadingKeepers: true, isLoadingAddresses: true }))

      const { data: parcelsData } = await searchParcelsQuery({ variables: { input } })
      setSearchResults(prevState => ({ ...prevState, parcels: mapSearchParcelsResult(parcelsData, 3) }))
      setLoaders(prevState => ({ ...prevState, isLoadingParcels: false }))

      const { data: keepersData } = await searchKeepersQuery({ variables: { input } })
      setSearchResults(prevState => ({ ...prevState, keepers: mapSearchKeepersResult(keepersData, 3) }))
      setLoaders(prevState => ({ ...prevState, isLoadingKeepers: false }))

      const { data: addressesData } = await searchAddressesQuery({ variables: { input } })
      setSearchResults(prevState => ({ ...prevState, addresses: mapSearchAddressesResult(addressesData, 3) }))
      setLoaders(prevState => ({ ...prevState, isLoadingAddresses: false }))
      return
    }
    setSearchResults({ parcels: [], keepers: [], addresses: [] })
  }

  const loadOptionsDebounced = useCallback(debounce(_onSearch, 600), [])

  useEffect(() => {
    loadOptionsDebounced.cancel()
    loadOptionsDebounced(text)
  }, [text])

  /**
   * Redirect to right page when a user clicks on a result.
   *
   * @param result - Selected search result
   */
  const chooseResult = (result: SearchResult) => {
    if (result.type === SystemType.PARCEL) {
      redirectTo(`/parcels/${result.value}`)
    } else if (result.type === SystemType.KEEPER) {
      redirectTo(`/keepers/${result.value}`)
    } else if (result.type === SystemType.ADDRESS) {
      redirectTo(`/keepers/${result.value}`)
    }

    abortController.current.abort()
    clearSearch()
  }

  return (
    <QuickSearchBarContainer>
      <QuickSearchInputContainer>
        {!searchBarActive && <QuickSearchIcon icon={"search"} color={"#a5aaae"} size={16} />}

        <QuickSearchInput
          value={text}
          onChange={e => setText(e.target.value)}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
        <QuickSearchCloseIcon
          icon={"x"}
          size={16}
          displayed={text.length > 0 ? "true" : "false"}
          onClick={clearSearch}
          color={"#8c9093"}
        />
      </QuickSearchInputContainer>
      {text.length > 2 && (
        <div>
          <SearchResultSection>
            <Title>COLIS</Title>
            {loaders.isLoadingParcels ? (
              <>
                <Loader active inline />
              </>
            ) : searchResults.parcels.length ? (
              searchResults.parcels.map(result => (
                <SearchItem key={result.value} searchData={result} chooseResult={chooseResult} />
              ))
            ) : (
              <p>Pas de données</p>
            )}
          </SearchResultSection>

          <SearchResultSection>
            <Title>KEEPERS</Title>
            {loaders.isLoadingKeepers ? (
              <>
                <Loader active inline />
              </>
            ) : searchResults.keepers.length ? (
              searchResults.keepers.map(result => (
                <SearchItem key={result.value} searchData={result} chooseResult={chooseResult} />
              ))
            ) : (
              <p>Pas de données</p>
            )}
          </SearchResultSection>

          <SearchResultSection>
            <Title>ADRESSES</Title>
            {loaders.isLoadingAddresses ? (
              <>
                <Loader active inline />
              </>
            ) : searchResults.addresses.length ? (
              searchResults.addresses.map(result => (
                <SearchItem key={result.value} searchData={result} chooseResult={chooseResult} />
              ))
            ) : (
              <p>Pas de données</p>
            )}
          </SearchResultSection>
        </div>
      )}
    </QuickSearchBarContainer>
  )
}

export default QuickSearchBar
