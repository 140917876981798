import { createStore, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"

import reducer from "reducers"

import { composeWithDevTools } from "redux-devtools-extension"

import axiosMiddleware from "redux-axios-middleware"
import client, { middlewareConfig } from "./agent"

const persistConfig = {
  key: "root",
  version: 0,
  storage,
  stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer(persistConfig, reducer)

export default () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(axiosMiddleware(client, middlewareConfig))),
  )
  const persistor = persistStore(store)

  return { store, persistor }
}
