import { ParcelStatus } from "types/parcel.types"
import { SystemType } from "types/system.types"
import { COLOR } from "utils/color"

export const PARCEL_STATUS_LIST_FILTERS = [
  {
    text: "Tous",
    inactiveColor: COLOR.WHITE_SMOKE,
    activeColor: COLOR.LIGHT_GREY,
    status: null,
    displayFor: [SystemType.KEEPER],
  },
  {
    text: "A venir ",
    inactiveColor: `${COLOR.MEDIUM_BLUE}1a`,
    activeColor: COLOR.MEDIUM_BLUE,
    status: ParcelStatus.IN_TRANSIT,
    displayFor: [SystemType.KEEPER, SystemType.PARCEL],
  },
  {
    text: "En livraison ",
    inactiveColor: `${COLOR.PURPLE}1a`,
    activeColor: COLOR.PURPLE,
    status: ParcelStatus.IN_DELIVERY,
    displayFor: [SystemType.KEEPER, SystemType.PARCEL],
  },
  {
    text: "Chez le Keeper ",
    inactiveColor: `${COLOR.DARK_CORAIL}1a`,
    activeColor: COLOR.DARK_CORAIL,
    status: ParcelStatus.DELIVERED_TO_KEEPER,
    displayFor: [SystemType.KEEPER, SystemType.PARCEL],
  },
  {
    text: "Livré ",
    inactiveColor: `${COLOR.CITRUS_YELLOW}1a`,
    activeColor: COLOR.CITRUS_YELLOW,
    status: ParcelStatus.DELIVERED_TO_RECIPIENT,
    displayFor: [SystemType.KEEPER, SystemType.PARCEL],
  },
  {
    text: "Retour à planifier ",
    inactiveColor: `${COLOR.TURQUOISE}1a`,
    activeColor: COLOR.TURQUOISE,
    status: ParcelStatus.RETURN_TO_PLAN,
    displayFor: [SystemType.KEEPER],
  },
  {
    text: "Retour demandé ",
    inactiveColor: `${COLOR.TURQUOISE}1a`,
    activeColor: COLOR.TURQUOISE,
    status: ParcelStatus.RETURN_REQUESTED,
    displayFor: [SystemType.KEEPER],
  },
  {
    text: "Retour pris en charge ",
    inactiveColor: `${COLOR.TURQUOISE}1a`,
    activeColor: COLOR.TURQUOISE,
    status: ParcelStatus.RETURNING,
    displayFor: [SystemType.KEEPER],
  },
  {
    text: "Retour terminé ",
    inactiveColor: `${COLOR.CITRUS_YELLOW}1a`,
    activeColor: COLOR.CITRUS_YELLOW,
    status: ParcelStatus.RETURN_COMPLETED,
    displayFor: [SystemType.KEEPER],
  },
]
