import { createDisplayableLogsReducer } from "components/SearchKeeperMonitoring/utils/createDisplayableLogsReducer.util"
import { PickColor } from "components/SearchKeeperMonitoring/PinColorsCheckboxes"
import { ReducedAddress, SearchKeeperLog } from "types/search-keeper-log.types"

export interface DisplayableLog {
  id: string
  style: PickColor
  searchingAddress: ReducedAddress
  nbKeepers: number
  shortestDistance: number | null
  addressIds: string[]
  numberOfAddress: number
}

export function createDisplayableLogs(logs: SearchKeeperLog[], colors: PickColor[]): DisplayableLog[] {
  let selectedLogs = []
  if (colors.includes(PickColor.RED_PICK)) {
    // Case we display calls with NO keeper
    const filteredLogs = logs.filter(log => log.nbKeepers === 0)
    const reduceLogsToRedPins = createDisplayableLogsReducer(PickColor.RED_PICK)
    const redPins = filteredLogs.reduce(reduceLogsToRedPins, [])
    selectedLogs = selectedLogs.concat(redPins)
  }
  if (colors.includes(PickColor.ORANGE_PICK)) {
    // Case we display calls with keeper
    const filteredLogs = logs.filter(log => log.nbKeepers !== 0)
    const reduceLogsToOrangePins = createDisplayableLogsReducer(PickColor.ORANGE_PICK)
    const orangePins = filteredLogs.reduce(reduceLogsToOrangePins, [])
    selectedLogs = selectedLogs.concat(orangePins)
  }

  return selectedLogs
}
