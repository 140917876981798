import React, { ReactElement, useEffect, Fragment } from "react"
import KeeperSponsoredStats from "components/Keeper/Sponsorship/KeeperSponsoredStats"
import styled from "styled-components"
import { ReduxState } from "types/reduxState.types"
import { useDispatch, useSelector } from "react-redux"
import { LoadingComponent } from "components/LoadingComponent"
import { Button } from "semantic-ui-react"
import KeeperSponsoredList from "components/Sponsorship/KeeperSponsoredList"
import { apiGetKeeperSponsorship, resetSponsorship } from "services/sponsorship"
import { Keeper } from "types/keeper.types"

const EmptyListText = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
`

interface KeeperSponsorProps {
  keeper: Keeper
}

const KeeperSponsorInfo = ({ keeper }: KeeperSponsorProps): ReactElement => {
  const first = 15
  const dispatch = useDispatch()
  const { list, pageInfos, keeperSponsor, totalCount } = useSelector((state: ReduxState) => state.sponsorship)

  useEffect(() => {
    dispatch(resetSponsorship())
    dispatch(apiGetKeeperSponsorship(keeper.id, first))
  }, [])

  const fetchNext = () => {
    dispatch(apiGetKeeperSponsorship(keeper.id, first, pageInfos.endCursor))
  }

  return (
    <Fragment>
      <KeeperSponsoredStats
        sponsorship={keeperSponsor}
        loading={list.loading}
        nbSponsored={list.data.keeperSponsorship.length || 0}
      />
      {list.data.keeperSponsorship.length > 0 ? (
        <KeeperSponsoredList sponsored={list.data.keeperSponsorship} total={totalCount} />
      ) : (
        <EmptyListText>{"Le Keeper n'a actuellement aucun filleul"}</EmptyListText>
      )}

      {pageInfos?.hasNextPage && (
        <Button style={{ marginTop: 10, width: 200 }} onClick={fetchNext}>
          Charger plus
        </Button>
      )}
      {list.loading && <LoadingComponent />}
    </Fragment>
  )
}

export default KeeperSponsorInfo
