import React, { ReactElement, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, Col, Row } from "reactstrap"
import { Loader } from "semantic-ui-react"
import moment from "moment"

import Title from "components/Title"
import StatHeader from "components/StatHeader"
import LineCollapsableElement from "components/List/LineCollapsableElement"
import SystemUpdateCollapsible from "components/SystemUpdate/SystemUpdateCollapsible"
import DateInput from "components/Form/DateInput"
import { apiGetSystemUpdates, resetSystemUpdates, apiGetSystemStats } from "services/system"
import SystemUpdateLineItem from "components/SystemUpdate/SystemUpdateLineItem"

interface SystemMonitoringProps {
  apiGetSystemUpdates
  resetSystemUpdates
  apiGetSystemStats
  systemUpdates: any
}

export function SystemMonitoring({
  apiGetSystemUpdates,
  resetSystemUpdates,
  apiGetSystemStats,
  systemUpdates,
}: SystemMonitoringProps): ReactElement {
  const [pagination, setPagination] = useState({ take: 15, skip: 0 })
  const [collapseId, setCollapseId] = useState(null)
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
  const { moreToLoad, data, loading } = systemUpdates.list

  const fetchNext = async () => {
    // TODO: await not awaiting here
    await apiGetSystemUpdates(date, pagination.skip, pagination.take)
    // Add +15 to skip param
    setPagination({ ...pagination, skip: data.length + 15 })
  }

  const collapseElt = (_collapseId: string) => {
    setCollapseId(collapseId === null ? _collapseId : null)
  }

  const reset = () => {
    setPagination({ take: 15, skip: 0 })
    resetSystemUpdates()
  }

  const changeDate = event => {
    reset()
    setDate(event.target.value)
  }

  useEffect(() => {
    reset()
    apiGetSystemStats()
  }, [])

  return (
    <div style={{ flex: 1, padding: 10 }}>
      <div style={{ padding: 30 }}>
        <Title>🛰 System Updates</Title>

        <div className="header-stats">
          <StatHeader title="Total" data={systemUpdates.stats.data.nb} />
          <StatHeader title="Moy. Exec time" data={`${systemUpdates.stats.data.executionTime / 1000}s`} />
        </div>

        <Row style={{ marginTop: "1em", alignItems: "flex-end" }}>
          <Col xs={2}>
            <DateInput label={"Au"} value={date} onChange={changeDate} />
          </Col>
          <Col xs={2}>
            <Button className="search-button" onClick={() => fetchNext()}>
              Chercher
            </Button>
          </Col>
        </Row>
      </div>

      {data.map(update => (
        <LineCollapsableElement
          key={`collapsible${update.id}`}
          collapseId={collapseId}
          collapseElt={collapseElt}
          element={update}
          lineHeight="lg"
          lineData={<SystemUpdateLineItem element={update} />}
          collapsibleData={<SystemUpdateCollapsible element={update} />}
        />
      ))}

      <br />
      {loading && <Loader active inline />}
      {moreToLoad && <Button onClick={fetchNext}>Charger les plus anciens</Button>}
    </div>
  )
}

const mapStateToProps = state => ({
  systemUpdates: state.systemUpdates,
})

const mapDispatchToProps = {
  apiGetSystemUpdates,
  resetSystemUpdates,
  apiGetSystemStats,
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemMonitoring)
