import React, { ReactElement } from "react"
import styled from "styled-components"
import { Button } from "semantic-ui-react"

const FlexContainer = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
  }
`

const Container = styled.div`
  &&& {
    width: 300px;
  }
`

export const SelectContainer = ({ children }: { children: ReactElement }) => (
  <FlexContainer>
    <Container>{children}</Container>
  </FlexContainer>
)

export const LoadMoreButton = styled(Button)`
  &&& {
    margin-top: 10px;
  }
`
