import React, { ReactElement } from "react"
import styled from "styled-components"

import { COLOR } from "utils/color"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 460px;
  background-color: white;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  z-index: 1;
`

const Main = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 35px;
`

export const DetailSearchLogContainer = ({ children }: { children: ReactElement }): ReactElement => {
  return (
    <Main>
      <Container>{children}</Container>
    </Main>
  )
}

export const ListContainer = styled.div`
  overflow-y: auto;
  font-size: 12px;
  margin-right: -10px;
  padding-right: 10px;

  &&::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &&::-webkit-scrollbar {
    width: 6px;
    border-radius: 50px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50px;
  }
`

export const LineContainer = styled.p`
  height: 27px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`

export const NetworkNameContainer = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 60%;
`

export const RightElement = styled.span<{ bold?: boolean }>`
  margin-left: auto;
  margin-right: 5px;
  ${({ bold }) => (bold ? "font-weight: 600;" : "")}
`

export const CloseElement = styled.p`
  &&& {
    margin-left: auto;
    cursor: pointer;
    color: ${COLOR.MEDIUM_SUPER_LIGHT_GREY};
  }
`

export const DetailSearchKeeperLogTitle = styled.p`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0px;
`

export const DetailSearchKeeperLogSubTitle = styled.p`
  font-size: 11px;
  font-weight: 700;
`

export const Break = styled.div`
  border: 1px solid #e8e8e8;
  margin: 10px 0px 15px 0px;
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 460px;
  background-color: white;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  z-index: 1;
  justify-content: center;
  align-items: center;
`

export const DetailSearchLogLoaderContainer = ({ children }: { children: ReactElement }): ReactElement => {
  return (
    <Main>
      <LoaderContainer>{children}</LoaderContainer>
    </Main>
  )
}
