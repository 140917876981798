import React, { ReactElement, useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { Button, Input } from "semantic-ui-react"
import moment from "moment"

import { PageWrapper } from "components/Layout/Structure"
import Title from "components/Title"
import Select from "components/Form/Select"
import DateInput from "components/Form/DateInput"
import FilterBarZipcodes from "components/FilterBarZipcodes/FilterBarZipcodes"
import LineCollapsableElement from "components/List/LineCollapsableElement"
import SearchKeeperLogLineItem from "components/SearchKeeperMonitoring/SearchKeeperLogLineItem"
import { FilterChip, FiltersContainer } from "components/Chips/FilterChip"
import WebServiceMonitoringHeader from "components/SearchKeeperMonitoring/WebServiceMonitoringHeader"
import { LowForm, TopForm, Tabs } from "components/SearchKeeperMonitoring/SearchKeeperEndPointMonitoring.styled"
import SearchKeeperMonitoringMap from "modules/Monitoring/SearchKeeperMonitoring/SearchKeeperMonitoringMap"
import { apiGetCompanies } from "services/companies"
import {
  apiReturningSearchKeeperEndpointLogs,
  apiGetCSVAnalysedSearchLog,
  resetSearchKeeperLogs,
} from "services/search-keepers-monitoring"
import { apiGetGeneratedOrders } from "services/generated-orders"
import { apiGetCompetitors, resetCompetitors } from "services/competitors"
import { CarriersName } from "types/carrier.types"
import { ReduxState } from "types/reduxState.types"
import generateCompaniesOptions from "utils/formik/generateCompaniesOptions"
import { fullHoursOptions } from "utils/formik/hoursOptions"

export default function ListEndpointCallingLogs(): ReactElement {
  const [startDay, setStartDay] = useState(moment().format("YYYY-MM-DD"))
  const [endDay, setEndDay] = useState(moment().format("YYYY-MM-DD"))
  const [startRange, setStartRange] = useState(fullHoursOptions[0])
  const [endRange, setEndRange] = useState(fullHoursOptions[fullHoursOptions.length - 1])
  const [company, setCompany] = useState(null)
  const [zipCodes, setZipCodes] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [radiusFilter, setRadiusFilter] = useState(null)

  const { data, loading } = useSelector((state: ReduxState) => state.searchKeeperLogs.stats)
  const { data: generatedOrdersData } = useSelector((state: ReduxState) => state.generatedOrders.stats)
  const companies = useSelector((state: ReduxState) => state.companies)
  const dispatch = useDispatch()

  const LIST_ENABLED = 1
  const MAP_ENABLED = 0

  const _onChangeCompany = target => {
    setCompany(target)
  }

  const _onSubmit = async () => {
    if (isSubmittable()) {
      await search()
    }
  }

  const isSubmittable = (): boolean => {
    return !!company && zipCodes.length !== 0
  }

  const search = async () => {
    const startingDate = new Date(startDay)
    const [startingHours, startingMinutes, startingSeconds] = startRange.value.split(":")
    startingDate.setHours(Number(startingHours), Number(startingMinutes), Number(startingSeconds))

    const endingDate = new Date(endDay)
    const [endingHours, endingMinutes, endingSeconds] = endRange.value.split(":")
    endingDate.setHours(Number(endingHours), Number(endingMinutes), Number(endingSeconds))

    await dispatch(resetSearchKeeperLogs())

    const {
      payload: {
        // @ts-ignore
        data: {
          company: { name: companyName },
        },
      },
    } = await dispatch(
      apiReturningSearchKeeperEndpointLogs(
        startingDate,
        endingDate,
        company.value,
        zipCodes.map(code => code.value),
      ),
    )

    // Check company before getting CSV
    if (companyName === CarriersName.COLISSIMO) {
      await dispatch(
        apiGetCSVAnalysedSearchLog(
          startingDate,
          endingDate,
          company.value,
          zipCodes.map(code => code.value),
        ),
      )
    }

    await dispatch(apiGetGeneratedOrders(company.value, startingDate, endingDate))
  }

  const getLogsWithinRadius = () => {
    if (radiusFilter) return data.filter(log => Number(log.shortestDistance) <= Number(radiusFilter))
    return data
  }

  // init
  useEffect(() => {
    dispatch(resetSearchKeeperLogs())
    dispatch(resetCompetitors())
    if (companies.list.data.length === 0) {
      dispatch(apiGetCompanies())
    }
    dispatch(apiGetCompetitors())
  }, [])

  const onSetStartRange = hoursOption => {
    setStartRange(hoursOption)
  }
  const onSetEndRange = hoursOption => {
    setEndRange(hoursOption)
  }
  const _onChangeZipCodes = zipCodes => {
    setZipCodes(zipCodes)
  }
  // reset all search Keeper Logs data when the form changes
  const resetDataOnChange = async (value, onChangeFunction) => {
    if (data) {
      await dispatch(resetSearchKeeperLogs())
      setRadiusFilter(null)
    }
    onChangeFunction(value)
  }

  return (
    <PageWrapper>
      <Title>🔍 Appels du Webservice de recherche de Keepers</Title>
      <TopForm>
        <Col xs={4}>
          <Select
            value={company}
            onChange={value => resetDataOnChange(value, _onChangeCompany)}
            options={generateCompaniesOptions(companies.list.data)}
            placeholder="Choisir Transporteur"
            menuPosition="fixed"
            label="Transporteur"
          />
        </Col>

        <Col xs={2}>
          <DateInput
            label={"Date de début"}
            value={startDay}
            onChange={event => resetDataOnChange(event.target.value, setStartDay)}
          />
        </Col>
        <Col xs={2}>
          <DateInput
            label={"Date de fin"}
            value={endDay}
            onChange={event => resetDataOnChange(event.target.value, setEndDay)}
          />
        </Col>
        <Col xs={2}>
          <Select
            value={startRange}
            onChange={value => resetDataOnChange(value, onSetStartRange)}
            options={fullHoursOptions}
            placeholder="Début du créneau"
            label="Début du créneau"
          />
        </Col>
        <Col xs={2}>
          <Select
            value={endRange}
            onChange={value => resetDataOnChange(value, onSetEndRange)}
            options={fullHoursOptions}
            placeholder="Fin du créneau"
            label="Fin du créneau"
          />
        </Col>
      </TopForm>
      <LowForm>
        <Col xs={12}>
          <FilterBarZipcodes onChangeZipcodes={value => resetDataOnChange(value, _onChangeZipCodes)} />
        </Col>
      </LowForm>
      <Row>
        <Col xs={4}>
          <Button className="search-button" disabled={!isSubmittable()} onClick={_onSubmit}>
            Chercher
          </Button>
        </Col>
      </Row>
      {!loading && Array.isArray(data) && (
        <Tabs>
          <FiltersContainer>
            <FilterChip text="Carte" active={activeTab === MAP_ENABLED} onClick={() => setActiveTab(MAP_ENABLED)} />
            <FilterChip text="Liste" active={activeTab === LIST_ENABLED} onClick={() => setActiveTab(LIST_ENABLED)} />
          </FiltersContainer>
        </Tabs>
      )}
      <>
        {!loading && Array.isArray(data) && (
          <WebServiceMonitoringHeader
            company={company}
            startDay={startDay}
            endDay={endDay}
            startRange={startRange}
            endRange={endRange}
            logs={getLogsWithinRadius()}
            setRadiusFilter={setRadiusFilter}
          />
        )}
        {!loading && Array.isArray(data) && activeTab === LIST_ENABLED && (
          <>
            {getLogsWithinRadius().map(log => {
              const displayableLog = { ...log, logs: [] }
              return (
                <LineCollapsableElement
                  key={`collapsible${log.id}`}
                  collapseElt={() => {}}
                  element={displayableLog}
                  lineHeight="lg"
                  lineData={<SearchKeeperLogLineItem log={displayableLog} />}
                />
              )
            })}{" "}
          </>
        )}
        {!loading && Array.isArray(data) && Array.isArray(generatedOrdersData) && activeTab === MAP_ENABLED && (
          <SearchKeeperMonitoringMap
            // Will only display logs with Keepers within the radiusFilter
            logs={getLogsWithinRadius()}
            generatedOrders={generatedOrdersData}
          />
        )}
      </>
    </PageWrapper>
  )
}
