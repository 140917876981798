import { SearchAddressesData, SearchKeepersData, SearchParcelsData } from "services/graphql/queries/admin.queries"
import { SystemType } from "types/system.types"

export type SearchResult = {
  value: string
  label: string
  type: SystemType
  icon: string
  title: string
  description: string
}

export function mapSearchParcelsResult(data: SearchParcelsData, maxResultsByType = 5): SearchResult[] {
  const results = data?.searchParcels

  if (!results) return []

  return results.slice(0, maxResultsByType).map(p => ({
    value: p.id,
    label: `${p.archived ? "" : `📦`} ${p.parcelNo} ${p.archived ? "(Colis archivé)" : ""}`,
    type: SystemType.PARCEL,
    icon: "📦",
    title: p.parcelNo,
    description: `Pour: ${p.order.recipient.firstName} ${p.order.recipient.lastName}`,
  }))
}

export function mapSearchKeepersResult(data: SearchKeepersData, maxResultsByType = 5): SearchResult[] {
  const results = data?.searchKeepers

  if (!results) return []

  return results.slice(0, maxResultsByType).map(k => ({
    value: k.id,
    label: `👤 ${k.firstName} ${k.lastName} (${k.email})`,
    type: SystemType.KEEPER,
    icon: "👤",
    title: `${k.firstName} ${k.lastName}`,
    description: `${k.email}`,
  }))
}

export function mapSearchAddressesResult(data: SearchAddressesData, maxResultsByType = 5): SearchResult[] {
  const results = data?.searchAddresses

  if (!results) return []

  return results.slice(0, maxResultsByType).map(ad => ({
    value: ad.keeper.id,
    label: `📍 ${ad.streetLine} ${ad.zipcode} ${ad.city} (${ad.keeper.firstName} ${ad.keeper.lastName})`,
    type: SystemType.ADDRESS,
    icon: "📍",
    title: `${ad.streetLine} ${ad.zipcode} ${ad.city}`,
    description: `${ad.keeper.firstName} ${ad.keeper.lastName}`,
  }))
}
