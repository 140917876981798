import React, { ReactElement, useState } from "react"
import { Popup } from "semantic-ui-react"

import {
  KeeperAddress,
  KeeperAddressTitle,
  KeeperAddressLine,
  PopupCopyAddressID,
} from "components/Keeper/Address/KeeperAddressBlock.styled"
import AddressStatusShip from "components/Keeper/Address/status/AddressStatusShip"
import AddressInfosBlock from "components/Address/AddressInfosBlock"
import { Emoji } from "components/Emoji/Emoji"
import { Address } from "types/address.types"

interface KeeperAddressBlockProps {
  address: Address
}

function KeeperAddressBlock({ address }: KeeperAddressBlockProps): ReactElement {
  const [isIDCopied, setIsIDCopied] = useState<boolean>(false)

  const copyAddressID = id => {
    setIsIDCopied(true)
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(id)

    setTimeout(() => {
      setIsIDCopied(false)
    }, 1000)
  }

  return (
    <KeeperAddress>
      <KeeperAddressTitle>
        <Emoji label="letter box">📪</Emoji> Adresse
      </KeeperAddressTitle>
      <AddressStatusShip status={address.status} />

      <Popup
        content={isIDCopied ? "Copié ✅" : "Clic pour copier"}
        size="small"
        position="bottom center"
        trigger={<PopupCopyAddressID onClick={() => copyAddressID(address.id)}>{address.id}</PopupCopyAddressID>}
      />

      <br />
      <KeeperAddressLine>
        {address.streetLine}, {address.zipcode} {address.city}
      </KeeperAddressLine>
      <AddressInfosBlock address={address} withNbColis />
    </KeeperAddress>
  )
}

export default KeeperAddressBlock
