import React, { ReactElement, useEffect, useState } from "react"
import MapGL from "react-map-gl"
import { Col } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { CSVLink } from "react-csv"

import MAPBOX_TOKEN from "config/MAPBOX_TOKEN"
import { createDisplayableLogs } from "components/SearchKeeperMonitoring/utils/createDisplayableLogs.util"
import { createOptions, PickColor, PinCheckboxes } from "components/SearchKeeperMonitoring/PinColorsCheckboxes"
import { displayableGreenPinsReducer } from "components/SearchKeeperMonitoring/utils/createDisplayableLogsReducer.util"
import { getAllSelectedCompetitorsPickupPoints } from "components/Competitors/Competitors.util"
import DetailSearchKeeperLog from "components/SearchKeeperMonitoring/DetailSearchKeeperLog"
import { CSVContainer, CSVButton } from "components/SearchKeeperMonitoring/SearchKeeperMonitoring.styled"
import { LowForm, TopForm } from "components/SearchKeeperMonitoring/SearchKeeperEndPointMonitoring.styled"
import SearchKeeperLogMarkers from "modules/Monitoring/SearchKeeperMonitoring/SearchKeeperLogMarkers"
import { resetReturnedAddresses } from "services/returned-addresses"
import { resetSearchKeeperLogsFocus } from "services/search-keepers-monitoring"
import { ReduxState } from "types/reduxState.types"
import { SearchKeeperMonitoringMapProps } from "types/search-keeper-log.types"

const csvColNames = [
  "Date",
  "Date estimée de livraison",
  "Adresse recherchée",
  "Latitude",
  "Longitude",
  "Code postal",
  "Nombre de Keepers renvoyés",
  "Keeper le plus proche (en m)",
  "Point1 Reseau",
  "Point1 Distance (m)",
  "Point2 Reseau",
  "Point2 Distance (m)",
  "Point3 Reseau",
  "Point3 Distance (m)",
]

export default function SearchKeeperMonitoringMap({
  logs,
  generatedOrders,
}: SearchKeeperMonitoringMapProps): ReactElement {
  const [pickColors, setPickColors] = useState(createOptions())
  const [selectedLogs, setSelectedLogs] = useState(
    createDisplayableLogs(
      logs,
      pickColors.map(color => color.value),
    ),
  )
  const [viewport, setViewPort] = useState({
    latitude: 46,
    longitude: 2,
    zoom: 5,
    bearing: 0,
    pitch: 0,
  })
  const [displayDetailSearchKeeperLog, setDisplayDetailSearchKeeperLog] = useState(false)

  const competitors = useSelector((state: ReduxState) => state.competitors)
  const searchKeeperLogDetail = useSelector((state: ReduxState) => state.searchKeeperLogs.focus)
  const searchLogsCSV = useSelector((state: ReduxState) => state.searchKeeperLogs.csv)
  const dispatch = useDispatch()

  const onActiveColorClick = async (enabled: boolean, colorValue: PickColor) => {
    const selectedColor = pickColors.find(color => color.value === colorValue)
    selectedColor.enabled = enabled

    const newPickColors = pickColors.map(color => {
      if (color.value === colorValue) {
        return selectedColor
      }
      return color
    })

    setPickColors(newPickColors)
    const colors = newPickColors.filter(color => color.enabled).map(color => color.value)

    // if orange pins are not display, make blue pins disappears
    if (!colors.includes(PickColor.ORANGE_PICK)) {
      await dispatch(resetReturnedAddresses())
    }
    setSelectedLogs(createDisplayableLogs(logs, colors))
  }

  // init
  useEffect(() => {
    dispatch(resetSearchKeeperLogsFocus())
    dispatch(resetReturnedAddresses())
    // to update pins when radiusFilter changes
    setSelectedLogs(
      createDisplayableLogs(
        logs,
        pickColors.map(color => color.value),
      ),
    )
    setDisplayDetailSearchKeeperLog(false)
  }, [logs])

  // MAP
  const _getCursor = ({ isHovering }) => {
    return isHovering ? "pointer" : "default"
  }
  const _onViewportChange = viewport => setViewPort(viewport)

  const onCloseDetailSearchKeeperLog = async () => {
    setDisplayDetailSearchKeeperLog(false)
    await dispatch(resetSearchKeeperLogsFocus())
    await dispatch(resetReturnedAddresses())
  }

  return (
    <>
      <TopForm>
        <Col xs={9}>
          <PinCheckboxes pickColors={pickColors} onActiveColorClick={onActiveColorClick} />
        </Col>
        <CSVContainer xs={3}>
          {!searchLogsCSV.loading && searchLogsCSV.data && searchLogsCSV.data.length > 0 && (
            <CSVButton>
              <CSVLink
                data={[csvColNames, ...searchLogsCSV.data]}
                enclosingCharacter=""
                filename={"extract-search-keeper-logs.csv"}
                target="_blank"
              >
                Extraire toutes les data
              </CSVLink>
            </CSVButton>
          )}
        </CSVContainer>
      </TopForm>
      <LowForm>
        {displayDetailSearchKeeperLog && (
          <DetailSearchKeeperLog
            loading={searchKeeperLogDetail.loading}
            log={searchKeeperLogDetail.data}
            onClose={onCloseDetailSearchKeeperLog}
          />
        )}
        <MapGL
          {...viewport}
          width="100%"
          height="100%"
          style={{ minHeight: 500 }}
          clickRadius={2}
          getCursor={_getCursor}
          interactiveLayerIds={[]}
          onViewportChange={_onViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          reuseMaps
        >
          <SearchKeeperLogMarkers
            logs={selectedLogs}
            generatedOrders={generatedOrders.reduce(displayableGreenPinsReducer, [])}
            pinsToDisplay={pickColors.filter(color => color.enabled).map(color => color.value)}
            competitorsPickupPoints={getAllSelectedCompetitorsPickupPoints(competitors, viewport)}
            displayDetailSearchKeeperLog={displayDetailSearchKeeperLog}
            setDisplayDetailSearchKeeperLog={setDisplayDetailSearchKeeperLog}
          />
        </MapGL>
      </LowForm>
    </>
  )
}
