import React from "react"
import { Button, Header, Icon, Modal } from "semantic-ui-react"
import { ModalContent } from "./ConfirmRevertTransferModal.styled"

interface ConfirmRevertTransferModalProps {
  title?: string
  subtitle?: string
  isOpened: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ConfirmRevertTransferModal = ({
  title = "🇫🇷 Êtes vous sur ?",
  subtitle = "Nous enverrons une notification push et retournerons le questionnaire au keeper si vous confirmez.",
  isOpened,
  onClose,
  onSubmit,
}: ConfirmRevertTransferModalProps) => {
  return (
    <Modal basic onClose={onClose} open={isOpened} size="small">
      <Header icon>
        <Icon name="attention" />
        {title}
      </Header>
      {subtitle && <ModalContent>{subtitle}</ModalContent>}
      <Modal.Actions>
        <Button basic color="red" inverted onClick={onClose}>
          <Icon name="remove" /> Non !
        </Button>
        <Button
          color="green"
          inverted
          onClick={async () => {
            onSubmit()
            onClose()
          }}
        >
          <Icon name="checkmark" /> Confirmer
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
