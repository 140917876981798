import React, { ReactElement, useState } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { Popup } from "semantic-ui-react"

import ParcelStatusChip from "components/Parcels/ParcelStatus"
import { CopyIcon, OrderType, TextEllipsis } from "components/Parcels/LineItemParcel.styled"
import { createParcelNoWithAdditionalInfo } from "components/Parcels/createParcelNoWithAdditionalInfo.util"
import { Parcel, ParcelFlag, ParcelNote } from "types/parcel.types"
import { displayParcelFlag } from "utils/displayParcelFlag"
import { COLOR } from "utils/color"

interface LineItemParcelProps {
  element: Parcel
}

const LineItemParcel = ({ element }: LineItemParcelProps): ReactElement => {
  const [phoneCopied, setPhoneCopied] = useState(false)
  const [parcelNoCopied, setParcelNoCopied] = useState(false)
  const address = element.order?.__address__ || element.order?.address
  const zipcodeParcel = address?.zipcode
  const keeper = address?.__keeper__ || address?.keeper
  const recipient = element.order?.recipient
  const orderType = element.order?.type
  const carrierDeliveryHubName = element.order?.carrierDeliveryHubName
  const companyAddress = carrierDeliveryHubName ? `${zipcodeParcel} - ${carrierDeliveryHubName}` : "Aucune"

  const notes = element?.__notes__ || element?.notes
  const latestNote: ParcelNote | undefined =
    notes?.length > 0
      ? // Spread array to avoid frozen array and errors
        [...notes].sort((a, b) => moment(b?.updatedAt).diff(moment(a?.updatedAt)))[0]
      : undefined

  const _copyPhone = (e, phone) => {
    e.preventDefault()

    setPhoneCopied(true)
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(phone)

    setTimeout(() => {
      setPhoneCopied(false)
    }, 1000)
  }

  const _copyParcelNo = (e, parcel: Parcel) => {
    e.preventDefault()

    const value = createParcelNoWithAdditionalInfo(parcel)

    setParcelNoCopied(true)
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(value)

    setTimeout(() => {
      setParcelNoCopied(false)
    }, 1000)
  }

  return (
    <Link to={`/parcels/${element.id}`}>
      <Row className="line-element-row">
        <Col xs={3}>
          <div className="line-element-info">
            <div className="content">
              📦{" "}
              <span
                style={{
                  fontWeight: 600,
                  color:
                    element.flag !== null
                      ? COLOR.DARK_RED
                      : element.archived
                      ? COLOR.MEDIUM_SUPER_LIGHT_GREY
                      : COLOR.DARK_BLUE,
                }}
              >
                {element.parcelNo}
              </span>
              {element.flag !== null && (
                <Popup
                  content={displayParcelFlag(element.flag)}
                  size="tiny"
                  position="bottom center"
                  trigger={<span style={{ marginLeft: 5 }}>🚩</span>}
                />
              )}
              <Popup
                content={parcelNoCopied ? "Copié ✅" : `Copier`}
                size="small"
                position="bottom center"
                trigger={
                  <span>
                    <CopyIcon name="copy outline" onClick={e => _copyParcelNo(e, element)} />
                  </span>
                }
              />
            </div>
          </div>
        </Col>
        <Col xs={1}>
          <div className="line-element-info">
            <div className="label">Agence</div>
            <TextEllipsis>{companyAddress}</TextEllipsis>
          </div>
        </Col>
        <Col xs={1}>
          <div className="line-element-info">
            <div className="label">Keeper</div>
            <TextEllipsis>
              <Popup
                content={phoneCopied ? "Copié ✅" : `${keeper?.phone}`}
                size="small"
                position="bottom center"
                trigger={
                  <span style={{ marginRight: "5px" }} onClick={e => _copyPhone(e, keeper?.phone)}>
                    📞
                  </span>
                }
              />
              {keeper?.firstName} {keeper?.lastName}{" "}
              {element.firstKeeperReception === true && (
                <Popup content="1ère réception Keeper" size="tiny" position="bottom center" trigger={<span>🆕</span>} />
              )}
              {element.outOfAvailabilities === true && (
                <Popup
                  content="Livraison hors dispo Keeper"
                  size="tiny"
                  position="bottom center"
                  trigger={<span>🔴</span>}
                />
              )}
            </TextEllipsis>
          </div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">Destinataire</div>
            <TextEllipsis>
              <Popup
                content={phoneCopied ? "Copié ✅" : `${recipient?.phone}`}
                size="small"
                position="bottom center"
                trigger={
                  <span style={{ marginRight: "5px" }} onClick={e => _copyPhone(e, recipient?.phone)}>
                    📞
                  </span>
                }
              />
              {recipient?.name || ""}{" "}
            </TextEllipsis>
          </div>
        </Col>
        {element.flag === ParcelFlag.RETURN_TO_ORDER ? (
          <Col xs={1}>
            <div className="line-element-info">
              <div className="label">Date de collecte</div>
              <TextEllipsis>{moment(element.updatedAt).format("DD/MM/YYYY")}</TextEllipsis>
            </div>
          </Col>
        ) : (
          <Col xs={1}>
            <div className="line-element-info">
              <div className="label">Dernière MAJ</div>
              <TextEllipsis>{moment(element.updatedAt).format("DD/MM/YYYY - HH:mm")}</TextEllipsis>
            </div>
          </Col>
        )}
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{latestNote !== undefined && `Note`}</div>
            {/* Put the popup possibility with whole text if it exceed 95 characters */}
            {latestNote !== undefined && latestNote?.text.length > 95 ? (
              <Popup
                content={latestNote?.text}
                size="tiny"
                position="bottom center"
                trigger={<div className="multiple-ellipsis">{latestNote !== undefined && latestNote?.text}</div>}
              />
            ) : (
              <div className="multiple-ellipsis">{latestNote !== undefined && latestNote?.text}</div>
            )}
          </div>
        </Col>

        <Col xs={2} style={{ display: "flex", justifyContent: "space-between" }}>
          {!element.archived && (
            <>
              <OrderType>🏷 {orderType}</OrderType>
              <ParcelStatusChip status={element.status} />
            </>
          )}
          {element.archived && <div>🗂 Archivé</div>}
        </Col>
      </Row>
    </Link>
  )
}

export default LineItemParcel
