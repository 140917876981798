import { Wallet } from "types/wallet.types"
import { WalletPayment } from "types/wallet-payment.types"
import { AdminUserRole } from "./admin-user.types"

export enum WalletEntryType {
  PENDING_RECEPTION = "PENDING_RECEPTION",
  RECEPTION = "RECEPTION",
  DELIVERY = "DELIVERY",
  RETURNING = "RETURNING",
  COMPENSATION = "COMPENSATION",
  REWARD = "REWARD",
  REWARD_FOR_RECEPTIONS_LVL1 = "REWARD_FOR_RECEPTIONS_LVL1",
  REWARD_FOR_RECEPTIONS_LVL2 = "REWARD_FOR_RECEPTIONS_LVL2",
  DROPOFF = "DROPOFF",
  RETURNING_BP = "RETURNING_BP",

  // SPONSORSHIP
  SPONSOR_FIRST_DELIVERY = "SPONSOR_FIRST_DELIVERY",
  SPONSORED_FIRST_DELIVERY = "SPONSORED_FIRST_DELIVERY",
  SPONSOR_DELIVERY_BONUS = "SPONSOR_DELIVERY_BONUS",

  // POC Keeper Delivery
  KEEPER_DELIVERY_SERVICE = "KEEPER_DELIVERY_SERVICE",
}

export enum WalletFeesType {
  STRIPE_FEES = "STRIPE_FEES",
}

export enum WalletEntryStatus {
  NEW = "NEW",
  PROCESSING = "PROCESSING",
  DONE = "DONE",
}

export interface WalletEntryProcessed {
  nbParcels: number
  amount: number
  type: WalletEntryType
  date: Date
}

export interface WalletEntry {
  id: string
  amount: number
  type: WalletEntryType
  paymentId: string
  payment: WalletPayment
  status: WalletEntryStatus
  walletId: string
  wallet: Wallet
  createdAt: Date
}

export const AUTHORIZED_ROLES_FOR_BILLING_MANAGEMENT = [
  AdminUserRole.ADMIN,
  AdminUserRole.CARE_SENIOR,
  AdminUserRole.CARE_MANAGER,
  AdminUserRole.HEAD_OF_CARE,
]

export const WalletEntryTypeKeeperAuthorized: WalletEntryType[] = [
  WalletEntryType.RECEPTION,
  WalletEntryType.DELIVERY,
  WalletEntryType.RETURNING,
  WalletEntryType.COMPENSATION,
  WalletEntryType.REWARD,
  WalletEntryType.DROPOFF,
  WalletEntryType.SPONSOR_FIRST_DELIVERY,
  WalletEntryType.SPONSORED_FIRST_DELIVERY,
  WalletEntryType.KEEPER_DELIVERY_SERVICE,
  WalletEntryType.RETURNING_BP,
]
