import React, { ReactElement, useEffect, useState } from "react"
import { Provider } from "react-redux"
import { Route, Switch, BrowserRouter } from "react-router-dom"
import { ApolloProvider } from "@apollo/client"
import moment from "moment"
import "moment/locale/fr"

import { PersistGate } from "redux-persist/integration/react"

import Login from "modules/Login"
import Home from "modules/Home"

import configureStore from "config/configureStore"
import history from "config/history"
import "styles/base.css"
import "styles/custom.css"
import "styles/pickme.css"
import "semantic-ui-css/semantic.min.css"
import "react-loading-skeleton/dist/skeleton.css"
import "react-medium-image-zoom/dist/styles.css"
import { apolloClient } from "services/graphql/apollo-client"

moment.locale("fr")

export default function App(): ReactElement {
  const [isReady, setReady] = useState(false)
  const { store, persistor } = configureStore()

  useEffect(() => {
    setReady(true)
  }, [])

  if (!isReady) return <></>

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history}>
            <Switch>
              <Route path="/login" exact component={Login} />
              <Route path="/" component={Home} />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  )
}
