import { gql } from "@apollo/client"

export const getOrderAppointment = gql`
  query getLatestActiveAppointment($orderId: String!) {
    getLatestActiveAppointment(orderId: $orderId) {
      mostRecentActiveAppointment {
        id
        order {
          id
        }
        status
        date
        createdAt
      }
      allAppointmentsCount
    }
  }
`
