import { API_LIST_APP_VERSION } from "actions/app-version"

export function getListAppVersions() {
  return {
    type: API_LIST_APP_VERSION,
    payload: {
      request: {
        url: `/appVersions`,
        method: "GET",
      },
    },
  }
}
