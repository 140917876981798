import React from "react"

const MainContainer = ({ children, style = {}, title = null, withBorder = false }) => {
  return (
    <div className={`main-container ${withBorder ? "with-border" : ""}`} style={style}>
      {title !== null && <div className="main-container-title">{title}</div>}
      {children}
    </div>
  )
}

export default MainContainer
