import React, { ReactElement } from "react"
import { Button, Checkbox, Input, Modal } from "semantic-ui-react"
import Select from "components/Form/Select"
import { AdminParcelEventReason, ParcelStatus } from "types/parcel.types"
import { AdminParcelEventType, ParcelAction } from "modules/Ops/Parcel/getParcelActions"
import { CheckboxWrapper } from "./ReturnRequestModal/RequestReturnModal.styled"
import { CarriersName } from "types/carrier.types"

interface AdminEventModalProps {
  adminEventModal: {
    opened: boolean
    type: AdminParcelEventType
    reason: { value: string; label: string }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any
  }
  submitAdminEvent: () => void
  cancelAdminEventModal: () => void
  parcelActions: ParcelAction[]
  changeReason: (reason: string) => void
  changeReassignStatus: (reassignStatus: ParcelStatus) => void
  changeDetails: (details: string) => void
  changeIsAlreadyCancelled: (isAlreadyCancelled: boolean) => void
  isAlreadyCancelled: boolean
  returningCarrierName: CarriersName
}

const AdminEventModal = ({
  adminEventModal,
  submitAdminEvent,
  cancelAdminEventModal,
  parcelActions,
  changeReason,
  changeReassignStatus,
  changeDetails,
  changeIsAlreadyCancelled,
  isAlreadyCancelled,
  returningCarrierName,
}: AdminEventModalProps): ReactElement => {
  const { data } = adminEventModal
  const parcelAction = parcelActions.find(i => i.value === adminEventModal.type)

  /**
   * Cancel modal : reset local states
   */
  const _cancelAdminEventModal = () => {
    cancelAdminEventModal()
  }

  /**
   * Submit modal : reset local states
   */
  const _submitAdminEvent = () => {
    submitAdminEvent()
  }

  const getModalTitle = (): string => {
    switch (adminEventModal.type) {
      case AdminParcelEventType.REMOVE_FLAG:
        return "Vous allez retirer le flag sur ce colis"
      default:
        return ""
    }
  }

  const title = getModalTitle()

  return (
    <Modal open={adminEventModal.opened}>
      <Modal.Header>En êtes vous sûr ?</Modal.Header>
      <Modal.Content>
        {title}

        {adminEventModal.type === AdminParcelEventType.CANCEL_RETURN_REQUESTED &&
          returningCarrierName === CarriersName.TUT_TUT && (
            <CheckboxWrapper>
              <Checkbox
                label="La commande a déjà été annulée"
                onClick={() => changeIsAlreadyCancelled(!isAlreadyCancelled)}
                checked={isAlreadyCancelled}
              />
            </CheckboxWrapper>
          )}

        {parcelAction?.options.length > 0 && !isAlreadyCancelled && (
          <Select
            value={adminEventModal.reason}
            onChange={changeReason}
            options={parcelAction?.options}
            placeholder="Que s'est il passé ?"
          />
        )}

        {adminEventModal.reason?.value === AdminParcelEventReason.DELIVERED_TO_PREVIOUSLY_ASSIGNED_KEEPER && (
          <div style={{ marginTop: 20 }}>
            <Select
              value={
                !!data.newParcelStatus
                  ? {
                      value: data.newParcelStatus,
                      label: parcelAction?.secondOptions.find(i => i.value === data.newParcelStatus)?.label,
                    }
                  : null
              }
              onChange={e => changeReassignStatus(e.value)}
              options={parcelAction?.secondOptions}
              placeholder="Dans quel statut voulez vous réassigner le colis ?"
            />
          </div>
        )}

        {adminEventModal.reason?.value === AdminParcelEventReason.OTHER && (
          <Input
            fluid
            placeholder="Préciser"
            style={{ marginTop: "1em" }}
            required
            onChange={e => changeDetails(e.target.value)}
            className="inputText"
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => _cancelAdminEventModal()}>
          Annuler
        </Button>
        <Button
          content="Valider"
          disabled={!adminEventModal.type}
          labelPosition="right"
          icon="checkmark"
          onClick={() => _submitAdminEvent()}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export default AdminEventModal
