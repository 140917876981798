import React, { forwardRef, ReactElement, useEffect, useMemo, useState } from "react"

import { HorizontalCalendar } from "../HorizontalCalendar/HorizontalCalendar"
import { filterInvalidAvailabilities } from "../utils/agenda"
import { DailyAvailabilitiesSelector } from "../DailyAvailabilitiesSelector/DailyAvailabilitiesSelector"
import { AddressAvailability } from "types/agenda.types"
import moment from "moment"
import { Wrapper } from "./AgendaSelector.styled"

interface AgendaSelectorProps {
  agenda: AddressAvailability[]
  onAvailabilitiesUpdated: (availabilities?: AddressAvailability[]) => void
}

function AgendaSelectorComponent(
  { agenda, onAvailabilitiesUpdated }: AgendaSelectorProps,
  ref: React.MutableRefObject<any>,
): ReactElement {
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [liveAvailabilities, setLiveAvailabilities] = useState<AddressAvailability[]>([])

  useEffect(() => {
    setLiveAvailabilities(filterInvalidAvailabilities(agenda))
  }, [agenda])

  /**
   * When a date is selected, we get the availabilities for this day in the live availabilities
   */
  const liveAvailabilitiesForDate: AddressAvailability[] = useMemo(() => {
    return liveAvailabilities.filter(({ date }) => date === moment(selectedDate).format("YYYY-MM-DD"))
  }, [liveAvailabilities, selectedDate])

  const daysWithAtLeastOneAvailability = useMemo(() => {
    return [...new Set(liveAvailabilities.map(({ date }) => date))]
  }, [liveAvailabilities])

  return (
    <Wrapper>
      <HorizontalCalendar
        onDateSelected={setSelectedDate}
        daysWithAtLeastOneAvailability={daysWithAtLeastOneAvailability}
        startDate={moment().startOf("day").toDate()}
      />
      {liveAvailabilitiesForDate && <DailyAvailabilitiesSelector dayAvailabilities={liveAvailabilitiesForDate} />}
    </Wrapper>
  )
}

export const AgendaSelector = forwardRef(AgendaSelectorComponent)
