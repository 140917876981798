import React, { ReactElement } from "react"
import styled from "styled-components"

const NumberWrapper = styled.div`
  font-style: italic;
`

export interface NumberOfAddressProps {
  number: number
}

export default function NumberOfAddress({ number }: NumberOfAddressProps): ReactElement {
  if (number <= 1) {
    return <></>
  }
  return <NumberWrapper>({number})</NumberWrapper>
}
