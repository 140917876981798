import React, { ReactElement } from "react"
import styled from "styled-components"

import { COLOR } from "utils/color"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: row;
`

export const TouchablePagination = styled.div<{ selected: boolean }>(
  ({ selected }) => `
  color: ${selected ? COLOR.WHITE : COLOR.DARK_GREY};
  background: ${selected ? COLOR.CORAIL : COLOR.SUPER_LIGHT_GREY};
  margin-right: 5px;
  border-radius: 10px;
  cursor: pointer;

  padding: 5px;
  width: 40px;
  text-align: center;
`,
)

interface PaginationProps {
  page: number
  totalPages: number
  onPress: (page: number) => void
}

export function Pagination({ page, totalPages, onPress }: PaginationProps): ReactElement {
  const onClickPage = (e, page: number) => {
    e.preventDefault()
    onPress(page)
  }

  function pageNumbers(count, current) {
    const pages = []

    if (current > 0) pages.push(0)
    if (current > 2) pages.push("...")
    if (current > 1 && current - 2 !== 0) pages.push(current - 2)
    if (current > 1 && current !== 0) pages.push(current - 1)
    pages.push(current)
    if (current < count) pages.push(current + 1)
    if (current < count - 1) pages.push(current + 2)
    if (current < count - 2) pages.push("...")
    if (current < count - 2) pages.push(count)

    return pages
  }

  const t = pageNumbers(totalPages - 1, page)

  return (
    <Container>
      {t.map((e, i) => {
        return (
          <TouchablePagination
            key={i}
            selected={e === page && e !== "..."}
            onClick={event => e !== "..." && onClickPage(event, e)}
          >
            {e}
          </TouchablePagination>
        )
      })}
    </Container>
  )
}
