import React, { ReactElement } from "react"
import { Col } from "reactstrap"
import LineElementContent from "components/Table/LineElementContent"
import LineElementInfo from "components/Table/LineElementInfo"
import LineElementLabel from "components/Table/LineElementLabel"

interface LineElementColProps {
  size: number
  label?: string
  content: string | number | ReactElement
  width?: string // Width in %
}

export default function LineElementCol({ size, label, content, width }: LineElementColProps): ReactElement {
  return (
    <Col
      style={{ width: width ?? "unset", paddingRight: "0.5em", paddingLeft: "0.5em" }}
      xs={width ? "unset" : size || 2}
    >
      <LineElementInfo>
        {!!label && <LineElementLabel>{label}</LineElementLabel>}
        <LineElementContent>{content}</LineElementContent>
      </LineElementInfo>
    </Col>
  )
}
