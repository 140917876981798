import styled from "styled-components"

import { COLOR } from "utils/color"

export const ParcelHeaderContainer = styled.div`
  width: 100%;
  background-color: ${COLOR.WHITE};
  display: flex;
  padding: 60px 40px 40px 40px;
  flex-direction: column;
  min-height: 200px;
  border-bottom: 1px solid ${COLOR.WHITE_SMOKE};
`

export const ParcelPartners = styled.div`
  display: flex;
  flex-direction: row;
`

export const ParcelInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`
export const ParcelInfosTitle = styled.div`
  font-size: 3em;
  font-weight: 600;
`

export const ParcelKeeperContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`
export const ParcelKeeperTitle = styled.div`
  display: flex;
  font-size: 1.5em;
  margin-bottom: 1em;
`
export const ParcelKeeperIconInfo = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
`
export const ParcelKeeperName = styled.div`
  margin-bottom: 0.2em;
  font-weight: 600;
  font-size: 1.2em;
`

export const ParcelRecipientContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`
export const ParcelRecipientTitle = styled.div`
  font-size: 1.5em;
  margin-bottom: 1em;
`

export const ParcelRecipientName = styled.div`
  margin-bottom: 1em;
  font-weight: 600;
`
