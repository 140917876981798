import styled from "styled-components"
import { COLOR } from "utils/color"
import { Row } from "reactstrap"
import { Button, Icon, Input, Modal, TextArea } from "semantic-ui-react"

export const CheckboxWrapper = styled.div`
  padding-top: 5px;
  margin-bottom: 19px;
`

export const Section = styled.div`
  margin-top: 30px;
`

export const FieldsRow = styled(Row)`
  display: flex;
  width: 100%;
`

export const SectionTitle = styled.p`
  margin-bottom: 5px;
  font-family: Poppins;
  font-weight: 600;
  color: ${COLOR.DARK_GREY};
`

export const CollectionCarrierDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`

export const CollectionCarrierDetailsHelpUrl = styled.div`
  font-size: 12px;
  color: ${COLOR.BLACK};
  padding: 15px;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 20px;

  &:hover {
    color: ${COLOR.MEDIUM_GREY};
  }
`

export const StyledInput = styled(Input)`
  padding: 0;
  height: 34px;
`

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  padding: 0.65em 1em !important;
  font-family: Poppins;
  outline: 0;
  line-height: 1.21428571em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition:
    box-shadow 0.1s ease,
    border-color 0.1s ease;

  &:focus {
    border-color: #85b7d9;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
`

export const StyledLabelInput = styled.div`
  font-size: 10px;
  color: ${COLOR.MEDIUM_GREY};
  margin: 10px 0 8px 0;
`

export const ReturnRequestModalActions = styled(Modal.Actions)`
  border-top: 0 !important;
  padding: 20px 70px !important;
  background-color: #f8f9fa;
`

export const ReturnRequestModalButton = styled(Button)`
  font-family: Poppins !important;
  border-radius: 3px !important;
  background-color: ${COLOR.BLACK} !important;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.9;
  }
`

export const ReturnRequestModalButtonActive = styled(ReturnRequestModalButton)`
  background-color: ${COLOR.BRIGHT_MEDIUM_GREEN} !important;
`

export const CheckIcon = styled(Icon)`
  margin: 0 0 0 15px !important;
`
