import React, { ReactElement } from "react"

import {
  Container,
  Data,
  GroupTitle,
  Subtitle,
  Title,
} from "components/SearchKeeperMonitoring/AnalyzedPickupPoints.styled"
import { Emoji } from "components/Emoji/Emoji"
import KeeperStatus from "components/Keeper/KeeperStatus"
import { KeeperPickupPointsProps } from "types/keeper.types"
import { displayDistance } from "utils/distance.util"

function KeeperPickupPoints({ keeper, address, distance }: KeeperPickupPointsProps): ReactElement {
  return (
    <Container>
      <Title>
        {keeper.firstName} {keeper.lastName}
      </Title>
      <Subtitle>{address.formattedLine}</Subtitle>

      <GroupTitle>
        <Emoji label="parcel">📦</Emoji> Keeper
      </GroupTitle>
      <KeeperStatus status={keeper.status} />

      {typeof distance === "number" && (
        <>
          <GroupTitle>
            <Emoji label="ruler">📏</Emoji> Distance au point
          </GroupTitle>
          <Data>{displayDistance(distance * 1000)}</Data>
        </>
      )}
    </Container>
  )
}

export default KeeperPickupPoints
