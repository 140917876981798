import React, { ReactElement } from "react"
import Skeleton from "react-loading-skeleton"
import { ParcelInfosContainer } from "components/Parcels/ParcelInfos.styled"

const ParcelInfosSkeleton = (): ReactElement => {
  return (
    <ParcelInfosContainer>
      <Skeleton width={"350px"} height={"40px"} />
      <Skeleton width={"150px"} height={"20px"} style={{ marginTop: 10 }} />
      <Skeleton width={"150px"} height={"25px"} style={{ marginTop: 10 }} />
      <Skeleton width={"200px"} height={"20px"} style={{ marginTop: 40 }} />

      <Skeleton width={"80px"} height={"15px"} style={{ marginTop: 5 }} />
      <Skeleton width={"125px"} height={"15px"} style={{ marginTop: 5 }} />

      <Skeleton width={"200px"} height={"20px"} style={{ marginTop: 15 }} />
      <Skeleton width={"200px"} height={"20px"} />
      <Skeleton width={"200px"} height={"20px"} />

      <Skeleton width={"200px"} height={"20px"} style={{ marginTop: 120 }} />
      <Skeleton width={"200px"} height={"20px"} />
    </ParcelInfosContainer>
  )
}

export default ParcelInfosSkeleton
