import { ReassignmentLog } from "types/reassignment-log.types"
import { API_GET_REASSIGNMENT_LOGS, RESET_REASSIGNMENT_LOGS } from "actions/reassignment-logs"
import { FctServiceReturn } from "services/services.types"

export function apiGetReassignmentLogs(nbResults: number, offset: string = null): FctServiceReturn<ReassignmentLog[]> {
  return {
    type: API_GET_REASSIGNMENT_LOGS,
    payload: {
      request: {
        url: `/admin/reassignmentLogs?first=${nbResults}${offset ? `&after=${offset}` : ""}`,
      },
    },
  }
}

export function resetReassignmentLogs(): FctServiceReturn<any> {
  return {
    type: RESET_REASSIGNMENT_LOGS,
  }
}
