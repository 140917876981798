export enum IntegrationEvent {
  SYNC = "SYNC",
  KEEPERS_AVAILABILITIES = "KEEPERS_AVAILABILITIES",
  KEEPERS_CAPACITY = "KEEPERS_CAPACITY",
  GET_PARCEL_EVENTS = "GET_PARCEL_EVENTS",
  PUSH_PARCEL_EVENTS = "PUSH_PARCEL_EVENTS",
}

export enum IntegrationInterface {
  SFTP = "SFTP",
  HTTP = "HTTP",
  WEBHOOK = "WEBHOOK",
}

export type IntegrationLog = {
  id: string
  action: string
  details: string
  status: "SUCCESS" | "ERROR"
  startDate: string
}

export type IntegrationUpdate = {
  id: string
  startDate: string
  event: IntegrationEvent
  interface: IntegrationInterface
  companyId: string
  executionTime: number
  logs: IntegrationLog[]
}

/**
 * SYSTEM
 */

export enum SystemEventMetaEmitter {
  KEEPER = "KEEPER",
  CARRIER = "CARRIER",
  RETAILER = "RETAILER",
  ADMIN = "ADMIN",
  SYSTEM = "SYSTEM",
  FINAL_CLIENT = "FINAL_CLIENT",
}

export enum SystemEventMetaInterface {
  WEBHOOK = "WEBHOOK",
  SFTP = "SFTP",
  PACMAN = "PACMAN",
  KEEPERAPP = "KEEPERAPP",
  CRON = "CRON",
  CTOB_PLATFORM = "CTOB_PLATFORM",
}

export enum SystemEventMetaAction {
  PARCELS_UPDATES = "PARCELS_UPDATES",
  NEW_ORDERS = "NEW_ORDERS",
  KEEPER_RECEPTION = "KEEPER_RECEPTION",
  KEEPER_DELIVERY = "KEEPER_DELIVERY",
  KEEPER_RETURNING = "KEEPER_RETURNING",
}

export enum SystemLogStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum SystemLogEntityType {
  PARCEL = "PARCEL",
  ORDER = "ORDER",
  WALLET = "WALLET",
  ACCOUNTANCY = "ACCOUNTANCY",
}

export enum SystemLogEvent {
  PARCEL_CREATED = "PARCEL_CREATED",
  PARCEL_UPDATED = "PARCEL_UPDATED",
  ORDER_CREATED = "ORDER_CREATED",
  ORDER_UPDATED = "ORDER_UPDATED",
  WALLET_NEW_ENTRY = "WALLET_NEW_ENTRY",
  ACCOUNTANCY_NEW_ENTRY = "ACCOUNTANCY_NEW_ENTRY",
}

export type SystemUpdate = {
  id: string
  details: {
    emitter: SystemEventMetaEmitter
    emitterId: string
    interface: SystemEventMetaInterface
    action: SystemEventMetaAction
    notificationsEnabled: boolean
  }
  startDate: string
  executionTime: number
  logs: SystemLog[]
}

export type SystemLog = {
  id: string
  event: SystemLogEvent
  entityType: SystemLogEntityType
  entityId: string
  entityNo: string
  details: string
  data: any
  status: SystemLogStatus
  createdAt: string
}

export enum SystemType {
  PARCEL = "PARCEL",
  KEEPER = "KEEPER",
  ADDRESS = "ADDRESS",
}
