import React, { ReactElement } from "react"
import {
  AUTHORIZED_ROLES_FOR_BILLING_MANAGEMENT,
  WalletEntry,
  WalletEntryType,
  WalletEntryTypeKeeperAuthorized,
} from "types/wallet-entry.types"
import {
  TitleBlock,
  Title,
  EmojiBlock,
  PartnerBlock,
  Partner,
  Type,
  Partners,
  SmallName,
} from "components/ParcelBilling/ParcelBilling.styled"
import { Emoji } from "components/Emoji/Emoji"
import { ParcelBillingModal } from "components/ParcelBilling/ParcelBillingModal/ParcelBillingModal"
import {
  getBillingEntryName,
  getWalletEntryName,
} from "components/ParcelBilling/ParcelBillingModal/datas/BillingModalDatas"
import RolesGuard from "components/RolesGuard"
import { CompanyBillingEntry } from "types/company-billing.types"

interface ParcelBillingProps {
  companyBillingEntries: CompanyBillingEntry[]
  walletEntries: WalletEntry[]
  idsData: {
    walletId: string
    parcelId: string
    companyId: string
  }
}

export const ParcelBilling = ({
  companyBillingEntries = [],
  walletEntries = [],
  idsData,
}: ParcelBillingProps): ReactElement => {
  return (
    <Partners>
      <TitleBlock>
        <EmojiBlock>
          <Emoji label={"money bag"}>💰</Emoji>
        </EmojiBlock>
        <Title>Facturation / Crédit</Title>
      </TitleBlock>
      <PartnerBlock>
        <Partner>
          <Type>Facturation</Type>
          {!companyBillingEntries || (companyBillingEntries?.length === 0 && <SmallName>-</SmallName>)}

          {companyBillingEntries?.map(billingEntry => (
            <SmallName key={billingEntry.id}>✅ {getBillingEntryName(billingEntry.type)}</SmallName>
          ))}
        </Partner>
        <Partner>
          <Type>Crédit Keeper</Type>
          {!walletEntries || (walletEntries?.length === 0 && <SmallName>-</SmallName>)}

          {walletEntries
            ?.filter(walletEntry => WalletEntryTypeKeeperAuthorized.includes(walletEntry.type))
            ?.map(walletEntry => (
              <SmallName key={walletEntry.id}>
                {walletEntry.type === WalletEntryType.PENDING_RECEPTION ? "⏳" : "✅"}{" "}
                {`${getWalletEntryName(walletEntry.type)} ${walletEntry.amount / 100}€`}
              </SmallName>
            ))}
        </Partner>
      </PartnerBlock>
      <RolesGuard allowedRoles={AUTHORIZED_ROLES_FOR_BILLING_MANAGEMENT}>
        <ParcelBillingModal
          companyBillingEntries={companyBillingEntries}
          walletEntries={walletEntries}
          idsData={idsData}
        />
      </RolesGuard>
    </Partners>
  )
}
