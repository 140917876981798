import React, { ReactElement } from "react"
import Skeleton from "react-loading-skeleton"

import { NoteHandlerContainer } from "components/Notes/NoteHandler.styled"

const NoteHandlerSkeleton = (): ReactElement => {
  return (
    <NoteHandlerContainer>
      <Skeleton count={1} width={"30%"} height={"15px"} style={{ lineHeight: 2 }} />
      <Skeleton count={3} width={"50%"} height={"10px"} style={{ lineHeight: 1 }} />
    </NoteHandlerContainer>
  )
}

export default NoteHandlerSkeleton
