import React, { ReactElement, useState, Fragment, useCallback } from "react"
import styled from "styled-components"
import { BankAccount } from "types/wallet.types"
import CardWalletChoice from "components/CardWalletChoice"
import WalletPayment from "components/CardWalletPayment/WalletPayment"
import WalletDonation from "components/CardWalletPayment/WalletDonation"
import WalletGiveaway from "components/CardWalletPayment/WalletGiveaway"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GetKeeperQuery } from "types/keeper.types"
import { changeBankAccountStatus, ChangeBankAccountStatusVariables } from "services/graphql/mutations/keeper.mutations"
import BanKAccountStatus from "components/Keeper/KeeperBankAccountStatus"

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px 10px 20px;
`

export const ContentContainer = styled.div`
  margin: 20px 0px 20px 0px;
`

const dataCard = [
  {
    emoji: "🏦",
    title: "Virement",
  },
  {
    emoji: "💕",
    title: "Don à une association",
  },
  {
    emoji: "💸",
    title: "Renoncer à ses récompenses",
  },
]

interface CardWalletPaymentProps {
  keeperId: string
  bankAccount?: BankAccount
  refetchKeeperData: ReturnType<typeof useLazyQuery<GetKeeperQuery>>[1]["refetch"]
  balance?: number
  askKeeperWalletTransfer: () => void
  askKeeperWalletDonation: (associationId: string) => void
  askKeeperWalletGiveaway: () => void
}

export default function CardWalletPayment({
  keeperId,
  bankAccount,
  refetchKeeperData,
  balance,
  askKeeperWalletTransfer,
  askKeeperWalletDonation,
  askKeeperWalletGiveaway,
}: CardWalletPaymentProps): ReactElement {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [changeBankAccountStatusMutation] = useMutation<boolean, ChangeBankAccountStatusVariables>(
    changeBankAccountStatus,
  )

  const setBankAccountStatus = useCallback(
    async (isEnabled: boolean) => {
      await changeBankAccountStatusMutation({
        variables: {
          keeperId,
          isEnabled: isEnabled,
        },
      })
      await refetchKeeperData()
    },
    [changeBankAccountStatusMutation, keeperId, refetchKeeperData],
  )

  const handleChoiceContent = useCallback(() => {
    if (!balance) {
      return (
        <>
          {Boolean(bankAccount) && (
            <BanKAccountStatus
              isStripeValidated={bankAccount.isStripeValidated}
              setBankAccountStatus={setBankAccountStatus}
              updatedAt={bankAccount.updatedAt}
            />
          )}
          <div>{"❌ Le Keeper n'a pas de cagnotte"}</div>
        </>
      )
    }

    if (selectedIndex === 0) {
      return (
        <WalletPayment
          balance={balance}
          bankAccount={bankAccount}
          setBankAccountStatus={setBankAccountStatus}
          redirectToDonation={() => {
            setSelectedIndex(1)
          }}
          askKeeperWalletTransfer={askKeeperWalletTransfer}
        />
      )
    } else if (selectedIndex === 1) {
      return <WalletDonation askKeeperWalletDonation={askKeeperWalletDonation} />
    } else if (selectedIndex === 2) {
      return <WalletGiveaway askKeeperWalletGiveaway={askKeeperWalletGiveaway} />
    }
  }, [selectedIndex, balance, bankAccount, setBankAccountStatus])

  return (
    <ContainerCard>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {dataCard.map((item, index) => (
          <Fragment key={index}>
            <CardWalletChoice
              emoji={item.emoji}
              isLast={dataCard.length - 1 === index}
              title={item.title}
              selected={selectedIndex === index}
              onPress={() => {
                setSelectedIndex(index)
              }}
            />
          </Fragment>
        ))}
      </div>
      <ContentContainer>{handleChoiceContent()}</ContentContainer>
    </ContainerCard>
  )
}
