import React, { Component, Fragment } from "react"
import { withRouter } from "react-router-dom"
import { Icon } from "semantic-ui-react"
import styled from "styled-components"

import { NavSoloItem } from "components/AppStructure/AppNav/NavSoloItem"
import { NavMultipleItem } from "components/AppStructure/AppNav/NavMultipleItem"
import { getSidebarConfig } from "config/routing"
import { COLOR } from "utils/color"

const DoubleArrow = styled.div`
  position: absolute;
  bottom: 10px;
  font-size: 20px;
  left: 1em;
  color: ${COLOR.LIGHT_GREY};
  cursor: pointer;
`
class Nav extends Component {
  state = {
    openedMenu: null,
  }

  setOpenedMenu = _name => {
    if (this.state.openedMenu !== _name) this.setState({ openedMenu: _name })
    else this.setState({ openedMenu: null })
  }

  render() {
    const { toggleSidebar, enableClosedSidebar, auth } = this.props
    const { pathname } = this.props.location
    // const { openedMenu } = this.state
    const config = getSidebarConfig()

    const categories = config

    return (
      <Fragment>
        {categories
          .filter(elt => elt.environments.includes(process.env.REACT_APP_STAGE))
          .filter(elt => !!elt.authorizedRoles && elt.authorizedRoles.includes(auth.role))
          .map(
            category =>
              category.inSidebar && (
                <React.Fragment key={`fragment-${category.name}`}>
                  <h5 className="app-sidebar__heading">{category.name}</h5>
                  <div className="metismenu vertical-nav-menu">
                    <ul className="metismenu-container">
                      {category.elements
                        .filter(elt => !elt.authorizedRoles || elt.authorizedRoles.includes(auth.role))
                        .map(item => (
                          <React.Fragment key={`fragment-item-${item.path}`}>
                            {item.inSidebar && item.children.length === 0 && (
                              <NavSoloItem
                                content={item}
                                active={pathname === item.path}
                                key={`navSoloItem-${item.path}`}
                              />
                            )}
                            {item.inSidebar && item.children.length > 0 && (
                              <NavMultipleItem
                                item={item}
                                key={`navMultipleItem-${item.path}`}
                                pathname={pathname}
                                opened={false /* openedMenu === item.name */}
                                onClick={() => this.setOpenedMenu(item.name)}
                              />
                            )}
                          </React.Fragment>
                        ))}
                    </ul>
                  </div>
                </React.Fragment>
              ),
          )}

        <DoubleArrow>
          <Icon name={enableClosedSidebar ? `unlock` : `lock`} onClick={() => toggleSidebar(!enableClosedSidebar)} />
        </DoubleArrow>
      </Fragment>
    )
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path)
  }
}

export default withRouter(Nav)
