import React, {ReactElement, useState} from "react"
import { MiniButtonCancel } from "components/Buttons/MiniButton"
import { Modal } from "semantic-ui-react"
import {
  ActionsSection,
  BillingModal,
  Button,
  ButtonContainer,
  Header,
  InputReason,
  ReasonBlock,
  ReasonTitle,
} from "components/ParcelBilling/ParcelBillingModal/ParcelBillingModal.styled"
import { useParcelBillingModal } from "hooks/useParcelBillingModal"
import { SummarySection } from "components/ParcelBilling/ParcelBillingModal/SummarySection"
import { AttachedBillingsSection } from "components/ParcelBilling/ParcelBillingModal/AttachedBillingsSection"
import { AddBillingsSection } from "components/ParcelBilling/ParcelBillingModal/AddBillingsSection"
import { ActionType, BillingEntry, TargetEntryType } from "types/billing-modal.types"
import { CompanyBillingEntry, CompanyBillingEntryType } from "types/company-billing.types"
import { WalletEntry, WalletEntryType } from "types/wallet-entry.types"
import { useMutation } from "@apollo/client"
import {
  updateParcelBilling,
  UpdateParcelBillingData,
  UpdateParcelBillingVariables,
} from "services/graphql/mutations/company-billing-payment.mutations"
import { apiGetParcelInfos } from "services/parcels"
import { useDispatch } from "react-redux"

interface ParcelBillingModalProps {
  companyBillingEntries: CompanyBillingEntry[]
  walletEntries: WalletEntry[]
  idsData: {
    walletId: string
    parcelId: string
    companyId: string
  }
}

export const ParcelBillingModal = ({
  companyBillingEntries = [],
  walletEntries = [],
  idsData,
}: ParcelBillingModalProps): ReactElement => {
  const dispatch = useDispatch()
  const [updateParcelBillingMutation] = useMutation<UpdateParcelBillingData, UpdateParcelBillingVariables>(
    updateParcelBilling,
  )

  const { billingUtils, reasonValue, handleReasonChange, modal } = useParcelBillingModal(
    companyBillingEntries,
    walletEntries,
  )

  /** Boolean value that indicates whether some action was performed (ActionType.REMOVE, ActionType.ADD) */
  const isActionChosen = !!billingUtils.billingEntries.filter(
    billingEntry => billingEntry.actionType !== ActionType.NEUTRAL,
  ).length

  /** Boolean value indicating whether data submission to the server is available ("Valider" button) */
  const isSubmitDisabled = !isActionChosen || !reasonValue

  const _updateParcelBilling = () => {
    const updateParcelBillingData = {
      parcelId: idsData.parcelId,
      walletEntriesAdded: [],
      walletEntriesRemoved: [],
      companyBillingEntriesAdded: [],
      companyBillingEntriesRemoved: [],
      reason: reasonValue,
    }

    billingUtils.billingEntries.forEach((billingEntry: BillingEntry) => {
      const isWalletEntry = billingEntry.targetEntry === TargetEntryType.WALLET
      const isBillingEntry = billingEntry.targetEntry === TargetEntryType.BILLING
      const isAddAction = billingEntry.actionType === ActionType.ADD
      const isRemoveAction = billingEntry.actionType === ActionType.REMOVE

      if (isWalletEntry && isAddAction) {
        updateParcelBillingData.walletEntriesAdded.push({
          type: billingEntry.entryType as WalletEntryType,
          walletId: idsData.walletId,
        })
      } else if (isBillingEntry && isAddAction) {
        updateParcelBillingData.companyBillingEntriesAdded.push({
          type: billingEntry.entryType as CompanyBillingEntryType,
          companyId: idsData.companyId,
        })
      } else if (isWalletEntry && isRemoveAction) {
        updateParcelBillingData.walletEntriesRemoved.push(billingEntry.id)
      } else if (isBillingEntry && isRemoveAction) {
        updateParcelBillingData.companyBillingEntriesRemoved.push(billingEntry.id)
      }
    })

    updateParcelBillingMutation({
      variables: {
        parcelBilling: updateParcelBillingData,
      },
      onCompleted: () => {
        modal.onClose()
        dispatch(apiGetParcelInfos(idsData.parcelId))
      },
      onError: () => {
        modal.onClose()
      },
    })
  }

  return (
    <BillingModal
      onOpen={modal.onOpen}
      open={modal.isOpen}
      trigger={
        <ButtonContainer>
          <MiniButtonCancel>Modifier</MiniButtonCancel>
        </ButtonContainer>
      }
    >
      <Modal.Content>
        <Header>Modifier les crédits / facturation</Header>

        <AttachedBillingsSection
          billingEntries={billingUtils.billingEntries}
          removeBillingEntry={billingUtils.removeBillingEntry}
        />

        <AddBillingsSection
          companyBillingEntryData={billingUtils.COMPANY_BILLING_ENTRY_DATA}
          walletEntryData={billingUtils.WALLET_ENTRY_DATA}
          addBillingEntry={billingUtils.addBillingEntry}
        />

        <SummarySection isActionChosen={isActionChosen} billingEntries={billingUtils.billingEntries} />

        <ReasonBlock>
          <ReasonTitle>Raison de la modification manuelle</ReasonTitle>
          <InputReason required value={reasonValue} onChange={handleReasonChange} type={"text"} />
        </ReasonBlock>

        <ActionsSection>
          <Button onClick={modal.onClose} cancel>
            Annuler
          </Button>
          <Button onClick={_updateParcelBilling} disabled={isSubmitDisabled}>
            Valider
          </Button>
        </ActionsSection>
      </Modal.Content>
    </BillingModal>
  )
}
