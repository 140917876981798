import uniqBy from "lodash/uniqBy"
import { toFailure, toSuccess } from "actions"
import { createReducer } from "@reduxjs/toolkit"
import {
  API_CREATE_PARCEL_FLAG,
  API_CREATE_PARCEL_NOTE,
  API_GET_KEEPERS_TO_REASSIGN,
  API_GET_PARCEL_EVENTS,
  API_GET_PARCEL_INFOS,
  API_REMOVE_PARCEL_FLAG,
  API_SEARCH_KEEPERS_TO_REASSIGN,
  API_UPDATE_PARCEL_NOTE,
  RESET_KEEPERS_TO_REASSIGN,
  API_GET_ALL_PARCEL,
  API_GET_RETURNING_PARCELS,
  RESET_FOCUS_PARCEL,
  RESET_PARCELS,
  API_SEND_PARCEL_ADMIN_EVENT,
  API_GET_PARCELS_STATS,
} from "actions/parcels"
import { API_UPDATE_ORDER_RECIPIENT } from "actions/orders"
import { toast } from "react-toastify"
import { Parcel, ParcelFlag, ParcelStatus } from "types/parcel.types"

interface initialStateParcelsProps {
  list: {
    loading: boolean
    parcelsStatsLoading: boolean
    error: boolean
    data: {
      pageInfos: {
        endCursor?: string
        hasNextPage?: boolean
      }
      parcels: Parcel[]
    }
    activeStatus: string
    parcelsStats: {
      [ParcelStatus.IN_TRANSIT]?: number
      [ParcelStatus.IN_DELIVERY]?: number
      [ParcelStatus.DELIVERED_TO_KEEPER]?: number
      [ParcelStatus.DELIVERED_TO_RECIPIENT]?: number
    }
  }
  returning: {
    loading: boolean
    error: boolean
    data: []
  }
  stats: {
    loading: boolean
    error: boolean
    data: {}
  }
  focus: {
    infos: {
      loading: boolean
      error: boolean
      data: {
        __notes__: any
        order: {
          recipient: {}
        }
        flag: ParcelFlag
      }
    }
    events: {
      loading: boolean
      error: boolean
      data: []
    }
  }
  reassign: {
    loading: boolean
    error: boolean
    data: any
    pageInfos: {}
  }
}

const initialState: initialStateParcelsProps = {
  list: {
    loading: false,
    parcelsStatsLoading: false,
    error: false,
    data: {
      pageInfos: {},
      parcels: [],
    },
    activeStatus: null,
    parcelsStats: {},
  },
  returning: {
    loading: false,
    error: false,
    data: [],
  },
  stats: {
    loading: false,
    error: false,
    data: {},
  },
  focus: {
    infos: {
      loading: false,
      error: false,
      data: {
        __notes__: [],
        order: {
          recipient: {},
        },
        flag: null,
      },
    },
    events: {
      loading: false,
      error: false,
      data: [],
    },
  },
  reassign: {
    loading: false,
    error: false,
    data: [],
    pageInfos: {},
  },
}

const parcels = createReducer(initialState, {
  // ############ All Parcel
  [RESET_PARCELS]: state => {
    state.list.data = initialState.list.data
  },

  [API_GET_ALL_PARCEL]: (state, action) => {
    state.list.loading = true
    state.list.activeStatus = action.payload.filter
  },
  [toSuccess(API_GET_ALL_PARCEL)]: (state, action) => {
    state.list.loading = false
    state.list.error = false

    state.list.data = {
      ...state.list.data,
      pageInfos: action.payload.data.pageInfos,
      parcels: state.list.data.parcels.concat(action.payload.data.edges.map(edge => edge.node)),
    }
  },
  [toFailure(API_GET_ALL_PARCEL)]: state => {
    state.list.loading = false
    state.list.error = true
  },

  [API_GET_PARCELS_STATS]: state => {
    state.list.parcelsStatsLoading = true
  },
  [toSuccess(API_GET_PARCELS_STATS)]: (state, action) => {
    state.list.parcelsStatsLoading = false
    state.list.error = false

    state.list.parcelsStats = action.payload.data
  },
  [toFailure(API_GET_PARCELS_STATS)]: state => {
    state.list.parcelsStatsLoading = false
    state.list.error = true
  },

  // ############ Get Returning Parcels
  [API_GET_RETURNING_PARCELS]: state => {
    state.returning.loading = true
  },
  [toSuccess(API_GET_RETURNING_PARCELS)]: (state, action) => {
    state.returning.loading = false
    state.returning.error = false

    state.returning.data = action.payload.data
  },
  [toFailure(API_GET_RETURNING_PARCELS)]: state => {
    state.returning.loading = false
    state.returning.error = true
  },

  // ############ Send Parcel Admin Event
  [API_SEND_PARCEL_ADMIN_EVENT]: state => {
    state.focus.infos.loading = true
  },
  [toSuccess(API_SEND_PARCEL_ADMIN_EVENT)]: (state, action) => {
    state.focus.infos.loading = false
    state.focus.infos.error = false

    state.focus.infos.data = action.payload.data?.parcel
    state.focus.events.data = action.payload.data?.events
  },
  [toFailure(API_SEND_PARCEL_ADMIN_EVENT)]: state => {
    state.focus.infos.loading = false
    state.focus.infos.error = true
  },

  // ############ Parcel One
  [API_GET_PARCEL_INFOS]: state => {
    state.focus.infos.loading = true
  },
  [toSuccess(API_GET_PARCEL_INFOS)]: (state, action) => {
    state.focus.infos.loading = false
    state.focus.infos.error = false

    state.focus.infos.data = action.payload.data
  },
  [toFailure(API_GET_PARCEL_INFOS)]: state => {
    state.focus.infos.loading = false
    state.focus.infos.error = true
  },
  [API_GET_PARCEL_EVENTS]: state => {
    state.focus.events.loading = true
  },
  [toSuccess(API_GET_PARCEL_EVENTS)]: (state, action) => {
    state.focus.events.loading = false
    state.focus.events.error = false

    state.focus.events.data = action.payload.data
  },
  [toFailure(API_GET_PARCEL_EVENTS)]: state => {
    state.focus.events.loading = false
    state.focus.events.error = true
  },

  // ############ Notes
  [API_CREATE_PARCEL_NOTE]: state => {
    state.focus.infos.loading = true
  },
  [toSuccess(API_CREATE_PARCEL_NOTE)]: (state, action) => {
    state.focus.infos.loading = false
    state.focus.infos.data.__notes__.push(action.payload.data)
  },
  [toFailure(API_CREATE_PARCEL_NOTE)]: state => {
    state.focus.infos.loading = false
    state.focus.infos.error = true
  },
  [API_UPDATE_PARCEL_NOTE]: state => {
    state.focus.infos.loading = true
  },
  [toSuccess(API_UPDATE_PARCEL_NOTE)]: (state, action) => {
    state.focus.infos.loading = false
    const index = state.focus.infos.data.__notes__.findIndex(({ id }) => id === action.payload.data.id)
    state.focus.infos.data.__notes__[index] = action.payload.data
  },
  [toFailure(API_UPDATE_PARCEL_NOTE)]: state => {
    state.focus.infos.loading = false
    state.focus.infos.error = true
  },

  // ############ Flags
  [API_CREATE_PARCEL_FLAG]: state => {
    state.focus.infos.loading = true
  },
  [toSuccess(API_CREATE_PARCEL_FLAG)]: (state, action) => {
    const flag = action.meta.previousAction.payload.request.data.flag

    state.focus.infos.loading = false
    state.focus.infos.data.flag = flag
  },
  [toFailure(API_CREATE_PARCEL_FLAG)]: state => {
    state.focus.infos.loading = false
    state.focus.infos.error = true
  },

  [API_REMOVE_PARCEL_FLAG]: state => {
    state.focus.infos.loading = true
  },
  [toSuccess(API_REMOVE_PARCEL_FLAG)]: state => {
    state.focus.infos.loading = false
    state.focus.infos.data.flag = null
  },
  [toFailure(API_REMOVE_PARCEL_FLAG)]: state => {
    state.focus.infos.loading = false
    state.focus.infos.error = true
  },

  [RESET_FOCUS_PARCEL]: state => {
    state.focus = initialState.focus
  },

  // ############ Reassign
  [API_GET_KEEPERS_TO_REASSIGN]: state => {
    state.reassign.loading = true
  },
  [toSuccess(API_GET_KEEPERS_TO_REASSIGN)]: (state, action) => {
    state.reassign.loading = false

    if (action.payload.data.edges && action.payload.data.totalCount > 0) {
      const newAddresses = action.payload.data.edges.sort(
        (a, b) => a?.node?.address?.distance - b?.node?.address?.distance,
      )

      state.reassign.data = uniqBy([...state.reassign.data, ...newAddresses], "cursor")

      state.reassign.pageInfos = action.payload.data.pageInfos
    } else {
      state.reassign.data = action.payload.data.edges
    }
  },
  [toFailure(API_GET_KEEPERS_TO_REASSIGN)]: state => {
    state.reassign.loading = false
    state.reassign.error = true
  },

  [API_SEARCH_KEEPERS_TO_REASSIGN]: state => {
    state.reassign.loading = true
  },
  [toSuccess(API_SEARCH_KEEPERS_TO_REASSIGN)]: (state, action) => {
    state.reassign.loading = false

    if (action.payload.data && action.payload.data.length > 0) {
      // TODO: REMOVE THIS DATA WITH CURSOR PAGINATION ON BACKEND
      const data = []

      action.payload.data.map(address => {
        const dataItem = {
          node: address,
        }

        data.push(dataItem)
      })

      state.reassign.data = data.sort((a, b) => a?.node?.address?.distance - b?.node?.address?.distance)

      // OLD WAY BEFORE CURSOR PAGINATION
      // state.reassign.data = action.payload.data.sort((a, b) => a?.address?.distance - b?.address?.distance)
    } else {
      state.reassign.data = action.payload.data
    }
  },
  [toFailure(API_SEARCH_KEEPERS_TO_REASSIGN)]: state => {
    state.reassign.loading = false
    state.reassign.error = true
  },

  [RESET_KEEPERS_TO_REASSIGN]: state => {
    state.reassign = initialState.reassign
  },

  // Update recipient order
  [API_UPDATE_ORDER_RECIPIENT]: () => {},
  [toSuccess(API_UPDATE_ORDER_RECIPIENT)]: (state, action) => {
    state.focus.infos.data.order.recipient = action.payload.data.recipient

    toast.success("Infos destinataire mises à jour")
  },
  [toFailure(API_UPDATE_ORDER_RECIPIENT)]: () => {},
})

export default parcels
