import React, { ReactElement } from "react"
import { Col, Row } from "reactstrap"
import CollapsibleContainer from "components/Table/CollapsibleContainer"
import { CompanyBillingPayment, CompanyCurrentBill } from "types/company-billing.types"

interface PaymentCollapsibleProps {
  element: CompanyBillingPayment | CompanyCurrentBill
}

const PaymentCollapsible = ({ element }: PaymentCollapsibleProps): ReactElement => {
  const { details } = element
  const deliveries = details?.deliveries || []

  const totalAmount = element?.details?.deliveries?.reduce((acc, item) => acc + item.amount, 0)
  const totalParcels = element?.details?.deliveries?.reduce((acc, item) => acc + item.total, 0)

  const totalReturningParcels = element?.details?.returning?.total || "--"

  return (
    <CollapsibleContainer>
      <Row>
        <Col xs={4}>
          <b>Type</b>
        </Col>
        <Col xs={4}>
          <b># colis</b>
        </Col>
        <Col xs={4}>
          <b>Total</b>
        </Col>
      </Row>
      {deliveries
        .slice()
        .sort((a, b) => a.nbParcels - b.nbParcels)
        .map((delivery, index) => (
          <Row key={index}>
            <Col xs={4}>Livraison {delivery.nbParcels} colis</Col>
            <Col xs={4}>{delivery.total}</Col>
            <Col xs={4}>{delivery.amount / 100} €</Col>
          </Row>
        ))}

      <Row style={{ marginTop: 10 }}>
        <Col xs={4}>Retours colis</Col>
        <Col xs={4}>{totalReturningParcels}</Col>
        <Col xs={4}> </Col>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Col xs={4}> </Col>
        <Col xs={4}>
          <b>{totalParcels} colis</b>
        </Col>
        <Col xs={4}>
          <b>{totalAmount / 100} € HT</b>
        </Col>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Col xs={4}> </Col>
        <Col xs={4}> </Col>
        <Col xs={4}>
          <b>{(totalAmount / 100) * 1.2} € TTC</b>
        </Col>
      </Row>
    </CollapsibleContainer>
  )
}

export default PaymentCollapsible
