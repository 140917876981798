import { Address } from "./address.types"

export enum AvailabilityRecurring {
  "MON" = "MON",
  "TUE" = "TUE",
  "WED" = "WED",
  "THU" = "THU",
  "FRI" = "FRI",
  "SAT" = "SAT",
  "SUN" = "SUN",
}

export enum AvailabilityTime {
  NINE = "09:00",
  TWELVE = "12:00",
  FIVETEEN = "15:00",
  EIGHTEEN = "18:00",
  TWENTYONE = "21:00",
}

export enum AvailabilitySlotType {
  MORNING = "morning",
  NOON = "noon",
  AFTERNOON = "afternoon",
  EVENING = "evening",
}

export enum AvailabilityType {
  PUNCTUAL = "PUNCTUAL",
  RECURRING = "RECURRING",
  DISABLED = "DISABLED",
}

export interface Availability {
  id: string
  addressId: string
  type: AvailabilityType
  startDate: string // "2021-05-04"
  endDate: string | null // "2021-05-04"
  startTime: AvailabilityTime
  endTime: AvailabilityTime
  recurring: string[]
  createdAt: string
  updatedAt: string
}

export interface AddressAvailability {
  id?: string // undefined for punctual availabilities that are not saved in DB yet
  date: string // YYYY-MM-DD
  startHour: number
  endHour: number
  addressId?: string
  address?: Address
  recurring: boolean // YYYY-MM-DD
  archived?: boolean
  createdAt?: Date
  updatedAt?: Date
  __typename?: string
  deleted?: boolean
}
