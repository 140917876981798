import { gql } from "@apollo/client"
import { RRFaddress } from "types/return-request.types"
import { AdminParcelEventReason } from "types/parcel.types"

export interface SaveBtoCRRFPacmanVariables {
  id?: string
  reason: AdminParcelEventReason
  pickupDate: Date
  interval: { start: string; end: string }
  dimensions?: string
  weight?: number
  cotransportationCode?: string
  referenceCode?: string
  parcelId: string
  returningCarrierId: string
  alreadyCollected: boolean
  alreadyOrderedToReturningCarrier: boolean
  rrfAddress?: RRFaddress
  details?: string
}

export interface SaveBtoCRRFPacmanInput {
  payload: SaveBtoCRRFPacmanVariables
}

export const saveBtoCRRFPacman = gql`
  mutation saveBtoCRRFPacman($payload: BtoCReturnRequestPacmanInput!) {
    saveBtoCRRFPacman(payload: $payload)
  }
`
