import styled from "styled-components"
import { MiniButton } from "components/Buttons/MiniButton"
import { COLOR } from "utils/color"
import { Form } from "formik"
import { Button } from "semantic-ui-react"

export const EventPopupsTableWrapper = styled.div`
  overflow: auto;
  max-height: 500px;
`

export const ZipcodesContainer = styled.div`
  font-size: 11px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 144px;
  text-overflow: ellipsis;
`

export const ContentContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  overflow: hidden;
  max-width: 250px;
  max-height: 36px;
  line-height: 18px;
  text-overflow: ellipsis;
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const EditMiniButton = styled(MiniButton)`
  color: ${COLOR.BLACK};
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  &:hover {
    background-color: ${COLOR.WHITE_SMOKE};
  }
`

export const RemoveMiniButton = styled(MiniButton)`
  color: ${COLOR.WHITE};
  background-color: ${COLOR.RED};
  &:hover {
    background-color: ${COLOR.MEDIUM_LIGHT_RED};
  }
`

export const ExportMiniButton = styled(MiniButton)`
  color: ${COLOR.WHITE};
  background-color: ${COLOR.BLUE};
  &:hover {
    background-color: ${COLOR.MEDIUM_LIGHT_BLUE};
  }
`

export const Subtitle = styled.p`
  font-size: 14px;
`

export const ImageComponent = styled.img``

export const SubmitButton = styled(Button)`
  margin-top: 20px !important;
  width: 111px;
  background-color: ${COLOR.GREEN} !important;
  color: ${COLOR.WHITE} !important;
  &:hover {
    background-color: ${COLOR.MEDIUM_LIGHT_GREEN} !important;
  }
`

export const StyledForm = styled(Form)`
  width: 523px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
