import { Icon } from "semantic-ui-react"
import styled from "styled-components"
import { COLOR } from "utils/color"

export const BlockRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const BlockRowItem = styled.div`
  display: flex;
  width: 50%;
`

export const BlockRowItemGap = styled(BlockRowItem)`
  flex-direction: row;
  column-gap: 36px;
`

export const QRCodeIcon = styled(Icon).attrs({ name: "qrcode", size: "small" })`
  color: ${COLOR.MEDIUM_SUPER_LIGHT_GREY};
  padding-left: 5px;
  &:hover {
    color: ${COLOR.LIGHT_GREY};
  }
  &:active {
    color: ${COLOR.BLACK};
  }
`
