import React, { ReactElement } from "react"
import Stat from "components/Stats/Stat"
import { SponsorshipKeeper } from "types/sponsorship.types"
import StatsLine from "components/Stats/StatsLine"

interface KeeperSponsoredStatsProps {
  sponsorship: SponsorshipKeeper
  loading: boolean
  nbSponsored: number
}

const KeeperSponsoredStats = ({ sponsorship, loading, nbSponsored }: KeeperSponsoredStatsProps): ReactElement => {
  return (
    <StatsLine>
      {(sponsorship?.sponsor?.firstName || sponsorship?.sponsor?.lastName) && (
        <Stat
          loading={loading}
          title="Nom de son parrain"
          content={`${sponsorship?.sponsor?.firstName} ${sponsorship?.sponsor?.lastName}`}
        />
      )}
      <Stat loading={loading} title="Total d'euros gagnés" content={`${sponsorship?.sponsorEarning?.earned / 100}€`} />
      <Stat
        loading={loading}
        title="Total d'euros en attente"
        content={`${sponsorship?.sponsorEarning?.incoming / 100}€`}
      />
      <Stat loading={loading} title="Nombre de filleuls" content={nbSponsored} />
    </StatsLine>
  )
}

export default KeeperSponsoredStats
