import { API_GET_ONE_CARRIER_MAP_LOG, RESET_CARRIER_MAP_LOG } from "actions/carrier-map-logs"
import { createReducer } from "@reduxjs/toolkit"
import { toFailure, toSuccess } from "actions"

const initialState = {
  stats: {
    loading: false,
    error: false,
    data: null,
  },
}

const carrierMapLogs = createReducer(initialState, {
  // Search One Carrier Map log
  [API_GET_ONE_CARRIER_MAP_LOG]: state => {
    state.stats.loading = true
  },
  [toSuccess(API_GET_ONE_CARRIER_MAP_LOG)]: (state, action) => {
    state.stats.loading = false
    state.stats.error = false

    state.stats.data = action.payload.data
  },
  [toFailure(API_GET_ONE_CARRIER_MAP_LOG)]: state => {
    state.stats.loading = false
    state.stats.error = true
  },

  // RESET STATE
  [RESET_CARRIER_MAP_LOG]: state => {
    state.stats.data = null
    state.stats.loading = false
  },
})

export default carrierMapLogs
