import styled from "styled-components"
import { COLOR } from "utils/color"

export const Section = styled.div`
  margin-top: 109px;
`

export const SectionTitle = styled.p`
  font-size: 20px;
  margin-bottom: 30px;
`

export const TransferDate = styled.p`
  font-size: 12px;
`

export const TotalSum = styled.p`
  font-size: 12px;
`

export const RevertButton = styled.button`
  font-size: 9px;
  text-align: center;
  border-radius: 10px;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.RED};
  padding: 2px 8px;
  max-width: 184px;
  cursor: pointer;
  border: 0;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${COLOR.BRIGHT_RED};
  }
`

export const DetailText = styled.p`
  color: ${COLOR.DARK_GREY};
`

export const HeaderLineContainer = styled.div`
  margin-bottom: 15px;
`

export const DetailLineContainer = styled.div`
  margin-bottom: 2px;
`

export const ButtonContainer = styled.div`
  margin-top: 10px;
`
