export type OptionType = { [key: string]: any }

export type OptionsType = Array<OptionType>

export type GroupType = {
  label: string
  options: OptionsType
  [key: string]: any
}

export type GroupsType = Array<GroupType>

export enum LoaderPosition {
  START = "start",
  CENTER = "center",
  END = "end",
}
