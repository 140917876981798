import React, { ReactElement, Fragment } from "react"
import Stat from "components/Stats/Stat"
import StatsLine from "components/Stats/StatsLine"

interface RatingStatsProps {
  stats: {
    nps: number
    average: number
    npsMonth: number
    npsWeek: number
    total: any
  }
  loading: boolean
}

const RatingStats = ({ stats, loading }: RatingStatsProps): ReactElement => (
  <Fragment>
    <StatsLine>
      <Stat title="Total" content={stats.total} loading={loading} />
    </StatsLine>
    <StatsLine>
      <Stat title="NPS Global" content={stats.nps || 0} loading={loading} />
      <Stat title="NPS du mois" content={stats.npsMonth || 0} loading={loading} />
      <Stat title="NPS de la semaine" content={stats.npsWeek || 0} loading={loading} />
      <Stat title="Note moyenne" content={stats.average || 0} loading={loading} />
    </StatsLine>
  </Fragment>
)

export default RatingStats
