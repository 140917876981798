import React, { ReactElement } from "react"
import { Dropdown, Menu } from "semantic-ui-react"
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon"
import { CompanyBillingPayment, CompanyBillingPaymentStatus, CompanyCurrentBill } from "types/company-billing.types"
import LineElementCol from "components/Table/LineElementCol"
import LineElementRow from "components/Table/LineElementRow"

interface PaymentLineItemProps {
  element: CompanyBillingPayment | CompanyCurrentBill
  updatePaymentStatus: (id: string, status: CompanyBillingPaymentStatus) => void
}

const PaymentLineItem = ({ element, updatePaymentStatus = null }: PaymentLineItemProps): ReactElement => {
  const nbParcels = element?.details?.deliveries?.reduce((acc, item) => acc + item.total, 0)

  const ACTIONS = [
    {
      key: "SET_TO_NEW",
      text: `Passer en Nouveau`,
      value: "NEW",
      onClick: () => updatePaymentStatus(element.id, CompanyBillingPaymentStatus.NEW),
    },
    {
      key: "SET_TO_PROCESSING",
      text: `Passer en Traitement en cours`,
      value: "PROCESSING",
      onClick: () => updatePaymentStatus(element.id, CompanyBillingPaymentStatus.PROCESSING),
    },
    {
      key: "SET_TO_DONE",
      text: `Passer en Terminé`,
      value: "DONE",
      onClick: () => updatePaymentStatus(element.id, CompanyBillingPaymentStatus.DONE),
    },
  ]

  return (
    <LineElementRow>
      <LineElementCol
        size={1}
        label={"Date"}
        content={`${element.month < 10 ? `0${element.month}` : element.month}/${element.year}`}
      />
      <LineElementCol
        size={2}
        label={"Montant HT"}
        content={<div style={{ fontSize: "1.2em" }}>{`${element.amount / 100}€`}</div>}
      />
      <LineElementCol
        size={2}
        label={"Montant TTC"}
        content={<div style={{ fontSize: "1.2em", fontWeight: 600 }}>{`${(element.amount / 100) * 1.2}€`}</div>}
      />
      <LineElementCol size={2} content={`📦 ${nbParcels} colis`} />
      <LineElementCol
        size={3}
        content={
          <>
            {element.status === "DONE" && (
              <div>
                <Icon circular name="check" size="small" inverted color="green" /> Terminé
              </div>
            )}
            {element.status === "PROCESSING" && (
              <div>
                <Icon circular name="cog" size="small" inverted color="yellow" /> Traitement en cours
              </div>
            )}
            {element.status === "NEW" && (
              <div>
                <Icon circular name="exclamation" size="small" inverted color="red" /> Nouveau
              </div>
            )}
          </>
        }
      />
      <LineElementCol
        size={2}
        content={
          <>
            {updatePaymentStatus !== null && (
              <Menu compact>
                <Dropdown text="Actions" direction="left" options={ACTIONS} simple item />
              </Menu>
            )}
          </>
        }
      />
    </LineElementRow>
  )
}

export default PaymentLineItem
