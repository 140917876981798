import { createReducer } from "@reduxjs/toolkit"
import { toFailure, toSuccess } from "actions"
import { API_GET_GENERATED_ORDERS, RESET_GENERATED_ORDERS } from "../actions/generated-orders"

const initialState = {
  stats: {
    loading: false,
    error: false,
    data: null,
  },
}

const generatedOrders = createReducer(initialState, {
  [API_GET_GENERATED_ORDERS]: state => {
    state.stats.loading = true
  },
  [toSuccess(API_GET_GENERATED_ORDERS)]: (state, action) => {
    state.stats.loading = false
    state.stats.error = false

    state.stats.data = action.payload.data
  },
  [toFailure(API_GET_GENERATED_ORDERS)]: state => {
    state.stats.loading = false
    state.stats.error = true
  },

  // RESET STATE
  [RESET_GENERATED_ORDERS]: state => {
    state.stats.data = null
    state.stats.loading = false
  },
})

export default generatedOrders
