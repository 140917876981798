import { ApolloClient, ApolloLink, InMemoryCache, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { BASE_API_URL } from "config/api"
import { createUploadLink } from "apollo-upload-client"

const httpLink = new createUploadLink({
  uri: `${BASE_API_URL}graphql`,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem("API_TOKEN")

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "x-pickme-authorization": token ? `Bearer ${token}` : "",
      "Apollo-Require-Preflight": "true",
    },
  }))

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log("GQL Errors", graphQLErrors)
  } else if (networkError) {
    console.log("Network Error", networkError)
  }
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, errorLink, httpLink]),
})
