import styled from "styled-components"
import { COLOR } from "utils/color"

export const PageWrapper = styled.div`
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${COLOR.LIGHT_WHITE};
`
