import {
  API_GET_NB_KEEPERS_FOR_PUSH_NOTIFICATIONS,
  API_SEARCH_TEST_KEEPER,
  API_SEND_TEST_PUSH_NOTIFICATION,
  API_SEND_PUSH_NOTIFICATIONS,
} from "actions/push-notifications"
import { FctServiceReturn } from "./services.types"

export function apiGetNbKeepersForPushNotifications({
  statuses,
  zipcodes,
}: {
  statuses: string
  zipcodes: string
}): FctServiceReturn<any> {
  return {
    type: API_GET_NB_KEEPERS_FOR_PUSH_NOTIFICATIONS,
    payload: {
      request: {
        url: `/admin/pushNotifications/nbKeepersAvailable`,
        params: {
          statuses,
          zipcodes,
        },
      },
    },
  }
}

export function apiSearchTestKeepers({ input }: { input: string }): FctServiceReturn<any> {
  return {
    type: API_SEARCH_TEST_KEEPER,
    payload: {
      request: {
        url: `/admin/pushNotifications/testKeeper`,
        params: {
          input,
        },
      },
    },
  }
}

export function apiSendTestPushNotification({
  keeperId,
  title,
  content,
  data,
}: {
  keeperId: string
  title: string
  content: string
  data: { redirectTo: string }
}): FctServiceReturn<any> {
  return {
    type: API_SEND_TEST_PUSH_NOTIFICATION,
    payload: {
      request: {
        method: "POST",
        url: `/admin/pushNotifications/sendTest`,
        data: {
          keeperId,
          title,
          content,
          data,
        },
      },
    },
  }
}

export function apiSendPushNotifications({
  statuses,
  zipcodes,
  title,
  content,
  data,
}: {
  statuses: string[]
  zipcodes: string[]
  title: string
  content: string
  data: { redirectTo: string }
}): FctServiceReturn<any> {
  return {
    type: API_SEND_PUSH_NOTIFICATIONS,
    payload: {
      request: {
        method: "POST",
        url: `/admin/pushNotifications/send`,
        data: {
          statuses,
          zipcodes,
          title,
          content,
          data,
        },
      },
    },
  }
}
