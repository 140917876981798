import React, { ReactElement } from "react"
import { Col, Row } from "reactstrap"
import styled from "styled-components"
import { Loader } from "semantic-ui-react"

import LineElementContainer from "components/Table/LineElementContainer"
import LineElementContent from "components/Table/LineElementContent"
import LineElementInfo from "components/Table/LineElementInfo"
import LineElementRow from "components/Table/LineElementRow"
import { COLOR } from "utils/color"

interface Header {
  name: string | ReactElement
  size: number
  value: string
  component?: (arg: any) => void
}

interface SimpleTableProps {
  headers: Header[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  lineHeight?: string
  footer?: ReactElement
  loading?: boolean
}

const HeaderRow = styled(Row)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5em 1em;
  justify-content: space-between;
  height: unset;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  height: 30px;
  border-radius: 10px;
  margin: 0 0 10px 0;
  box-shadow: 1px 2px 3px rgb(196 196 196 / 20%);
`

export const SimpleTableContainer = styled.div`
  padding: 10px 0;
`

export default function SimpleTable({
  headers,
  data,
  lineHeight = "sm",
  footer = null,
  loading = false,
}: SimpleTableProps): ReactElement {
  if (loading) return <Loader inline active />

  return (
    <SimpleTableContainer>
      <HeaderRow>
        {headers.map((header, index) => {
          return (
            <Col key={`header-${index}`} xs={header.size} style={{ display: "flex", justifyContent: "center" }}>
              <b>{header.name}</b>
            </Col>
          )
        })}
      </HeaderRow>

      {data.map((line, lineIndex) => {
        return (
          <LineElementContainer key={`line-${lineIndex}`} lineHeight={lineHeight}>
            <LineElementRow>
              {headers.map((header, colIndex) => {
                return (
                  <Col
                    key={`line-${lineIndex}-col-${colIndex}`}
                    xs={header.size}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <LineElementInfo>
                      <LineElementContent>
                        <b>{header.component ? header.component(line[header.value]) : line[header.value]}</b>
                      </LineElementContent>
                    </LineElementInfo>
                  </Col>
                )
              })}
            </LineElementRow>
          </LineElementContainer>
        )
      })}
      {footer && (
        <LineElementContainer key={`line-footer`} lineHeight={lineHeight}>
          <LineElementRow
            style={{ backgroundColor: COLOR.WHITE_SMOKE, fontWeight: 600, fontSize: "1.1em", margin: "0" }}
          >
            {headers.map((header, colIndex) => {
              return (
                <Col
                  key={`line-footer-col-${colIndex}`}
                  xs={header.size}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LineElementInfo>
                    <LineElementContent>
                      <b>{header.component ? header.component(footer[header.value]) : footer[header.value]}</b>
                    </LineElementContent>
                  </LineElementInfo>
                </Col>
              )
            })}
          </LineElementRow>
        </LineElementContainer>
      )}
    </SimpleTableContainer>
  )
}
