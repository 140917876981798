import { API_GET_EXTRACTS } from "actions/extracts"
import { ApiGetExtractsData, ApiGetExtractsInput } from "types/extracts.types"

export function apiGetExtracts({
  dataType,
  keepersStatuses,
  keepersZipcodes,
  keepersAgenda,
  leadsTypes,
  leadsZipcodes,
  leadsStatus,
}: ApiGetExtractsInput): ApiGetExtractsData {
  return {
    type: API_GET_EXTRACTS,
    payload: {
      request: {
        url: `/admin/extracts?dataType=${dataType}&keepersStatuses=${keepersStatuses}&keepersZipcodes=${keepersZipcodes}&keepersAgenda=${keepersAgenda}&leadsTypes=${leadsTypes}&leadsZipcodes=${leadsZipcodes}&leadsStatus=${leadsStatus}`,
      },
    },
  }
}
