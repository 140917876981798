export enum EventEntityName {
  GLOBAL = "global",
  KEEPER = "keeper",
  COMPANY = "company",
  CARRIER = "carrier",
  RETAILER = "retailer",
  ORDER = "order",
  PARCEL = "parcel",
  PAYMENT = "payment",
}

export class EventEntity {
  public id!: string
  public entity!: EventEntityName
  public entityId?: string
  public title!: string
  public eventCode?: string
  public eventReason?: string
  public eventDate?: Date
  public description?: string
  public topicName?: string
  public data?: any
  public externalAttributes?: Record<string, string>
  public transmittedToCarrier!: boolean
  public transmittedToRetailer!: boolean
  public createdAt?: Date
  public updatedAt?: Date
}

export enum EventType {
  SECONDARY = "Secondary",
  PRINCIPAL = "Principal",
}

export enum EventEmitterType {
  CARRIER = "Carrier",
  KEEPER = "Keeper",
  ADMIN = "Admin",
  SYSTEM = "System",
}
