import React, { ReactElement, useState } from "react"
import { Loader } from "semantic-ui-react"
import { CompanyBillingPayment, CompanyBillingPaymentStatus, CompanyCurrentBill } from "types/company-billing.types"
import LineCollapsableElement from "components/List/LineCollapsableElement"
import PaymentCollapsible from "components/Companies/Billing/PaymentCollapsible"
import PaymentLineItem from "components/Companies/Billing/PaymentLineItem"

interface CompanyPaymentTableProps {
  payments: CompanyBillingPayment[] | [CompanyCurrentBill]
  loading: boolean
  updatePaymentStatus?: (id: string, status: CompanyBillingPaymentStatus) => void
}

export function CompanyPaymentTable({
  payments,
  loading,
  updatePaymentStatus,
}: CompanyPaymentTableProps): ReactElement {
  const [collapseId, setCollapseId] = useState(null)

  const collapseElt = (_collapseId: string) => {
    setCollapseId(collapseId === null ? _collapseId : null)
  }

  if (loading) return <Loader active inline />

  return (
    <>
      {payments
        .slice()
        .sort((a, b) => b.year - a.year || b.month - a.month)
        .map(payment => (
          <LineCollapsableElement
            key={`collapsible-payment-${payment.id}`}
            collapseId={collapseId}
            collapseElt={collapseElt}
            element={payment}
            lineHeight="lg"
            lineData={<PaymentLineItem element={payment} updatePaymentStatus={updatePaymentStatus} />}
            collapsibleData={<PaymentCollapsible element={payment} />}
          />
        ))}
    </>
  )
}
