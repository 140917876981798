import { allowedFileExtensions } from "./allowedFileExtensions"

export const validateFileExtension = (file: File | null): boolean => {
  if (!file) {
    return false
  }

  const fileExtension = file.name.split(".").pop().toLowerCase()

  if (!fileExtension || !allowedFileExtensions.includes(fileExtension)) {
    return false
  }

  return true
}
