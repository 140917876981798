import { Keeper, KeeperStatus } from "./keeper.types"
import { Lead } from "./lead.types"

export enum DataTypeDefault {
  KEEPERS = "KEEPERS",
  LEADS = "LEADS",
  TOP_KEEPERS_AREAS = "TOP_KEEPERS_AREAS",
  RELAISCOLIS_KEEPERS = "RELAISCOLIS_KEEPERS",
}

export enum ExtractKeeperStatus {
  NEW = "NEW",
  PENDING = "PENDING",
  VALIDATED = "VALIDATED",
}

export enum ExtractKeeperAgenda {
  NO_AVAILABILITIES_NEXT_WEEK = "NO_AVAILABILITIES_NEXT_WEEK",
  ALL = "ALL",
}

export const ExtractKeeperAgendaValueLabel = [
  {
    value: ExtractKeeperAgenda.NO_AVAILABILITIES_NEXT_WEEK,
    label: "SANS DISPONIBILITÉS SEMAINE PROCHAINE",
  },
  {
    value: ExtractKeeperAgenda.ALL,
    label: "TOUS LES KEEPERS",
  },
]

export enum ExtractLeadType {
  APP_KEEPER = "APP_KEEPER",
  LANDING = "LANDING",
}

export enum ExtractLeadStatus {
  YES = "YES",
  NO = "NO",
  ALL = "ALL",
}

export const LeadsStatusValueLabel = [
  {
    value: ExtractLeadStatus.YES,
    label: "OUI",
  },
  {
    value: ExtractLeadStatus.NO,
    label: "NON",
  },
  {
    value: ExtractLeadStatus.ALL,
    label: "TOUT",
  },
]

export interface ApiGetExtractsInput {
  dataType: DataTypeDefault

  keepersStatuses?: string
  keepersZipcodes?: string
  keepersAgenda?: ExtractKeeperAgenda

  leadsTypes?: string
  leadsZipcodes?: string
  leadsStatus?: ExtractLeadStatus
}

export interface ApiGetExtractsData {
  type: string
  payload: {
    data?: Keeper[] | Lead[] | RelaisColisKeeperExtract[]

    request?: {
      method?: string
      url: string
      data?: any
    }
  }
}

export interface KeeperCSV {
  firstName: string
  lastName: string
  email: string
  zipcode: string
  status: KeeperStatus
}

export interface LeadCSV {
  email: string
  zipcode: string
  createdAt: Date
  isKeeper: string
}

export interface TopKeepersAreasCSV {
  keeper: string
  createdAt: string
  email: string
  birthdate: string
  phone: string
  address: string
  floor: string
  zipcode: string
  maxParcels: number
  orders_nb: string
  ratings_nb: string
  ratings_avg: string
}

export type RelaisColisKeeperExtract = {
  name: string
  ambassador: boolean
  phone: string
  email: string
  address: string
  zipcode: string
  city: string
  longitude: number
  latitude: number
  status: ExtractKeeperStatus
  nb_colis: number
  maxParcels: number
  tue_punctual_9_12: boolean
  tue_punctual_12_15: boolean
  tue_punctual_9_15: boolean
  wed_punctual_9_12: boolean
  wed_punctual_12_15: boolean
  wed_punctual_9_15: boolean
  thu_punctual_9_12: boolean
  thu_punctual_12_15: boolean
  thu_punctual_9_15: boolean
  fri_punctual_9_12: boolean
  fri_punctual_12_15: boolean
  fri_punctual_9_15: boolean
  sat_punctual_9_12: boolean
  sat_punctual_12_15: boolean
  sat_punctual_9_15: boolean
  tue_recurring_9_12: boolean
  tue_recurring_12_15: boolean
  tue_recurring_9_15: boolean
  wed_recurring_9_12: boolean
  wed_recurring_12_15: boolean
  wed_recurring_9_15: boolean
  thu_recurring_9_12: boolean
  thu_recurring_12_15: boolean
  thu_recurring_9_15: boolean
  fri_recurring_9_12: boolean
  fri_recurring_12_15: boolean
  fri_recurring_9_15: boolean
  sat_recurring_9_12: boolean
  sat_recurring_12_15: boolean
  sat_recurring_9_15: boolean
}
