import React, { ReactElement } from "react"
import { EventEntity, EventType } from "types/event.types"
import { format } from "date-fns-tz"
import { getEventType } from "components/ParcelEvent/getEventType"
import {
  BreakHorizontalLine,
  DatePrincipalWrap,
  EventDesc,
  EventWrap,
  IconWrap,
  TimeWrap,
} from "components/ParcelEvent/ParcelEvent.styled"
import { areEventsOnDifferentDays, getIcon } from "components/ParcelEvent/utils"

interface ParcelEventProps {
  event: EventEntity
  prevEventDate: Date | null
}

const ParcelEvent = ({ event, prevEventDate }: ParcelEventProps): ReactElement => {
  /** If you want to add a new event, go to the getEventType function */
  const eventType = getEventType(event)
  const isEventPrincipal = eventType.type === EventType.PRINCIPAL
  const isEventParcelCreated = event.eventCode === "PARCEL_CREATED"

  /** The variable showDate determines whether the DatePrincipalWrap component will be displayed */
  const showDate = isEventParcelCreated
    ? true
    : prevEventDate
    ? areEventsOnDifferentDays(event.eventDate, prevEventDate)
    : false

  return (
    <>
      <EventWrap marginBottom={showDate ? "10px" : isEventPrincipal && eventType.showReason ? "16px" : "13px"}>
        {showDate ? (
          <DatePrincipalWrap>
            <div>{format(new Date(event.eventDate), "MMM", { timeZone: "Europe/Paris" })}</div>
            <div>{format(new Date(event.eventDate), "dd/MM", { timeZone: "Europe/Paris" })}</div>
          </DatePrincipalWrap>
        ) : (
          <DatePrincipalWrap />
        )}
        <IconWrap>{getIcon(event, eventType.showReason, showDate)}</IconWrap>
        <TimeWrap>{format(new Date(event.eventDate), "HH:mm", { timeZone: "Europe/Paris" })}</TimeWrap>
        <EventDesc>{getEventType(event).text}</EventDesc>
      </EventWrap>
      {showDate && prevEventDate && (
        <EventWrap marginBottom={"0px"}>
          <DatePrincipalWrap />
          <BreakHorizontalLine />
        </EventWrap>
      )}
    </>
  )
}

export default ParcelEvent
