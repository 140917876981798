import React, { Fragment, ReactElement } from "react"

import KeeperScoring from "components/KeeperScoring/KeeperScoring"
import { Container, RatingContainer, ParcelNbContainer, ParcelNbBold } from "components/Keeper/KeeperRating.styled"
import { KeeperScoring as KeeperScoringProps } from "types/keeper.types"

interface KeeperRatingProps {
  keeperScoring: KeeperScoringProps
  nbOngoingParcels?: number
  nbTotalDeliveredParcels?: number
  nbMaxParcels?: number
  containerStyle?: React.CSSProperties
}

export default function KeeperRatingComponent({
  keeperScoring,
  nbOngoingParcels,
  nbTotalDeliveredParcels,
  nbMaxParcels,
  containerStyle,
}: KeeperRatingProps): ReactElement {
  return (
    <Container style={containerStyle}>
      <RatingContainer>
        <Fragment>
          <ParcelNbContainer>
            <ParcelNbBold>{nbTotalDeliveredParcels}</ParcelNbBold> colis livrés ({nbOngoingParcels}/{nbMaxParcels} en
            cours)
          </ParcelNbContainer>
          <KeeperScoring keeperScoring={keeperScoring} />
        </Fragment>
      </RatingContainer>
    </Container>
  )
}
