import { useEffect, useMemo, useState } from "react"
import _ from "lodash"

export type useInfinityScrollProps = {
  scrollingTableSelector: string
  hasMore: boolean
  loading: boolean
  fetchMore: () => Promise<unknown>
}

const useInfinityScroll = ({ scrollingTableSelector, hasMore, loading, fetchMore }: useInfinityScrollProps) => {
  const [isFetchRequired, setIsFetchRequired] = useState<boolean>(false)
  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const isFetching = useMemo(() => loading || isFetchingMore, [loading || isFetchingMore])

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const node = document.querySelector<HTMLElement>(scrollingTableSelector)
    if (!node) return
    const _scrollHandler = () => {
      const ratio = node.scrollHeight - (node.scrollTop + node.clientHeight)
      // The lower the value we compare to ratio, the lower the scroll slider must go to fetch new data
      if (ratio < 100) {
        setIsFetchRequired(true)
      }
    }
    const scrollHandler = _.throttle(_scrollHandler, 200)
    if (hasMore) {
      node.addEventListener("scroll", scrollHandler)
      return () => {
        node.removeEventListener("scroll", scrollHandler)
      }
    }
  }, [hasMore])

  useEffect(() => {
    if (isFetchRequired && !isFetching) {
      setIsFetchRequired(false)
      setIsFetchingMore(true)
      fetchMore().finally(() => {
        setIsFetchingMore(false)
      })
    }
  }, [isFetchRequired, fetchMore, isFetching])
}

export default useInfinityScroll
