export const hoursOptions = [
  { label: "7h", value: "07:00:00" },
  { label: "8h", value: "08:00:00" },
  { label: "8h30", value: "08:30:00" },
  { label: "9h", value: "09:00:00" },
  { label: "9h30", value: "09:30:00" },
  { label: "10h", value: "10:00:00" },
  { label: "10h30", value: "10:30:00" },
  { label: "11h", value: "11:00:00" },
  { label: "11h30", value: "11:30:00" },
  { label: "12h", value: "12:00:00" },
  { label: "12h30", value: "12:30:00" },
  { label: "13h", value: "13:00:00" },
  { label: "13h30", value: "13:30:00" },
  { label: "14h", value: "14:00:00" },
  { label: "14h30", value: "14:30:00" },
  { label: "15h", value: "15:00:00" },
  { label: "15h30", value: "15:30:00" },
  { label: "16h", value: "16:00:00" },
  { label: "16h30", value: "16:30:00" },
  { label: "17h", value: "17:00:00" },
  { label: "17h30", value: "17:30:00" },
  { label: "18h", value: "18:00:00" },
  { label: "18h30", value: "18:30:00" },
  { label: "19h", value: "19:00:00" },
  { label: "19h30", value: "19:30:00" },
  { label: "20h", value: "20:00:00" },
  { label: "20h30", value: "20:30:00" },
  { label: "21h", value: "21:00:00" },
  { label: "21h30", value: "21:30:00" },
]

const earlyHoursOptions = [
  { label: "0h", value: "00:00:00" },
  { label: "1h", value: "01:00:00" },
  { label: "2h", value: "02:00:00" },
  { label: "3h", value: "03:00:00" },
  { label: "4h", value: "04:00:00" },
  { label: "5h", value: "05:00:00" },
  { label: "6h", value: "06:00:00" },
]

const lateHoursOptions = [
  { label: "22h", value: "22:00:00" },
  { label: "23h", value: "23:00:00" },
  { label: "24h", value: "24:00:00" },
]

export const fullHoursOptions = [...earlyHoursOptions, ...hoursOptions, ...lateHoursOptions]
