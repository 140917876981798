import React, { ReactElement } from "react"

import { TextStatusContainer, TextStatusTitle } from "components/Keeper/Address/status/AddressStatusShip.styled"
import { AddressStatus } from "types/address.types"
import { COLOR } from "utils/color"

interface PreviousAddressStatusProps {
  status: AddressStatus
}

export default function PreviousAddressStatusShip({ status }: PreviousAddressStatusProps): ReactElement {
  return (
    status === AddressStatus.INACTIVE && (
      <TextStatusContainer statusColor={COLOR.CORAIL}>
        <TextStatusTitle>Ancienne adresse</TextStatusTitle>
      </TextStatusContainer>
    )
  )
}
