import React, { HTMLInputTypeAttribute, ReactElement, useCallback, useRef, useState } from "react"
import { FieldProps } from "formik/dist/Field"
import Compressor from "compressorjs"
import styled from "styled-components"
import { Form as SemForm } from "semantic-ui-react"

import FilterBarZipcodes from "components/FilterBarZipcodes/FilterBarZipcodes"
import Select from "components/Form/Select"
import { HiddenFileInput } from "components/HiddenFileInput/HiddenFileInput"
import { FormError } from "components/Form/FormError"
import { MiniButton } from "components/Buttons/MiniButton"
import { Textarea } from "components/Notes/NoteHandler.styled"

import { COLOR } from "utils/color"
import { validateFileExtension } from "utils/formik/validateFileExtension"

const UploadMiniButton = styled(MiniButton)`
  margin-top: 11px;
  color: ${COLOR.BLACK};
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  &:hover {
    background-color: ${COLOR.WHITE_SMOKE};
  }
`

const StyledTextArea = styled(Textarea)`
  display: block;
  width: 100%;
`

const FieldLabel = styled.span`
  color: #5f5f5f;
  font-size: 10px;
  font-weight: 300;
  & + .filter-bar {
    margin: 0;
  }
`

const ImageDisplay = ({ file }) => {
  const [imageUrl, setImageUrl] = useState(typeof file === "string" ? file : null)

  const handleFileSelect = () => {
    if (file instanceof File || file instanceof Blob) {
      const reader = new FileReader()

      reader.onloadend = () => {
        setImageUrl(reader.result as string)
      }

      reader.readAsDataURL(file)
    }
  }

  // Вызывается при изменении файла
  React.useEffect(() => {
    handleFileSelect()
  }, [file])

  return (
    <div>
      {imageUrl ? <img src={imageUrl} alt="Preview" style={{ maxWidth: "600px" }} /> : <p>No image selected</p>}
    </div>
  )
}

export const redirectToOptions = [
  {
    label: "Dashboard screen",
    value: "Dashboard",
  },
  {
    label: "Agenda screen",
    value: "Agenda",
  },
  {
    label: "External URL",
    value: "",
  },
]

export interface CustomInputComponentProps {
  type: HTMLInputTypeAttribute
  label: string
  placeholder: string
  setRedirectToOption?: (option: (typeof redirectToOptions)[number]["value"]) => void
}

export const CustomEventPopupInputComponent: React.ComponentType<FieldProps<any> & CustomInputComponentProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  placeholder,
  label,
  setRedirectToOption,
  ...props
}) => {
  let inputElement: ReactElement = null
  const inputFileRef = useRef<HTMLInputElement>(null)

  const handleInputClick = () => inputFileRef?.current.click()

  const handleFileChange = useCallback(async e => {
    const file = e.target.files[0]

    if (!validateFileExtension(file)) return null

    new Compressor(file, {
      success: compressedFile => {
        setFieldValue(field.name, compressedFile)
      },
    })
  }, [])

  switch (props.type) {
    case "zipcodes": {
      inputElement = (
        <FilterBarZipcodes
          onChangeZipcodes={zipcodes =>
            setFieldValue(
              "zipcodes",
              zipcodes.map(({ value }) => value),
            )
          }
          defaultZipcodes={field.value.map(value => ({ label: value, value }))}
          showQuickAccessChip={false}
        />
      )
      break
    }
    case "textarea": {
      inputElement = <StyledTextArea {...field} {...props} rows={5} placeholder={placeholder} />
      break
    }
    case "select": {
      inputElement = (
        <Select
          onChange={({ value }) => {
            setFieldValue(field.name, value)
            setRedirectToOption(value)
          }}
          options={redirectToOptions}
          value={redirectToOptions.find(option => option.value === field.value || option.value === "")}
          placeholder=""
        />
      )
      break
    }
    case "image": {
      inputElement = (
        <>
          <ImageDisplay file={field.value} />
          <UploadMiniButton onClick={handleInputClick}>Ajouter</UploadMiniButton>
          <HiddenFileInput handleFileChange={handleFileChange} ref={inputFileRef} />
        </>
      )
      break
    }
    default: {
      inputElement = <SemForm.Input {...field} {...props} fluid placeholder={placeholder} />
    }
  }
  return (
    <div>
      {!!label && <FieldLabel>{label}</FieldLabel>}
      {inputElement}
      {touched[field.name] && errors[field.name] && (
        <FormError style={{ marginTop: 0 }}>{errors[field.name] as string}</FormError>
      )}
    </div>
  )
}
