export const API_SEND_EVENTS = "API_SEND_EVENTS"
export const API_GET_TRACKING = "API_GET_TRACKING"
export const RESET_TRACKING = "RESET_TRACKING"

/* System updates */
export const API_GET_SYSTEM_UPDATES = "API_GET_SYSTEM_UPDATES"
export const API_GET_SYSTEM_STATS = "API_GET_SYSTEM_STATS"
export const RESET_SYSTEM_UPDATES = "RESET_SYSTEM_UPDATES"

/* Integration updates */
export const API_GET_INTEGRATION_UPDATES = "API_GET_INTEGRATION_UPDATES"
export const API_GET_INTEGRATION_STATS = "API_GET_INTEGRATION_STATS"
export const RESET_INTEGRATION_UPDATES = "RESET_INTEGRATION_UPDATES"
