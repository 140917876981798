import React, { ReactElement } from "react"
import {
  EntryItem,
  Section,
  SectionsWrapper,
} from "components/ParcelBilling/ParcelBillingModal/ParcelBillingModal.styled"
import {
  ActionType,
  AddBillingEntry,
  CompanyBillingEntryDataItem,
  TargetEntryType,
  WalletEntryDataItem,
} from "types/billing-modal.types"
import { v1 as uuidV1 } from "uuid"

interface AddBillingsSectionProps {
  companyBillingEntryData: CompanyBillingEntryDataItem[]
  walletEntryData: WalletEntryDataItem[]
  addBillingEntry: ({ id, actionType, targetEntry, entryText, entryType }: AddBillingEntry) => void
}

export const AddBillingsSection = ({
  companyBillingEntryData,
  walletEntryData,
  addBillingEntry,
}: AddBillingsSectionProps): ReactElement => {
  return (
    <SectionsWrapper marginBottom={50}>
      <Section>
        {companyBillingEntryData.map((companyBillingEntry: CompanyBillingEntryDataItem) => (
          <EntryItem
            disabled={companyBillingEntry.isDisabled}
            onClick={() =>
              addBillingEntry({
                id: uuidV1(),
                actionType: ActionType.ADD,
                targetEntry: TargetEntryType.BILLING,
                entryText: companyBillingEntry.entryText,
                entryType: companyBillingEntry.entryType,
              })
            }
            key={companyBillingEntry.key}
          >
            + Ajouter une facturation “{companyBillingEntry.entryText}”
          </EntryItem>
        ))}
      </Section>
      <Section>
        {walletEntryData.map((walletEntry: WalletEntryDataItem) => (
          <EntryItem
            disabled={walletEntry.isDisabled}
            onClick={() =>
              addBillingEntry({
                id: uuidV1(),
                actionType: ActionType.ADD,
                targetEntry: TargetEntryType.WALLET,
                entryText: walletEntry.entryText,
                entryType: walletEntry.entryType,
              })
            }
            key={walletEntry.key}
          >
            + Ajouter un crédit “{walletEntry.entryText}”
          </EntryItem>
        ))}
      </Section>
    </SectionsWrapper>
  )
}
