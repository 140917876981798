import React, { ReactElement } from "react"
import Zoom from "react-medium-image-zoom"
import { Icon } from "semantic-ui-react"
import styled from "styled-components"
import { COLOR } from "utils/color"

const ProfilePictureDefault = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 20px;
  background-color: ${COLOR.LIGHT_GREY};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

function KeeperProfilePicture({ url }: { url: string }): ReactElement {
  if (!url)
    return (
      <ProfilePictureDefault>
        <Icon name={"user"} style={{ color: COLOR.WHITE, margin: 0, height: "unset" }} />
      </ProfilePictureDefault>
    )

  return (
    <Zoom>
      <img alt="Profile picture" src={url} height="40" style={{ borderRadius: 20, marginRight: 20 }} />
    </Zoom>
  )
}

export default KeeperProfilePicture
