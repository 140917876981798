import React, { ReactElement } from "react"
import styled from "styled-components"
import { Col, Row } from "reactstrap"
import LineCollapsableElement from "components/List/LineCollapsableElement"
import { LoadingComponent } from "components/LoadingComponent"
import LineItemRating from "components/Ratings/RatingsLineItem"
import { Rating } from "types/ratings.types"
import { COLOR } from "utils/color"

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TableStats = styled.div`
  font-size: 10px;
  color: ${COLOR.LIGHT_GREY};
`

const Container = styled.div`
  padding: 10px 0;
`

interface RatingsListProps {
  ratings: Rating[]
  loading: boolean
  total: number | null
  style?: any
}

function RatingsList({ ratings, loading = false, total = null, style = {} }: RatingsListProps): ReactElement {
  if (ratings.length === 0) return <div className="no-content">Aucun rating</div>

  return (
    <Container style={style}>
      <TableHeader>
        <TableStats>{total !== null && `${ratings.length}/${total} ratings chargés`}</TableStats>
      </TableHeader>

      <Row className="line-element-row" style={{ height: "unset" }}>
        <Col xs={1}>
          <b>Créé le</b>
        </Col>
        <Col xs={1}>
          <b>Commande</b>
        </Col>
        <Col xs={2}>
          <b>De</b>
        </Col>
        <Col xs={2}>
          <b>Keeper</b>
        </Col>
        <Col xs={1}>
          <b>Note</b>
        </Col>
        <Col xs={5}>
          <b>Feedback</b>
        </Col>
      </Row>

      {loading && <LoadingComponent />}

      {ratings
        .slice()
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .map(rating => (
          <LineCollapsableElement
            key={`linecollapsible-rating-${rating.id}`}
            collapseId={null}
            element={rating}
            lineData={<LineItemRating element={rating} />}
            collapsibleData={null}
          />
        ))}
    </Container>
  )
}

export default RatingsList
