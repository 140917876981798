import styled from "styled-components"

import { COLOR } from "utils/color"

const LineElementContainer = styled.div<{ lineHeight: string; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ disabled }) => (disabled ? "rgba(0, 0, 0, 0.002)" : `${COLOR.WHITE}`)};
  justify-content: center;
  margin-bottom: 5px;
  border-radius: 10px;
  box-shadow: 1px 2px 3px rgb(196 196 196 / 20%);
  &:hover {
    background-color: rgba(0, 0, 0, 0.002);
  }
  height: ${({ lineHeight }) =>
    lineHeight === "unset" ? "unset" : lineHeight === "lg" ? "80px" : lineHeight === "md" ? "60px" : "45px"};
`

export default LineElementContainer
