import React, { ReactElement, useEffect, useState } from "react"
import { Button } from "semantic-ui-react"

import LineCollapsableElement from "components/List/LineCollapsableElement"
import { LoadingComponent } from "components/LoadingComponent"
import LineItemParcel from "components/Parcels/LineItemParcel"
import { ParcelStatusFilterChip } from "components/Chips/ParcelStatusFilterChip"
import { DynamicParcelListContainer, FiltersContainer, TableHeader } from "components/Parcels/DynamicParcelList.styled"
import { PARCEL_STATUS_LIST_FILTERS } from "components/Parcels/parcelStatusListFilters"
import { DynamicParcelsListGQLProps } from "types/parcel.types"

function DynamicParcelsListGQL({
  parcels,
  activeStatus,
  totalCount,
  setPreviousParcelResultsCount,
  loading,
  nbParcelsResults,
  moreToLoad,
  style,
  fetchPreviousAddressesParcels,
  provider,
}: DynamicParcelsListGQLProps): ReactElement {
  const [activeFilter, setActiveFilter] = useState(activeStatus)
  const nbRemainingParcelsToQuery = totalCount - nbParcelsResults > nbParcelsResults ? nbParcelsResults * 2 : totalCount

  useEffect(() => {
    if (activeFilter) {
      fetchPreviousAddressesParcels(nbParcelsResults, activeFilter)
    } else {
      fetchPreviousAddressesParcels(nbParcelsResults)
    }
  }, [activeFilter])

  const fetchNext = filter => {
    if (filter) {
      fetchPreviousAddressesParcels(nbRemainingParcelsToQuery, filter)
    } else {
      fetchPreviousAddressesParcels(nbRemainingParcelsToQuery)
    }
    setPreviousParcelResultsCount(nbRemainingParcelsToQuery)
  }

  return (
    <DynamicParcelListContainer style={style}>
      <TableHeader>
        <FiltersContainer>
          {PARCEL_STATUS_LIST_FILTERS.filter(status => status.displayFor.includes(provider)).map(filter => (
            <ParcelStatusFilterChip
              key={`parcel-status-filter-chip-${filter.text.toLowerCase().replace(/\s+/g, "-")}`}
              text={filter.text}
              inactiveColor={filter.inactiveColor}
              activeColor={filter.activeColor}
              active={activeFilter === filter.status}
              onClick={() => {
                setActiveFilter(filter.status)
              }}
            />
          ))}
        </FiltersContainer>
      </TableHeader>
      {parcels.length === 0 && !loading && activeFilter !== null && <div className="no-content">Aucun colis</div>}
      {parcels.map(parcel => (
        <LineCollapsableElement
          key={`linecollapsible-parcel-${parcel.node.id}`}
          collapseId={null}
          element={parcel.node}
          lineHeight="sm"
          lineData={<LineItemParcel element={parcel.node} />}
          collapsibleData={null}
        />
      ))}

      {loading && <LoadingComponent />}

      {moreToLoad && (
        <Button style={{ marginTop: 10 }} onClick={() => fetchNext(activeFilter)}>
          Charger plus
        </Button>
      )}
    </DynamicParcelListContainer>
  )
}

export default DynamicParcelsListGQL
