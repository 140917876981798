import React, { ReactElement } from "react"
import Skeleton from "react-loading-skeleton"

import { Break } from "components/SearchKeeperMonitoring/DetailSearchKeeperLog.styled"

export const DetailSearchKeeperLogSkeleton = (): ReactElement => {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <Skeleton width={"250px"} height={"20px"} />
          <Skeleton width={"250px"} height={"20px"} />
          <Skeleton width={"250px"} height={"20px"} style={{ marginTop: 35 }} />
          <Skeleton width={"250px"} height={"20px"} />

          <Break />

          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
          <Skeleton width={"250px"} height={"15px"} />
        </div>
      </div>
    </>
  )
}
