import React from "react"
import { FieldsRow, Section, SectionTitle, StyledLabelInput } from "./RequestReturnModal.styled"
import { Col } from "reactstrap"
import Select from "components/Form/Select"
import { dimensionOptions } from "./data"
import { ReturnRequestFieldKeys, ReturnRequestFormData } from "types/return-request.types"

interface ParcelFieldsProps {
  returnRequestFormData: ReturnRequestFormData
  changeData: (key: ReturnRequestFieldKeys, value) => void
}

export const ParcelFields = ({ returnRequestFormData, changeData }: ParcelFieldsProps) => {
  return (
    <Section>
      <SectionTitle>📦 Colis</SectionTitle>
      <FieldsRow>
        <Col xs={6}>
          <StyledLabelInput>Poids du colis</StyledLabelInput>
          <Select
            value={returnRequestFormData.parcelDimensions}
            isDisabled={false}
            onChange={weight => changeData(ReturnRequestFieldKeys.parcelDimensions, weight)}
            options={dimensionOptions}
            placeholder={""}
          />
        </Col>
      </FieldsRow>
    </Section>
  )
}
