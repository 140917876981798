import styled from "styled-components"

export const TextStatusContainer = styled.div<{ statusColor: string }>(
  ({ statusColor }) => `
    ${statusColor ? "background:" + statusColor : ""};
    position: absolute;
    top: 9px;
    right: 10px;
    border-radius: 20px;
    padding: 5px 6px;
  `,
)

export const TextStatusTitle = styled.div`
  font-size: 9px;
  font-weight: 600;
  line-height: 1.15em;
  color: white;
`
