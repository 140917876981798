import React from "react"
import { Button, Header, Icon, Modal } from "semantic-ui-react"
import { ModalContent } from "./ConfirmReturnRequestCancellationModal.styled"

interface ConfirmCancelReturnRequestStyledProps {
  isOpened: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ConfirmReturnRequestCancellationModal = ({
  isOpened,
  onClose,
  onSubmit,
}: ConfirmCancelReturnRequestStyledProps) => {
  return (
    <Modal basic onClose={onClose} open={isOpened} size="small">
      <Header icon>
        <Icon name="attention" />
        En êtes vous sûr?
      </Header>
      <ModalContent>
        Nous enverrons une notification push et retournerons le questionnaire au keeper si vous confirmez.
      </ModalContent>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={onClose}>
          <Icon name="remove" /> Non !
        </Button>
        <Button
          color="green"
          inverted
          onClick={async () => {
            onSubmit()
            onClose()
          }}
        >
          <Icon name="checkmark" /> Confirmer
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
