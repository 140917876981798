// eslint-disable-next-line max-classes-per-file
import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { createFakeOrders } from "services/orders"
import { apiGetTracking, resetTracking, apiSendEvents } from "services/system"
import { Form, Field, Formik } from "formik"
import { Row, Col } from "reactstrap"
import MainContainer from "components/PageStructure/MainContainer"
import generateCompaniesOptions from "utils/formik/generateCompaniesOptions"
import generateKeeperAddressOptions from "utils/formik/generateKeeperAddressOptions"
import FormSaveButton from "components/Buttons/FormSaveButton"
import _ from "lodash"
import Title from "components/Title"
import generateOrdersOptions from "utils/formik/generateOrdersOptions"
import generateParcelsOptions from "utils/formik/generateParcelsOptions"
import Select from "components/Form/Select"
import { Loader } from "semantic-ui-react"
import moment from "moment"
import { apiParcelAskReturn } from "services/parcels"
import { ParcelEventCode } from "types/parcel-event.types"
import { Keeper } from "types/keeper.types"
import { Company } from "types/company.types"
import { Order } from "types/order.types"
import { Parcel } from "types/parcel.types"
import { EventEntity } from "types/event.types"

interface EventsTestingProps {
  createFakeOrders: () => {
    type: string
    payload: any // TODO: TYPE
    error?: any
  }
  apiSendEvents: (data: {
    parcels: Parcel[]
    details: {
      emitter: string
      emitterId: string
      action: string
    }
  }) => {
    type: string
    payload: any // TODO: TYPE
    error?: any
  }
  apiParcelAskReturn: (data: { parcelNo: string; carrierId: string }) => {
    type: string
    payload: any // TODO: TYPE
    error?: any
  }
  apiGetTracking: (
    id: string,
    type: string,
  ) => {
    type: string
    payload: any // TODO: TYPE
    error?: any
  }
  resetTracking: () => {
    type: string
    payload: any // TODO: TYPE
    error?: any
  }

  keepers: { loading: boolean; list: { loading: boolean; data: Keeper[] } }
  companies: { loading: boolean; list: { data: Company[] } }
  orders: { loading: boolean; list: { loading: boolean; data: Order[] } }
  tracking: {
    loading: boolean
    focus: { loading: boolean; data: { parcel: Parcel; order: Order; events: EventEntity[] } }
  }
}

interface EventsTestingState {
  trackedParcel: {
    value: string
    label: string
    type: string
  }
  systemUpdated: boolean
  updates: {
    parcelsResult: Parcel[]
    orders: Order[]
  }
}

class Events extends Component<EventsTestingProps, EventsTestingState> {
  constructor(props) {
    super(props)

    this.state = {
      trackedParcel: { value: "", label: "", type: "" },
      systemUpdated: false,
      updates: null,
    }
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.props.resetTracking()
  }

  generateTrackingOptions = (_orders, _parcels) => {
    const orders = _orders !== null ? generateOrdersOptions(_orders.map(i => ({ ...i.data, type: "ORDER" }))) : []
    const parcels = _parcels !== null ? generateParcelsOptions(_parcels.map(i => ({ ...i.data, type: "PARCEL" }))) : []

    return orders.concat(parcels)
  }

  onParcelTrackedChange = e => {
    this.setState({ trackedParcel: e })
    this.props.apiGetTracking(e.value, e.type)
  }

  submit = async values => {
    this.setState({ systemUpdated: false, updates: null })
    const data = {
      parcels: values.parcels.map(i => ({
        parcelNo: i.data.parcelNo,
        eventCode: values.event.value,
        eventDate: new Date(),
        from: "PACMAN FAKE",
      })),
      details: {
        emitter: values.as.type,
        emitterId: values.as.value,
        action: "PARCELS_UPDATES",
      },
    }

    const response = await this.props.apiSendEvents(data)
    this.setState({ systemUpdated: true, updates: response.payload.data })

    if (this.state.trackedParcel.value !== "") {
      this.props.apiGetTracking(this.state.trackedParcel.value, this.state.trackedParcel.type)
    }
  }

  render() {
    const { companies, keepers, orders, tracking } = this.props
    const { trackedParcel, systemUpdated, updates } = this.state

    const retailerEvents = []

    const carrierEventsBtoC = [
      // DELIVERY
      // { value: ParcelEventCode.CARRIER_BTOC_DELIVERY_INIT, label: "🚛📦 (Delivery BtoC) Parcel Init" }, // EventCode actually useless
      {
        value: ParcelEventCode.CARRIER_BTOC_DELIVERY_PICKUP_SUCCESS,
        label: "🚛📦 (Delivery BtoC) Parcel pickup success",
      },
      {
        value: ParcelEventCode.CARRIER_BTOC_DELIVERY_PICKUP_FAILURE,
        label: "🚛📦 (Delivery BtoC) Parcel pickup failure",
      },
      { value: ParcelEventCode.CARRIER_BTOC_DELIVERY_IN_TRANSIT, label: "🚛📦 (Delivery BtoC) Delivery in transit" },
      { value: ParcelEventCode.CARRIER_BTOC_DELIVERY_OUT_FOR_DELIVERY, label: "🚛📦 (Delivery BtoC) Out for delivery" },
      { value: ParcelEventCode.CARRIER_BTOC_DELIVERY_DELIVERY_SUCCESS, label: "🚛📦 (Delivery BtoC) Delivery success" },
      { value: ParcelEventCode.CARRIER_BTOC_DELIVERY_DELIVERY_FAILURE, label: "🚛📦 (Delivery BtoC) Delivery failure" },
      { value: ParcelEventCode.CARRIER_BTOC_DELIVERY_PARCEL_LOST, label: "🚛📦 (Delivery BtoC) Parcel lost" },
      {
        value: ParcelEventCode.CARRIER_BTOC_DELIVERY_RETURNED_TO_SENDER,
        label: "🚛📦 (Delivery BtoC) Returned to sender",
      },
      {
        value: ParcelEventCode.CARRIER_BTOC_DELIVERY_DELIVERY_CANCELLED,
        label: "🚛📦 (Delivery BtoC) Delivery cancelled",
      },

      // RETURN
      // { value: ParcelEventCode.CARRIER_BTOC_RETURN_INIT, label: "🚛💨 (Return BtoC) Parcel Init" }, // EventCode actually useless
      { value: ParcelEventCode.CARRIER_BTOC_RETURN_PICKUP_SUCCESS, label: "🚛💨 (Return BtoC) Pickup success" },
      { value: ParcelEventCode.CARRIER_BTOC_RETURN_PICKUP_FAILURE, label: "🚛💨 (Return BtoC) Pickup failure" },
      { value: ParcelEventCode.CARRIER_BTOC_RETURN_IN_TRANSIT, label: "🚛💨 (Return BtoC) In transit" },
      { value: ParcelEventCode.CARRIER_BTOC_RETURN_OUT_FOR_DELIVERY, label: "🚛💨 (Return BtoC) Out for delivery" },
      { value: ParcelEventCode.CARRIER_BTOC_RETURN_DELIVERY_SUCCESS, label: "🚛💨 (Return BtoC) Delivery success" },
      { value: ParcelEventCode.CARRIER_BTOC_RETURN_DELIVERY_FAILURE, label: "🚛💨 (Return BtoC) Delivery failure" },
      { value: ParcelEventCode.CARRIER_BTOC_RETURN_PARCEL_LOST, label: "🚛💨 (Return BtoC) Parcel lost" },
      { value: ParcelEventCode.CARRIER_BTOC_RETURN_PICKUP_CANCELLED, label: "🚛💨 (Return BtoC) Pickup cancelled" },
    ]

    const carrierEventsCtoB = [
      { value: ParcelEventCode.CARRIER_CTOB_DELIVERY_PICKUP_SUCCESS, label: "🚚 (Delivery CtoB) Pickup success" },
      { value: ParcelEventCode.CARRIER_CTOB_DELIVERY_PICKUP_FAILURE, label: "🚚 (Delivery CtoB) Pickup failure" },
      // { value: ParcelEventCode.CARRIER_CTOB_RETURN_PICKUP_CANCELLED, label: "🚚 (Delivery CtoB) Pickup cancelled" }, // EventCode will be deleted
    ]

    const eventsCtoB = [
      // { value: ParcelEventCode.DROPOFF_MEETING_MISSED, label: "📆 Dropoff meeting missed" },
      // { value: ParcelEventCode.KEEPER_NOT_AVAILABLE_FOR_DROPOFF, label: "📆 Keeper not available for Dropoff" },
      // {
      //   value: ParcelEventCode.KEEPER_NOT_AVAILABLE_FOR_CARRIER_PICKUP,
      //   label: "📆 Keeper not available for Carrier Pickup",
      // },
    ]

    const keeperEvents = [
      { value: ParcelEventCode.KEEPER_RECEPTION_SUCCESS, label: "😎 Keeper reception success" },
      { value: ParcelEventCode.KEEPER_RECEPTION_FAILURE, label: "😎 Keeper reception failure" },
      { value: ParcelEventCode.KEEPER_RECIPIENT_DELIVERY_SUCCESS, label: "😎 Recipient delivery success" },
      { value: ParcelEventCode.KEEPER_RECIPIENT_DELIVERY_FAILURE, label: "😎 Recipient delivery failure" },
      { value: ParcelEventCode.KEEPER_RETURNING_SUCCESS, label: "😎 Returning success" },
      { value: ParcelEventCode.KEEPER_RETURNING_FAILURE, label: "😎 Returning failure" },
      { value: ParcelEventCode.KEEPER_DROPOFF_SUCCESS, label: "😎 Dropoff success" },
      { value: ParcelEventCode.KEEPER_DROPOFF_FAILURE, label: "😎 Dropoff failure" },
      { value: ParcelEventCode.KEEPER_DROPOFF_DELIVERY_SUCCESS, label: "😎 Pickup Carrier success" },
      { value: ParcelEventCode.KEEPER_DROPOFF_DELIVERY_FAILURE, label: "😎 Pickup Carrier failure" },
    ]

    const events = [...retailerEvents, ...carrierEventsBtoC, ...carrierEventsCtoB, ...eventsCtoB, ...keeperEvents]

    return (
      <div style={{ padding: 40 }}>
        <Formik
          enableReinitialize
          initialValues={{
            orders: [],
            parcels: [],
            as: "",
            event: {
              value: null,
              label: null,
            },
            returningCarrier: {},
          }}
          onSubmit={values => {
            this.submit(values)
          }}
        >
          {({ errors, values }) => {
            return (
              <Form style={{ flex: 1, display: "flex" }}>
                <Row style={{ flex: 1, display: "flex" }}>
                  <Col xs={12} md={8}>
                    <Title>Send parcel events</Title>
                    <MainContainer title="Select parcels" style={{ padding: "10px 40px 10px 0" }}>
                      <Field
                        component={Select}
                        label="Orders"
                        required={false}
                        editable
                        name="orders"
                        placeholder="Filter by orders..."
                        type="local"
                        options={generateOrdersOptions(orders.list.data)}
                      />
                      <Field
                        component={Select}
                        label="parcels"
                        required={false}
                        editable
                        name="parcels"
                        placeholder="Choose parcels"
                        type="local"
                        options={
                          values.orders === null
                            ? []
                            : generateParcelsOptions(
                                values.orders.reduce((acc, i) => {
                                  // eslint-disable-next-line no-param-reassign
                                  acc = acc.concat(i.data.parcels)
                                  return acc
                                }, []),
                              )
                        }
                      />
                    </MainContainer>
                    <MainContainer title="Event" style={{ padding: "10px 40px 10px 0" }}>
                      <Field
                        component={Select}
                        label="As"
                        required
                        editable
                        name="as"
                        placeholder="Choose who is sending the event"
                        options={generateCompaniesOptions(companies.list.data).concat(
                          generateKeeperAddressOptions(keepers.list.data),
                        )}
                      />
                      <Field
                        component={Select}
                        label="Event"
                        required
                        editable
                        name="event"
                        placeholder="Event"
                        options={events}
                      />
                      {values.event.value === "onReturnRequested" && (
                        <Field
                          component={Select}
                          label="Returning carrier"
                          required
                          editable
                          name="returningCarrier"
                          placeholder="Choose carrier which handles the return"
                          options={generateCompaniesOptions(companies.list.data, "CARRIER")}
                        />
                      )}
                    </MainContainer>
                    <FormSaveButton
                      text="Envoyer"
                      type="submit"
                      disabled={!_.isEmpty(errors)}
                      style={{ marginTop: 20 }}
                    />

                    <br />
                    <br />
                    {systemUpdated && (
                      <Fragment>
                        <MainContainer title="Orders updated">
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            {updates.orders.map(order => (
                              <div className="order-update-result order" key={order.id}>
                                💳
                                <br />
                                <b>{order.orderNo}</b>
                                <br />
                                <br />
                                <div className="update-status">
                                  <div className="update-status-label">Status</div>
                                  <div className="chip">{order.status}</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </MainContainer>
                        <MainContainer title="Parcels updated">
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            {updates.parcelsResult.map(parcel => {
                              const lastUpdatedShipment = parcel.shipments
                                ?.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))
                                .find(() => true)

                              return (
                                <div className="order-update-result parcel" key={parcel.id}>
                                  📦
                                  <br />
                                  <b>{parcel.parcelNo}</b>
                                  <br />
                                  <br />
                                  <div className="update-status">
                                    <div className="update-status-label">Retailer</div>
                                    <div className="chip">{parcel.retailerStatus}</div>
                                  </div>
                                  <div className="update-status">
                                    <div className="update-status-label">Shipment ({lastUpdatedShipment.type})</div>
                                    <div className="chip">{lastUpdatedShipment.status}</div>
                                  </div>
                                  <div className="update-status">
                                    <div className="update-status-label">Status</div>
                                    <div className="chip">{parcel.status}</div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </MainContainer>
                      </Fragment>
                    )}
                  </Col>
                  <Col xs={12} md={4} style={{ flex: 1 }}>
                    <div className="parcel-event-log">
                      <div style={{ fontSize: 10, marginBottom: 5 }}>Tracking Events</div>
                      <Select
                        value={trackedParcel}
                        onChange={this.onParcelTrackedChange}
                        options={this.generateTrackingOptions(values.orders, values.parcels)}
                        placeholder="Choose to see events"
                      />
                      <div style={{ marginBottom: 10 }}></div>

                      {tracking.focus.loading && <Loader active inline />}
                      {!tracking.focus.loading && tracking.focus.data !== null && (
                        <Fragment>
                          {trackedParcel.value !== "" && (
                            <div className="order-update-result" style={{ width: "100%", marginBottom: 10 }}>
                              {trackedParcel.type === "PARCEL" && (
                                <Fragment>
                                  <div className="update-status">
                                    <div className="update-status-label">Retailer</div>
                                    <div className="chip">{tracking.focus.data.parcel.retailerStatus}</div>
                                  </div>
                                  <div className="update-status">
                                    <div className="update-status-label">Delivery shipment</div>
                                    <div className="chip">
                                      {
                                        tracking.focus.data.parcel.__shipments__.find(i => i.type === "DELIVERY")
                                          ?.status
                                      }
                                    </div>
                                  </div>
                                </Fragment>
                              )}
                              <div className="update-status">
                                <div className="update-status-label">Status</div>
                                <div className="chip">
                                  {trackedParcel.type === "PARCEL"
                                    ? tracking.focus.data.parcel.status
                                    : tracking.focus.data.order.status}
                                </div>
                              </div>
                            </div>
                          )}

                          <div>
                            {tracking.focus.data.events
                              .slice()
                              .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                              .map(event => (
                                <Row style={{ alignItems: "center", marginBottom: 5 }} key={event.id}>
                                  <Col xs={3}>
                                    <div style={{ fontSize: 10, color: "#9c9c9c" }}>
                                      {moment(event.eventDate).format("DD/MM/YYYY HH:mm")}
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div className="chip">{event.externalAttributes.status}</div>
                                  </Col>
                                  <Col xs={6}>
                                    <div style={{ fontSize: 10 }}>{event.title}</div>
                                  </Col>
                                </Row>
                              ))}
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  keepers: state.keepers,
  companies: state.companies,
  orders: state.orders,
  tracking: state.tracking,
})

const mapDispatchToProps = {
  createFakeOrders,
  apiSendEvents,
  apiParcelAskReturn,
  apiGetTracking,
  resetTracking,
}

export default connect(mapStateToProps, mapDispatchToProps)(Events)
