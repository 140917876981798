import { Company, CompanyAddress } from "./company.types"

export enum ShipmentStatus {
  CREATED = "CREATED",
  PLANNED = "PLANNED",
  IN_TRANSIT = "IN_TRANSIT",
  IN_DELIVERY = "IN_DELIVERY",
  DELIVERED = "DELIVERED",
  DELIVERY_FAILURE = "DELIVERY_FAILURE",
  LOST = "LOST",
}

export enum ShipmentType {
  DELIVERY = "DELIVERY",
  RETURN = "RETURN",
  DELIVERY_BTOC = "DELIVERY_BTOC",
  DELIVERY_CTOB = "DELIVERY_CTOB",
  RETURN_BTOC = "RETURN_BTOC",
}

export type ShipmentParcelMeta = {
  parcelId?: string
  cotransportationCode?: string
  referenceCode?: string
}

export type ShipmentPicking = {
  contact?: {
    firstName: string
    lastName: string
    phone: string
    email: string
  }
  address?: {
    streetLine: string
    postalCode: string
    city: string
    country: string
    instructions: string
    latitude: number
    longitude: number
  }
  interval?: { start: Date; end: Date }[]
  parcelShipmentMeta: ShipmentParcelMeta[]
}

export type Shipment = {
  id: string
  type: ShipmentType
  status: ShipmentStatus
  __carrier__?: Company
  carrier?: Company
  shipmentNo: string
  announcedDeliveryDate: string
  createdAt: string
  updatedAt: string
  picking?: ShipmentPicking
  carrierDeliveryHub?: CompanyAddress
  __carrierDeliveryHub__?: CompanyAddress
}
