import { toFailure, toSuccess } from "actions"
import { API_GET_ALL_SYSTEM_LOG } from "actions/systemLogs"
import { createReducer } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { API_SEND_EVENTS } from "actions/system"

const initialState = {
  list: {
    loading: false,
    error: false,
    data: [],
  },
  focus: {
    loading: false,
    error: false,
    data: {},
  },
}

const systemLogs = createReducer(initialState, {
  [API_GET_ALL_SYSTEM_LOG]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_ALL_SYSTEM_LOG)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data = action.payload.data
  },
  [toFailure(API_GET_ALL_SYSTEM_LOG)]: state => {
    state.list.loading = false
    state.list.error = true
  },
  [toFailure(API_SEND_EVENTS)]: () => {
    toast.error("Error")
  },
  [toSuccess(API_SEND_EVENTS)]: () => {
    toast.success("System updated")
  },
})

export default systemLogs
