import React, { ReactElement } from "react"
import { Image, Popup } from "semantic-ui-react"

import Star from "assets/components/icons/star.png"
import ActivityIcon from "assets/components/icons/activity.png"
import PhoneForward from "assets/components/icons/phone-forwarded.png"
import Lorry from "assets/components/icons/lorry.png"
import Calendar7 from "assets/components/icons/calendar7.png"
import Calendar30 from "assets/components/icons/calendar30.png"
import {
  KeeperScoringContainer,
  PopupTrigger,
  PopupContainer,
  PopupTitle,
  PopupRate,
  PopupDescription,
  PopupRangeContainer,
  PopupRedRange,
  PopupOrangeRange,
  PopupGreenRange,
  PopupCarrierDetail,
  PopupCarrierLine,
} from "components/KeeperScoring/KeeperScoring.styled"
import { KeeperScoring as KeeperScoringProps } from "types/keeper.types"
import { CarriersName } from "types/carrier.types"
import { displayMinutes } from "utils/time"
import { COLOR } from "utils/color"

const scorings = [
  {
    name: "rating",
    icon: <Image src={Star} style={{ padding: "4px" }} />,
    title: "Notation destinataires",
    suffixe: " /5",
    description: "Note moyenne donnée par les destinataires (30 derniers jours)",
    redValue: "< 3.5/5",
    orangeValue: "entre 3.5/5 et 4.5/5",
    greenValue: ">= 4.5/5",
  },
  {
    name: "deliveryFailureRate",
    icon: <Image src={ActivityIcon} style={{ padding: "4px" }} />,
    title: "Taux d’échec livraison",
    suffixe: "%",
    description: "Taux moyen d’échec de livraison déclaré par le transporteur (30 derniers jours)",
    redValue: "> 10%",
    orangeValue: "entre 10% et 2%",
    greenValue: "< 2%",
  },
  {
    name: "contactTime",
    icon: <Image src={PhoneForward} style={{ padding: "5px" }} />,
    title: "Rapidité de contact",
    suffixe: "",
    description: "Temps moyen de prise de contact avec le destinataire après la réception (30 derniers jours)",
    redValue: "> 1h",
    orangeValue: "entre 1h et 15min",
    greenValue: "< 15min",
  },
  {
    name: "deliveryTime",
    icon: <Image src={Lorry} style={{ padding: "5px" }} />,
    title: "Rapidité de livraison",
    suffixe: "",
    description: "Délai moyen de remise du colis au destinataire après la réception (30 derniers jours)",
    redValue: "> 48h",
    orangeValue: "entre 24h et 48h",
    greenValue: "< 24h",
  },
  {
    name: "commitment7Days",
    icon: <Image src={Calendar7} style={{ padding: "5px" }} />,
    title: "Engagement 7 derniers jours",
    suffixe: "j",
    description: "Nombre de jours disponibles 9h-15h en moyenne (hors dimanche, 7 derniers jours)",
    redValue: "< 4j",
    orangeValue: "4j",
    greenValue: ">= 5j",
  },
  {
    name: "commitment30Days",
    icon: <Image src={Calendar30} style={{ padding: "5px" }} />,
    title: "Engagement 30 derniers jours",
    suffixe: "j",
    description: "Nombre de jours disponibles 9h-15h en moyenne (hors dimanche, 7 derniers jours)",
    redValue: "< 4j",
    orangeValue: "4j",
    greenValue: ">= 5j",
  },
]

const getRateColor = (name: string, rate: number): string => {
  switch (name) {
    case "rating":
      if (rate < 3.5) return COLOR.RED
      else if (rate >= 3.5 && rate < 4.5) return COLOR.ORANGE
      else return COLOR.GREEN
    case "deliveryFailureRate":
      if (rate > 10) return COLOR.RED
      else if (rate <= 10 && rate > 2) return COLOR.ORANGE
      else return COLOR.GREEN
    case "contactTime":
      if (rate > 60) return COLOR.RED
      else if (rate <= 60 && rate > 15) return COLOR.ORANGE
      else return COLOR.GREEN
    case "deliveryTime":
      if (rate > 48 * 60) return COLOR.RED
      else if (rate <= 48 * 60 && rate > 24 * 60) return COLOR.ORANGE
      else return COLOR.GREEN
    case "commitment7Days":
      if (rate < 4) return COLOR.RED
      else if (rate === 4) return COLOR.ORANGE
      else return COLOR.GREEN
    case "commitment30Days":
      if (rate < 4) return COLOR.RED
      else if (rate === 4) return COLOR.ORANGE
      else return COLOR.GREEN
  }
}

const PopUpScoringContent = ({
  name,
  color,
  title,
  rate: rate,
  suffixe,
  description,
  redValue,
  orangeValue,
  greenValue,
}) => {
  let popupRate = rate
  if (rate === null) popupRate = null
  else if (name === "deliveryTime" || name === "contactTime") popupRate = displayMinutes(rate)
  else if (name === "deliveryFailureRate") popupRate = rate?.totalRate

  return (
    <PopupContainer>
      <PopupTitle>{title}</PopupTitle>
      <PopupRate color={color}>{popupRate !== null ? `${popupRate}${suffixe}` : "Données non disponibles"}</PopupRate>
      <PopupDescription color={COLOR.GREY}>{description}</PopupDescription>
      <PopupRangeContainer color={COLOR.GREY}>
        <PopupRedRange color={COLOR.RED} />
        {redValue}
        <PopupOrangeRange color={COLOR.ORANGE} />
        {orangeValue}
        <PopupGreenRange color={COLOR.GREEN} />
        {greenValue}
      </PopupRangeContainer>
      {rate && name === "deliveryFailureRate" && (
        <PopupCarrierDetail>
          Détail par transporteur:
          {typeof rate.colissimo === "number" && (
            <PopupCarrierLine>
              {CarriersName.COLISSIMO} : {rate.colissimo}
              {suffixe}
            </PopupCarrierLine>
          )}
          {typeof rate.dhl === "number" && (
            <PopupCarrierLine>
              {CarriersName.DHL} : {rate.dhl}
              {suffixe}
            </PopupCarrierLine>
          )}
          {typeof rate.gls === "number" && (
            <PopupCarrierLine>
              {CarriersName.GLS} : {rate.gls}
              {suffixe}
            </PopupCarrierLine>
          )}
          {typeof rate.geodis === "number" && (
            <PopupCarrierLine>
              {CarriersName.GEODIS} : {rate.geodis}
              {suffixe}
            </PopupCarrierLine>
          )}
          {typeof rate.relaisColis === "number" && (
            <PopupCarrierLine>
              {CarriersName.RELAISCOLIS} : {rate.relaisColis}
              {suffixe}
            </PopupCarrierLine>
          )}
        </PopupCarrierDetail>
      )}
    </PopupContainer>
  )
}

const KeeperScoring = ({ keeperScoring }: { keeperScoring: KeeperScoringProps }): ReactElement => {
  return (
    <KeeperScoringContainer>
      {scorings.map(scoring => {
        const { name, icon } = scoring
        // If the Keeper doesn't have any keeperScoring, simply display "Grey" indicator for all scores
        if (keeperScoring === null || keeperScoring[name] === null) {
          return (
            <Popup
              key={`keeper-scoring-${name}`}
              content={<PopUpScoringContent {...scoring} rate={null} color={COLOR.GREY} />}
              size="small"
              position="bottom center"
              trigger={<PopupTrigger color={COLOR.GREY}>{icon}</PopupTrigger>}
            />
          )
        }

        const rate = name === "deliveryFailureRate" ? keeperScoring[name].totalRate : keeperScoring[name]
        const color = rate !== null ? getRateColor(name, rate) : COLOR.GREY

        return (
          <Popup
            key={`keeper-scoring-${name}`}
            content={<PopUpScoringContent {...scoring} rate={keeperScoring[name]} color={color} />}
            size="small"
            position="bottom center"
            trigger={<PopupTrigger color={color}>{icon}</PopupTrigger>}
          />
        )
      })}
    </KeeperScoringContainer>
  )
}

export default KeeperScoring
