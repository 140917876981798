import React, { ReactElement, useState } from "react"
import { Button, Header, Icon, Loader, Modal, Popup } from "semantic-ui-react"

import GLSLogo from "assets/carriers/GLS.png"
import DHLLogo from "assets/carriers/DHL.png"
import RelaisColisLogo from "assets/carriers/RelaisColis.png"
import GeodisLogoSolo from "assets/carriers/logo-solo/Geodis.png"
import ColissionLogoSolo from "assets/carriers/logo-solo/Colissimo.png"
import { PopupMessage } from "components/Keeper/Keeper.styled"
import {
  ButtonContainer,
  CarrierLogo,
  CheckIconContainer,
  CheckIcon,
  CloseIconContainer,
  CloseIcon,
  LoaderIconContainer,
} from "components/Keeper/AllowedCarriers.styled"
import { CarriersName, AllowedCarrierProps, KeeperCarrierButtonProps } from "types/carrier.types"

function CarrierButton({ carrier, cursor, loading }: KeeperCarrierButtonProps): ReactElement {
  return (
    <ButtonContainer icon cursor={cursor}>
      {
        {
          [CarriersName.COLISSIMO]: <CarrierLogo src={ColissionLogoSolo} size="mini" />,
          [CarriersName.DHL]: <CarrierLogo src={DHLLogo} size="mini" />,
          [CarriersName.GEODIS]: <CarrierLogo src={GeodisLogoSolo} size="mini" />,
          [CarriersName.GLS]: <CarrierLogo src={GLSLogo} size="mini" />,
          [CarriersName.RELAISCOLIS]: <CarrierLogo src={RelaisColisLogo} size="mini" width={"25px"} />,
        }[carrier.carrierName]
      }
      {loading ? (
        <LoaderIconContainer>
          <Loader size="mini" active inverted />
        </LoaderIconContainer>
      ) : carrier.allowed ? (
        <CheckIconContainer>
          <CheckIcon name="check" size="small" />
        </CheckIconContainer>
      ) : (
        <CloseIconContainer>
          <CloseIcon name="close" size="small" />
        </CloseIconContainer>
      )}
    </ButtonContainer>
  )
}

function AllowedCarrier({ carrier, updateAllowedCarrier, loading }: AllowedCarrierProps): ReactElement {
  const [open, setOpen] = useState(false)

  if (
    carrier.carrierName === CarriersName.COLISSIMO ||
    carrier.carrierName === CarriersName.DHL ||
    carrier.carrierName === CarriersName.GLS
  ) {
    return (
      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="small"
        trigger={
          <Popup
            content={`Cliquer pour ${carrier.allowed ? "désactiver" : `activer`} ${carrier.carrierName}`}
            size="small"
            position="bottom left"
            trigger={
              <PopupMessage onClick={() => setOpen(true)}>
                <CarrierButton carrier={carrier} cursor loading={loading} />
              </PopupMessage>
            }
          />
        }
      >
        <Header icon>
          <Icon name="attention" />
          En êtes-vous vraiment sûr.e ?
        </Header>
        <Modal.Content style={{ display: "flex", justifyContent: "center" }}>
          Vous êtes sur le point {carrier.allowed ? "de désactiver" : `d'activer`} {carrier.carrierName} pour ce Keeper
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" inverted onClick={() => setOpen(false)}>
            <Icon name="remove" /> Non !
          </Button>
          <Button
            color="green"
            inverted
            onClick={() => {
              updateAllowedCarrier()
              setOpen(false)
            }}
          >
            <Icon name="checkmark" /> Confirmer
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  return <CarrierButton carrier={carrier} loading={loading} />
}

export default AllowedCarrier
