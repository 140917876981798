import React, { ReactElement } from "react"
import PinGreen from "assets/mapbox/pin_green.png"
import styled from "styled-components"
import PinRed from "assets/mapbox/pin_red.png"
import PinBlue from "assets/mapbox/pin_blue.png"
import PinOrange from "assets/mapbox/pin_orange.png"
import { Icon, Label } from "semantic-ui-react"
import { MapPin } from "components/Maps/MapPin"

export enum PickColor {
  RED_PICK = "RED_PICK",
  ORANGE_PICK = "ORANGE_PICK",
  GREEN_PICK = "GREEN_PICK",
  BLUE_PICK = "BLUE_PICK",
}

type PinColorsOptions = {
  value: PickColor
  label: string
  pin: string
  enabled: boolean
}[]

export const createOptions = (): PinColorsOptions => [
  { value: PickColor.RED_PICK, label: "Appels sans résultat", pin: PinRed, enabled: true },
  { value: PickColor.ORANGE_PICK, label: "Appels avec résultats", pin: PinOrange, enabled: true },
  {
    value: PickColor.BLUE_PICK,
    label: "Keepers renvoyés",
    pin: PinBlue,
    enabled: true,
  },
  { value: PickColor.GREEN_PICK, label: "Commandes crées", pin: PinGreen, enabled: true },
]

const PinOptionContainer = styled.div`
  display: flex;
`

const PinOptionSelector = styled.div`
  flex-direction: column;
  margin-right: 10px;
  cursor: pointer;
`

interface PinCheckboxesProps {
  pickColors: PinColorsOptions
  onActiveColorClick: (enabled: boolean, value: PickColor) => void
}

export function PinCheckboxes({ pickColors, onActiveColorClick }: PinCheckboxesProps): ReactElement {
  const onClick = pickColor => {
    const enabled = !pickColor.enabled
    onActiveColorClick(enabled, pickColor.value)
  }

  return (
    <PinOptionContainer>
      {pickColors.map((pickColor, index) => {
        return (
          <PinOptionSelector key={`pin-option-selector-${index}`} onClick={() => onClick(pickColor)}>
            <Label active={pickColor.enabled} style={{ backgroundColor: "transparent", fontWeight: "400", padding: 0 }}>
              <MapPin src={pickColor.pin} style={{ marginRight: 5 }} />
              {pickColor.label}
              <Icon
                style={{ marginLeft: 5 }}
                name={pickColor.enabled ? "eye" : "eye slash"}
                disabled={!pickColor.enabled}
              />
            </Label>
          </PinOptionSelector>
        )
      })}
    </PinOptionContainer>
  )
}
