import styled from "styled-components"
import { COLOR } from "utils/color"

export const RecipientIdCardContainer = styled.div`
  width: 50%;
  margin-right: 1em;
  margin-top: 2em;
`

export const RecipientIdCardTitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 20px;
  padding: 2px;
`

export const RecipientIdCardEmoji = styled.div`
  font-size: 15px;
  width: 25px;
  height: 25px;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RecipientIdCardTitle = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.9em;
  font-weight: 600;
  color: ${COLOR.DARK_GREY};
`

export const RecipientIdCardContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 5px 5px;
  font-size: 11px;
`

export const RecipientIdCardImageLink = styled.div`
  display: flex;
  padding: 5px;
  margin-top: 10px;
  img {
    flex: 1;
    max-width: 100%;
  }
`
