import { createReducer, current } from "@reduxjs/toolkit"
import { toFailure, toSuccess } from "actions"
import {
  GET_ALL_PAYMENTS,
  GET_DONE_PAYMENTS,
  GET_NEW_PAYMENTS,
  MOVE_NEW_PAYMENTS_TO_DONE,
  RESET_PAYMENTS,
  REVERT_PAYMENT,
  UPDATE_PAYMENTS,
} from "actions/payments"
import { PaymentExtended, PrepareAllPayments } from "types/payments.types"
import { WIRE_TRANSFERS_LIMIT } from "components/WireTransfers/data/constants"
import uniqBy from "lodash/uniqBy"

export interface PaymentsInitialState {
  /**
   * Consists of the 'new' and 'done' objects
   * new - list of payments with the NEW status
   * done - list of batch elements grouped by packmanBatchId
   * */
  listAll: {
    loadingRevert: boolean
    loadingMore: boolean
    error: boolean
    data: PrepareAllPayments
    moreToLoad: boolean
  }
  /** Requested payments */
  listNew: {
    loading: boolean
    error: boolean
    data: PaymentExtended[]
  }
  /** Payments with a DONE status.
   * key = ID of the batch payments related to (packmanBatchId)
   * value = list of the payments related to this batch with a DONE status
   */
  listDone: {
    loading: boolean
    error: boolean
    data: { [key: string]: PaymentExtended[] }
  }
}

const initialState: PaymentsInitialState = {
  listAll: {
    loadingRevert: false,
    loadingMore: false,
    error: false,
    data: { done: [], new: [] },
    moreToLoad: true,
  },
  listNew: {
    loading: false,
    error: false,
    data: [],
  },
  listDone: {
    loading: false,
    error: false,
    data: {},
  },
}

const payments = createReducer(initialState, {
  [GET_NEW_PAYMENTS]: state => {
    state.listNew.loading = true
  },
  [toSuccess(GET_NEW_PAYMENTS)]: (state, action) => {
    state.listNew.loading = false
    state.listNew.error = false
    state.listNew.data = action.payload.data
  },
  [toFailure(GET_NEW_PAYMENTS)]: state => {
    state.listNew.loading = false
    state.listNew.error = true
  },

  [GET_ALL_PAYMENTS]: state => {
    state.listAll.loadingMore = true
  },

  [toSuccess(GET_ALL_PAYMENTS)]: (state, action) => {
    state.listAll.loadingMore = false
    state.listAll.error = false

    /**
     * Payments with a NEW status don't have pagination; they are returned all at once.
     * */
    state.listAll.data = {
      ...state.listAll.data,
      done: uniqBy([...action.payload.data.done, ...state.listAll.data.done], "packmanBatchId"),
    }
    state.listAll.moreToLoad = action.payload.data.done.length >= WIRE_TRANSFERS_LIMIT
  },
  [toFailure(GET_ALL_PAYMENTS)]: state => {
    state.listAll.loadingMore = false
    state.listAll.error = true
  },

  [GET_DONE_PAYMENTS]: state => {
    state.listDone.loading = true
  },
  [toSuccess(GET_DONE_PAYMENTS)]: (state, action) => {
    state.listDone.loading = false
    state.listDone.error = false

    const paymentsDoneList = action.payload.data
    const lastFetchedPayment = paymentsDoneList.at(-1)

    if (lastFetchedPayment === undefined) {
      return
    }

    const existingData = state.listDone.data[lastFetchedPayment.packmanBatchId] || []

    state.listDone.data = {
      ...state.listDone.data,
      [lastFetchedPayment.packmanBatchId]: uniqBy([...existingData, ...paymentsDoneList], "id"),
    }
  },
  [toFailure(GET_DONE_PAYMENTS)]: state => {
    state.listDone.loading = false
    state.listDone.error = true
  },

  [MOVE_NEW_PAYMENTS_TO_DONE]: state => {
    state.listNew.loading = true
  },
  [toSuccess(MOVE_NEW_PAYMENTS_TO_DONE)]: state => {
    state.listNew.loading = false
    state.listNew.error = false
  },
  [toFailure(MOVE_NEW_PAYMENTS_TO_DONE)]: state => {
    state.listNew.loading = false
    state.listNew.error = true
  },

  [REVERT_PAYMENT]: state => {
    state.listAll.loadingRevert = true
  },
  [toSuccess(REVERT_PAYMENT)]: state => {
    state.listAll.loadingRevert = false
    state.listAll.error = false
  },
  [toFailure(REVERT_PAYMENT)]: state => {
    state.listAll.loadingRevert = false
    state.listAll.error = true
  },

  [RESET_PAYMENTS]: state => {
    state.listAll = initialState.listAll
    state.listDone = initialState.listDone
  },

  [UPDATE_PAYMENTS]: (state, action) => {
    const { paymentId, packmanBatchId } = action.payload

    let targetSum = 0

    /**
     * After reverting a payment, remove it from the array and retrieve its targetSum for the next processing.
     * */
    const updatedData =
      state.listDone.data[packmanBatchId]?.filter(payment => {
        current(payment)
        if (payment.id === paymentId) {
          targetSum = parseFloat((payment.amount / 100).toFixed(2))
        }
        return payment.id !== paymentId
      }) || []

    state.listDone.data = {
      ...state.listDone.data,
      [packmanBatchId]: updatedData || [],
    }

    /**
     * Update the relevant batch item by decreasing totalCount and totalSum corresponding to the removed payment.
     * */
    state.listAll.data.done = state.listAll.data.done.map(packmanBatch =>
      packmanBatch.packmanBatchId === packmanBatchId
        ? {
            ...packmanBatch,
            totalCount: packmanBatch.totalCount - 1,
            totalSum: parseFloat((packmanBatch.totalSum - targetSum).toFixed(2)),
          }
        : packmanBatch,
    )
  },
})

export default payments
