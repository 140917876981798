import { Parcel } from "types/parcel.types"
import { CarriersName } from "types/carrier.types"
import { Shipment, ShipmentType } from "types/shipment.types"

/**
 * This function returns a string
 * that consists of carrierName, parcelNo, shipmentNo (AWB), and recipientFirstName.
 *
 * Example of return: "DHL: JD014600011540676750 (2837392938 pour Frederic)",
 * where:
 * - "DHL" is the carrierName,
 * - "JD014600011540676750" is the parcelNo,
 * - "2837392938" is the shipmentNo (only for DHL),
 * - "Frederic" is the recipientFirstName.
 */
export function createParcelNoWithAdditionalInfo(parcel: Parcel): string {
  let value = ""

  const carrierName = parcel?.order?.__client__?.name || parcel?.carrierName
  if (carrierName) {
    value += `${carrierName} : `
  }

  const parcelNo = parcel?.parcelNo
  if (parcelNo) {
    value += `${parcelNo} `
  }

  const shipments = parcel?.__shipments__ || parcel?.shipments

  // search AWB for DHL
  const shipment = (shipments ?? []).find((shipment: Shipment) => {
    // first option is used to retrieve carrierName from parcels page
    // third option is used to retrieve carrierName from keeper page
    const carrierName = shipment.__carrier__?.name || shipment.carrier?.name || parcel?.order?.__client__?.name
    return carrierName === CarriersName.DHL && shipment.type === ShipmentType.DELIVERY_BTOC
  })

  const valuesInsideBraces = []

  if (shipment) {
    const awb = shipment.shipmentNo
    valuesInsideBraces.push(awb)
  }

  const recipientFirstName = parcel?.order?.recipient?.firstName
  if (recipientFirstName) {
    valuesInsideBraces.push(`pour ${recipientFirstName}`)
  }

  if (valuesInsideBraces.length) {
    value += `(${valuesInsideBraces.join(" ")})`
  }

  return value
}
