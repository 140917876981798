import styled from "styled-components"
import { COLOR } from "utils/color"

export const ParcelPartnerContainer = styled.div`
  border-radius: 10px;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 10px;
`
export const PartnerInfoDoubleColumns = styled.div`
  display: flex;
  flex-direction: row;
`
export const PartnerInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`

export const PartnerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 20px;
  padding: 2px;
`

export const PartnerEmoji = styled.div`
  font-size: 15px;
  width: 25px;
  height: 25px;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const PartnerType = styled.div`
  margin-left: 10px;
  font-size: 0.9em;
  font-weight: 600;
  color: ${COLOR.DARK_GREY};
`

export const PartnerName = styled.div`
  font-size: 1em;
  max-height: 30px;
  width: 70%;
  margin: 11px 0 5px 0;
`
export const PartnerInfo = styled.div<{ directionRow?: boolean }>`
  display: flex;
  flex-direction: ${({ directionRow }) => (directionRow ? "row" : "column")};
  justify-content: space-between;
  margin-bottom: 5px;
  flex: 1;
`

export const PartnerInfoLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.GREY};
  margin-bottom: 7px;
`

export const PartnerInfoButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 16px;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.BLACK};
  background-color: ${COLOR.ROSE};
  outline: none;
  border: none;
  border-radius: 14px;
  transition: all 300ms linear;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const PartnerLabel = styled.div`
  font-size: 0.8em;
  color: ${COLOR.LIGHT_GREY};
`

export const PartnerStatus = styled.div`
  margin-top: 5px;
  border: 1px solid ${COLOR.BLACK};
  padding: 4px;
  border-radius: 10px;
  width: fit-content;
  font-size: 9px;
  font-weight: 600;
  cursor: pointer;
  z-index: 2;

  &:hover {
    margin-top: 5px;
    border: 1px solid rgb(110, 110, 110);
    color: rgb(110, 110, 110);
    padding: 4px;
    border-radius: 10px;
    width: fit-content;
    font-size: 9px;
    font-weight: 600;
  }
`
