import React, { Fragment, ReactElement } from "react"
import Skeleton from "react-loading-skeleton"

const ParcelEventsSkeleton = (): ReactElement => {
  return (
    <Fragment>
      <div className="parcel-content parcel-content-events">
        <div className="history-events">
          <div style={{ marginTop: 20 }}>
            <Skeleton count={10} height={"24px"} style={{ lineHeight: 5 }} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ParcelEventsSkeleton
