import styled from "styled-components"

import { COLOR } from "utils/color"

export const ParcelOrderInfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`

export const ParcelOrderInfosBloc = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`

export const ParcelOrderInfosLabel = styled.div`
  font-size: 10px;
  color: ${COLOR.LIGHT_GREY};
`

export const ParcelOrderInfosData = styled.div`
  font-size: 12px;
  color: ${COLOR.BLACK};
`
