import { BillingEntry, CompanyBillingEntryDataItem, WalletEntryDataItem } from "types/billing-modal.types"
import { CompanyBillingEntryType } from "types/company-billing.types"
import { WalletEntryType } from "types/wallet-entry.types"

export const getCompanyBillingEntryData = (companyBillingData: BillingEntry[]): CompanyBillingEntryDataItem[] => {
  return [
    {
      key: 1,
      entryText: "Livraison",
      entryType: CompanyBillingEntryType.CARRIER_DELIVERY,
      isDisabled: !!companyBillingData.find(
        companyBilling =>
          companyBilling.entryType === CompanyBillingEntryType.PARCEL_CANCELLED ||
          companyBilling.entryType === CompanyBillingEntryType.CARRIER_DELIVERY,
      ),
    },
    {
      key: 2,
      entryText: "Retour",
      entryType: CompanyBillingEntryType.PARCEL_RETURN,
      isDisabled: !!companyBillingData.find(
        companyBilling => companyBilling.entryType === CompanyBillingEntryType.PARCEL_RETURN,
      ),
    },
    {
      key: 3,
      entryText: "Annulation",
      entryType: CompanyBillingEntryType.PARCEL_CANCELLED,
      isDisabled: !!companyBillingData.find(
        companyBilling =>
          companyBilling.entryType === CompanyBillingEntryType.CARRIER_DELIVERY ||
          companyBilling.entryType === CompanyBillingEntryType.PARCEL_CANCELLED,
      ),
    },
  ]
}

export const getWalletEntryData = (walletEntryData: BillingEntry[]): WalletEntryDataItem[] => {
  return [
    {
      key: 2,
      entryText: "Livraison",
      entryType: WalletEntryType.DELIVERY,
      isDisabled: !!walletEntryData.find(walletEntry => walletEntry.entryType === WalletEntryType.DELIVERY),
    },
    {
      key: 3,
      entryText: "Compensation",
      entryType: WalletEntryType.COMPENSATION,
      isDisabled: !!walletEntryData.find(walletEntry => walletEntry.entryType === WalletEntryType.COMPENSATION),
    },
  ]
}

export const getBillingEntryName = (billingEntryType: CompanyBillingEntryType): string => {
  switch (billingEntryType) {
    case CompanyBillingEntryType.CARRIER_DELIVERY:
      return "Livraison"
    case CompanyBillingEntryType.PARCEL_RETURN:
      return "Retour"
    case CompanyBillingEntryType.PARCEL_DROPOFF:
      return "Dropoff"
    case CompanyBillingEntryType.PARCEL_CANCELLED:
      return "Annulation"
  }
}

export const getWalletEntryName = (walletEntryType: WalletEntryType): string => {
  switch (walletEntryType) {
    case WalletEntryType.PENDING_RECEPTION:
      return "Réception"
    case WalletEntryType.RECEPTION:
      return "Réception"
    case WalletEntryType.DELIVERY:
      return "Livraison"
    case WalletEntryType.COMPENSATION:
      return "Compensation"
    case WalletEntryType.RETURNING:
      return "Retour"
    case WalletEntryType.DROPOFF:
      return "Dropoff"
    case WalletEntryType.SPONSOR_FIRST_DELIVERY:
      return "Parrainage - Un ami a effectué sa première livraison"
    case WalletEntryType.SPONSORED_FIRST_DELIVERY:
      return "Parrainage - Premier colis livré"
    case WalletEntryType.KEEPER_DELIVERY_SERVICE:
      return "Livraison à domicile"
    case WalletEntryType.REWARD:
      return "Reward"
    case WalletEntryType.RETURNING_BP:
      return `Retour en bureau de Poste`
  }
}
