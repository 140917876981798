import React from "react"
import { Loader } from "semantic-ui-react"
import { SemanticSIZES } from "semantic-ui-react/dist/commonjs/generic"

interface LoadingComponentProps {
  size?: SemanticSIZES
}

export const LoadingComponent = ({ size = "medium" }: LoadingComponentProps) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "2em 0",
      }}
    >
      <Loader active inline size={size} />
    </div>
  )
}
