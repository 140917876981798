import React, { ReactElement, Fragment } from "react"
import styled from "styled-components"
import { Button } from "semantic-ui-react"
import BanKAccountStatus from "components/Keeper/KeeperBankAccountStatus"

import { COLOR } from "utils/color"

const Description = styled.p`
  font-size: 13px;
`

interface WalletPaymentProps {
  balance: number
  bankAccount?: {
    isStripeValidated: boolean
    updatedAt: Date
  }
  setBankAccountStatus: (isEnabled: boolean) => void
  askKeeperWalletTransfer: () => void
  redirectToDonation: () => void
}

const WalletPayment = ({
  balance,
  bankAccount,
  setBankAccountStatus,
  redirectToDonation,
  askKeeperWalletTransfer,
}: WalletPaymentProps): ReactElement => {
  if (!bankAccount) {
    return (
      <Fragment>
        <Description>❌ Compte bancaire non créé.</Description>
        <Button onClick={redirectToDonation}>Donner à une association</Button>
      </Fragment>
    )
  } else if (!bankAccount.isStripeValidated) {
    return (
      <Fragment>
        <BanKAccountStatus
          isStripeValidated={bankAccount.isStripeValidated}
          setBankAccountStatus={setBankAccountStatus}
          updatedAt={bankAccount.updatedAt}
        />
        <Button onClick={redirectToDonation}>Donner à une association</Button>
      </Fragment>
    )
  } else if (bankAccount && bankAccount.isStripeValidated && balance < 3.5) {
    return (
      <Fragment>
        <BanKAccountStatus
          isStripeValidated={bankAccount.isStripeValidated}
          setBankAccountStatus={setBankAccountStatus}
          updatedAt={bankAccount.updatedAt}
        />
        <Description>Nous ne pouvons pas effectuer de virement pour une cagnotte de 3,5€ ou moins.</Description>
        <Button onClick={redirectToDonation}>Donner à une association</Button>
      </Fragment>
    )
  } else if (bankAccount && bankAccount.isStripeValidated && balance >= 3.5 && balance < 15) {
    return (
      <Fragment>
        <BanKAccountStatus
          isStripeValidated={bankAccount.isStripeValidated}
          setBankAccountStatus={setBankAccountStatus}
          updatedAt={bankAccount.updatedAt}
        />
        <Description style={{ color: COLOR.DARK_CORAIL, fontWeight: 600 }}>
          ⚠️ La cagnotte du Keeper est inférieur à 15€. Des frais de 2,10€ correspondant aux frais de transaction Stripe
          seront appliqués.
        </Description>
        <Button onClick={askKeeperWalletTransfer}>Transférer la cagnotte</Button>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <BanKAccountStatus
        isStripeValidated={bankAccount.isStripeValidated}
        setBankAccountStatus={setBankAccountStatus}
        updatedAt={bankAccount.updatedAt}
      />
      <Button onClick={askKeeperWalletTransfer}>Transférer la cagnotte</Button>
    </Fragment>
  )
}

export default WalletPayment
