import styled from "styled-components"

import { COLOR } from "utils/color"

export const QuickAccessChip = styled.div<{ selected?: boolean }>`
  padding: 3px 6px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 9px;
  position: relative;
  background-color: ${({ selected }) => (selected ? COLOR.MEDIUM_LIGHT_GREEN : COLOR.LIGHT_BLUE)};

  &:hover {
    background-color: ${({ selected }) => (selected ? COLOR.MEDIUM_LIGHT_GREEN : COLOR.MEDIUM_LIGHT_BLUE)};
  }
`

export const QuickAccessChipContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  margin-bottom: 0.5em;
`
