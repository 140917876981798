import React, { ReactElement } from "react"
import styled from "styled-components"

import { COLOR } from "utils/color"

const Container = styled.div<{ smallIcon: boolean }>(
  ({ smallIcon }) => `
  width: ${smallIcon ? "40px" : "56px"};
  height: ${smallIcon ? "40px" : "56px"};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(219, 219, 219, 0.2);
`,
)

const Text = styled.p<{ smallIcon: boolean }>(
  ({ smallIcon }) => `
  color: ${COLOR.DARK_GREY};
  font-size: ${smallIcon ? "16px" : "22px"};
  margin-left: 2px; /* SMALL FIX TO ICON POSITION */
`,
)

interface EmojiBackgroundProps {
  emoji: string
  smallIcon: boolean
}

function EmojiBackground({ emoji, smallIcon }: EmojiBackgroundProps): ReactElement {
  return (
    <Container smallIcon={smallIcon}>
      <Text smallIcon={smallIcon}>{emoji}</Text>
    </Container>
  )
}

export default EmojiBackground
