import { AdminUserRole } from "types/admin-user.types"
import routes from "config/routing/routes"

export const getRoutingConfig = (userRole: AdminUserRole): any => {
  const items = []

  routes
    .filter(elt => elt.environments.includes(process.env.REACT_APP_STAGE))
    .filter(elt => elt.authorizedRoles !== undefined && elt.authorizedRoles.includes(userRole))
    .forEach(category => {
      if (category.elements.length !== 0) {
        category.elements.forEach(elt => {
          if (elt.component !== undefined && (!elt.authorizedRoles || elt.authorizedRoles.includes(userRole))) {
            items.push({
              path: elt.path,
              exact: elt.exact,
              component: elt.component,
              icon: elt.icon,
              inSidebar: elt.inSidebar,
              label: elt.label,
              isOwnerOnly: elt.isOwnerOnly,
              authorizedRoles: elt.authorizedRoles || category.authorizedRoles,
            })
          }

          if (elt.children.length !== 0) {
            elt.children.forEach(subelt => {
              if (subelt.component !== undefined) {
                items.push({
                  path: subelt.path,
                  exact: subelt.exact,
                  component: subelt.component,
                  icon: subelt.icon,
                  inSidebar: subelt.inSidebar,
                  label: subelt.label,
                  isOwnerOnly: subelt.isOwnerOnly || elt.isOwnerOnly,
                  authorizedRoles: subelt.authorizedRoles || elt.authorizedRoles || category.authorizedRoles,
                })
              }
            })
          }
        })
      }
    })
  return items
}

export const getSidebarConfig = () => routes
