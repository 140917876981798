import React, { ReactElement } from "react"
import styled from "styled-components"

import { COLOR } from "utils/color"

const ParcelStatusFilterChipContainer = styled.div<{
  active?: boolean
  activeColor?: string
  inactiveColor?: string
  transparentColor?: string
}>`
  padding: 6px 12px;
  margin-right: 5px;
  border-radius: 25px;
  cursor: pointer;
  color: ${({ active, activeColor }) => (active ? COLOR.WHITE : activeColor)};
  background-color: ${({ active, activeColor, inactiveColor }) => (active ? activeColor : inactiveColor)};
  font-size: 11px;
  position: relative;
`

interface ParcelStatusFilterChipProps {
  text: string
  onClick: () => void
  activeColor: string
  inactiveColor: string
  active: boolean
  number?: number
}

export function ParcelStatusFilterChip({
  text,
  onClick,
  activeColor,
  inactiveColor,
  active,
  number,
}: ParcelStatusFilterChipProps): ReactElement {
  return (
    <ParcelStatusFilterChipContainer
      active={active}
      activeColor={activeColor}
      inactiveColor={inactiveColor}
      onClick={onClick}
    >
      {text}
      {number !== undefined && <b style={{ marginLeft: 5 }}>{number}</b>}
    </ParcelStatusFilterChipContainer>
  )
}
