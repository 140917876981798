import React, { Component } from "react"
import { connect } from "react-redux"
import { Button } from "semantic-ui-react"

import { LoadingComponent } from "components/LoadingComponent"
import Title from "components/Title"
import { PageWrapper } from "components/Layout/Structure"
import RatingStats from "components/Ratings/RatingsStats"
import RatingSort from "components/Ratings/RatingsSort"
import RatingsList from "components/Ratings/RatingsList"
import { apiGetRatings, resetRatings } from "services/ratings"
import { RatingsData } from "types/ratings.types"

interface RatingsProps {
  apiGetRatings: ({ take, skip, onlyLowRating }) => void
  resetRatings: () => void
  list: { loading: boolean; onlyLowRating: boolean; data: RatingsData }
}

interface RatingsState {
  take: number
  skip: number
  onlyLowRating: boolean
  moreToLoad: boolean
}

class Ratings extends Component<RatingsProps, RatingsState> {
  constructor(props) {
    super(props)

    this.state = {
      take: 20,
      skip: 0,
      moreToLoad: true,
      onlyLowRating: false,
    }
  }

  componentDidMount() {
    this.props.resetRatings()
    this.fetchNext(this.props.list.onlyLowRating || false)
  }

  fetchNext = async onlyLowRating => {
    const { skip, take } = this.state

    await this.props.apiGetRatings({ skip, take, onlyLowRating })
    if (
      this.props.list.data.ratings.length === this.state.skip ||
      this.props.list.data.ratings.length < this.state.take
    ) {
      this.setState({ moreToLoad: false })
    } else {
      this.setState({ skip: this.props.list.data.ratings.length })
    }
  }

  handleOnlyLowRating = async () => {
    await this.props.resetRatings()
    this.setState({ take: 20, skip: 0, moreToLoad: true }, () => {
      this.fetchNext(!this.state.onlyLowRating)
      this.setState({ onlyLowRating: !this.state?.onlyLowRating })
    })
  }

  getNps = ratings =>
    `${Math.round(
      (ratings.filter(i => i.rate === 5).length / ratings.length -
        ratings.filter(i => [1, 2, 3].includes(i.rate)).length / ratings.length) *
        100,
    )}`

  getAverage = ratings => Math.round(Number(ratings.reduce((acc, i) => acc + i.rate, 0) / ratings.length) * 100) / 100

  render() {
    const { list } = this.props
    const { data } = list
    const { moreToLoad } = this.state
    const ratings = data?.ratings

    const stats = {
      nps: data?.npsGlobal,
      average: data?.rateAverageGlobal,
      total: ratings?.length,
      npsMonth: data?.npsMonth,
      npsWeek: data?.npsWeek,
    }

    if (ratings === undefined) return <LoadingComponent />

    return (
      <PageWrapper>
        <Title>Ratings</Title>

        <RatingStats stats={stats} loading={list.loading} />
        <RatingSort onlyLowRating={this.state.onlyLowRating} handleOnlyLowRating={this.handleOnlyLowRating} />
        <RatingsList ratings={ratings} loading={list.loading} total={ratings.length} />
        {list.loading && <LoadingComponent />}
        {moreToLoad && (
          <Button style={{ marginTop: 10, width: 200 }} onClick={() => this.fetchNext(this.state.onlyLowRating)}>
            Charger plus
          </Button>
        )}
      </PageWrapper>
    )
  }
}

const mapStateToProps = state => ({
  list: state.ratings.list,
})

const mapDispatchToProps = {
  apiGetRatings,
  resetRatings,
}

export default connect(mapStateToProps, mapDispatchToProps)(Ratings)
