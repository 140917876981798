import React, { ReactElement, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import { PageWrapper } from "components/Layout/Structure"
import Title from "components/Title"
import SimpleTable from "components/Table/SimpleTable"
import { LoadingComponent } from "components/LoadingComponent"
import { AssociationButtonDetail } from "components/Association/Association.styled"
import { apiGetAssociations } from "services/associations"
import { ReduxState } from "types/reduxState.types"

export function AssociationsList(): ReactElement {
  const associations = useSelector((state: ReduxState) => state.associations)
  const associationsList = associations?.list?.data

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(apiGetAssociations())
  }, [])

  if (associations.list.loading) return <LoadingComponent />

  return (
    <PageWrapper>
      <Title>Associations</Title>

      {associationsList && associationsList.length > 0 ? (
        <SimpleTable
          headers={[
            {
              name: "Nom",
              size: 2,
              value: "name",
            },
            {
              name: "Description",
              size: 6,
              value: "descriptionText",
            },
            {
              name: "Image",
              size: 1,
              value: "imageUrl",
              component: img => (img ? <img className="association-image" src={img} /> : "Aucune image"),
            },
            {
              name: "Site web",
              size: 1,
              value: "websiteUrl",
              component: url =>
                url ? (
                  <a href={url} target="_blank" rel="noreferrer">
                    <div className="content">Lien 🖥</div>
                  </a>
                ) : (
                  "Aucune site web"
                ),
            },
            {
              name: "Don total",
              size: 1,
              value: "totalDonations",
              component: totalDonations => (totalDonations ? `${totalDonations / 100} €` : "Aucun Don"),
            },
            {
              name: "Détails",
              size: 1,
              value: "id",
              component: id => (
                <Link to={`/associations/${id}`}>
                  <AssociationButtonDetail>Détails</AssociationButtonDetail>
                </Link>
              ),
            },
          ]}
          data={associationsList}
          lineHeight="unset"
        />
      ) : (
        <div className="no-content">Aucune association</div>
      )}
    </PageWrapper>
  )
}

export default AssociationsList
