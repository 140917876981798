import { API_GET_GENERATED_ORDERS, RESET_GENERATED_ORDERS } from "../actions/generated-orders"

const createBaseUrl = (companyId: string) => `/admin/companies/${companyId}/orders`

export function apiGetGeneratedOrders(companyId: string, startDate: Date, endDate: Date) {
  return {
    type: API_GET_GENERATED_ORDERS,
    payload: {
      request: {
        url: `${createBaseUrl(companyId)}?createdAfter=${startDate}&createdBefore=${endDate}`,
      },
    },
  }
}

export function resetGeneratedOrders() {
  return {
    type: RESET_GENERATED_ORDERS,
  }
}
